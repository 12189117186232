import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router";
import "./App.css";
import {BrowserRouter as Router,Routes,Route,useLocation} from "react-router-dom";

import Login from "./Screens/UserRegistration/Login";

import SignUp from "./Screens/UserRegistration/SignUp";
import ForgotPassword from "./Screens/UserRegistration/ForgotPassword";
import MobileVerification from "./Screens/UserRegistration/MobileVerification";
import EmailVerification from "./Screens/UserRegistration/EmailVerification";
import PersonalDetails from "./Screens/FreelancerProfile/PersonalDetails";
import AboutUs from "./Screens/AboutUs";
import ContactUs from "./Screens/ContactUs";

import RIHeaderMobile from "./Components/RIHeaderMobile";

import ReportaBug from "./Screens/ReportaBug";

import TermsConditions from "./Screens/OtherPages/TermsConditions";

import PrivacyPolicy from "./Screens/OtherPages/PrivacyPolicy";

import SocialLogin from "./Screens/UserRegistration/SocialLogin";

import ForgotPasswordPreScreen from "./Screens/UserRegistration/ForgotPasswordPreScreen";
import LoginWithOTP from "./Screens/UserRegistration/LoginWithOTP";
import VerifyOTP from "./Screens/UserRegistration/VerifyOTP";
import FreelancerHomePage from "./Screens/FreelancerHomePage/FreelancerHomePage";
import VerifyEmailOTP from "./Screens/UserRegistration/VerifyEmailOTP";
import FreelancerHomePageWeb from "./Screens/FreelancerHomePage/FreelancerHomePageWeb";
import WorkProfile from "../src/Screens/FreelancerProfile/WorkProfile";
import FreelancerProfileMain from "./Screens/ClientFreelancerProfile/FreelancerProfileMain";

import KycForm from "./Screens/KycForm/KycForm";

import ExploreSkills from "./Screens/ExploreSkillsScreen/ExploreSkills";

import ArticlesRozgaar from "./Screens/OtherPages/ArticlesRozgaar";
import ArticlesRozgaarWeb from "./Screens/OtherPages/ArticlesRozgaarWeb";

import PublicClientRequirementList from "./Screens/ApplyRequirements/PublicClientRequirementList";
import PublicClientRequirementDetail from "./Screens/ApplyRequirements/PublicClientRequirementDetail";
import CreateProposal from "./Screens/ApplyRequirements/CreateProposal";
import FrequentlyAskedQuestions from "./Screens/FAQ/FrequentlyAskedQuestions";
import MyApplications from "./Screens/MyApplications";


import FreelancerMembership from "./Screens/FreelancerMembership";

import WorkPlace from "./Screens/FreelancerWorkplace/WorkPlace";

import NotificationDashboard from "./Screens/OtherPages/NotificationDashboard";
import { UserContext } from "./Context/UserContext";
import DashboardContact from "./Screens/FreelancerDashboard/DashboardContact";

import Income from "./Screens/FreelancerDashboard/Income";
import ProfessionAndEducation from "./Screens/FreelancerProfile/ProfessionAndEducation";
import Portfolio from "./Screens/FreelancerProfile/Portfolio";
import CompanyDetails from "./Screens/FreelancerProfile/CompanyDetails";
import RazorPayGateway from "./Screens/RazorPayPaymentGateway/RazorPayGateway";
import GatewayPaymentStatus from "./Screens/RazorPayPaymentGateway/GatewayPaymentStatus";
import HowToHire from "./Screens/HowToHire/HowToHire";
import HowToHireWeb from "./Screens/HowToHire/HowToHireWeb";
import FutureWork from "./Screens/FutureOfWork/FutureWork";
import ReactGA from 'react-ga';
import jwt from "jwt-simple";
import PackageOption from "./Screens/FreelancerPackage/PackageOption";

import WorkRequirementDashboard from "./Screens/FreelancerWorkplace/WorkRequirementDashboard";
import { pageViewTracker } from "./Components/GoogleTracking";
import RIFooter from "./Components/RIFooter";
const App = () => {
  global.apiLink = "https://api-preview.rozgaarindia.com";
  global.apiLink8000 = "http://api-preview.rozgaarindia.com:8000";
  global.apiLink8001 = "http://api-preview.rozgaarindia.com:8001";
  global.chatLink = "https://chat.rozgaarindia.com";

  const [width, setWidth] = useState(window.innerWidth);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const value = useMemo(
    () => ({ isUserLoggedIn, setIsUserLoggedIn }),
    [isUserLoggedIn, setIsUserLoggedIn]
  );
  ReactGA.initialize('UA-109083878-3', {
    debug: true,
    titleCase: false,
    gaOptions: {
      userId: 123
    }
  });
  ReactGA.pageview(window.location.pathname + window.location.search);
  useEffect(() => {
   pageViewTracker();
  }, [])
  

  useEffect(() => {


    localStorage.getItem("userLoggedIn")
      ? setIsUserLoggedIn(true)
      : setIsUserLoggedIn(false);
  }, []);

  console.log("this", width);
  useEffect(() => {
    const windowWidth = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", windowWidth);

    return () => {
      window.removeEventListener("resize", windowWidth);
    };
  }, []);

  return width > 1023 ? (
    <Router>
      <UserContext.Provider value={value}>
        <ScrollToTop />
        <RIHeaderMobile type={"web"} />
        <div className="screeWidth">
          <Routes>
            <Route path="/" element={<FreelancerHomePageWeb />} />
            <Route path="/signup" element={<SignUp />} />
            <Route
              path="/personalDetails"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <PersonalDetails /> : <></>}
                </>
              }
            />

            <Route
              path="/workProfile"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <WorkProfile /> : <></>}
                </>
              }
            />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/personalDetails" element={<PersonalDetails />} />
            <Route
              path="/professionAndEducation"
              element={<ProfessionAndEducation />}
            />
            <Route path="/companyDetails" element={<CompanyDetails />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route
              path="/mobileverification"
              element={<MobileVerification />}
            />
           
            <Route path="/emailverification" element={<EmailVerification />} />
           
            <Route
              path="/contactus"
              element={<ContactUs />}
            />
            <Route
              path="/aboutUs"
              element={<AboutUs />}
            />
           

            <Route
              path="/reportabug"
              element={<ReportaBug />}
            />
            <Route
              path="/termsConditions"
              element={<TermsConditions />}
            />
            <Route
              path="/privacyPolicy"
              element={<PrivacyPolicy />}
            />
            <Route
              path="/notification"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <NotificationDashboard /> : <></>}
                </>
              }
            />
            <Route path="/SocialLogin" element={<SocialLogin />} />
            <Route
              path="/ForgotPasswordPreScreen"
              element={<ForgotPasswordPreScreen />}
            />
            <Route path="/loginWithOTP" element={<LoginWithOTP />} />
            <Route path="/verifyOTP" element={<VerifyOTP />} />
            
            <Route path="/verifyEmailOTP" element={<VerifyEmailOTP />} />

            <Route
              path="/freelancerProfile"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <FreelancerProfileMain /> : <></>}
                </>
              }
            />
          
            <Route
              path="/WorkRequirementDashboard"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <WorkRequirementDashboard /> : <></>}
                </>
              }
            />
            <Route
              path="/requirementList"
              element={<PublicClientRequirementList />}
            />
            <Route
              path="/requirementDetail/:reqId"
              element={

                <PublicClientRequirementDetail />

              }
            />

            <Route
              path="/KycForm"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <KycForm /> : <></>}
                </>
              }
            />
          
            <Route
              path="/articlesRozgaar/:reqId"
              element={<ArticlesRozgaarWeb />}
            />
           

            
            <Route
              path="/ExploreSkills"
              element={<ExploreSkills />}
            />

            <Route
              path="/FreelancerMemberShip"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <FreelancerMembership /> : <></>}
                </>
              }
            />
            <Route
              path="/createProposal/:requirementId"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <CreateProposal /> : <></>}
                </>
              }
            />


            <Route
              path="/FreelancerWorkPlace"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <WorkPlace /> : <></>}
                </>
              }
            />
           
            <Route
              path="/FAQ"
              element={
               
                  
                 <FrequentlyAskedQuestions /> 
                
              }
            />
            <Route
              path="/DashboardContact"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <DashboardContact /> : <></>}
                </>
              }
            />
            <Route path="/payment/:paymentType/:orderId" element={<RazorPayGateway />} />
            <Route
              path="/freelancerMemebership"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <FreelancerMembership /> : <></>}
                </>
              }
            />
            <Route
              path="/FAQ"
              element={
               
                  <FrequentlyAskedQuestions /> 
               
              }
            />
            <Route
              path="/myApplications"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <MyApplications /> : <></>}
                </>
              }
            />

            <Route
              path="/DashboardContact"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <DashboardContact /> : <></>}
                </>
              }
            />
        
            <Route path="/GatewayPaymentStatus/:orderId" element={<GatewayPaymentStatus />} />

            <Route
              path="/HowToHire"
              element={<HowToHireWeb />}
            />
            <Route
              path="/FutureWork"
              element={<FutureWork />}
            />
            <Route path="/Income" element={<Income />} />
            <Route path="/FreelancerPackage" element={<PackageOption />} />
          </Routes>
        </div>
        <RIFooter/>
      </UserContext.Provider>
    </Router>
  ) : (
    <Router>
      <UserContext.Provider value={value}>
        <ScrollToTop />
        <RIHeaderMobile />
        <div>
          <Routes>
            <Route path="/" element={<FreelancerHomePage />} />

            <Route path="/signup" element={<SignUp />} />

            <Route path="/login" element={<Login />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route
              path="/mobileverification"
              element={<MobileVerification />}
            />
            <Route path="/emailverification" element={<EmailVerification />} />
           
            <Route
              path="/FAQ"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <FrequentlyAskedQuestions /> : <></>}
                </>
              }
            />
           
            <Route
              path="/contactus"
              element={<ContactUs />}
            />
            <Route
              path="/aboutUs"
              element={<AboutUs />}
            />
           
            <Route
              path="/reportabug"
              element={<ReportaBug />}
            />
            <Route
              path="/termsConditions"
              element={<TermsConditions />}
            />
            <Route
              path="/notification"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <NotificationDashboard device={"Mobile"} /> : <></>}
                </>
              }
            />
            <Route
              path="/WorkRequirementDashboard"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <WorkRequirementDashboard device={"Mobile"} /> : <></>}
                </>
              }
            />
            <Route
              path="/privacyPolicy"
              element={<PrivacyPolicy />}
            />
            <Route path="/SocialLogin" element={<SocialLogin />} />
            <Route
              path="/ForgotPasswordPreScreen"
              element={<ForgotPasswordPreScreen />}
            />
            <Route path="/loginWithOTP" element={<LoginWithOTP />} />
            <Route path="/verifyOTP" element={<VerifyOTP />} />
            <Route path="/verifyEmailOTP" element={<VerifyEmailOTP />} />
            
            <Route
              path="/workProfile"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <WorkProfile device={"Mobile"} /> : <></>}
                </>
              }
            />
            <Route
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <Portfolio device={"Mobile"} /> : <></>}
                </>
              }
              path="/portfolio"
            />
            <Route
              path="/personalDetails"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? (
                    <PersonalDetails device={"Mobile"} />
                  ) : (
                    <></>
                  )}
                </>
              }
            />
            <Route
              path="/professionAndEducation"
              element={<ProfessionAndEducation device={"Mobile"} />}
            />
            <Route
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <CompanyDetails device={"Mobile"} /> : <></>}
                </>
              }
              path="/companyDetails"
            />
            <Route
              path="/freelancerProfile"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? (
                    <FreelancerProfileMain device={"Mobile"} />
                  ) : (
                    <></>
                  )}
                </>
              }
            />
           
            <Route
              path="/KycForm"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <KycForm device={"Mobile"} /> : <></>}
                </>
              }
            />
           
            <Route
              path="/articlesRozgaar/:reqId"
              element={<ArticlesRozgaar />}
            />
        
            
            <Route
              path="/requirementList"
              element={<PublicClientRequirementList device={"Mobile"} />}
            />
            <Route
              path="/requirementDetail/:reqId"

              element={

                <PublicClientRequirementDetail device={"Mobile"} />

              }
            />
            <Route
              path="/ExploreSkills"
              element={<ExploreSkills device={"Mobile"} />}
            />
            <Route
              path="/freelancerMemebership"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? (
                    <FreelancerMembership device={"Mobile"} />
                  ) : (
                    <></>
                  )}
                </>
              }
            />
            <Route
              path="/createProposal/:requirementId"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <CreateProposal /> : <></>}
                </>
              }
            />
            <Route
              path="/FreelancerWorkPlace"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? <WorkPlace device={"Mobile"} /> : <></>}
                </>
              }
            />
           
            <Route
              path="/DashboardContact"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? (
                    <DashboardContact device={"Mobile"} />
                  ) : (
                    <></>
                  )}
                </>
              }
            />
            
            <Route
              path="/freelancerMemebership"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? (
                    <FreelancerMembership device={"Mobile"} />
                  ) : (
                    <></>
                  )}
                </>
              }
            />
            <Route
              path="/myApplications"
              element={
                <>
                  <PublicRedirect />
                  {isUserLoggedIn ? (
                    <MyApplications device={"Mobile"} />
                  ) : (
                    <></>
                  )}
                </>
              }
            />


            <Route
              path="/HowToHire"
              element={<HowToHire />}
            />
            <Route
              path="/FutureWork"
              element={<FutureWork />}
            />
            <Route path="/Income" element={<Income device={"Mobile"} />} />

            <Route path="/payment/:paymentType/:orderId" element={<RazorPayGateway />} />

            <Route path="/GatewayPaymentStatus/:orderId" element={<GatewayPaymentStatus />} />
            <Route path="/FreelancerPackage" element={<PackageOption device={"Mobile"} />} />
          </Routes>
        </div>
        <RIFooter/>
      </UserContext.Provider>
    </Router>
  );
};

export const encodeAndSendToChat = (senderId, recieverId) => {

  var payload = {
    senderId: senderId,
    recieverId: recieverId || "",
    userType: "freelancer",
  };
  var secret = "5wXnlnG7uE";
  var token = jwt.encode(payload, secret);
  window.open(`${global.chatLink}/chat?token=${token}`);
};
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const PublicRedirect = () => {
  let navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("userLoggedIn")) {
      navigate("/login");
    }


  }, []);
  return <></>;
};

export default App;
