import HeadingFreelancer from "./HeadingFreelancer";
import classes from './FutureOfWorkFreelancer.module.css'
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";


const FutureOfWorkFreelancer = () =>{

 
    return(
<div className={classes.mainContainer}> 
<div className={classes.imageAndContent}>
<div className={classes.webImageAlignment}> 
<img src="./assets/FreelancerAssets/banners/FutureOfWork.png" alt="The_Future_Of_Work_Rozgaar" title="The_Future_Of_Work_Rozgaar" loading="lazy" width={"100%"} height={350}/>
</div>
<div className={classes.webContentArea}> 
<HeadingFreelancer heading="The Future Of Work." />
<div className={classes.contentForFuture}> We are on a mission to revolutionise freelancing. We match professional freelancers with the top freelancing requirements, making remote work more efficient than ever before. A platform to find the right work for you, with great clients, and get paid securely. </div>
<div className={classes.KnowMoreTab}><Link to="/FutureWork" className={classes.knowmoreClick}>Know More <AiOutlineArrowRight className={classes.iconArrow}/></Link></div> 
</div>

</div>
<div className={classes.imageAlignment}> 
<img src="./assets/FreelancerAssets/banners/FutureOfWork.png" alt="The_Future_Of_Work_Rozgaar" title="The_Future_Of_Work_Rozgaar" width={"60%"} height={250} loading="lazy"/>
</div>
</div>
    )
}

export default FutureOfWorkFreelancer;