import React from "react";

import classes from "./RIModal.module.css";
import { BsCheckLg } from "react-icons/bs";
import ActionButton from "../ActionButton";


const RIModal = (props) => {

  const Backdrop = () => {
    return <div className={classes.backdrop} />;
  };
  return (
    <>
      <Backdrop />
      <div className={classes.modalContainer}>
        <div className={classes.modal}>
          <div className={classes.ModalCheckIcon}>
            <BsCheckLg className={classes.modalcheck} />
          </div>
          <div className={classes.ModalCheckTextFirst}>
            {props.Heading}
          </div>
          <div className={classes.ModalCheckTextSecond}>
            {props.Text}
          </div>
          <div className={classes.ActionBtn}>
            <ActionButton
              buttonType={"small"}
              buttonText={"Ok"}
              onClick={() =>
                props.onClick()
              }
            />

          </div>
        </div>
      </div>
    </>
  );
};

export default RIModal;
