import React, { useState, useEffect, useRef } from "react";
import classes from "./PersonalDetails.module.css";
import { useNavigate } from "react-router-dom";
import Loader from "react-loader-spinner";
import { BsCameraFill, BsPlusCircle, BsPlusCircleFill } from "react-icons/bs";
import { AiTwotoneDelete } from "react-icons/ai";
import { Helmet } from "react-helmet";
import { FaTwitter, FaLinkedin, FaGlobe } from "react-icons/fa";
import NewFreelancerDashBoardMobile from "../../Components/NewFreelancerDashBoard/NewFreelancerDashBoardMobile";
import Tab from "../../Components/FreelancerProfile/EditFreelancerProfile/Tab";
import NewFreelancerDashBoard from "../../Components/NewFreelancerDashBoard/NewFreelancerDashboard";
import TopAlertNotification from "../../Components/FreelancerProfile/TopAlertNotification";
import ProfileFormErrors from "../../Components/FreelancerProfile/EditFreelancerProfile/ProfileFormErrors";
import { pageViewTracker } from "../../Components/GoogleTracking";
const ActionButton = React.lazy(() => import("../../Components/ActionButton"));
const RiTextInputs = React.lazy(() =>
  import("../../Components/PostRequirement/RiTextInputs")
);
const genderData = [
  {
    type: "Male",
  },
  {
    type: "Female",
  },
  {
    type: "Other",
  },
];

const PersonalDetails = (props) => {
  const userId = localStorage.getItem("userID");
  const dobRef = useRef(null);
  const locationRef = useRef(null);
  const todayDate = new Date().toISOString().slice(0, 10);
  const [profilePicture, setProfilePicture] = useState();
  const [name, setName] = useState({ firstName: "", lastName: "" });
  const [userName, setUserName] = useState("");
  const [gender, setGender] = useState("Male");
  const [mobile, setMobile] = useState("");
  const [dob, setDob] = useState("");
  const [error, setError] = useState({});
  const [email, setEmail] = useState("");
  const [alternateEmail, setAlternateEmail] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [country, setCountry] = useState("India");
  const [sameAsAbove, setSameAsAbove] = useState(false);
  const [state, setState] = useState("");
  const [alternateDialCode, setAlternateDialCode] = useState("+91");
  const [cityLoading, setCityLoading] = useState(false);
  const [cityData, setCityData] = useState([]);
  const [isDobValid, setIsDobValid] = useState(true);

  const [social, setSocial] = useState({
    website: "",
    twitter: "",
    linkedIn: "",
  });
  const [city, setCity] = useState([]);
  const [pinCode, setPinCode] = useState("");
  const [loading, setLoading] = useState("");
  const [isTrue, setIsTrue] = useState({
    alternateEmail: false,
    whatsapp: false,
  });
  const [profileCompletionStatus, setProfileCompletionStatus] = useState("");
  const [whatsAppDialCode, setWhatsAppDialCode] = useState("+91");
  const [isMobile, setIsMobile] = useState("");
  const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;

  let navigate = useNavigate();

  useEffect(() => {
    pageViewTracker();
  }, [])


  useEffect(() => {
    GetFreelancerProfile();
    window.scrollTo(0, 0);
  }, []);

  const GetFreelancerProfile = (props) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );

    var formdata = new FormData();
    formdata.append("FreelancerId", userId);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(global.apiLink + "/api/rozgaarapi/FreelancerProfile", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setProfileCompletionStatus(
            result.FreelancerProfileresponse.ProfilePercentComplete
          );
          setName({
            firstName: result.data.FirstName,
            lastName: result.data.LastName,
          });
          setGender(result.data.Gender);
          setDob(result.data.DOB);
          setMobile(result.data.Mobile);
          setIsMobile(result.data.Mobile);
          isTrue.whatsapp = result.data.WhatsappMobile ? true : false;
          setWhatsapp(result.data.WhatsappMobile);
          result.data.Mobile === result.data.WhatsappMobile &&
            setSameAsAbove(true);
          setProfilePicture(result.data.ProfilePicture);
          setEmail(result.data.Email);
          isTrue.alternateEmail = result.data.AlternateEmail ? true : false;
          setAlternateEmail(result.data.AlternateEmail);
          setCountry(result.data.Country);
          setAlternateDialCode(result.data.CountryCode);
          setState(result.data.State);
          cityList(result.data.Country, result.data.State);
          setCity(result.data.City);
          setPinCode(result.data.Pincode);
          setUserName(result.data.FirstName + " " + result.data.LastName);
          setWhatsAppDialCode(result.data.CountryCodeWN);
          result.data.SocialMedia.map((item) => {
            switch (item.Name) {
              case "Website":
                return (social.website = item.URL);
              case "LinkedIn":
                return (social.linkedIn = item.URL);
              case "Twitter":
                return (social.twitter = item.URL);
              default:
                break;
            }
          });
        } else {
          alert("Sorry! Failed to fetch data.");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const dobInTheFuture = (e) => {
    setDob(e.target.value);
    const today = new Date();
    const selectedDate = new Date(e.target.value);
    if (selectedDate > today) {
      return setIsDobValid(false);
    } else {
      return setIsDobValid(true);
    }
  };

  const formValidation = () => {
    let errors = {};
    let isValid = true;

    if (country === "") {
      errors.country = "Please select your country !";
      isValid = false;
      locationRef.current.scrollIntoView();
    }
    if (state === "") {
      errors.state = "Please select your state!";
      isValid = false;
      locationRef.current.scrollIntoView();
    }
    if (city === "") {
      errors.city = "Please select your city!";
      isValid = false;
      locationRef.current.scrollIntoView();
    }
    if (!pinCode || pinCode === "" || pinCode.length !== 6) {
      errors.pinCode = "Please enter your valid pincode !";
      isValid = false;
      locationRef.current.scrollIntoView();
    }
    if (mobile && mobile.length !== 10) {
      errors.mobile = "Invalid Mobile !";
      isValid = false;
    }
    if (whatsapp && whatsapp.length !== 10) {
      errors.whatsapp = "Invalid Whatsapp number !";
      isValid = false;
    }
    if (email && !emailRegex.test(email)) {
      errors.email = "Invalid Email !";
      isValid = false;
    }
    if (alternateEmail && !emailRegex.test(alternateEmail)) {
      errors.alternateEmail = "Invalid Alternative email !";
      isValid = false;
    }
    // if (dob === "") {
    //   errors.dob = "Please enter date of birth !";
    //   isValid = false;
    //   dobRef.current.scrollIntoView();
    // }
    if (!isDobValid) {
      errors.dob = "Please entered valid date";
      isValid = false;
    }
    if (name.firstName === "") {
      errors.firstName = "Firstname can't be empty !";
      isValid = false;
      window.scrollTo(0, 0);
    }
    if (name.lastName === "") {
      errors.lastName = "Lastname can't be empty !";
      isValid = false;
      window.scrollTo(0, 0);
    }

    setError({ ...error, ...errors });

    if (isValid) {
      SendFreelancerProfileAPI();
    }
  };

  
  const SocialLinksArray = () => {
    let socialArray = [];
    social.website &&
      socialArray.push({
        Name: "Website",
        URL: social.website,
        Type: "Website",
      });
    social.linkedIn &&
      socialArray.push({
        Name: "LinkedIn",
        URL: social.linkedIn,
        Type: "Social",
      });
    social.twitter &&
      socialArray.push({
        Name: "Twitter",
        URL: social.twitter,
        Type: "Social",
      });
    return JSON.stringify(socialArray === [] ? [{}] : socialArray);
  };

  const SendFreelancerProfileAPI = () => {
    setLoading("SendFreelancerProfile");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );

    var formdata = new FormData();
    formdata.append("FreelancerId", userId);
    typeof profilePicture === "object" &&
      formdata.append("ProfilePicture", profilePicture);
    formdata.append("FirstName", name.firstName);
    formdata.append("LastName", name.lastName);
    formdata.append("Gender", gender);
    formdata.append("DOB", dob);
    formdata.append("Mobile", mobile);
    formdata.append("WhatsappMobile", whatsapp);
    formdata.append("Email", email);
    formdata.append("AlternateEmail", alternateEmail);
    formdata.append("Country", country);
    formdata.append("State", state);
    formdata.append("City", city);
    formdata.append("Pincode", pinCode);
    formdata.append("AlternateEmail", alternateEmail);
    formdata.append("SocialProfile", SocialLinksArray());
    formdata.append("CountryCode ", alternateDialCode || "+91");
    formdata.append("CountryCodeWN ", whatsAppDialCode || "+91");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(global.apiLink + "/api/rozgaarapi/FreelancerProfile", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          navigate("/professionAndEducation");
        } else {
          alert("Sorry! Failed to submit data.");
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(""));
  };

  const handleState = (e) => {
    error.state = "";
    setState(e.target.value);
    cityList(country, e.target.value);
  };

  useEffect(() => {
    sameAsAbove && setWhatsapp(mobile);
  }, [sameAsAbove]);

  const cityList = (country, state) => {
    setCityLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Country: country,
      State: state,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/api/rozgaarapi/GetCityLocatinList", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setCityData(result.data);
        } else {
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setCityLoading(false);
      });
  };

  const WebOrMobileSwitch = (device) => {
    switch (device) {
      case "Mobile":
        return (
          <NewFreelancerDashBoardMobile>
            <Tab />
            <div className={classes.Maincontainer_border}>
              <div className={classes.web_view}>
                <TopAlertNotification
                  profileCompletion={profileCompletionStatus}
                />
                <div className={classes.mainContainer}>
                  <div className={classes.input_container}>
                    <div className={classes.profilePhoto}>
                      {profilePicture ? (
                        <div className={classes.updatetImageContainer}>
                          <div>
                            <img
                              src={
                                typeof profilePicture === "string"
                                  ? profilePicture
                                  : URL.createObjectURL(profilePicture)
                              }
                              alt={userName}
                              title={userName}
                              loading="lazy"
                              height={120}
                              width={120}
                              className={classes.updateImage}
                            />
                          </div>

                          <div className={classes.uploadButtonContainer}>
                            <div className={classes.imageEditIcon}>
                              <AiTwotoneDelete
                                className={classes.uploadIcon}
                                onClick={() => setProfilePicture("")}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={classes.uploadContainer}>
                          <div>
                            <label
                              className={classes.form_upload_label}
                              for="upload"
                            >
                              <input
                                type="file"
                                id="upload"
                                hidden
                                accept=".png, .jpg, .jpeg"
                                onChange={(e) => {
                                  setProfilePicture(e.target.files[0]);
                                }}
                              />
                              <BsCameraFill className={classes.uploadIcon} />
                            </label>
                          </div>
                        </div>
                      )}
                      <div className={classes.uploadLogoText}>
                        <label>{userName}</label>
                      </div>
                    </div>
                    <RiTextInputs
                      input={"SingleLineInput"}
                      type={"text"}
                      placeHolder={"First Name"}
                      value={name.firstName}
                      onChange={(e) =>
                        setName({ ...name, firstName: e.target.value })
                      }
                      onInput={() => {
                        error.firstName = "";
                      }}
                    />

                    {error.firstName && <ProfileFormErrors errorMessage={error.firstName} />}
                    <RiTextInputs
                      input={"SingleLineInput"}
                      type={"text"}
                      placeHolder={"Last Name"}
                      value={name.lastName}
                      onChange={(e) =>
                        setName({ ...name, lastName: e.target.value })
                      }
                      onInput={() => {
                        error.lastName = "";
                      }}
                    />

                    {error.lastName && <ProfileFormErrors errorMessage={error.lastName} />}
                    <RiTextInputs
                      input={"SingleLineInput"}
                      type={"email"}
                      onInput={() => {
                        error.email = "";
                      }}
                      placeHolder={"Enter Email"}
                      disabled={true}
                      onChange={(e) => {
                        let keyword = e.target.value.toLowerCase();
                        var re = /^[a-z@A-Z.0-9_]*$/;
                        if (keyword === "" || re.test(keyword)) {
                          setEmail(keyword);
                        }
                      }}
                      value={email}
                    />

                    {error.email && <ProfileFormErrors errorMessage={error.email} />}
                    <div
                      onClick={() =>
                        setIsTrue({
                          ...isTrue,
                          alternateEmail: !isTrue.alternateEmail,
                        })
                      }
                      className={classes.alternateFieldContainer}
                    >
                      {isTrue.alternateEmail ? (
                        <BsPlusCircleFill size={18} />
                      ) : (
                        <BsPlusCircle size={18} />
                      )}
                      <div className={classes.aternateFieldText}>
                        Alternative Email
                      </div>
                    </div>
                    {isTrue.alternateEmail && (
                      <>
                        <RiTextInputs
                          input={"SingleLineInput"}
                          type={"email"}
                          onInput={() => {
                            error.alternateEmail = " ";
                          }}
                          placeHolder={"Enter Alternate Email"}
                          onChange={(e) => {
                            let keyword = e.target.value.toLowerCase();
                            var re = /^[a-z@A-Z.0-9_]*$/;
                            if (keyword === "" || re.test(keyword)) {
                              setAlternateEmail(keyword);
                            }
                          }}
                          value={alternateEmail}
                        />
                        <div className={classes.error_message}>
                          {error.alternateEmail}
                        </div>
                      </>
                    )}
                    <div
                      className={
                        isMobile === ""
                          ? classes.disable
                          : classes.disabledInput
                      }
                    >
                      <RiTextInputs
                        input={"mobile"}
                        maxLength="10"
                        minLength="10"
                        onInput={() => {
                          error.mobile = "";
                        }}

                        onDialCodechange={(e) => {
                          setAlternateDialCode(e.target.value);
                        }}
                        dialCodeValue={alternateDialCode}
                        onChange={(e) =>
                          setMobile(
                            /^[0-9]+$|^$/.test(e.target.value)
                              ? e.target.value
                              : mobile
                          )
                        }
                        value={mobile}
                        placeHolder={"Enter Phone number"}
                        required={true}
                      />
                    </div>
                    <div
                      onClick={() =>
                        setIsTrue({ ...isTrue, whatsapp: !isTrue.whatsapp })
                      }
                      className={classes.alternateFieldContainer}
                    >
                      {isTrue.whatsapp ? (
                        <BsPlusCircleFill size={18} />
                      ) : (
                        <BsPlusCircle size={18} />
                      )}
                      <div className={classes.aternateFieldText}>
                        Whatsapp number
                      </div>
                    </div>

                    {error.mobile && <ProfileFormErrors errorMessage={error.mobile} />}
                    {isTrue.whatsapp && (
                      <>
                        <RiTextInputs
                          input={"mobile"}
                          maxLength="10"
                          minLength="10"
                          onInput={() => {
                            error.whatsapp = " ";
                          }}
                          onDialCodechange={(e) => {
                            setWhatsAppDialCode(e.target.value);
                          }}
                          dialCodeValue={whatsAppDialCode}
                          disabled={sameAsAbove}
                          onChange={(e) =>
                            setWhatsapp(
                              /^[0-9]+$|^$/.test(e.target.value)
                                ? e.target.value
                                : whatsapp
                            )
                          }
                          value={whatsapp}
                          placeHolder={"Enter Whatsapp number"}
                          required={true}
                        />
                        <div className={classes.error_message}>
                          {error.whatsapp}
                        </div>
                        <div>
                          {" "}
                          <input
                            checked={sameAsAbove}
                            onChange={() => setSameAsAbove(!sameAsAbove)}
                            type="checkbox"
                            className={classes.CheckBoxInput}
                          />
                          Same as above
                        </div>
                      </>
                    )}
                    <RiTextInputs
                      input={"Dropdown"}
                      placeHolder={"Gender"}
                      displayData={genderData}
                      onChange={(e) => setGender(e.target.value)}
                      value={gender}
                    />
                    <div ref={dobRef}>
                      <RiTextInputs
                        input={"date"}
                        type={"date"}
                        placeHolder={"Date of birth"}
                        value={dob}
                        max={todayDate}
                        onInput={() => {
                          error.dob = "";
                        }}
                        onChange={dobInTheFuture}
                      />
                    </div>

                    {error.dob && <ProfileFormErrors errorMessage={error.dob} />}

                    <div ref={locationRef} className={classes.heading}>
                      Current Location <span className={classes.manadatoryFields}> * </span>
                    </div>
                    <div className={classes.subHeading}>
                      This will help us to match you with perfect work
                    </div>
                    <RiTextInputs
                      input={"Dropdown"}
                      placeHolder={"Select Country"}
                      onChange={(e) => {
                        setCountry(e.target.value);
                        error.country = "";
                        setState("");
                        setCity("");
                        error.state = "";
                        error.city = ""
                      }}
                      onInput={() => {
                        error.country = "";
                      }}
                      value={country}
                      default={country}
                    />
                    {error.country && <ProfileFormErrors errorMessage={error.country} />}

                    <>
                      <RiTextInputs
                        input={"Dropdown"}
                        placeHolder={"Select State"}
                        selectedCountry={country}
                        onChange={(e) => {

                          handleState(e);
                        }}
                        value={state}
                        defaultState={state}
                      />

                      {error.state && <ProfileFormErrors errorMessage={error.state} />}
                    </>
                    {cityLoading ? (
                      <div className={classes.inputArea}>
                        <Loader
                          type="TailSpin"
                          color="#1778f2"
                          width={20}
                          height={18}
                        />
                      </div>
                    ) : (
                      <>
                        <RiTextInputs
                          input={"Dropdown"}
                          placeHolder={"Select City"}
                          displayData={cityData}
                          onChange={(e) => {
                            setCity(e.target.value);
                            error.city = "";
                          }}
                          value={city}
                        />

                        {error.city && <ProfileFormErrors errorMessage={error.city} />}
                      </>
                    )}

                    <RiTextInputs
                      input={"SingleLineInput"}
                      placeHolder={"Pin Code"}
                      type={"text"}
                      onInput={() => (error.pinCode = "")}
                      maxLength="6"
                      onChange={(e) =>
                        setPinCode(
                          /^[0-9]+$|^$/.test(e.target.value) ||
                            country !== "India"
                            ? e.target.value
                            : pinCode
                        )
                      }
                      value={pinCode}
                    />

                    {error.pinCode && <ProfileFormErrors errorMessage={error.pinCode} />}
                    <div className={classes.heading}>Connect Social</div>
                    <div className={classes.subHeading}>
                      Paste your social profile link below
                    </div>
                    <RiTextInputs
                      input={"social"}
                      type={"text"}
                      placeHolder={"Website"}
                      value={social.website}
                      onChange={(e) =>
                        setSocial({ ...social, website: e.target.value })
                      }
                      icon={<FaGlobe size={25} color="#0e76a8" />}
                    />
                    <RiTextInputs
                      input={"social"}
                      type={"text"}
                      placeHolder={"Twitter"}
                      value={social.twitter}
                      onChange={(e) =>
                        setSocial({ ...social, twitter: e.target.value })
                      }
                      icon={<FaTwitter size={25} color="#00acee " />}
                    />
                    <RiTextInputs
                      input={"social"}
                      type={"text"}
                      placeHolder={"LinkedIn"}
                      value={social.linkedIn}
                      onChange={(e) =>
                        setSocial({ ...social, linkedIn: e.target.value })
                      }
                      icon={<FaLinkedin size={25} color="#0e76a8" />}
                    />
                  </div>
                  <div className={classes.ActionBtn}>
                    <ActionButton
                      buttonText={
                        loading === "SendFreelancerProfile" ? (
                          <Loader
                            type="TailSpin"
                            color="white"
                            width={20}
                            height={20}
                          />
                        ) : (
                          "Next"
                        )
                      }
                      onClick={formValidation}
                    />
                  </div>
                </div>
              </div>
            </div>
          </NewFreelancerDashBoardMobile>
        );

      default:
        return (
          <NewFreelancerDashBoard>
            <div className={classes.Maincontainer_border}>
              <div className={classes.web_view}>
                <div className={classes.mainContainer}>
                  <TopAlertNotification
                    profileCompletion={profileCompletionStatus}
                  />
                  <div className={classes.input_container}>
                    <h1 className={classes.mainHeading}>Personal Details</h1>
                    <div className={classes.borderBottomHeading}> </div>
                    <div className={classes.profilePhoto}>
                      {profilePicture ? (
                        <div className={classes.updatetImageContainer}>
                          <div>
                            <img
                              src={
                                typeof profilePicture === "string"
                                  ? profilePicture
                                  : URL.createObjectURL(profilePicture)
                              }
                              alt={userName}
                              title={userName}
                              loading="lazy"
                              height={120}
                              width={120}
                              className={classes.updateImage}
                            />
                          </div>

                          <div className={classes.uploadButtonContainer}>
                            <div className={classes.imageEditIcon}>
                              <AiTwotoneDelete
                                className={classes.uploadIcon}
                                onClick={() => setProfilePicture("")}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className={classes.uploadContainer}>
                          <div>
                            <label
                              className={classes.form_upload_label}
                              for="upload"
                            >
                              <input
                                type="file"
                                id="upload"
                                hidden
                                accept=".png, .jpg, .jpeg"
                                onChange={(e) => {
                                  setProfilePicture(e.target.files[0]);
                                }}
                              />
                              <BsCameraFill className={classes.uploadIcon} />
                            </label>
                          </div>
                        </div>
                      )}
                      <div className={classes.uploadLogoText}>
                        <label>{userName}</label>
                      </div>
                    </div>
                    <div className={classes.twoColoumnContainer}>
                      <div className={classes.twoColoumnField}>
                        <RiTextInputs
                          input={"SingleLineInput"}
                          type={"text"}
                          placeHolder={"First Name"}
                          value={name.firstName}
                          onChange={(e) =>
                            setName({ ...name, firstName: e.target.value })
                          }
                          onInput={() => {
                            error.firstName = "";
                          }}
                        />
                        {error.firstName && <ProfileFormErrors errorMessage={error.firstName} />}
                      </div>
                      <div className={classes.twoColoumnField}>
                        <RiTextInputs
                          input={"SingleLineInput"}
                          type={"text"}
                          placeHolder={"Last Name"}
                          value={name.lastName}
                          onChange={(e) =>
                            setName({ ...name, lastName: e.target.value })
                          }
                          onInput={() => {
                            error.lastName = "";
                          }}
                        />
                        {error.lastName && <ProfileFormErrors errorMessage={error.lastName} />}
                      </div>
                    </div>
                    <div className={classes.twoColoumnContainer}>
                      <div className={classes.twoColoumnField}>
                        <RiTextInputs
                          input={"SingleLineInput"}
                          type={"email"}
                          onInput={() => {
                            error.email = "";
                          }}
                          placeHolder={"Enter Email"}
                          disabled={true}
                          onChange={(e) => {
                            let keyword = e.target.value.toLowerCase();
                            var re = /^[a-z@A-Z.0-9_]*$/;
                            if (keyword === "" || re.test(keyword)) {
                              setEmail(keyword);
                            }
                          }}
                          value={email}
                        />
                        {error.email && <ProfileFormErrors errorMessage={error.email} />}
                        <div
                          onClick={() =>
                            setIsTrue({
                              ...isTrue,
                              alternateEmail: !isTrue.alternateEmail,
                            })
                          }
                          className={classes.alternateFieldContainer}
                        >
                          {isTrue.alternateEmail ? (
                            <BsPlusCircleFill size={18} />
                          ) : (
                            <BsPlusCircle size={18} />
                          )}
                          <div className={classes.aternateFieldText}>
                            Alternative Email
                          </div>
                        </div>
                      </div>
                      <div className={classes.twoColoumnField}>
                        <div
                          className={
                            isMobile === ""
                              ? classes.disable
                              : classes.disabledInput
                          }
                        >
                          <RiTextInputs
                            input={"mobile"}
                            maxLength="10"
                            minLength="10"
                            onInput={() => {
                              error.mobile = "";
                            }}
                            onDialCodechange={(e) => {
                              setAlternateDialCode(e.target.value);
                            }}
                            dialCodeValue={alternateDialCode}
                            onChange={(e) =>
                              setMobile(
                                /^[0-9]+$|^$/.test(e.target.value)
                                  ? e.target.value
                                  : mobile
                              )
                            }
                            value={mobile}
                            placeHolder={"Enter Phone number"}
                            required={true}
                          />
                        </div>
                        <div
                          onClick={() =>
                            setIsTrue({ ...isTrue, whatsapp: !isTrue.whatsapp })
                          }
                          className={classes.alternateFieldContainer}
                        >
                          {isTrue.whatsapp ? (
                            <BsPlusCircleFill size={18} />
                          ) : (
                            <BsPlusCircle size={18} />
                          )}
                          <div className={classes.aternateFieldText}>
                            Whatsapp number
                          </div>
                        </div>
                        {error.mobile && <ProfileFormErrors errorMessage={error.mobile} />}
                      </div>
                    </div>
                    <div className={classes.twoColoumnContainer}>
                      {isTrue.alternateEmail && (
                        <div className={classes.twoColoumnField}>
                          <RiTextInputs
                            input={"SingleLineInput"}
                            type={"email"}
                            onInput={() => {
                              error.email = "";
                            }}
                            placeHolder={"Enter Alternate Email"}
                            onChange={(e) => {
                              let keyword = e.target.value.toLowerCase();
                              var re = /^[a-z@A-Z.0-9_]*$/;
                              if (keyword === "" || re.test(keyword)) {
                                setAlternateEmail(keyword);
                              }
                            }}
                            value={alternateEmail}
                          />
                          {error.alternateEmail && <ProfileFormErrors errorMessage={error.alternateEmail} />}
                        </div>
                      )}

                      {isTrue.whatsapp && (
                        <div className={classes.twoColoumnField}>
                          <RiTextInputs
                            input={"mobile"}
                            maxLength="10"
                            minLength="10"
                            onInput={() => {
                              error.whatsapp = "";
                            }}
                            onDialCodechange={(e) => {
                              setWhatsAppDialCode(e.target.value);
                            }}
                            dialCodeValue={whatsAppDialCode}
                            disabled={sameAsAbove}
                            onChange={(e) =>
                              setWhatsapp(
                                /^[0-9]+$|^$/.test(e.target.value)
                                  ? e.target.value
                                  : whatsapp
                              )
                            }
                            value={whatsapp}
                            placeHolder={"Enter Whatsapp number"}
                            required={true}
                          />

                          {error.whatsapp && <ProfileFormErrors errorMessage={error.whatsapp} />}
                          <div>
                            {" "}
                            <input
                              checked={sameAsAbove}
                              onChange={() => setSameAsAbove(!sameAsAbove)}
                              type="checkbox"
                              className={classes.CheckBoxInput}
                            />
                            Same as mobile
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={classes.genderDobContainer}>
                      <div className={classes.twoColoumnField}>
                        <RiTextInputs
                          input={"Dropdown"}
                          placeHolder={"Gender"}
                          displayData={genderData}
                          onChange={(e) => setGender(e.target.value)}
                          value={gender}
                        />
                      </div>
                      <div ref={dobRef} className={classes.twoColoumnField}>
                        <RiTextInputs
                          input={"date"}
                          type={"date"}
                          placeHolder={"Date of birth"}
                          value={dob}
                          max={todayDate}
                          onInput={() => {
                            error.dob = "";
                          }}
                          onChange={dobInTheFuture}
                        />
                        {error.dob && <ProfileFormErrors errorMessage={error.dob} />}
                      </div>
                    </div>
                    <div ref={locationRef} className={classes.heading}>
                      Current Location <span className={classes.manadatoryFields}> * </span>
                    </div>
                    <div className={classes.subHeading}>
                      This will help us to match you with perfect work
                    </div>
                    <div className={classes.twoColoumnContainer}>
                      <div className={classes.twoColoumnField}>
                        <RiTextInputs
                          input={"Dropdown"}
                          placeHolder={"Select Country"}
                          onChange={(e) => {
                            setCountry(e.target.value);
                            error.country = "";
                            setState("");
                            setCity("");
                            error.state = "";
                            error.city = ""
                          }}
                          value={country}
                          default={country}
                        />
                        {error.country && <ProfileFormErrors errorMessage={error.country} />}
                      </div>

                      <div className={classes.twoColoumnField}>
                        <RiTextInputs
                          input={"Dropdown"}
                          placeHolder={"Select State"}
                          selectedCountry={country}
                          onChange={(e) => {
                            handleState(e);
                          }}
                          value={state}
                          defaultState={state}
                        />
                        {error.state && <ProfileFormErrors errorMessage={error.state} />}
                      </div>
                    </div>
                    <div className={classes.twoColoumnContainer}>
                      <div className={classes.twoColoumnField}>
                        {cityLoading ? (
                          <div className={classes.inputArea}>
                            <Loader
                              type="TailSpin"
                              color="#1778f2"
                              width={20}
                              height={18}
                            />
                          </div>
                        ) : (
                          <>
                            <RiTextInputs
                              input={"Dropdown"}
                              placeHolder={"Select City"}
                              displayData={cityData}
                              onChange={(e) => {
                                setCity(e.target.value);
                                error.city = "";
                              }}
                              value={city}
                            />
                            {error.city && <ProfileFormErrors errorMessage={error.city} />}
                          </>
                        )}
                      </div>
                      <div className={classes.twoColoumnField}>
                        <RiTextInputs
                          input={"SingleLineInput"}
                          placeHolder={"Pin Code"}
                          type={"text"}
                          onInput={() => (error.pinCode = "")}
                          maxLength="6"
                          onChange={(e) =>
                            setPinCode(
                              /^[0-9]+$|^$/.test(e.target.value) ||
                                country !== "India"
                                ? e.target.value
                                : pinCode
                            )
                          }
                          value={pinCode}
                        />
                        {error.pinCode && <ProfileFormErrors errorMessage={error.pinCode} />}
                      </div>
                    </div>
                    <div className={classes.heading}>Connect Social</div>
                    <div className={classes.subHeading}>
                      Paste your social profile link below
                    </div>
                    <div className={classes.socialContainer}>
                      <RiTextInputs
                        input={"social"}
                        type={"text"}
                        placeHolder={"Website"}
                        value={social.website}
                        onChange={(e) =>
                          setSocial({ ...social, website: e.target.value })
                        }
                        icon={<FaGlobe size={25} color="#0e76a8" />}
                      />
                      <RiTextInputs
                        input={"social"}
                        type={"text"}
                        placeHolder={"Twitter"}
                        value={social.twitter}
                        onChange={(e) =>
                          setSocial({ ...social, twitter: e.target.value })
                        }
                        icon={<FaTwitter size={25} color="#00acee " />}
                      />
                      <RiTextInputs
                        input={"social"}
                        type={"text"}
                        placeHolder={"LinkedIn"}
                        value={social.linkedIn}
                        onChange={(e) =>
                          setSocial({ ...social, linkedIn: e.target.value })
                        }
                        icon={<FaLinkedin size={25} color="#0e76a8" />}
                      />
                    </div>
                  </div>
                  <div className={classes.ActionBtn}>
                    <ActionButton
                      buttonText={
                        loading === "SendFreelancerProfile" ? (
                          <Loader
                            type="TailSpin"
                            color="white"
                            width={20}
                            height={20}
                          />
                        ) : (
                          "Next"
                        )
                      }
                      onClick={formValidation}
                    />
                  </div>
                </div>
              </div>
            </div>
          </NewFreelancerDashBoard>
        );
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Frequently Asked Questions about Freelance Jobs  | Rozgaar India`}</title>
        <meta
          name="description"
          content={`Read the Freelancer FAQ's to learn everything about Freelancing, freelance projects, remote work, contract work and gigs.`}

        />
        <link rel="canonical" href="https://www.rozgaarindia.com/personalDetails" />
      </Helmet>

      <React.Suspense
        fallback={
          <div className="pageLoader">
            <Loader
              type="TailSpin"
              color="red"
              height={80}
              width={80}
              className="text-center my-5"
            />
          </div>
        }
      >
        {WebOrMobileSwitch(props.device)}
      </React.Suspense>
    </>
  );
};

export default PersonalDetails;
