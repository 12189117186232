import React, { useState, useEffect } from 'react';
import classes from './ProfessionAndEducation.module.css';
import Loader from 'react-loader-spinner';
import Step5Freelancer from './MyProfileSuccess';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import { BiPlusCircle, BiXCircle, BiMinusCircle } from "react-icons/bi";
import { AiOutlineCloudUpload, AiFillCheckCircle } from 'react-icons/ai'
import NewFreelancerDashBoardMobile from '../../Components/NewFreelancerDashBoard/NewFreelancerDashBoardMobile';
import Tab from '../../Components/FreelancerProfile/EditFreelancerProfile/Tab';
import NewFreelancerDashBoard from '../../Components/NewFreelancerDashBoard/NewFreelancerDashboard';
import TopAlertNotification from '../../Components/FreelancerProfile/TopAlertNotification';
import ProfileFormErrors from '../../Components/FreelancerProfile/EditFreelancerProfile/ProfileFormErrors';
import { pageViewTracker } from '../../Components/GoogleTracking';

const ActionButton = React.lazy(() => import('../../Components/ActionButton'));
const RiTextInputs = React.lazy(() => import('../../Components/PostRequirement/RiTextInputs'));



const ProfessionAndEducation = (props) => {


    const userId = localStorage.getItem("userID");
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState({});
    const [freelancerProfileId, setFreelancerProfileId] = useState();
    const [highestQualification, setHighestQualification] = useState({ degree: '', college: '', year: '' });
    const [certificationsList, setCertificationsList] = useState([]);
    const [loading, setLoading] = useState('');
    const [experienceType, setExperienceType] = useState("");
    const [isExperienced, setIsExperienced] = useState("0");
    const [openCertification, setOpenCertification] = useState()
    const [companyName, setCompanyName] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [experienceYears, setExperienceYears] = useState('');
    const [website, setWebsite] = useState('');
    const [experience, setExperience] = useState([]);
    const [jobType, setJobType] = useState("Full Time");
    const [profileCompletionStatus, setProfileCompletionStatus] = useState("");
    const [certificateData, setCertificateData] = useState({
        CertificateName: '',
        CertificateAuthority: '',
        CertificateYear: '',
        CertificateFile: ''
    });
    let navigate = useNavigate();

    useEffect(() => {
      pageViewTracker();
    }, [])
    

    useEffect(() => {
        GetFreelancerProfileAPI();
        window.scrollTo(0, 0)
    }, []);

    const yearData = (start, end) => {
        let years = [];
        while (start !== end) {
            years.push({ value: start.toString(), type: start.toString() })
            start--
        }
        return years;
    }

    const GetFreelancerProfileAPI = () => {

        setLoading('GetFreelancerProfile');
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");

        var formdata = new FormData();
        formdata.append("FreelancerId", userId);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };


        fetch(global.apiLink + "/api/rozgaarapi/FreelancerProfile", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200) {
                    setProfileCompletionStatus(result.FreelancerProfileresponse.ProfilePercentComplete)
                    setCertificationsList(result.data.Certification);
                    setOpenCertification(result.data.Certification ? true : false)
                    setExperience(result.data.WorkExperience);
                    setIsExperienced(result.data.IsExperienced);
                    setFreelancerProfileId(result.data.FreelancerProfileId)
                    result.data.Education.map((item) => {
                        if (item.HigestQualification === "Yes") {
                            highestQualification.degree = item.Degree;
                            highestQualification.college = item.College;
                            highestQualification.year = item.Year.substr(0, 4);
                        }
                    })
                }
                else {
                    alert('Sorry! Data could not be fetched');
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(''));
    }

    const SendFreelancerProfileAPI = () => {
        setLoading('SendFreelancerProfile')
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");

        var formdata = new FormData();
        formdata.append("FreelancerId", userId);
        formdata.append("FreelancerEducation", JSON.stringify([{
            "HigestQualification": "Yes",
            "Degree": highestQualification.degree,
            "College": highestQualification.college,
            "Year": highestQualification.year + "-01-01"
        }]));
        formdata.append("WorkExperience", JSON.stringify(experience));
        formdata.append("IsExperienced", isExperienced);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/api/rozgaarapi/FreelancerProfile", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200) {
                    navigate('/portfolio')
                }
                else {
                    alert("Sorry! Failed to submit data.")
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(''));

    }

    const formValidation = () => {
        let errors = {};
        let isValid = true;
      
        if(isExperienced === ''){
            errors.isFresher="Please select atleast one";
                
                isValid = false;
        }
        if (isExperienced === '1' && experience.length === 0) {

           if(experienceType !== 'freelance'){
            if(experienceYears === ''){
                errors.isExperienced="Please select atleast one";
                
                isValid = false;
            }
           }
           if(experienceType !== 'job'){
                if(companyAddress === '' || companyName === '' || experienceYears === ''){
                    errors.companyName = 'Please fill this field !';
                    errors.companyAddress = 'Please fill this field !';
                    errors.experienceYears = 'Please fill this field !';                   
                    isValid = false;
                }
           }
            
        }
        setError({ ...error, ...errors })

        if (isValid) {
            SendFreelancerProfileAPI();
        }
       
    }

    const AddFreelancerCertificationAPI = () => {
        setLoading('FreelancerCertification');
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");

        var formdata = new FormData();
        formdata.append("FreelancerProfileId", freelancerProfileId);
        formdata.append("Name", certificateData.CertificateName);
        formdata.append("Authority", certificateData.CertificateAuthority);
        formdata.append("Year", certificateData.CertificateYear + '-01-01');
        formdata.append("CertificateFile", certificateData.CertificateFile);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/api/rozgaarapi/FreelancerCertification", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200) {
                    setCertificationsList(result.data);
                    setCertificateData({
                        CertificateName: '',
                        CertificateAuthority: '',
                        CertificateYear: '',
                        CertificateFile: ''
                    });
                }
                else {
                    alert('Sorry! Certification could not be uploaded');
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(''));

    }

    const DeleteFreelancerCertificationAPI = (certificateId, deleteIndex) => {

        setLoading('FreelancerCertification');
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");

        var formdata = new FormData();
        formdata.append("FreelancerCertificationId", certificateId);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/api/rozgaarapi/DeleterzFreelancerCertification", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200) {
                    setCertificationsList(certificationsList.filter((item, index) => index !== deleteIndex));
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(''));

    }

    const HandleAddCertification = () => {

        let errors = {};
        let isValid = true;

        if (certificateData.CertificateName === '') {
            errors.certificateName = "Please enter course name !";
            isValid = false;
        }
        if (certificateData.CertificateAuthority === '') {
            errors.certificateAuthority = "Please enter certifying authority !";
            isValid = false;
        }
        if (certificateData.CertificateYear === '') {
            errors.certificateYear = "Please enter year !";
            isValid = false;
        }
        if (certificateData.CertificateFile === '') {
            errors.certificateFile = "Please upload certificate file !";
            isValid = false;
        }

        setError({ ...error, ...errors })

        if (isValid) {
            AddFreelancerCertificationAPI()
        }

    }


    const HandleAddExperience = () => {

        switch (experienceType) {
            case 'freelance':

                if (!experienceYears) {
                    setError({ ...error, experienceYears: 'Please fill this field !' })
                }
                else {
                    setExperience([...experience, {
                        CurrentCountry: '',
                        CurrentState: '',
                        CurrentCity: '',
                        CurrentPincode: '',
                        IsFreelancer: "1",
                        WorkExperienceWebsite: (website),
                        WorkExperienceJobType: (jobType),
                        WorkExperienceYears: (experienceYears),
                        WorkExperienceComanyName: '',
                        WorkExperienceCompanyAddress: '',
                    }])
                    setWebsite('');
                    setJobType('');
                    setExperienceYears('');
                }
                break;
            case 'job':
                if (companyName === '') {
                    setError({ ...error, companyName: 'Please fill this field !' });
                }
                else if (companyAddress === '') {
                    setError({ ...error, companyAddress: 'Please fill this field !' });
                }
                else if (!experienceYears) {
                    setError({ ...error, experienceYears: 'Please fill this field !' });
                }
                else {
                    setExperience([...experience, {
                        CurrentCountry: '',
                        CurrentState: '',
                        CurrentCity: '',
                        CurrentPincode: '',
                        IsFreelancer: "0",
                        WorkExperienceWebsite: '',
                        WorkExperienceJobType: '',
                        WorkExperienceYears: (experienceYears),
                        WorkExperienceComanyName: (companyName),
                        WorkExperienceCompanyAddress: (companyAddress),
                    }])
                    setExperienceYears('');
                    setCompanyName('');
                    setCompanyAddress('');
                }
                break;

            default:
                break;
        }
    }

    const HandleDeleteExperience = (deleteIndex) => {
        setExperience(experience.filter((item, index) => index !== deleteIndex));
    }

    const WebOrMobileSwitch = (device) => {
        switch (device) {
            case "Mobile":
                return <NewFreelancerDashBoardMobile >
                    <Tab />
                    <div className={classes.Maincontainer_border}>
                        <div className={classes.web_view}>
                            <TopAlertNotification profileCompletion={profileCompletionStatus} />
                            <div className={classes.mainContainer}>
                                <div className={classes.heading}>Are you a Fresher or Working Professional? <span className={classes.manadatoryFields}> * </span></div>
                                <div className={classes.subHeading}>Whether you're new or professional, we offer work for both </div>

                                <div className={classes.radio}>
                                    <div onClick={() => setIsExperienced("0")} className={classes.RadioOne}>
                                        <input checked={isExperienced === "0"} type="radio" id="fresher" required value="yes" onChange={() => setIsExperienced("0")} />
                                        <label for="fresher">Fresher</label>
                                    </div>
                                    <div onClick={() => setIsExperienced("1")} className={classes.RadioTwo}>
                                        <input checked={isExperienced === "1"} type="radio" id="experienced" required value="no" onChange={() => setIsExperienced("1")} />
                                        <label for="experienced">Experienced</label>
                                    </div>
                                </div>
                                {error.isFresher && <ProfileFormErrors errorMessage={error.isFresher} />}
                                {
                                    (experience.length !== 0 && isExperienced === "1") &&
                                    experience.map((item, index) => {
                                        return <div className={classes.CrossIconContainerExperience}><div className={classes.experienceListContainer}>
                                            <b>Experience Type:</b> {item.IsFreelancer === "1" ? "Freelance" : "Job"}
                                            {
                                                item.IsFreelancer === "1" &&
                                                <div>
                                                    <b>Website:</b> {item.WorkExperienceWebsite}<br />
                                                    <b>Work Type:</b> {item.WorkExperienceJobType}<br />
                                                    <b>Experience Duration:</b> {item.WorkExperienceYears}
                                                </div>
                                            }
                                            {
                                                item.IsFreelancer === "0" &&
                                                <div>
                                                    <b>Company Name:</b> {item.WorkExperienceComanyName}<br />
                                                    <b>Company Address:</b>{item.WorkExperienceCompanyAddress}<br />
                                                    <b>Experience Duration:</b> {item.WorkExperienceYears}
                                                </div>
                                            }
                                        </div>
                                            <BiXCircle onClick={() => HandleDeleteExperience(index)} size={20} className={classes.crossIconExperience} /></div>
                                    })

                                }
                                {
                                    isExperienced === "1" &&
                                    <div className={classes.experienceContainer}>
                                        <div className={classes.subHeadingExperience}>Do you have experience in Freelancing or Job ?<span className={classes.manadatoryFields}> * </span></div>
                                        <div className={classes.radio}>
                                            <div onClick={() => setExperienceType('job')} className={classes.RadioOne}>
                                                <input checked={experienceType === 'job'} type="radio" id="experienceType" required value="yes" onChange={() => setExperienceType('job')} />
                                                <label for="male">Job</label>
                                            </div>
                                            <div onClick={() => setExperienceType('freelance')} className={classes.RadioTwo}>
                                                <input checked={experienceType === 'freelance'} type="radio" id="female" required value="no" onChange={() => setExperienceType('freelance')} />
                                                <label for="female">Freelancing</label>
                                            </div>
                                        </div>
                                        
                                        {error.isExperienced && <ProfileFormErrors errorMessage={error.isExperienced} />}

                                        {
                                            experienceType === 'job'
                                            &&
                                            <div className={classes.jobInfo}>
                                                <RiTextInputs
                                                    input={"Dropdown"}
                                                    placeHolder={"Experience"}
                                                    value={experienceYears}

                                                    onChange={(e) => { error.experienceYears = ''; setExperienceYears(e.target.value) }}

                                                />
                                                
                                                {error.experienceYears && <ProfileFormErrors errorMessage={error.experienceYears} />}
                                                <RiTextInputs
                                                    input={"SingleLineInput"}
                                                    type={"text"}
                                                    placeHolder={"Company Name"}
                                                    value={companyName}
                                                    onInput={() => error.companyName = ''}
                                                    onChange={(e) => setCompanyName(e.target.value)}
                                                />
                                               
                                                {error.companyName && <ProfileFormErrors errorMessage={error.companyName} />}
                                                <RiTextInputs
                                                    input={"SingleLineInput"}
                                                    type={"text"}
                                                    placeHolder={"Company Address"}
                                                    value={companyAddress}
                                                    onInput={() => error.companyAddress = ''}
                                                    onChange={(e) => setCompanyAddress(e.target.value)}
                                                />
                                                
                                                {error.companyAddress && <ProfileFormErrors errorMessage={error.companyAddress} />}
                                                <div className={classes.addExperience} onClick={() => { HandleAddExperience() }} > Add Experience </div>
                                            </div>
                                        }
                                        {experienceType === 'freelance' &&
                                            <div>
                                                <RiTextInputs
                                                    input={"Dropdown"}
                                                    placeHolder={"Experience"}
                                                    value={experienceYears}

                                                    onChange={(e) => { error.experienceYears = ''; setExperienceYears(e.target.value) }}

                                                />
                                                
                                                {error.experienceYears && <ProfileFormErrors errorMessage={error.experienceYears} />}
                                               
                                                {error.website && <ProfileFormErrors errorMessage={error.website} />}
                                                <div className={classes.radioFreelancer}>
                                                    <div onClick={() => setJobType("Part Time")} className={[classes.RadioTwoFreelance]}>
                                                        <input checked={jobType === "Part Time"} type="radio" id="partTime" required value="Part Time" onChange={() => setJobType("Part Time")} />
                                                        <label for="partTime">Part Time</label>
                                                    </div>
                                                    <div onClick={() => setJobType("Full Time")} className={classes.RadioTwoFreelance}>
                                                        <input checked={jobType === "Full Time"} type="radio" id="fullTime" required value="Full Time" onChange={() => setJobType("Full Time")} />
                                                        <label for="fullTime">Full Time</label>
                                                    </div>
                                                </div>

                                                <div className={classes.addExperience} onClick={() => { HandleAddExperience() }} > Add Experience </div>
                                            </div>}

                                    </div>
                                }

                                <div className={classes.heading}>Highest Qualification</div>
                                <div className={classes.subHeading}>Enter details about your education </div>
                                <RiTextInputs
                                    input={"SingleLineInput"}
                                    type={"text"}
                                    placeHolder={"Degree"}
                                    value={highestQualification.degree}
                                    onChange={(e) => setHighestQualification({ ...highestQualification, degree: e.target.value })}
                                />
                                <RiTextInputs
                                    input={"SingleLineInput"}
                                    type={"text"}
                                    placeHolder={"College"}
                                    value={highestQualification.college}
                                    onChange={(e) => setHighestQualification({ ...highestQualification, college: e.target.value })}
                                />
                                <RiTextInputs
                                    input={"Dropdown"}
                                    placeHolder={"Year"}
                                    displayData={yearData(2022, 1950)}
                                    value={highestQualification.year}
                                    onChange={(e) => setHighestQualification({ ...highestQualification, year: e.target.value })}
                                />

                                <div onClick={() => setOpenCertification(!openCertification)} className={classes.certificationHeadingContainer}><div className={classes.heading}>
                                    Add Certifications</div>{openCertification ? <BiMinusCircle size={24} /> : <BiPlusCircle size={24} />}</div>
                                {
                                    loading === 'FreelancerCertification' && <Loader type="TailSpin" color="red" height={40} width={40} className="text-center my-5" />
                                }
                                <div className={classes.subHeading}>It's a great way to show off your skills</div>
                                {
                                    certificationsList.length !== 0 && openCertification &&
                                    certificationsList.map((item, index) => {
                                        return <div className={classes.CertificationContainer}>
                                            <div className={classes.certificationListContainer}>

                                                <div>
                                                    <b>Course Name:</b> {item.CertificateName}<br />
                                                    <b>Certificate Authority:</b> {item.CertificateAuthority}<br />
                                                    <b>Certificate Year:</b> {item.CertificateYear.split('-')[0]}<br />
                                                    <b>Certificate File:</b> {item.CertificateFile.split('/').pop()}
                                                </div>

                                            </div>
                                            <BiXCircle onClick={() => DeleteFreelancerCertificationAPI(item.CertificationId, index)} size={20} className={classes.crossIconCertificate} />
                                        </div>
                                    })

                                }
                                {
                                    openCertification && <div className={classes.certificationContainer}>
                                        <RiTextInputs
                                            input={"SingleLineInput"}
                                            type={"text"}
                                            placeHolder={"Course Name"}
                                            value={certificateData.CertificateName}
                                            onChange={(e) => setCertificateData({ ...certificateData, CertificateName: e.target.value })}
                                            onInput={() => { error.certificateName = "" }}
                                        />
                                       
                                        {error.certificateName && <ProfileFormErrors errorMessage={error.certificateName} />}
                                        <RiTextInputs
                                            input={"SingleLineInput"}
                                            type={"text"}
                                            placeHolder={"Issuing Authority"}
                                            onInput={() => { error.certificateAuthority = "" }}
                                            value={certificateData.CertificateAuthority}
                                            onChange={(e) => setCertificateData({ ...certificateData, CertificateAuthority: e.target.value })}
                                        />
                                        
                                        {error.certificateAuthority && <ProfileFormErrors errorMessage={error.certificateAuthority} />}
                                        <RiTextInputs
                                            input={"Dropdown"}
                                            placeHolder={"Year"}
                                            displayData={yearData(2022, 1950)}
                                            value={certificateData.CertificateYear}
                                            onChange={(e) => { error.certificateYear = ''; setCertificateData({ ...certificateData, CertificateYear: e.target.value }) }}
                                        />
                                      
                                        {error.certificateYear && <ProfileFormErrors errorMessage={error.certificateYear} />}
                                        <RiTextInputs
                                            input={"file"}
                                            label={"Attach Certificate"}
                                            placeHolder={"Upload"}
                                            accept="application/msword, application/pdf,"
                                            onChange={(e) => { error.certificateFile = " "; setCertificateData({ ...certificateData, CertificateFile: e.target.files[0] }) }}
                                            icon={typeof certificateData.CertificateFile === 'object' ? <AiFillCheckCircle size="25" color='green' /> : <AiOutlineCloudUpload size="25" />}
                                        />
                                       
                                        {error.certificateFile && <ProfileFormErrors errorMessage={error.certificateFile} />}
                                        <div className={classes.plusIcon} onClick={HandleAddCertification}>Save</div>
                                    </div>
                                }


                                <div className={classes.ActionBtn}>
                                    <ActionButton buttonText={loading === "SendFreelancerProfile" ? <Loader type="TailSpin" color="white" width={20} height={20} /> : "Next"} className={classes.Login_button} onClick={formValidation} />
                                </div>
                            </div>

                        </div>
                    </div>
                </NewFreelancerDashBoardMobile>

            default:
                return <NewFreelancerDashBoard >
                    <div className={classes.Maincontainer_border}>
                        <div className={classes.web_view}>
                            <div className={classes.mainContainer}>
                                <TopAlertNotification profileCompletion={profileCompletionStatus} />
                                <h1 className={classes.mainHeading} >Profession & Education</h1>
                                <div className={classes.borderBottomHeading}>    </div>
                                <div className={classes.inputSections}>
                                    <div className={classes.heading}>Are you a Fresher or Working Professional ? <span className={classes.manadatoryFields}> * </span></div>
                                    <div className={classes.subHeading}>Whether you're new or professional, we offer work for both </div>
                                    <div className={classes.radio}>
                                        <div onClick={() =>{ setIsExperienced("0");error.isFresher=""}} className={classes.RadioOne}>
                                            <input checked={isExperienced === "0"} type="radio" id="fresher" required value="yes" onChange={() => setIsExperienced("0")} />
                                            <label for="fresher">Fresher</label>
                                        </div>
                                        <div onClick={() => {setIsExperienced("1");error.isFresher=""}} className={classes.RadioTwo}>
                                            <input checked={isExperienced === "1"} type="radio" id="experienced" required value="no" onChange={() => setIsExperienced("1")} />
                                            <label for="experienced">Experienced</label>
                                        </div>
                                    </div>
                                    {error.isFresher && <ProfileFormErrors errorMessage={error.isFresher} />}
                                    {
                                        (experience.length !== 0 && isExperienced === "1") &&
                                        experience.map((item, index) => {
                                            return <div className={classes.CrossIconContainerExperience}><div className={classes.experienceListContainer}>
                                                <b>Experience Type:</b> {item.IsFreelancer === "1" ? "Freelance" : "Job"}
                                                {
                                                    item.IsFreelancer === "1" &&
                                                    <div>
                                                        <b>Website:</b> {item.WorkExperienceWebsite}<br />
                                                        <b>Work Type:</b> {item.WorkExperienceJobType}<br />
                                                        <b>Experience Duration:</b> {item.WorkExperienceYears}
                                                    </div>
                                                }
                                                {error.experienceType && <ProfileFormErrors errorMessage={error.experienceType} />}
                                                {
                                                    item.IsFreelancer === "0" &&
                                                    <div>
                                                        <b>Company Name:</b> {item.WorkExperienceComanyName}<br />
                                                       
                                                        <b>Company Address:</b>{item.WorkExperienceCompanyAddress}<br />
                                                        <b>Experience Duration:</b> {item.WorkExperienceYears}
                                                    </div>
                                                }
                                               
                                            </div>
                                                <BiXCircle onClick={() => HandleDeleteExperience(index)} size={20} className={classes.crossIconExperience} /></div>
                                        })

                                    }
                                    {
                                        isExperienced === "1" &&
                                        <div className={classes.experienceContainer}>
                                            <div className={classes.subHeadingExperience}>Do you have experience in Freelancing or Job ?<span className={classes.manadatoryFields}> * </span></div>
                                            <div className={classes.radio}>
                                                <div onClick={() =>{ setExperienceType('job'); error.isExperienced = ''}} className={classes.RadioOne}  >
                                                    <input checked={experienceType === 'job'} type="radio" id="experienceType" required value="experienceType" onChange={() => setExperienceType('job')}/>
                                                    <label for="male">Job</label>
                                                </div>
                                                <div onClick={() => {setExperienceType('freelance');  error.isExperienced = ''}} className={classes.RadioTwo}>
                                                    <input checked={experienceType === 'freelance'} type="radio" id="experienceType" required value="experienceType" onChange={() => setExperienceType('freelance')}/>
                                                    <label for="female">Freelancing</label>
                                                </div>
                                            </div>
                                           
                                            {error.isExperienced && <ProfileFormErrors errorMessage={error.isExperienced} />}

                                            {
                                                experienceType === 'job'
                                                &&
                                                <div className={classes.jobInfo}>
                                                    <RiTextInputs
                                                        input={"Dropdown"}
                                                        placeHolder={"Experience"}
                                                        value={experienceYears}

                                                        onChange={(e) => { error.experienceYears = ''; setExperienceYears(e.target.value) }}

                                                    />
                                                   
                                                    {error.experienceYears && <ProfileFormErrors errorMessage={error.experienceYears} />}
                                                    <RiTextInputs
                                                        input={"SingleLineInput"}
                                                        type={"text"}
                                                        placeHolder={"Company Name"}
                                                        value={companyName}
                                                        onInput={() => error.companyName = ''}
                                                        onChange={(e) => setCompanyName(e.target.value)}
                                                    />
                                                    {error.companyName && <ProfileFormErrors errorMessage={error.companyName} />}
                                                    <RiTextInputs
                                                        input={"SingleLineInput"}
                                                        type={"text"}
                                                        placeHolder={"Company Address"}
                                                        value={companyAddress}
                                                        onInput={() => error.companyAddress = ''}
                                                        onChange={(e) => setCompanyAddress(e.target.value)}
                                                    />
                                                   
                                                    {error.companyAddress && <ProfileFormErrors errorMessage={error.companyAddress} />}
                                                    <div className={classes.addExperience} onClick={() => { HandleAddExperience() }} > Add Experience </div>
                                                </div>
                                            }
                                            {experienceType === 'freelance' &&
                                                <div>
                                                    <RiTextInputs
                                                        input={"Dropdown"}
                                                        placeHolder={"Experience"}
                                                        label={"Experience"}
                                                        value={experienceYears}

                                                        onChange={(e) => { error.experienceYears = ''; setExperienceYears(e.target.value) }}

                                                    />
                                                   
                                                    {error.experienceYears && <ProfileFormErrors errorMessage={error.experienceYears} />}

                                                    <div className={classes.error_message}>{error.website}</div>
                                                    <div className={classes.radioFreelancer}>
                                                        <div onClick={() => setJobType("Part Time")} className={[classes.RadioTwoFreelance]}>
                                                            <input checked={jobType === "Part Time"} type="radio" id="partTime" required value="Part Time" onChange={() => setJobType("Part Time")} />
                                                            <label for="partTime">Part Time</label>
                                                        </div>
                                                        <div onClick={() => setJobType("Full Time")} className={classes.RadioTwoFreelance}>
                                                            <input checked={jobType === "Full Time"} type="radio" id="fullTime" required value="Full Time" onChange={() => setJobType("Full Time")} />
                                                            <label for="fullTime">Full Time</label>
                                                        </div>
                                                    </div>
                                                    <div className={classes.addExperience} onClick={() => { HandleAddExperience() }} > Add Experience </div>
                                                </div>}

                                        </div>
                                    }
                                </div>
                                <div className={classes.inputSections}>
                                    <div className={classes.heading}>Highest Qualification</div>
                                    <div className={classes.subHeading}>Enter details about your education </div>
                                    <RiTextInputs
                                        input={"SingleLineInput"}
                                        type={"text"}
                                        placeHolder={"Degree"}
                                        value={highestQualification.degree}
                                        onChange={(e) => setHighestQualification({ ...highestQualification, degree: e.target.value })}
                                    />
                                    <RiTextInputs
                                        input={"SingleLineInput"}
                                        type={"text"}
                                        placeHolder={"College"}
                                        value={highestQualification.college}
                                        onChange={(e) => setHighestQualification({ ...highestQualification, college: e.target.value })}
                                    />
                                    <RiTextInputs
                                        input={"Dropdown"}
                                        placeHolder={"Year"}
                                        displayData={yearData(2022, 1950)}
                                        value={highestQualification.year}
                                        onChange={(e) => setHighestQualification({ ...highestQualification, year: e.target.value })}
                                    />
                                </div>
                                <div onClick={() => setOpenCertification(!openCertification)} className={classes.certificationHeadingContainer}><div className={classes.heading}>
                                    Add Certifications</div>{openCertification ? <BiMinusCircle size={24} /> : <BiPlusCircle size={24} />}</div>
                                {
                                    loading === 'FreelancerCertification' && <Loader type="TailSpin" color="red" height={40} width={40} className="text-center my-5" />
                                }
                                <div className={classes.subHeading}>It's a great way to show off your skills</div>
                                {
                                    certificationsList.length !== 0 && openCertification &&
                                    certificationsList.map((item, index) => {
                                        return <div className={classes.CertificationContainer}><div className={classes.certificationListContainer}>


                                            <div>
                                                <b>Course Name:</b> {item.CertificateName}<br />
                                                <b>Certificate Authority:</b> {item.CertificateAuthority}<br />
                                                <b>Certificate Year:</b> {item.CertificateYear.split('-')[0]}<br />
                                                <b>Certificate File:</b> {item.CertificateFile.split('/').pop()}
                                            </div>

                                        </div>
                                            <BiXCircle onClick={() => DeleteFreelancerCertificationAPI(item.CertificationId, index)} size={20} className={classes.crossIconCertificate} /></div>
                                    })

                                }
                                {
                                    openCertification && <div className={classes.certificationContainer}>
                                        <RiTextInputs
                                            input={"SingleLineInput"}
                                            type={"text"}
                                            placeHolder={"Course Name"}
                                            value={certificateData.CertificateName}
                                            onChange={(e) => setCertificateData({ ...certificateData, CertificateName: e.target.value })}
                                            onInput={() => { error.certificateName = " " }}
                                        />
                                       
                                        {error.certificateName && <ProfileFormErrors errorMessage={error.certificateName} />}
                                        <RiTextInputs
                                            input={"SingleLineInput"}
                                            type={"text"}
                                            placeHolder={"Issuing Authority"}
                                            value={certificateData.CertificateAuthority}
                                            onInput={() => { error.certificateAuthority = " " }}
                                            onChange={(e) => setCertificateData({ ...certificateData, CertificateAuthority: e.target.value })}
                                        />
                                        
                                        {error.certificateAuthority && <ProfileFormErrors errorMessage={error.certificateAuthority} />}
                                        <RiTextInputs
                                            input={"Dropdown"}
                                            placeHolder={"Year"}
                                            displayData={yearData(2022, 1950)}
                                            value={certificateData.CertificateYear}
                                            onChange={(e) => { error.certificateYear = ''; setCertificateData({ ...certificateData, CertificateYear: e.target.value }) }}
                                        />
                                        
                                        {error.certificateYear && <ProfileFormErrors errorMessage={error.certificateYear} />}
                                        <RiTextInputs
                                            input={"file"}
                                            label={"Attach Certificate"}
                                            placeHolder={"Upload"}
                                            accept="application/msword, application/pdf,"
                                            onChange={(e) => { error.certificateFile = " "; setCertificateData({ ...certificateData, CertificateFile: e.target.files[0] }) }}
                                            icon={typeof certificateData.CertificateFile === 'object' ? <AiFillCheckCircle size="25" color='green' /> : <AiOutlineCloudUpload size="25" />}
                                        />
                                        
                                        {error.certificateFile && <ProfileFormErrors errorMessage={error.certificateFile} />}
                                        <div className={classes.plusIcon} onClick={HandleAddCertification}>Save</div>
                                    </div>
                                }


                                <div className={classes.ActionBtn}>
                                    <ActionButton buttonText={loading === "SendFreelancerProfile" ? <Loader type="TailSpin" color="white" width={20} height={20} /> : "Next"} className={classes.Login_button} onClick={formValidation} />
                                </div>
                            </div>

                        </div>
                    </div>
                </NewFreelancerDashBoard>
        }
    }



    return (
        <>
         <Helmet>
        <meta charSet="utf-8" />
        <title>{`Frequently Asked Questions about Freelance Jobs  | Rozgaar India`}</title>
        <meta
          name="description"
          content={`Read the Freelancer FAQ's to learn everything about Freelancing, freelance projects, remote work, contract work and gigs.`}
       
       />
           <link rel="canonical" href="https://www.rozgaarindia.com/professionAndEducation" /> 
      </Helmet>
            <React.Suspense fallback={<div className="pageLoader"><Loader type="TailSpin" color="red" height={80} width={80} className="text-center my-5" /></div>}>
                {
                    WebOrMobileSwitch(props.device)
                }
                {showModal ?
                    <Step5Freelancer onClick={() => setShowModal(false)} /> : <></>}
            </React.Suspense>
        </>
    )
}

export default ProfessionAndEducation
