import React,{useState , useEffect} from 'react'

import ArticlesFreelancers from "../../Components/FreelancerHomePageComp/ArticlesFreelancers";

import BannerAreaWeb from "../../Components/FreelancerHomePageComp/BannerAreaWeb";
import FutureOfWorkFreelancer from "../../Components/FreelancerHomePageComp/FutureOfWorkFreelancer";
import HelpFreelancers from "../../Components/FreelancerHomePageComp/HelpFreelancers";

import LogoCompWeb from "../../Components/FreelancerHomePageComp/LogoCompWeb";

import RemoteWorkCareerBoxesWeb from "../../Components/FreelancerHomePageComp/RemoteWorkCareerBoxesWeb";

import NewRequirementCard from "../../Components/FreelancerHomePageComp/NewRequirementCard";
import RIFooter from "../../Components/RIFooter";
import { Helmet } from "react-helmet";
import { pageViewTracker } from '../../Components/GoogleTracking';


const FreelancerHomePageWeb = () => {



    const [articleData, setArticledata] = useState([]);
    const [isLoading, setIsLoading] = useState()
    const [requirementData, setRequirementData] = useState([])

    useEffect(() => {
      ArticleApiDataFetch();
      RequirementData();
      pageViewTracker();
    }, []);

    const RequirementData = () => {
        setIsLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        myHeaders.append("Content-Type", "application/json");
        var raw = "";
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        fetch(global.apiLink + "/api/client/RequirementListPublicView", requestOptions)
          .then(response => response.json())
          .then(result => {
           
            setRequirementData(result.data);
          })
          .catch(error => console.log('error', error))
          .finally(() => { setIsLoading(false) })
      }

      const ArticleApiDataFetch = () => {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "ArticleType": "Freelancer"
        });
    
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
    
        fetch(
          global.apiLink+"/api/rozgaarapi/IspublishedArticle",
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            if (result.status_code === 200) {
              setArticledata(result.data);
            }
          })
          .catch((error) => console.log("error", error));
      };
    
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>{`Frequently Asked Questions about Freelance Jobs  | Rozgaar India`}</title>
        <meta
          name="description"
          content={`Read the Freelancer FAQ's to learn everything about Freelancing, freelance projects, remote work, contract work and gigs.`}
       
       />
           <link rel="canonical" href="https://www.rozgaarindia.com/" /> 
      </Helmet>
            <BannerAreaWeb />
            <NewRequirementCard RequirementData={requirementData} isLoading={isLoading} />
            <LogoCompWeb />
            <FutureOfWorkFreelancer />
            <RemoteWorkCareerBoxesWeb />
            <HelpFreelancers />
            <ArticlesFreelancers articleCard = {articleData}/>
            {/* <RIFooter /> */}
        </>

    )
}

export default FreelancerHomePageWeb;