import classes from './LeftMenu.module.css';
import React, { useState, useRef, useEffect } from 'react';
import { FcCustomerSupport, FcBriefcase, FcPositiveDynamic, FcSalesPerformance, FcSms, FcRules, FcClock, FcManager, FcRating, FcApprove } from "react-icons/fc";
import { Link, useLocation } from 'react-router-dom';
import { BiChevronDown } from "react-icons/bi";
import { encodeAndSendToChat } from '../../App';

const LeftMenu = (props) => {


  const [optionSelected, setOptionSelected] = useState();

  const refLocaction = useRef(null);

  const { pathname } = useLocation();
  useEffect(() => {
    executeScroll();
  }, [pathname]);
  const menuOptions = (menu) => {
    setOptionSelected(menu)
    props.option(menu)
  }
  const executeScroll = () => scrollToRef(refLocaction);
  const scrollToRef = (ref) =>
    window.scrollTo(
      0,
      pathname === "/FreelancerWorkPlace" ||
        pathname === "/WorkRequirementDashboard" ||
        pathname === "/myApplications" ||
        pathname === "/Income"
        ? 0
        : ref.current.scrollIntoView()
    );

  return (
    <div>


      <div className={classes.rightMenuMain}>


        <Link to="/FreelancerWorkPlace" className={classes.linkdesign}>

          <div
            ref={refLocaction}
            className={`${classes.leftMenu} ${pathname === "/FreelancerWorkPlace"
                ? classes.leftMenuSelected
                : ""
              }`}
          >
            {" "}
            <FcBriefcase size="18px" />
            <div className={classes.menuText}>Work Place</div>
          </div>


        </Link>
        <Link to="/WorkRequirementDashboard" className={classes.linkdesign}>

          <div
            ref={refLocaction}
            className={`${classes.leftMenu} ${pathname === "/WorkRequirementDashboard"
                ? classes.leftMenuSelected
                : ""
              }`}
          >
            {" "}
            <FcRules size="18px" />
            <div className={classes.menuText} onClick={() => { menuOptions('Revenue') }}>Find work</div>
          </div>
        </Link>

        <Link to="/myApplications" className={classes.linkdesign}>

          <div
            ref={refLocaction}
            className={`${classes.leftMenu} ${pathname === "/myApplications"
                ? classes.leftMenuSelected
                : ""
              }`}
          >
            {" "}
            <FcSalesPerformance size="18px" />
            <div className={classes.menuText} onClick={() => { menuOptions('Revenue') }}>Applications</div>
          </div>
        </Link>

        <Link to="/Income" className={classes.linkdesign}>

          <div
            ref={refLocaction}
            className={`${classes.leftMenu} ${pathname === "/Income"
                ? classes.leftMenuSelected
                : ""
              }`}
          >
            {" "}
            <FcPositiveDynamic size="18px" />
            <div className={classes.menuText} onClick={() => { menuOptions('Sales') }}>Income</div>
          </div>
        </Link>
        <a href={`${global.chatLink}/chat?senderId=${sessionStorage.getItem("userID")}`} onClick={(e) => { e.preventDefault(); encodeAndSendToChat(localStorage.getItem("userID")) }} target="_blank" className={classes.linkdesign}>
          <div className={classes.leftMenu}> <FcSms size='18px' />
            <div className={classes.menuText} onClick={() => { menuOptions('Chat') }}>Chat</div>
          </div>

        </a>
        <Link to="/notification" className={classes.linkdesign}>

          <div
            ref={refLocaction}
            className={`${classes.leftMenu} ${pathname === "/notification"
                ? classes.leftMenuSelected
                : ""
              }`}
          >
            {" "}
            <FcClock size="18px" />
            <div className={classes.menuText} onClick={() => { menuOptions('Notifications') }}>Notifications</div>
          </div>
        </Link>
        <Link to="/workProfile" className={classes.linkdesign}>

          <div

            className={classes.leftMenu}
          >

            <FcManager size="18px" />
            <div className={classes.menuText} >Work Profile</div>  <BiChevronDown className={classes.downIcon} size={20} /></div>

        </Link>

        {
          (window.location.pathname === '/workProfile' ||
            window.location.pathname === '/personalDetails' ||
            window.location.pathname === '/professionAndEducation' ||
            window.location.pathname === '/portfolio' ||
            window.location.pathname === '/companyDetails') &&

          <div className={classes.subMenu}>
            <Link to="/workProfile" className={classes.linkdesign}>


              <div
                ref={refLocaction}
                className={`${classes.leftMenu} ${pathname === "/workProfile"
                    ? classes.leftMenuSelected
                    : ""
                  }`}
              >
                {" "}

                <div className={classes.menuText}>Work Info</div>
              </div>
            </Link>
            <Link to="/personalDetails" className={classes.linkdesign}>
              <div
                ref={refLocaction}
                className={`${classes.leftMenu} ${pathname === "/personalDetails"
                    ? classes.leftMenuSelected
                    : ""
                  }`}
              >
                {" "}

                <div className={classes.menuText}>Personal Details</div>
              </div>
            </Link>
            <Link to="/professionAndEducation" className={classes.linkdesign}>
              <div
                ref={refLocaction}
                className={`${classes.leftMenu} ${pathname === "/professionAndEducation"
                    ? classes.leftMenuSelected
                    : ""
                  }`}
              >
                {" "}

                <div className={classes.menuText}>Profession & Education</div>
              </div>
            </Link>

            <Link to="/portfolio" className={classes.linkdesign}>


              <div
                ref={refLocaction}
                className={`${classes.leftMenu} ${pathname === "/portfolio"
                    ? classes.leftMenuSelected
                    : ""
                  }`}
              >
                {" "}

                <div className={classes.menuText} onClick={() => { menuOptions('portfolio') }} >Portfolio</div>
              </div>
            </Link>
            <Link to="/companyDetails" className={classes.linkdesign}>

              <div
                ref={refLocaction}
                className={`${classes.leftMenu} ${pathname === "/companyDetails"
                    ? classes.leftMenuSelected
                    : ""
                  }`}
              >
                {" "}

                <div className={classes.menuText}>Company Details</div>
              </div>
            </Link>

          </div>
        }

        <Link to="/FreelancerPackage" className={classes.linkdesign}>

          <div
            ref={refLocaction}
            className={`${classes.leftMenu} ${pathname === "/FreelancerPackage"
                ? classes.leftMenuSelected
                : ""
              }`}
          >
            {" "}
            <FcRating size="18px" />
            <div className={classes.menuText} onClick={() => { menuOptions('Membership') }}>Membership</div>
          </div>
        </Link>
        <Link to="/KycForm" className={classes.linkdesign}>

          <div
            ref={refLocaction}
            className={`${classes.leftMenu} ${pathname === "/KycForm"
                ? classes.leftMenuSelected
                : ""
              }`}
          >
            {" "}
            <FcApprove size="18px" />
            <div className={classes.menuText} onClick={() => { menuOptions('KYC') }}>KYC</div>
          </div>
        </Link>
        <Link to="/DashboardContact" className={classes.linkdesign}>

          <div
            ref={refLocaction}
            className={`${classes.leftMenu} ${pathname === "/DashboardContact"
                ? classes.leftMenuSelected
                : ""
              }`}
          >
            {" "}
            <FcCustomerSupport size="18px" />
            <div className={classes.menuText}>Contact support</div>
          </div>
        </Link>

      </div>

    </div>
  )
}

export default LeftMenu;