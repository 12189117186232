import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ActionButton from "../../Components/ActionButton";
import NewRequirementCard from "../../Components/ApplyRequirements/NewRequirementCard";
import KycBox from "../../Components/FreelancerDashboardWeb/KycBox";
import Package from "../../Components/FreelancerDashboardWeb/Package";
import ProfileHealth from "../../Components/FreelancerDashboardWeb/ProfileHealth";
import ProfileProgress from "../../Components/FreelancerDashboardWeb/ProfileProgress";
import MyApplicationSnippet from "../../Components/NewFreelancerDashBoard/MyApplicationSnippet";
import MyProposals from "../../Components/NewFreelancerDashBoard/MyProposals";
import NewFreelancerDashBoard from "../../Components/NewFreelancerDashBoard/NewFreelancerDashboard";
import NewFreelancerDashBoardMobile from "../../Components/NewFreelancerDashBoard/NewFreelancerDashBoardMobile";
import { Helmet } from "react-helmet";

import { useNavigate } from 'react-router-dom';
import classes from "./WorkPlace.module.css";
import { pageViewTracker } from "../../Components/GoogleTracking";

const WorkPlace = (props) => {
  let navigate = useNavigate();
  const [dayTime, setDayTime] = useState();

  const [isLoading, setIsLoading] = useState();
  const [postArequirementDetails, setPostArequirementDetails] = useState([]);
 
  const [freelancerIncome, setFreelancerIncome] = useState(false);
  const [freelancerProposal, setFreelancerProposal] = useState([]);

  const [freelancerPackage, setFreelancerPackage] = useState({
    activePackage:"",
    availableCredit:"",
    
  });
  const [kycStatus, setKycStatus] = useState(" ");
  const [recommendedRed, setRecommendedReq] = useState([]);
  const [firstName,setFirstName]=useState()
  const [profileHealthData, setProfileHealthData] = useState({
    userName: "",
    EmptyProfileFields: [],
    AccountBalance: "",
    ProfileCompleted: "",
    activePackage:"",
  });



  const userId = localStorage.getItem("userID")


  var today = new Date();
  var curHr = today.getHours();

  useEffect(() => {
    pageViewTracker();
  }, [])
  

  useEffect(() => {
   
    freelancerProfileStatusApi();
    if (curHr < 12) {
      setDayTime("Good Morning");
    } else if (curHr < 18) {
      setDayTime("Good Afternoon");
    } else {
      setDayTime("Good Evening");
    }
  }, []);


  const freelancerProfileStatusApi = () => {
    setIsLoading(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      FreelancerId: userId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/api/freelancerapp/rozgaarapi/CompleteFreelancerProfile", requestOptions)
      .then((response) => response.json())
      .then((result) => {


 
        if (result.status === "Success" && result.IsFPComplete === false) {

          localStorage.setItem('freelancerProfileStatus', result.IsFPComplete)
          navigate("/workProfile")


        } else {
          localStorage.setItem('freelancerProfileStatus', result.IsFPComplete)
          dashBoardData()
          

        }
      })


      .catch((error) => console.log("error", error))
      .finally(() => {
        setIsLoading(false);
      });
  };


  const dashBoardData = () => {
    setIsLoading(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      FreelancerId: userId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/api/freelancerapp/rozgaarapi/FreelancerDashboard", requestOptions)
      .then((response) => response.json())
      .then((result) => {

        setProfileHealthData({
          ...profileHealthData,
          userName: result.data.UserName,
          EmptyProfileFields: result.FreelancerProfileresponse.MandatoryFieldsLeft,
          AccountBalance: result.FreelancerIncome,
          ProfileCompleted: result.FreelancerProfileresponse.ProfilePercentComplete,
          activePackage:result.ActivePackage
        });
        setFreelancerIncome(result.FreelancerIncome)
        setFreelancerProposal(result.FreelancerProposal)
        setFreelancerPackage({...freelancerPackage, 
          activePackage:result.ActivePackage,
          availableCredit:result.AvailableCredit,
          
        })
        setKycStatus(result.KycStatus)
        setRecommendedReq(result.RecommendedRequirments)
        setFirstName(result.data.FirstName)
      })


      .catch((error) => console.log("error", error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    MyRequirementApplicationsAPI()
  }, [])

    const MyRequirementApplicationsAPI = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        
        var formdata = new FormData();
        formdata.append("FreelancerId", userId);
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow'
        };
        
        fetch(global.apiLink +"/api/freelancerapp/rozgaarapi/MyAaplication", requestOptions)
          .then(response => response.json())
          .then(result => {
              if(result.status_code === 200){
                 
                setPostArequirementDetails(result.dataFreelancerdetail)
               
              }
          })
          .catch(error => console.log('error', error));
    }
    

  
  const MobileWebHandlerSwitch = (device) => {
    switch (device) {
      case "Mobile":
        return (
          <NewFreelancerDashBoardMobile >
            <div className={classes.welcomeMessage}>
              <div>
                <div className={classes.firstWelcome}>
                  {" "}
                  {dayTime},
                  <div className={classes.welcomeName}>{firstName} </div>
                </div>
                <div className={classes.secondLine}>
                  {" "}
                  Hope you are having a lovely day!{" "}
                </div>
              </div>
            </div>

            <MyApplicationSnippet postArequirementDetails={postArequirementDetails} />
            <MyProposals freelancerProposal={freelancerProposal} />
            <ProfileHealth profileHealthData={profileHealthData} />
            <ProfileProgress freelancerIncome={freelancerIncome} />
            <Package freelancerPackage={freelancerPackage} />
          
            <KycBox KycStatus={kycStatus} />
            <div className={classes.recReq}>
              <NewRequirementCard RequirementData={recommendedRed} isLoading={isLoading} heading={"Recommended Requirements"} />
            </div>

           
          </NewFreelancerDashBoardMobile>
        );

      default:
        return (
          <NewFreelancerDashBoard >
            <div className={classes.welcomeMessage}>
              <div>
                <div className={classes.firstWelcome}>
                  {" "}
                  {dayTime},
                  <div className={classes.welcomeName}>
                    {firstName &&
                      firstName.charAt(0).toUpperCase() +
                      firstName.slice(1)}{" "}
                  </div>
                </div>
                <div className={classes.secondLine}>
                  {" "}
                  Hope you are having a lovely day!{" "}
                </div>
              </div>
              <Link to={"/requirementList"}>
                <ActionButton
                  buttonText="Search Freelance Jobs"
                  buttonType={"small"}
                />
              </Link>
            </div>

            <img
              src="../assets/banners/dashboardBannnerTop.jpg"
              className={classes.banner}
              alt="Interact_And_Work_With_Our_Global_Clientele"
              title="Interact_And_Work_With_Our_Global_Clientele"
              loading="lazy"
              width={"99%"}
              height={170}
            />

            <div className={classes.pageDivider}>
              <div className={classes.leftArea}>

                <MyApplicationSnippet postArequirementDetails={postArequirementDetails} />

                <div className={classes.recReq}>
                  <NewRequirementCard RequirementData={recommendedRed} isLoading={isLoading} heading={"Recommended Requirements"} />

                </div>

              </div>

              <div className={classes.rightArea}>
                <MyProposals freelancerProposal={freelancerProposal} />
                <ProfileProgress freelancerIncome={freelancerIncome} />
                <Package freelancerPackage={freelancerPackage} />
                <ProfileHealth profileHealthData={profileHealthData}  />
                <KycBox KycStatus={kycStatus} />
              </div>
            </div>
          </NewFreelancerDashBoard>
        );
    }
  };

  return <>
   <Helmet>
        <meta charSet="utf-8" />
        <title>{`Frequently Asked Questions about Freelance Jobs  | Rozgaar India`}</title>
        <meta
          name="description"
          content={`Read the Freelancer FAQ's to learn everything about Freelancing, freelance projects, remote work, contract work and gigs.`}
       
       />
           <link rel="canonical" href="https://www.rozgaarindia.com/FreelancerWorkPlace" /> 
      </Helmet>
  {MobileWebHandlerSwitch(props.device)}
  </>;
};
export default WorkPlace;
