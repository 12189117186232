import classes from "./LogoComp.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const LogoComp = () => {
  const PrevCarouselBtn = (props) => {
    const { style, onClick } = props;
    return (
      <div
        className={""}
        style={{
          ...style,
          display: "inline",
          position: "absolute",
          top: "28%",
          left: "1px",
          color: "#fff",
          zIndex: "100",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <FaAngleLeft size="30" />
      </div>
    );
  };
  const NextCarouselBtn = (props) => {
    const { style, onClick } = props;
    return (
      <div
        style={{
          ...style,
          display: "inline",
          position: "absolute",
          top: "28%",
          right: "1px",
          color: "#fff",
          zIndex: "100",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <FaAngleRight size="30" />
      </div>
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    prevArrow: <PrevCarouselBtn />,
    nextArrow: <NextCarouselBtn />,
  };
  const div = [
    {
      src: "/assets/TrustedCompaniesLogo/Hero_Insurance.svg",
      title:"Hero_Insurance",
      height:"25px"
    },
    {
      src: "/assets/TrustedCompaniesLogo/Hyundai.svg",
      title:"Hyundai",
      height:"25px"
    },
    {
      src: "/assets/TrustedCompaniesLogo/Hero.svg",
      title:"Hero",
      height:"25px"
    },
    {
      src: "/assets/TrustedCompaniesLogo/TimesAscent.svg",
      title:"TimesAscent",
      height:"25px"
    },
    {
      src: "/assets/TrustedCompaniesLogo/Amazon_logo.svg",
      title:"Amazon_logo",
      height:"30px"
    },
    {
      src: "/assets/TrustedCompaniesLogo/Skill_India.svg",
      title:"Skill_India",
      height:"35px"
    },
    {
      src: "/assets/TrustedCompaniesLogo/Digital_India.svg",
      title:"Digital_India",
      height:"30px"
    },
  ];
  return (
    <div className={classes.mainContainer}>
      <Slider {...settings}>
        {div.map((item, index) => (
          <div key={index} className={classes.mapdiv}>
            <img src={item.src} alt={item.title} className={classes.mob_img} loading="lazy" title={item.title} width={"60%"} height={item.height} />
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default LogoComp;
