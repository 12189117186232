import React, { useState, useEffect } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { BsLink45Deg } from "react-icons/bs";
import Loader from "react-loader-spinner";
import { Helmet } from "react-helmet";
import ActionButton from "../../Components/ActionButton";
import NewFreelancerDashBoard from "../../Components/NewFreelancerDashBoard/NewFreelancerDashboard";
import NewFreelancerDashBoardMobile from "../../Components/NewFreelancerDashBoard/NewFreelancerDashBoardMobile";
import RiTextInputs from "../../Components/PostRequirement/RiTextInputs";
import classes from "../../Screens/KycForm/KycForm.module.css";
import { BsCheckLg } from "react-icons/bs";
import { pageViewTracker } from "../../Components/GoogleTracking";

const KycForm = (props) => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [isLodaingPage, setIsLodaingPage] = useState(false);
  const [status, setStatus] = useState("");
  const [statusReason, setStatusReason] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pin, setPin] = useState("");
  const [accHolderName, setAccHolderName] = useState("");
  const [accNumber, setAccNumber] = useState("");
  const [cnfmAccNumber, setCnfmAccNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [uploadPan, setUploadPan] = useState("");
  const [uploadIdProofFront, setUploadIdProofFront] = useState("");
  const [uploadIdProofBack, setUploadIdProofBack] = useState("");
  const [error, setError] = useState({});
  const [cityData, setCityData] = useState("");
  const [cityLoading, setCityLoading] = useState(false);
  const [getIFSCApiData, setGetIFSCApiData] = useState("");
  const [documentName, setDocumentName] = useState("");
  const todayDate = new Date().toISOString().slice(0, 10);



  useEffect(() => {
    KycStatus();
    pageViewTracker();
  }, []);

  const KycFormApi = () => {
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );

    var formdata = new FormData();
    formdata.append("FreelancerId", localStorage.getItem("userID"));
    formdata.append("FullName", fullName || "");
    formdata.append("Email", email || "");
    formdata.append("Mobile", mobile || "");
    formdata.append("DateOfBirth", dateOfBirth || "");
    formdata.append("Gender", gender || "");
    formdata.append("Address", address || "");
    formdata.append("Country", country || "");
    formdata.append("State", state || "");
    formdata.append("City", city || "");
    formdata.append("Pincode", pin || "");
    formdata.append("DocumentName", documentName || "");
    if (typeof uploadIdProofFront === "object") {
      formdata.append("DocumentFrontSide", uploadIdProofFront || "");
    }
    if (typeof uploadIdProofBack === "object") {
      formdata.append("UploadDocumentBackside", uploadIdProofBack || "");
    }
    if (typeof uploadPan === "object") {
      formdata.append("PanCard", uploadPan || "");
    }
    formdata.append("AccountHolderName", accHolderName || "");
    formdata.append("AccountNumber", accNumber || "");
    formdata.append("BankName", bankName || "");
    formdata.append("BranchName", branchName || "");
    formdata.append("Ifsc", ifsc || "");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(global.apiLink + "/api/rozgaarapi/RzKyc", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          
          KycFormGetAPi();
          
        } else {
          alert("Opps!,There is some techincal error. Please contact support");
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const KycStatus=()=>{
    var myHeaders = new Headers();
  myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({
    "FreelancerId": localStorage.getItem("userID")
  });
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  fetch("https://api-preview.rozgaarindia.com/api/rozgaarapi/KycDetailbyId", requestOptions)
    .then(response => response.json())
    .then(result => {
      if (result.status_code === 200) {
        result.message === "data not found"
        ? setStatus("new")
        : setStatus(result.data.Status);
        setFullName(result.data.FullName);
        setEmail(result.data.Email || localStorage.getItem("USEREMAIL"));
        setMobile(result.data.Mobile || localStorage.getItem("USERMOBILENO"));
        setDateOfBirth(result.data.DateOfBirth);
        setGender(result.data.Gender);
        setAddress(result.data.Address);
        setCountry(result.data.Country);
        setState(result.data.State);
        cityList(result.data.Country, result.data.State);
        setCity(result.data.City);
        setPin(result.data.Pincode);
        setDocumentName(result.data.DocumentName);
        setAccHolderName(result.data.AccountHolderName);
        setAccNumber(result.data.AccountNumber);
        setCnfmAccNumber(result.data.AccountNumber);
        setIfsc(result.data.Ifsc);
        setBankName(result.data.BankName);
        setBranchName(result.data.Branchname);
        setUploadPan(result.data.PanCard);
        setUploadIdProofFront(result.data.DocumentFrontSide);
        setUploadIdProofBack(result.data.UploadDocumentBackside);
        setStatusReason(result.data.StatusReason)
      }
    })
    .catch(error => console.log('error', error));
  }

  const KycFormGetAPi = () => {
    setIsLodaingPage(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      FreelancerId: localStorage.getItem("userID"),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/api/rozgaarapi/KycSubmmit", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status=== "SUCCESS") {
          setStatus(result.data.Status)
        
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setIsLodaingPage(false);
      });
  };



  const cityList = (country, state) => {
    setCityLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Country: country,
      State: state,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/api/rozgaarapi/GetCityLocatinList", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "Success") {
          setCityData(result.data);
        } else {
         
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setCityLoading(false);
      });
  };

  const getIFSBApi = (ifsccode) => {
    if (ifsccode.length < 11) {
      return;
    }
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch("https://ifsc.razorpay.com/" + ifsccode, requestOptions)
      .then((response) => response.json())
      .then((result) => {
      
        setGetIFSCApiData(result);
        setBranchName(result.BRANCH);
        setBankName(result.BANK);
      })
      .catch((error) => console.log("error", error));
  };


  const mobileRegex = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setMobile(e.target.value);
    }
  };

  const pinCodeRegex = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setPin(e.target.value);
    }
  };

  const accNumberRegex = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setAccNumber(e.target.value);
    }
  };
  const cnfmAccNumberRegex = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setCnfmAccNumber(e.target.value);
    }
  };
 
  const idProofFront = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setUploadIdProofFront(e.target.files[0]);
    }
  };

  const idProofBack = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setUploadIdProofBack(e.target.files[0]);
    }
  };

  const panImageUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setUploadPan(e.target.files[0]);
    }
  };

  const kycFormValidation = () => {
    let errors = {};
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
     
    let isValid = true;
    if (!fullName || fullName === "") {
      errors.fullName = "Full Name field is mandatory";
      isValid = false;
    }
    if (!email || email === "") {
      errors.email = "Email field is mandatory";
      isValid = false;
    } else if (emailRegex.test(email) === false) {
      errors.email = "Invalid email entered";
      isValid = false;
    }
    if (!mobile || mobile === "") {
      errors.mobile = "Mobile field is mandatory";
      isValid = false;
    }
    if (!dateOfBirth || dateOfBirth === "") {
      errors.dateOfBirth = "Date Of Birth field is mandatory";
      isValid = false;
    }
    if (!gender || gender === "") {
      errors.gender = "Gender field is mandatory";
      isValid = false;
    }
    if (!address || address === "") {
      errors.address = "Address field is mandatory";
      isValid = false;
    }
    if (!country || country === "") {
      errors.country = "Country field is mandatory";
      isValid = false;
    }
    if (!state || state === "") {
      errors.state = "State field is mandatory";
      isValid = false;
    }
    if (!city || city === "") {
      errors.city = "City field is mandatory";
      isValid = false;
    }
    if (!pin || pin === "") {
      errors.pin = "Pin field is mandatory";
      isValid = false;
    }
    if (!documentName || documentName === "") {
      errors.documentName = "Document Name field is mandatory";
      isValid = false;
    }
    if (!accHolderName || accHolderName === "") {
      errors.accHolderName = "Account Holder Name field is mandatory";
      isValid = false;
    }
    if (!accNumber || accNumber === "") {
      errors.accNumber = "Account Number field is mandatory";
      isValid = false;
    }
    if (!cnfmAccNumber || cnfmAccNumber === ""||cnfmAccNumber !== accNumber) {
      errors.cnfmAccNumber = "Confirm Account field is mandatory";
      isValid = false;
    }
    if (accNumber !== cnfmAccNumber) {
      errors.accNumber = "Account Number does not match";
      isValid = false;
    }
    if (!ifsc || ifsc === "" ) {
      errors.ifsc = "Ifsc code field is mandatory";
      isValid = false;
    }
   if (!bankName&& ifsc){
    errors.bankName ="Please enter valid ifsc code";
    isValid = false;
   }
    if (!uploadPan || uploadPan === "") {
      errors.uploadPan = "Upload Pan field is mandatory";
      isValid = false;
    }

    if (!uploadIdProofFront || uploadIdProofFront === "") {
      errors.uploadIdProofFront = "Upload Id Proof front side";
      isValid = false;
    }
    if (!uploadIdProofBack || uploadIdProofBack === "") {
      errors.uploadIdProofBack = "Upload Id Proof back side";
      isValid = false;
    }
    setError(errors);
    return isValid;
  };
 
  const SwitchStatus = (status) => {
    
    switch (status) {
      case "pending":
        return (
          <div className={classes.pending}>
            Your kyc is pending.{statusReason}
          </div>
        );
      case "Rejected":
        return (
          <div className={classes.rejected}>
            Your kyc is rejected.{statusReason}
          </div>
        );
        case "Approved":
        return (
          <div className={classes.approved}>
            Your kyc has been approved.
          </div>
        );
      default:
        return "";
    }
  };
 
  const MobileWebHandlerSwitch = (device) => {
    switch (device) {
      case "Mobile":
        return (
          <NewFreelancerDashBoardMobile>
            {isLodaingPage ? (
              <div className={classes.loadercenter}>
                <Loader type="TailSpin" color="red" width={50} height={50} />
              </div>
            ) : (
              <>
                {status === "submitted" || status === "approve" ? (
                  <div className={classes.kycFormMainContainer}>
                    <div className={classes.center}>
                      <BsCheckLg className={classes.statusicon} />
                      <div className={classes.size}>
                        {" "}
                        Your kyc form has been {status} successfully{" "}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={classes.kycFormMainContainerForm}>
                     {SwitchStatus(status)}
                  <div className={status==="Approved"?classes.kycFormMainContainerDisabled:classes.kycFormMainContainer}>
                   
                   
                    <h1 className={classes.kycHeading}>Personal Information</h1>
                    <RiTextInputs
                      input={"SingleLineInput"}
                      type={"text"}
                      label={"Full Name"}
                      placeHolder={"Enter your full name"}
                      readOnly={status === "approve" || status === "submitted"}
                      onInput={() => {
                        error.fullName = " ";
                      }}
                      onChange={(e) => setFullName(e.target.value)}
                      value={fullName}
                    />
                    <div className={classes.ErrorMessage}>
                      {fullName ? "" : error.fullName}
                    </div>

                    <div className={classes.flex}>
                      <div className={classes.widthhalf}>
                        <RiTextInputs
                          input={"SingleLineInput"}
                          type={"email"}
                          label={"Email"}
                          placeHolder={"Enter your email"}
                          readOnly={
                            status === "approve" ||
                            status === "submitted" ||
                            localStorage.getItem("USEREMAIL")
                          }
                          onInput={() => {
                            error.email = " ";
                          }}
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                        <div className={classes.ErrorMessage}>
                          {email ? "" : error.email}
                        </div>
                      </div>
                      <div className={classes.widthhalf}>
                        <RiTextInputs
                          input={"SingleLineInput"}
                          label={"Mobile Number"}
                          maxLength="10"
                          minLength="0"
                          readOnly={
                            status === "approve" ||
                            status === "submitted" ||
                            localStorage.getItem("USERMOBILENO")
                          }
                          placeHolder={"Enter your mobile number"}
                          onInput={() => {
                            error.mobile = " ";
                          }}
                          onChange={(e) => {
                            mobileRegex(e);
                          }}
                          value={mobile}
                        />
                        <div className={classes.ErrorMessage}>
                          {mobile ? "" : error.mobile}
                        </div>
                      </div>
                    </div>

                    <div className={classes.flex}>
                      <div className={classes.widthhalf}>
                        <RiTextInputs
                          input={"date"}
                          type={"date"}
                          label={"Date of Birth"}
                          name={"Date of Birth"}
                          max={todayDate}
                          placeHolder={""}
                          readOnly={
                            status === "approve" || status === "submitted"
                          }
                          onInput={() => {
                            error.dateOfBirth = " ";
                          }}
                          onChange={(e) => setDateOfBirth(e.target.value)}
                          value={dateOfBirth}
                        />
                        <div className={classes.ErrorMessage}>
                          {dateOfBirth ? "" : error.dateOfBirth}
                        </div>
                      </div>
                      <div className={classes.widthhalf}>
                        <RiTextInputs
                          input={"Dropdown"}
                          label={"Select Gender"}
                          onChange={(e) => setGender(e.target.value)}
                          value={gender}
                          onInput={() => {
                            error.gender = " ";
                          }}
                          disabled={
                            status === "approve" || status === "submitted"
                          }
                        />
                        <div className={classes.ErrorMessage}>
                          {gender ? "" : error.gender}
                        </div>
                      </div>
                    </div>
                    <RiTextInputs
                      input={"SingleLineInput"}
                      type={"text"}
                      label={"Address"}
                      readOnly={status === "approve" || status === "submitted"}
                      placeHolder={"Address"}
                      onInput={() => {
                        error.address = " ";
                      }}
                      onChange={(e) => setAddress(e.target.value)}
                      value={address}
                    />
                    <div className={classes.ErrorMessage}>
                      {address ? "" : error.address}
                    </div>
                    <RiTextInputs
                      input={"Dropdown"}
                      placeHolder={"Select Country"}
                      label={"Select Country"}
                      value={country}
                      onInput={() => {
                        error.country = " ";
                      }}
                      default={country}
                      onChange={(e) => setCountry(e.target.value)}
                      disabled={status === "approve" || status === "submitted"}
                    />
                    <div className={classes.ErrorMessage}>
                      {country ? "" : error.country}
                    </div>

                    <div className={classes.flex}>
                      <div className={classes.widthThirtyThree}>
                        <RiTextInputs
                          input={"Dropdown"}
                          label={"Select State"}
                          placeHolder={"State"}
                          selectedCountry={country}
                          onInput={() => {
                            error.country = " ";
                          }}
                          onChange={(e) => {
                            setState(e.target.value);
                            cityList(country, e.target.value);
                          }}
                          value={state}
                          defaultState={state}
                          disabled={
                            status === "approve" || status === "submitted"
                          }
                        />
                        <div className={classes.ErrorMessage}>
                          {state ? "" : error.state}
                        </div>
                      </div>
                      <div className={classes.widthThirtyThree}>
                        {cityLoading ? (
                          <div className={classes.inputArea}>
                            <Loader
                              type="TailSpin"
                              color="red"
                              width={20}
                              height={18}
                            />
                          </div>
                        ) : (
                          <>
                            <RiTextInputs
                              input={"Dropdown"}
                              label={"Select City"}
                              placeHolder={"Select City"}
                              displayData={cityData}
                              onInput={() => {
                                error.city = " ";
                              }}
                              onChange={(e) => setCity(e.target.value)}
                              value={city}
                              disabled={
                                status === "approve" || status === "submitted"
                              }
                            />
                            <div className={classes.ErrorMessage}>
                              {city ? "" : error.city}
                            </div>
                          </>
                        )}
                      </div>
                      <div className={classes.widthThirtyThree}>
                        <RiTextInputs
                          input={"SingleLineInput"}
                          placeHolder={"Enter pin code"}
                          type={"text"}
                          label={"Pin Code"}
                          maxLength="6"
                          onInput={() => {
                            error.pin = " ";
                          }}
                          readOnly={
                            status === "approve" || status === "submitted"
                          }
                          onChange={(e) => {
                            pinCodeRegex(e);
                          }}
                          value={pin}
                        />
                        <div className={classes.ErrorMessage}>
                          {pin ? "" : error.pin}
                        </div>
                      </div>
                    </div>
                    <div className={classes.uploadIdProof}>Upload ID Proof</div>
                    <div>
                      <RiTextInputs
                        input={"Dropdown"}
                        label={"Select Document Name"}
                        onChange={(e) => setDocumentName(e.target.value)}
                        value={documentName}
                        onInput={() => {
                          error.documentName = " ";
                        }}
                        disabled={
                          status === "approve" || status === "submitted"
                        }
                      />
                         <div className={classes.ErrorMessage}>
                      {documentName ? "" : error.documentName}
                    </div>
                    </div>
                    <div className={classes.flexIdProof}>
                      <div className={classes.widthimageupload}>
                        <RiTextInputs
                          input={"file"}
                          accept="image/*"

                          onInput={() => {
                            error.uploadIdProofFront = " ";
                          }}
                          placeHolder={
                            uploadIdProofFront
                              ? typeof uploadIdProofFront === "object"
                                ? uploadIdProofFront.name
                                : uploadIdProofFront.split("/").pop()
                              : "upload Id front image"
                          }
                          disabled={
                            status === "approve" || status === "submitted"
                          }
                          label={"Upload Id front"}
                          icon={
                            typeof uploadIdProofFront === "object" ? (
                              <AiFillCheckCircle size="25" color="green" />
                            ) : (
                              <BsLink45Deg size="25" />
                            )
                          }
                          onChange={idProofFront}
                        />
                        <div className={classes.ErrorMessage}>
                          {uploadIdProofFront ? "" : error.uploadIdProofFront}
                        </div>
                      </div>
                      <div className={classes.widthimageupload}>
                        <RiTextInputs
                          input={"file"}
                          accept="image/*"

                          onInput={() => {
                            error.uploadIdProofBack = " ";
                          }}
                          placeHolder={
                            uploadIdProofBack
                              ? typeof uploadIdProofBack === "object"
                                ? uploadIdProofBack.name
                                : uploadIdProofBack.split("/").pop()
                              : "Upload Id Proof back image"
                          }
                          disabled={
                            status === "approve" || status === "submitted"
                          }
                        
                          label={"Upload ID back"}
                          icon={
                            typeof uploadIdProofBack === "object" ? (
                              <AiFillCheckCircle size="25" color="green" />
                            ) : (
                              <BsLink45Deg size="25" />
                            )
                          }
                          onChange={idProofBack}
                        />
                        <div className={classes.ErrorMessage}>
                          {uploadIdProofBack ? "" : error.uploadIdProofBack}
                        </div>
                      </div>
                    </div>
                    <h1>Bank Details</h1>
                    <RiTextInputs
                      input={"SingleLineInput"}
                      type={"text"}
                      label={"Account Holder Name"}
                      placeHolder={"Enter your account holder name"}
                      readOnly={status === "approve" || status === "submitted"}
                      onChange={(e) => setAccHolderName(e.target.value)}
                      onInput={() => {
                        error.accHolderName = " ";
                      }}
                      value={accHolderName}
                    />
                    <div className={classes.ErrorMessage}>
                      {accHolderName ? "" : error.accHolderName}
                    </div>
                    <div className={classes.flex}>
                      <div className={classes.widthhalf}>
                        <RiTextInputs
                          input={"SingleLineInput"}
                          type={"text"}
                          label={"Account Number"}
                          placeHolder={"Enter your account number"}
                          readOnly={
                            status === "approve" || status === "submitted"
                          }
                          onInput={() => {
                            error.accNumber = " ";
                          }}
                          onChange={(e) => {
                            accNumberRegex(e);
                          }}
                          value={accNumber}
                        />

                        <div className={classes.ErrorMessage}>
                          {accNumber ? "" : error.accNumber}
                        </div>
                      </div>
                      <div className={classes.widthhalf}>
                        <RiTextInputs
                          input={"SingleLineInput"}
                          type={"text"}
                          label={"Confirm Account Number"}
                          placeHolder={"Confirm your account number"}
                          readOnly={
                            status === "approve" || status === "submitted"
                          }
                          onInput={() => {
                            error.cnfmAccNumber = " ";
                          }}
                          onChange={(e) => {
                            cnfmAccNumberRegex(e);
                          }}
                          value={cnfmAccNumber}
                        />
                        <div className={classes.ErrorMessage}>
                        {accNumber &&
                          cnfmAccNumber &&
                          accNumber !== cnfmAccNumber
                            ? "Please enter valid account number"
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className={classes.flex}>
                      <div className={classes.widthThirtyThree}>
                        <RiTextInputs
                          input={"SingleLineInput"}
                          type={"text"}
                          label={"IFSC Code"}
                          maxLength="11"
                          placeHolder={"Enter your IFSC code"}
                          onInput={() => {
                            error.ifsc = " ";
                          }}
                          readOnly={
                            status === "approve" || status === "submitted"
                          }
                          onChange={(e) => {
                            setIfsc(e.target.value);
                            getIFSBApi(e.target.value);
                          }}
                          value={ifsc}
                        />
                        <div className={classes.ErrorMessage}>
                          {ifsc ? "" : error.ifsc}
                        </div>
                      </div>

                      <div className={classes.widthThirtyThree}>
                        <RiTextInputs
                          input={"SingleLineInput"}
                          type={"text"}
                          label={"Bank Name"}
                          placeHolder={"Enter your bank name"}
                          onInput={() => {
                            error.bankName = " ";
                          }}
                          readOnly={
                            status === "approve" || status === "submitted"
                          }
                        
                          value={bankName}
                        />
                        <div className={classes.ErrorMessage}>
                          {bankName ? "" : error.bankName}
                        </div>
                      </div>
                      <div className={classes.widthThirtyThree}>
                        <RiTextInputs
                          input={"SingleLineInput"}
                          type={"text"}
                          label={"Branch Name"}
                          placeHolder={"Enter your branch name"}
                          onInput={() => {
                            error.branchName = " ";
                          }}
                          readOnly={
                            status === "approve" || status === "submitted"
                          }
                         
                          value={branchName}
                        />
                        <div className={classes.ErrorMessage}>
                          {branchName ? "" : error.branchName}
                        </div>
                      </div>
                    </div>
                    <div className={classes.widthimageupload}>
                      {country === "India" || country === "" ? (
                        <RiTextInputs
                          input={"file"}
                          accept="image/*"
                          onInput={() => {
                            error.uploadPan = " ";
                          }}
                          placeHolder={
                            uploadPan
                              ? typeof uploadPan === "object"
                                ? uploadPan.name
                                : uploadPan.split("/").pop()
                              : "Upload your pan card image"
                          }
                          label={"Upload Pan Card"}
                          disabled={
                            status === "approve" || status === "submitted"
                          }
                          icon={
                            typeof uploadPan === "object" ? (
                              <AiFillCheckCircle size="25" color="green" />
                            ) : (
                              <BsLink45Deg size="25" />
                            )
                          }
                          onChange={panImageUpload}
                        />
                      ) : (
                        <RiTextInputs
                          input={"file"}
                          onInput={() => {
                            error.uploadPan = " ";
                          }}
                          placeHolder={
                            uploadPan
                              ? typeof uploadPan === "object"
                                ? uploadPan.name
                                : uploadPan.split("/").pop()
                              : "Upload your Driving license image"
                          }
                          label={"Upload Driving license"}
                          disabled={
                            status === "approve" || status === "submitted"
                          }
                          icon={
                            typeof uploadPan === "object" ? (
                              <AiFillCheckCircle size="25" color="green" />
                            ) : (
                              <BsLink45Deg size="25" />
                            )
                          }
                          onChange={panImageUpload}
                        />
                      )}

                      <div className={classes.ErrorMessage}>
                        {uploadPan ? "" : error.uploadPan}
                      </div>
                    </div>
                    {isLoading ? (
                      <Loader
                        type="TailSpin"
                        color="red"
                        width={20}
                        height={18}
                      />
                    ) : (
                      <div className={classes.submitButton}>
                        <ActionButton
                          buttonText={"Submit"}
                          onClick={() => {kycFormValidation()?KycFormApi():<></>}}
                        />
                      </div>
                    )}
                  </div>
                  </div>
                )}
              </>
            )}
          </NewFreelancerDashBoardMobile>
        );
      default:
        return (
          <NewFreelancerDashBoard>
            {isLodaingPage ? (
              <div className={classes.loadercenter}>
                <Loader type="TailSpin" color="red" width={100} height={100} />
              </div>
            ) : (
              <>
                {status === "submitted" || status === "approve" ? (
                  <div className={classes.kycFormMainContainer}>
                    <div className={classes.center}>
                      <BsCheckLg className={classes.statusicon} />
                      <div className={classes.size}>
                        {" "}
                        Your kyc form has been {status} successfully{" "}
                      </div>
                    </div>
                  </div>
                ) : (
               <div className={classes.kycFormMainContainerForm}>
              
                 {SwitchStatus(status)}
                  <div className={(status==="Approved"||status==="pending")?classes.kycFormMainContainerDisabled:classes.kycFormMainContainer}>
                  

                  

                  <h1 className={classes.kycHeading}>Personal Information</h1>
                  <RiTextInputs
                    input={"SingleLineInput"}
                    type={"text"}
                    label={"Full Name"}
                    placeHolder={"Enter your full name"}
                    readOnly={status === "approve" || status === "submitted"}
                    onChange={(e) => setFullName(e.target.value)}
                    onInput={() => {
                      error.fullName = " ";
                    }}
                    value={fullName}
                  />
                  <div className={classes.ErrorMessage}>
                    {fullName ? "" : error.fullName}
                  </div>

                  <div className={classes.flex}>
                    <div className={classes.widthhalf}>
                      <RiTextInputs
                        input={"SingleLineInput"}
                        type={"email"}
                        label={"Email"}
                        placeHolder={"Enter your email"}
                        readOnly={
                          status === "approve" ||
                          status === "submitted" ||
                          localStorage.getItem("USEREMAIL")
                        }
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        onInput={() => {
                          error.email = " ";
                        }}
                      />
                      <div className={classes.ErrorMessage}>
                        {email ? "" : error.email}
                      </div>
                    </div>
                    <div className={classes.widthhalf}>
                      <RiTextInputs
                        input={"SingleLineInput"}
                        label={"Mobile Number"}
                        maxLength="10"
                        minLength="0"
                        readOnly={
                          status === "approve" ||
                          status === "submitted" ||
                          localStorage.getItem("USERMOBILENO")
                        }
                        placeHolder={"Enter your mobile number"}
                        onInput={() => {
                          error.mobile = " ";
                        }}
                        onChange={(e) => {
                          mobileRegex(e);
                        }}
                        value={mobile}
                      />
                      <div className={classes.ErrorMessage}>
                        {mobile ? "" : error.mobile}
                      </div>
                    </div>
                  </div>

                  <div className={classes.flex}>
                    <div className={classes.widthhalf}>
                      <RiTextInputs
                        input={"date"}
                        type={"date"}
                        label={"Date of Birth"}
                        name={"Date of Birth"}
                        placeHolder={""}
                        max={todayDate}
                        readOnly={
                          status === "approve" || status === "submitted"
                        }
                        onChange={(e) => setDateOfBirth(e.target.value)}
                        onInput={() => {
                          error.dateOfBirth = " ";
                        }}
                        value={dateOfBirth}
                      />
                     
                      <div className={classes.ErrorMessage}>
                        {dateOfBirth ? "" : error.dateOfBirth}
                      </div>
                    </div>
                    <div className={classes.widthhalfgender}>
                      <RiTextInputs
                        input={"Dropdown"}
                        label={"Select Gender"}
                        onChange={(e) => setGender(e.target.value)}
                        value={gender}
                        onInput={() => {
                          error.gender = " ";
                        }}
                        disabled={
                          status === "approve" || status === "submitted"
                        }
                      />
                      <div className={classes.ErrorMessage}>
                        {gender ? "" : error.gender}
                      </div>
                    </div>
                  </div>
                  <RiTextInputs
                    input={"SingleLineInput"}
                    type={"text"}
                    label={"Address"}
                    readOnly={status === "approve" || status === "submitted"}
                    placeHolder={"Address"}
                    onInput={() => {
                      error.address = " ";
                    }}
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                  />
                  <div className={classes.ErrorMessage}>
                    {address ? "" : error.address}
                  </div>
                  <RiTextInputs
                    input={"Dropdown"}
                    placeHolder={"Select Country"}
                    label={"Select Country"}
                    value={country}
                    default={country}
                    onInput={() => {
                      error.country = " ";
                    }}
                    onChange={(e) => setCountry(e.target.value)}
                    disabled={status === "approve" || status === "submitted"}
                  />
                  <div className={classes.ErrorMessage}>
                    {country ? "" : error.country}
                  </div>

                  <div className={classes.flex}>
                    <div className={classes.widthThirtyThree}>
                      <RiTextInputs
                        input={"Dropdown"}
                        label={"Select State"}
                        placeHolder={"State"}
                        onInput={() => {
                          error.state = " ";
                        }}
                        selectedCountry={country}
                        onChange={(e) => {
                          setState(e.target.value);
                          cityList(country, e.target.value);
                        }}
                        value={state}
                        defaultState={state}
                        disabled={
                          status === "approve" || status === "submitted"
                        }
                      />
                      <div className={classes.ErrorMessage}>
                        {state ? "" : error.state}
                      </div>
                    </div>
                    <div className={classes.widthThirtyThree}>
                      {cityLoading ? (
                        <div className={classes.inputArea}>
                          <Loader
                            type="TailSpin"
                            color="red"
                            width={20}
                            height={18}
                          />
                        </div>
                      ) : (
                        <>
                          <RiTextInputs
                            input={"Dropdown"}
                            label={"Select City"}
                            placeHolder={"Select City"}
                            displayData={cityData}
                            onChange={(e) => setCity(e.target.value)}
                            value={city}
                            onInput={() => {
                              error.city = " ";
                            }}
                            disabled={
                              status === "approve" || status === "submitted"
                            }
                          />
                          <div className={classes.ErrorMessage}>
                            {city ? "" : error.city}
                          </div>
                        </>
                      )}
                    </div>
                    <div className={classes.widthThirtyThree}>
                      <RiTextInputs
                        input={"SingleLineInput"}
                        placeHolder={"Enter pin code"}
                        type={"text"}
                        label={"Pin Code"}
                        maxLength="6"
                        readOnly={
                          status === "approve" || status === "submitted"
                        }
                        onInput={() => {
                          error.pin = " ";
                        }}
                        onChange={(e) => {
                          pinCodeRegex(e);
                        }}
                        value={pin}
                      />
                      <div className={classes.ErrorMessage}>
                        {pin ? "" : error.pin}
                      </div>
                    </div>
                  </div>
                 
                  <RiTextInputs
                    input={"Dropdown"}
                    label={"Select Document Name"}
                    onChange={(e) => setDocumentName(e.target.value)}
                    value={documentName}
                    onInput={() => {
                      error.documentName = " ";
                    }}
                    disabled={status === "approve" || status === "submitted"}
                  />
                     <div className={classes.ErrorMessage}>
                    {documentName ? "" : error.documentName}
                  </div>
                  <div className={classes.flexIdProof}>
                    <div className={classes.widthimageupload}>
                      <RiTextInputs
                        input={"file"}
                        accept="image/*"
                        onInput={() => {
                          error.uploadIdProofFront = " ";
                        }}
                        placeHolder={
                          uploadIdProofFront
                            ? typeof uploadIdProofFront === "object"
                              ? uploadIdProofFront.name
                              : uploadIdProofFront.split("/").pop()
                            : "upload Id front image"
                        }
                        disabled={
                          status === "approve" || status === "submitted"
                        }
                        label={"Upload Id front"}
                        icon={
                          typeof uploadIdProofFront === "object" ? (
                            <AiFillCheckCircle size="25" color="green" />
                          ) : (
                            <BsLink45Deg size="25" />
                          )
                        }
                        onChange={idProofFront}
                      />
                      <div className={classes.ErrorMessage}>
                        {uploadIdProofFront ? "" : error.uploadIdProofFront}
                      </div>
                    </div>
                    <div className={classes.widthimageupload}>
                      <RiTextInputs
                        input={"file"}
                        accept="image/*"
                        onInput={() => {
                          error.uploadIdProofBack = " ";
                        }}
                        placeHolder={
                          uploadIdProofBack
                            ? typeof uploadIdProofBack === "object"
                              ? uploadIdProofBack.name
                              : uploadIdProofBack.split("/").pop()
                            : "Upload Id Proof back image"
                        }
                        disabled={
                          status === "approve" || status === "submitted"
                        }
                      
                        label={"Upload Id back"}
                        icon={
                          typeof uploadIdProofBack === "object" ? (
                            <AiFillCheckCircle size="25" color="green" />
                          ) : (
                            <BsLink45Deg size="25" />
                          )
                        }
                        onChange={idProofBack}
                      />
                      <div className={classes.ErrorMessage}>
                        {uploadIdProofBack ? "" : error.uploadIdProofBack}
                      </div>
                    </div>
                  </div>
                  <h1>Bank Details</h1>
                  <RiTextInputs
                    input={"SingleLineInput"}
                    type={"text"}
                    label={"Account Holder Name"}
                    placeHolder={"Enter your account holder name"}
                    onInput={() => {
                      error.accHolderName = " ";
                    }}
                    readOnly={status === "approve" || status === "submitted"}
                    onChange={(e) => setAccHolderName(e.target.value)}
                    value={accHolderName}
                  />
                  <div className={classes.ErrorMessage}>
                    {accHolderName ? "" : error.accHolderName}
                  </div>
                  <div className={classes.flex}>
                    <div className={classes.widthhalf}>
                      <RiTextInputs
                        input={"SingleLineInput"}
                        type={"text"}
                        label={"Account Number"}
                        onInput={() => {
                          error.accNumber = " ";
                        }}
                        placeHolder={"Enter your account number"}
                        readOnly={
                          status === "approve" || status === "submitted"
                        }
                        onChange={(e) => {
                          accNumberRegex(e);
                        }}
                        value={accNumber}
                      />

                      <div className={classes.ErrorMessage}>
                        {accNumber ? "" : error.accNumber}
                      </div>
                    </div>
                    <div className={classes.widthhalf}>
                      <RiTextInputs
                        input={"SingleLineInput"}
                        type={"text"}
                        label={"Confirm Account Number"}
                        placeHolder={"Confirm your account number"}
                        readOnly={
                          status === "approve" || status === "submitted"
                        }
                        onInput={() => {
                          error.cnfmAccNumber = " ";
                        }}
                        onChange={(e) => {
                          cnfmAccNumberRegex(e);
                        }}
                        value={cnfmAccNumber}
                      />
                      <div className={classes.ErrorMessage}>
                        {accNumber &&
                        cnfmAccNumber &&
                        accNumber !== cnfmAccNumber
                          ? "Please enter valid account number"
                          : ""}{" "}
                      </div>
                    </div>
                  </div>
                  <div className={classes.flex}>
                    <div className={classes.widthThirtyThree}>
                      <RiTextInputs
                        input={"SingleLineInput"}
                        type={"text"}
                        label={"IFSC Code"}
                        maxLength="11"
                        placeHolder={"Enter your IFSC code"}
                        readOnly={
                          status === "approve" || status === "submitted"
                        }
                        onInput={() => {
                          error.ifsc = " ";
                        }}
                        onChange={(e) => {
                          setIfsc(e.target.value);
                          getIFSBApi(e.target.value);
                        }}
                        value={ifsc}
                      />
                      <div className={classes.ErrorMessage}>
                        {ifsc ? "" : error.ifsc}
                      </div>
                    </div>

                    <div className={classes.widthThirtyThree}>
                      <RiTextInputs
                        input={"SingleLineInput"}
                        type={"text"}
                        label={"Bank Name"}
                        placeHolder={"Enter your bank name"}
                        readOnly={
                          status === "approve" || status === "submitted"
                        }
                        // onChange={(e) => setBankName(getIFSCApiData.BANK)}
                        onInput={() => {
                          error.bankName = " ";
                        }}
                        value={bankName}
                      />
                      <div className={classes.ErrorMessage}>
                        {bankName ? "" : error.bankName}
                      </div>
                    </div>
                    <div className={classes.widthThirtyThree}>
                      <RiTextInputs
                        input={"SingleLineInput"}
                        type={"text"}
                        label={"Branch Name"}
                        placeHolder={"Enter your branch name"}
                        readOnly={
                          status === "approve" || status === "submitted"
                        }
                        onInput={() => {
                          error.branchName = " ";
                        }}
                       
                        value={branchName}
                      />
                      <div className={classes.ErrorMessage}>
                        {branchName ? "" : error.branchName}
                      </div>
                    </div>
                  </div>
                  <div className={classes.widthimageupload}>
                    {country === "India" || country === "" ||country===null? (
                      <RiTextInputs
                        input={"file"}
                        accept="image/*"
                        onInput={() => {
                          error.uploadPan = " ";
                        }}
                        placeHolder={
                          uploadPan
                            ? typeof uploadPan === "object"
                              ? uploadPan.name
                              : uploadPan.split("/").pop()
                            : "Upload your pan card image"
                        }
                        label={"Upload Pan Card"}
                        disabled={
                          status === "approve" || status === "submitted"
                        }
                        icon={
                          typeof uploadPan === "object" ? (
                            <AiFillCheckCircle size="25" color="green" />
                          ) : (
                            <BsLink45Deg size="25" />
                          )
                        }
                        onChange={panImageUpload}
                      />
                    ) : (
                      <RiTextInputs
                        input={"file"}
                        accept="image/*"
                        onInput={() => {
                          error.uploadPan = " ";
                        }}
                        placeHolder={
                          uploadPan
                            ? typeof uploadPan === "object"
                              ? uploadPan.name
                              : uploadPan.split("/").pop()
                            : "Upload your Driving license image"
                        }
                        label={"Upload Driving license"}
                        disabled={
                          status === "approve" || status === "submitted"
                        }
                        icon={
                          typeof uploadPan === "object" ? (
                            <AiFillCheckCircle size="25" color="green" />
                          ) : (
                            <BsLink45Deg size="25" />
                          )
                        }
                        onChange={panImageUpload}
                      />
                    )}

                    <div className={classes.ErrorMessage}>
                      {uploadPan ? "" : error.uploadPan}
                    </div>
                  </div>
                  {isLoading ? (
                    <Loader
                      type="TailSpin"
                      color="red"
                      width={20}
                      height={18}
                    />
                  ) : (
                    <div className={classes.submitButton}>
                      <ActionButton
                        buttonText={"Submit"}
                        onClick={() => {kycFormValidation()?KycFormApi():<></>}}
                      />
                    </div>
                  )}
                </div>
               </div>
                )}
              </>
            )}
          </NewFreelancerDashBoard>
        );
    }
  };

  return <>
   <Helmet>
        <meta charSet="utf-8" />
        <title>{`Frequently Asked Questions about Freelance Jobs  | Rozgaar India`}</title>
        <meta
          name="description"
          content={`Read the Freelancer FAQ's to learn everything about Freelancing, freelance projects, remote work, contract work and gigs.`}
       
       />
           <link rel="canonical" href="https://www.rozgaarindia.com/KycForm" /> 
      </Helmet>
  {MobileWebHandlerSwitch(props.device)}
  </>;
};

export default KycForm;
