import classes from './NewHeadingRI.module.css'

const NewHeadingRI = (props) => {


    return (
        <div className={classes.mainHeadingContainer} >
            <div className={classes.newHeadingFirst}>{props.firstLine} {props.secondLine} </div>
            {props.thirdLineColor ? <div className={classes.thirdLineWhite}> {props.thirdLine} </div> :
                <div className={classes.thirdLine}> {props.thirdLine} </div>
            }
        </div>

    )
}

export default NewHeadingRI;