import { BsEmojiSunglassesFill, BsFillHandThumbsUpFill, BsFillLightningChargeFill, BsFillShieldFill } from 'react-icons/bs';
import classes from './RemoteWorkCareerBoxes.module.css'

const RemoteWorkCareerBoxesWeb = () => {





    return (
        <div className={classes.mainContainer}>
            <div className={classes.boxesAlingment}>
                <div className={classes.headingBox}>
                    <b> The #1 Source of</b> <div> Freelance work  </div>
                </div>


                <div className={classes.BoxA}>

                    <div className={classes.contentBoxHeading}>Zero Commission </div>

                    <div> <BsFillHandThumbsUpFill className={classes.iconInBoxes} /> </div>
                    <div className={classes.contentBoxMainContent}> We do not charge any commission from freelancer earnings.    </div>
                </div>
                <div className={classes.BoxColorChange}>

                    <div className={classes.contentBoxHeading}> Matched Freelance Jobs </div>
                    <div> <BsEmojiSunglassesFill className={classes.iconInBoxes} /> </div>

                    <div className={classes.contentBoxMainContent}> Apply on best suited freelance jobs matched with your skill.  </div>
                </div>
                <div className={classes.imageBox}>
                    <img src="./assets/FreelancerAssets/banners/Career_image1_Web.jpg" alt="Remote_Work_Career" title='Remote_Work_Career' loading='lazy' className={classes.boxImage} width={"100%"} height={250} />

                </div>

            </div>

            <div className={classes.boxesAlingment}>
                <div className={classes.BoxA}>
                    <div className={classes.contentBoxHeading}> Payment Protection </div>
                    <div> <BsFillShieldFill className={classes.iconInBoxes} /> </div>


                    <div className={classes.contentBoxMainContent}> Ensure to take a certain amount from client before starting the work.  </div>

                </div>
                <div className={classes.BoxA}>
                    <div className={classes.contentBoxHeading}>Express Withdraw </div>
                    <div> <BsFillLightningChargeFill className={classes.iconInBoxes} /> </div>

                    <div className={classes.contentBoxMainContent}>  Get paid for the work you do in a timely manner in a click.  </div>
                </div>



                <div className={classes.wideImageAlingment}>
                    <img src="./assets/FreelancerAssets/banners/Career_image2_Web.jpg" alt="Remote_Work_Career" width={"420px"} height={'250px'} title='Remote_Work_Career' loading='lazy' className={classes.logoArea} />

                </div>

            </div>



        </div>

    )
}

export default RemoteWorkCareerBoxesWeb;