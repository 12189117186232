import React, { useState, useEffect } from "react";
import ActionButton from "../../Components/ActionButton";
import HireTypeWeb from "../../Components/HowToHireComps/HireTypeWeb";
import NewHeadingRI from "../../Components/NewHeadingRI";
import classes from "./HowToHireWeb.module.css";
import { Link } from "react-router-dom";
import Faqdropdown from "../FrequentlyAskedQuestions/Faqdropdown";
import HowToHireSec from "../../Components/HowToHireComps/HowToHireSec";
import { Helmet } from "react-helmet";
import { pageViewTracker } from "../../Components/GoogleTracking";

const HowToHireWeb = () => {
  const [show, setShow] = useState();
  const [Data, setData] = useState([]);
  const [question] = useState([]);
  const [answer] = useState([]);

  useEffect(() => {
    FrequentlyAskedQuestions();
    pageViewTracker();
  }, []);

  const FrequentlyAskedQuestions = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Question: question,
      Answer: answer,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/api/rozgaarapi/GetFaq", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => console.log("error", error));
  };


  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title>{`Frequently Asked Questions about Freelance Jobs  | Rozgaar India`}</title>
        <meta
          name="description"
          content={`Read the Freelancer FAQ's to learn everything about Freelancing, freelance projects, remote work, contract work and gigs.`}
       
       />
           <link rel="canonical" href="https://www.rozgaarindia.com/HowToHire" /> 
      </Helmet>
      <div className={classes.mainDiv}>
        <div className={classes.imageDiv}>
          <div className={classes.headingDiv}>
            <h1 className={classes.mainHeading}>Find Remote, Hybrid, Onsite, Flexible Work </h1>
            <h2 className={classes.subHeading}>
              India’s leading secure and flexible platform for
              <br /> remote freelancers
            </h2>
            <Link to={localStorage.getItem("userLoggedIn") ? "/FreelancerWorkPlace" : "/SignUp"} className={classes.PARBtn_Link}>
              <ActionButton
                buttonText={"Join as a Freelancer"}
                buttonType={"left"}
              />
            </Link>
          </div>
          <img
            src="/assets/banners/how_to_hire_web_banner.jpg"
            className={classes.hireImage}
            alt="Find_Remote_Hybrid_Onsite_Flexible_Work" title="Find_Remote_Hybrid_Onsite_Flexible_Work" loading="lazy" width={"40%"} height={400}
          />
        </div>
        <div className={classes.helpYouHireDiv}>
          <NewHeadingRI firstLine={"Work the way you want"} />
          <h3 className={classes.rightWork}>Find the right work for you, with great clients</h3>
          <HireTypeWeb />
          <h2 className={classes.workTypeHeading}>Find freelance jobs online and get paid using our secure, flexible and platform</h2>
          <div className={classes.hoetohireSec}>
            <HowToHireSec />
          </div>
          <h2 className={classes.faqHeading}>FAQ</h2>

          <div className={classes.mapDiv}>
            {Data.slice(0, show ? show.length : 5).map((item, value) => {
              return (
                <>
                  <Faqdropdown question={item.Question} answer={item.Answer} />
                </>
              );
            })}

            <div className={classes.faq_ActionButton}>
              {!show ? (
                <ActionButton
                  buttonText={"View More"}
                  buttonType={"small"}
                  onClick={() => setShow(true)}
                />
              ) : (
                <ActionButton
                  buttonText={"View less"}
                  buttonType={"small"}
                  onClick={() => setShow(false)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowToHireWeb;
