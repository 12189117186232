import { useEffect, useRef, useState } from "react";

import ApplicationDetail from "../../Components/ApplyRequirements/ApplicationDetail";

import RequirementDetailArea from "../../Components/ApplyRequirements/RequirementDetailArea";
import RequirementPageHeader from "../../Components/ApplyRequirements/RequirementPageHeader";

import RegisterModal from "../../Components/Modals/RegisterModal";
import classes from './PublicClientRequirementDetail.module.css'
import SharePOst from '../../Components/ApplyRequirements/SharePost';
import { useLocation, useParams } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import NewRequirementCard from '../../Components/ApplyRequirements/NewRequirementCard';
import CreateProposal from '../../Screens/ApplyRequirements/CreateProposal';
import RI_Modal from '../../Components/RI_Modal';
import { Helmet } from "react-helmet";
import { pageViewTracker } from "../../Components/GoogleTracking";
import SuccessModal from "../../Components/FreelancerProfile/SuccessModal";

const PublicClientRequirementDetail = (props) => {
  const proposalRef = useRef(null);
  const modalRef = useRef(null);

  let userID = localStorage.getItem("userID");
 
  const [requirementData, setRequirementData] = useState([]);
  const [shareModalShow, setShareModalShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [creditModal, setCreditModal] = useState(false);
  const [skillsModal, setSkillsModal] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [applcationModal, setApplcationModal] = useState(false);
  const [availableCredits, setAvailableCredits] = useState("");
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [showComp, setShowComp] = useState(false);
  const [headerDetail, setHeaderDetail] = useState({
    FreelancerPolicy: " ",
    Status: " ",
    Skills: [],
    RequirementType: " ",
    FirstName: "",
    LastName: "",
  });
  const [requirementDetail, setRequirementDetailArea] = useState({
    Title: " ",
    Description: " ",
    CompanyName: " ",
    CompanyLogo: " ",
    CompanyWebsite: " ",
    RequirementType: " ",
    UpdatedDate: " ",
    IsCompany: " ",
    Skills: [],
    Location: "",
    FreelancerPolicy: " ",
    budget: "",
    budgetUnit: "",
    clientId: "",
    FirstName: "",
    LastName: "",
    Country:"",
    State:"",
    City:""
  });

  const [applicationDetails, setApplicationDetails] = useState({
    PraposalAmount: " ",
    Currency: " ",
    Unit: " ",
    Addons: [],
    Applied: "",
    WaitList: "",
    RequirementType: "",
   
    IsCompany: "",
    CompanyName: "",
    clientId: "",
   

  });

  const { reqId } = useParams();
  const RequirementID = reqId;
  const { pathname } = useLocation();

  useEffect(()=>{
pageViewTracker();
  },[])

  useEffect(() => {
    RequirmentDetailApi();
   
  }, [pathname]);

  const freelancerEligibility = () => {


    if (applicationDetails.hasFreelancerApplied === "Applied" || applicationDetails.hasFreelancerApplied === "True") {

      setShowComp(true);
      scrollToElement();
    }
    else {



      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        FreelancerId: userID,
        RequirementId: RequirementID,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        global.apiLink +
        "/api/freelancerapp/rozgaarapi/FreelancerApplicationEligibility",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {

          if (result.status_code === 200 && result.status === "Success" && result.freelancercreditcount > 0 && result.ProfilePercentComplete === "100.0%" && result.reason1 !== "skills not matched") {

            setAvailableCredits(result.AvailableCredit)
            setApplcationModal(true)


          }
          else if (result.freelancercreditcount === 0) {
            setCreditModal(true)
         
          }
          else if (result.ProfilePercentComplete !== "100.0%") {
            setProfileModal(true)
           
          }
          else if (result.reason1 === "skills not matched") {
            setSkillsModal(true)
            
          }


        })
        .catch((error) => console.log("error", error));
    }
  };
  const freelancerApplication = () => {
  
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      FreelancerId: userID,
      RequirementId: RequirementID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      global.apiLink + "/api/rzadmin/applyrequirement",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
       
        if (result.status_code === 200 && result.status === "SUCCESS") {

          setApplicationDetails({
            ...applicationDetails, clientId: result.RequirementApplication.ClientId, ClientRStatus: result.RequirementApplication.Status,Status:result.RequirementApplication.Status
          })
          setRequirementDetailArea({ ...requirementDetail, clientId: result.RequirementApplication.ClientId })

          setShowComp(true);
          scrollToElement();
         
        }
      })
      .catch((error) => console.log("error", error));
  };



  const RequirmentDetailApi = () => {
    setLoading(true)

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      FreelancerId: userID,
      RequirementId: RequirementID,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/api/client/RequirementDetailPublicView", requestOptions)
      .then(response => response.json())
      .then(result => {
      
        setRequirementData(result.data.SimilarRequirements)
        setRequirementDetailArea({
          ...requirementDetail,
          Title: result.data.Title,
          Description: result.data.Description,
          CompanyName: result.data.CompanyName,
          CompanyLogo: result.data.CompanyLogo,
          CompanyWebsite: result.data.CompanyWebsite,
          RequirementType: result.data.RequirementType,
          FreelancerPolicy: result.data.FreelancerPolicy,
          UpdatedDate: result.data.UpdatedDate,
          IsCompany: result.data.IsCompany,
          Skills: result.data.Skills,
          Location: result.data.Location,
          budget: result.data.Budget,
          BudgetCurrency: result.data.BudgetCurrency,
          budgetUnit: result.data.BudgetUnit,
          requirementId: result.data.RequirementID,
          Addons: result.data.Addons,
          FirstName: result.data.FirstName,
          LastName: result.data.LastName,
          clientId: result.data.UserId,
          Country:result.data.Country,
          State:result.data.State,
          City:result.data.City,
          hasFreelancerApplied: result.HasFreelancerApplied,
          ClientRStatus:result.ClientRStatus,
          Status:result.data.Status,
        });
        setHeaderDetail({
          ...headerDetail,
          FreelancerPolicy: result.data.FreelancerPolicy,
          Skills: result.data.Skills,
          Status: result.data.Status,
          RequirementType: result.data.RequirementType,
          FirstName: result.data.FirstName,
          LastName: result.data.LastName,
        });
        setApplicationDetails({
          ...applicationDetails,
          WaitList: result.data.WaitList,
          Applied: result.data.Applied,
          Addons: result.data.Addons,
          Unit: result.data.BudgetUnit,
          PraposalAmount: result.data.Budget,
          RequirementType: result.data.RequirementType,
          Currency: result.data.BudgetCurrency,
          hasFreelancerApplied: result.HasFreelancerApplied,
          IsCompany: result.data.IsCompany,
          CompanyName: result.data.CompanyName,
          clientId: result.data.UserId,
          Status:result.data.Status,
          ClientRStatus:result.ClientRStatus
        });
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const size = window.innerHeight;

  const scrollToElement = () => {
    window.scrollTo({ top: size, behavior: "smooth" });
  }




  const MobileWebHandlerSwitch = (device) => {
    switch (device) {
      case "Mobile":
        return (
          <div className={classes.pageLayout}>
            {isLoading ? (
              <div className={classes.pageLoader}>
                <Loader type="TailSpin" color="red" height={80} width={80} />
              </div>
            ) : (
              <>
                {shareModalShow && (
                  <SharePOst
                    closeModal={() => setShareModalShow(false)}
                    device={"Mobile"}
                    url={window.location.href}
                  />
                )}

                {modalShow && (
                   <SuccessModal
                   heading={"Proposal sent successfully"}
                   onSubmit={() => setModalShow(false)}
                   SuccessModalType={"ProposalSuccessModal"}
                   BackdropClick={() => setModalShow(false)}
                 />
                )}
                {creditModal && <RI_Modal closeRIModal={() => setCreditModal(false)} modalType={"creditModal"} Heading={"Low application credits"} Text={"You do not have enough credit to apply for this requirement"} />}
                {applcationModal && <RI_Modal availableCredits={availableCredits} closeRIModal={() => setApplcationModal(false)} applicationAgree={() => { setShowComp(true); freelancerApplication(); setApplcationModal(false); }} modalType={"applcationModal"} Heading={"You are going to apply on this post by using your credits"} Text={availableCredits} />}
                {skillsModal && <RI_Modal closeRIModal={() => setSkillsModal(false)} modalType={"skillModal"} Heading={"Your skills does not match with this requirement"} Text={"Search for related freelance jobs "} />}
                {profileModal && <RI_Modal closeRIModal={() => setProfileModal(false)} modalType={"profileModal"} Heading={"Your profile is incomplete"} Text={"To apply on freelance jobs,complete your profile"} />}
                <RequirementPageHeader
                  headerDetail={headerDetail}
                  OnSharePOst={() => setShareModalShow(true)}
                />
                <RequirementDetailArea requirementDetail={requirementDetail}  
                openForm={() =>

                  freelancerEligibility()

                }/>
                <ApplicationDetail
                  applicationDetails={applicationDetails}
                  openForm={() =>

                    freelancerEligibility()

                  }
                  device={"Mobile"}

                />

                {userID !== "" && showComp === true ? (
                  <CreateProposal
                    proposalData={requirementDetail}
                    toggle={setShowComp}
                    modal={setModalShow}
                  />
                ) : (
                  <></>
                )}

                {requirementData && requirementData.length > 0 ? (
                  <NewRequirementCard
                    RequirementData={requirementData}
                    heading={"Similar Requirements"}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        );

      default:
        return (
          <div className={classes.pageLayout}>
            <>
              {shareModalShow && (
                <SharePOst
                  closeModal={() => setShareModalShow(false)}
                  url={window.location.href}
                />
              )}

              <div ref={modalRef}>
                {modalShow && (
                  <SuccessModal
                    heading={"Proposal sent successfully"}
                    onSubmit={() => setModalShow(false)}
                    SuccessModalType={"ProposalSuccessModal"}
                    BackdropClick={() => setModalShow(false)}
                  />
                )}
              </div>

              {creditModal && <RI_Modal closeRIModal={() => setCreditModal(false)} modalType={"creditModal"} Heading={"Low application credits"} Text={"You do not have enough credit to apply for this requirement"} />}
              {applcationModal && <RI_Modal availableCredits={availableCredits} closeRIModal={() => setApplcationModal(false)} applicationAgree={() => { setShowComp(true); freelancerApplication(); setApplcationModal(false); }} modalType={"applcationModal"} Heading={"You are going to apply on this post by using your credits"} Text={availableCredits} />}
              {skillsModal && <RI_Modal closeRIModal={() => setSkillsModal(false)} modalType={"skillModal"} Heading={"Your skills does not match with this requirement"} Text={"Search for related freelance jobs "} />}
              {profileModal && <RI_Modal closeRIModal={() => setProfileModal(false)} modalType={"profileModal"} Heading={"Your profile is incomplete"} Text={"To apply on freelance jobs,complete your profile"} />}
              <RequirementPageHeader
                headerDetail={headerDetail}
                OnSharePOst={() => setShareModalShow(true)}
              />
              <div className={classes.pageColumn}>
                <div className={classes.leftArea}>
                  <RequirementDetailArea
                    requirementDetail={requirementDetail}
                  />
                </div>
                <div className={classes.rightArea}>
                  <ApplicationDetail
                    applicationDetails={applicationDetails}
                    openForm={() => {
                    
                      scrollToElement();
                      freelancerEligibility();
                    }}
                    onClick={() => {

                    
                      scrollToElement();
                    }}
                  />
                </div>
              </div>

              {userID !== null && showComp === true ? (
                <CreateProposal
                  proposalData={requirementDetail}
                  toggle={setShowComp}
                  modal={setModalShow}
                />
              ) : (
                <></>
              )}

              {requirementData && requirementData.length > 0 ? (
                <NewRequirementCard
                  RequirementData={requirementData}
                  heading={"Similar Requirements"}
                />
              ) : (
                <></>
              )}

              <div ref={proposalRef}> </div>
            </>
          </div>
        );
    }
  };

  return <>
  <Helmet>
        <meta charSet="utf-8" />
        <title>{`Best Remote work and freelance projects at RozgaarIndia.com via Times Ascent`}</title>
        <meta
          name="description"
          content={`Rozgaar India is one of India's Leading online service marketplace for remote work and freelance projects. You can find the best skilled online service providers at RozgaarIndia.com`}
       
       />
        <link rel="canonical" href="https://www.rozgaarindia.com/requirementDetail" />
    
      </Helmet>
  {MobileWebHandlerSwitch(props.device)}
  </>;
};
export default PublicClientRequirementDetail;
