import React, { useState, useEffect } from 'react';
import AboutFreelancer from '../../Components/ClientFreelancerProfileComp/AboutFreelancer';
import MenuArea from '../../Components/ClientFreelancerProfileComp/MenuArea';
import ProfileImage from '../../Components/ClientFreelancerProfileComp/ProfileImage';

import WorkDetail from '../../Components/ClientFreelancerProfileComp/WorkDetail';
import classes from './FreelancerProfileMain.module.css'
import { Helmet } from "react-helmet";
import { pageViewTracker } from '../../Components/GoogleTracking';


const FreelancerProfileMain = (props) => {


  const userId = localStorage.getItem("userID");
  const [loading, setLoading] = useState('');
  const [profileImageData, setProfileImageData] = useState({
    profilePicture: '',
    CoverImage:'',
    rating:'',
    firstName: '',
    lastName: '',
    primarySkill: '',
    isAvailable: '',
    availableCountry: '',
    availableCity: '',
    SocialMedia: []
  });
  const [aboutFreelancerData, setAboutFreelancerData] = useState({
    skills: [],
    description: ''
  });
  const [menuAreaData, setMenuAreaData] = useState({
    portfolio: "",
    certification: "",
    experience: "",
    education: []
  });
  const [workDetailData, setWorkDetailData] = useState({
    isAvailable: '',
    isMonthly: '',
    isOnCommision: '',
    isContractual: '',
    isOneTime: '',
    languages: []
  });
 


  useEffect(() => {
    GetFreelancerProfileAPI();

    pageViewTracker();
  }, []);


  const GetFreelancerProfileAPI = async () => {

    setLoading('GetFreelancerProfile');
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");

    var formdata = new FormData();
    formdata.append("FreelancerId", userId);


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    await fetch(global.apiLink+"/api/rozgaarapi/FreelancerProfile", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status_code === 200) {
          setProfileImageData({
            profilePicture: result.data.ProfilePicture,
            firstName: result.data.FirstName,
            lastName: result.data.LastName,
            primarySkill: result.data.Skills[0].Skills,
            isAvailable: result.data.IsAvailable,
            availableCountry: result.data.AvailableCountry,
            availableCity: result.data.AvailableCity,
            SocialMedia: result.data.SocialMedia,
            CoverImage: result.data.CoverImage,
            rating: result.data.RatingReview
          });
          setAboutFreelancerData({
            skills: result.data.Skills,
            description: result.data.Description
          });
          setMenuAreaData({
            portfolio: result.data.Portfolio,
            certification: result.data.Certification,
            experience: result.data.WorkExperience,
            education: result.data.Education
          });
          setWorkDetailData({
            isAvailable: result.data.IsAvailable,
            isMonthly: result.data.IsMonthly,
            isOnCommision: result.data.IsOnCommision,
            isContractual: result.data.IsContractual,
            isOneTime: result.data.IsOneTime,
            languages: result.data.Languages
          })

        }
      })
      .catch(error => console.log('error', error))
      .finally(() => setLoading(''));
  }


  const MobileWebHandlerSwitch = (device) => {
    switch (device) {
      case "Mobile":
        return (
          <div className={classes.pageLayout}>

            <div className={classes.mainProfileImageContainer}>
              <img className={classes.mainProfileImage} src={profileImageData.CoverImage} alt="" />
            </div>

            <ProfileImage profileImageData={profileImageData} />

            <AboutFreelancer aboutFreelancerData={aboutFreelancerData} />

            <MenuArea menuAreaData={menuAreaData} />

            <WorkDetail workDetailData={workDetailData} />

           
          </div>


        );

      default:
        return (
          <div className={classes.pageLayout}>

            <div className={classes.mainProfileImageContainer}>
              <img className={classes.mainProfileImage} src={profileImageData.CoverImage} alt="" />
            </div>


            <div className={classes.pageContainer}>
              <div className={classes.leftArea}>

                <div className={classes.profileAboutContainer}>
                  <ProfileImage profileImageData={profileImageData} />
                  <AboutFreelancer aboutFreelancerData={aboutFreelancerData} />
                </div>
                <div className={classes.MenuAreaContainer}>
                <MenuArea menuAreaData={menuAreaData} />
                </div>
              </div>
              <div className={classes.rightArea}>
                <WorkDetail workDetailData={workDetailData} />
              

              </div>
            </div>
          </div>
        );
    }
  };

  return <>
   <Helmet>
        <meta charSet="utf-8" />
        <title>{`Freelancer Requirement Specifications | Rozgaar India`}</title>
        <meta
          name="description"
          content={`Promote your job requirement and receive top professional freelancer profiles. Rozgaar making short-term hire easier and faster `}
       
       />
     <link rel="canonical" href="https://www.rozgaarindia.com/freelancerprofile" />
      </Helmet>
  {MobileWebHandlerSwitch(props.device)}</>;

}

export default FreelancerProfileMain;