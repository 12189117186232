import React from "react";
import classes from "./RI_Modal.module.css";
import { BiX } from "react-icons/bi";
import ActionButton from "./ActionButton";
import { useNavigate } from 'react-router-dom';


const RI_Modal = (props) => {
  const navigate = useNavigate()
  return (
    <>

      {props.modalType === "creditModal" ?
        <div className={classes.modalContainer}>
          <div className={classes.modal}>
            <div className={classes.crossicon}>
              <div onClick={props.closeRIModal}>
                <BiX />
              </div>
            </div>
            <div className={classes.ModalCheckIcon}>
              <img src="/assets/modal/Credit _Icon.svg" className={classes.modalcheck} />
            </div>
            <div className={classes.ModalCheckTextFirst}>
              {props.Heading}
            </div>
            <div className={classes.ModalCheckTextSecond}>
              {props.Text}
            </div>
            <div className={classes.ActionBtn}>

              <ActionButton
                buttonType={"small"}
                buttonText={"Purchase Credit"}

                onClick={() => navigate("/FreelancerPackage")}

              />
            </div>


          </div>
        </div>

        : props.modalType === "skillModal" ?
          <div className={classes.modalContainer}>
            <div className={classes.modal}>
              <div className={classes.crossicon} >
                <div onClick={props.closeRIModal}>
                  <BiX />
                </div>
              </div>
              <div className={classes.ModalCheckIcon}>
                <img src="/assets/modal/Brain.svg" className={classes.modalcheck} />
              </div>
              <div className={classes.ModalCheckTextFirst}>
                {props.Heading}
              </div>
              <div className={classes.ModalCheckTextSecond}>
                {props.Text}
              </div>
              <div className={classes.ActionBtn}>
                <ActionButton
                  buttonType={"small"}
                  buttonText={"Recommended Requirement"}
                  onClick={() => navigate("/requirementList")}
                />


              </div>
            </div>
          </div>

          : props.modalType === "profileModal" ?
            <div className={classes.modalContainer}>
              <div className={classes.modal}>
                <div className={classes.crossicon} >
                  <div onClick={props.closeRIModal}>
                    <BiX />
                  </div>
                </div>
                <div className={classes.ModalCheckIcon}>
                  <img src="/assets/modal/Profile.svg" className={classes.modalthird} />
                </div>
                <div className={classes.ModalCheckTextFirst}>
                  {props.Heading}
                </div>
                <div className={classes.ModalCheckTextSecond}>
                  {props.Text}
                </div>
                <div className={classes.ActionBtn}>
                  <ActionButton
                    buttonType={"small"}
                    buttonText={"Complete now"}
                    onClick={() => navigate("/workProfile")}


                  />
                </div>
              </div>
            </div>



            :
            props.modalType === "applcationModal" ?
              <div className={classes.modalContainer}>
                <div className={classes.modal}>
                  <div className={classes.crossicon}>
                    <div onClick={props.closeRIModal}>
                      <BiX />
                    </div>
                  </div>
                  <div className={classes.ModalCheckIcon}>
                    <img src="/assets/modal/Credit _Icon.svg" className={classes.modalcheck} />
                  </div>
                  <div className={classes.ModalCheckTextFirst}>
                    {props.Heading}
                  </div>
                  <div className={classes.ModalCheckTextSecond}>
                    Using 1/{props.Text} Credits
                  </div>
                  <div className={classes.ActionBtn}>
                    <ActionButton
                      buttonType={"small"}
                      buttonText={"No Thanks"}
                      onClick={props.closeRIModal}

                    />
                    <ActionButton
                      buttonType={"small"}
                      buttonText={"Agree"}
                      onClick={props.applicationAgree}
                    />

                  </div>
                </div>
              </div>
              :

              <></>}

    </>)
}


export default RI_Modal;
