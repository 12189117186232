import { FcBriefcase } from 'react-icons/fc';
import classes from './NoIncomeData.module.css'

const NoIncomeData = () => {


    return (
        <div className={classes.mainContainer}>

            <div className={classes.iconForIncome}>
                <FcBriefcase size={'50px'} />
            </div>
            <div className={classes.incomeMessage}>
                You do not have any income yet, apply on freelancer projects
            </div>

        </div>
    )
}

export default NoIncomeData;