import React from 'react'
import classes from './ProfileProgress.module.css'
import { BsArrowRightShort } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const ProfileProgress = (props) => {
  return (
    <div className={classes.mainDiv}>
    
        
        <div className={classes.profileProgressCard}>
            <div className={classes.myEarnings}>My Earnings</div>
            <div className={classes.amountDiv}>
            <div className={classes.amount}>₹{props.freelancerIncome}</div>
            <Link to="/Income"> 
            <div className={classes.arrowIconDiv}><BsArrowRightShort size={22}/></div>
            </Link>
            </div>
        </div>
    </div>
  )
}

export default ProfileProgress