import React, { useState } from "react";
import classes from "./CreateProposal.module.css";
import { FcRating, FcRules } from "react-icons/fc";
import { IoIosClose } from "react-icons/io";
import { HiLightBulb } from "react-icons/hi";
import { BsDot } from "react-icons/bs";
import { FcList } from 'react-icons/fc';
import { Link } from "react-router-dom";
import RiTextInputs from "../../Components/PostRequirement/RiTextInputs";
import ActionButton from "../../Components/ActionButton";
import { encodeAndSendToChat } from "../../App";
import Loader from "react-loader-spinner";

const CreateProposal = (props) => {
  const freelancerId = localStorage.getItem("userID");
  const [isLoading, setIsLoading] = useState(false);
  const [agreeChecked, setAgreeChecked] = useState(false);
  const [freelancerBudget, setFreelancerBudget] = useState();
  const [proposalDetail, setProposalDetail] = useState();
  const [deposit, setDeposit] = useState();
  const [error, setError] = useState({});
  const [radioError, setRadioErrors] = useState("");
  const ApplyRequirementAPI = () => {

    setIsLoading(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      FreelancerId: freelancerId,
      RequirementId: props.proposalData.requirementId,
      ProposalAmount: freelancerBudget,
      ProposalDetail: proposalDetail,
      DepositAmountCurrency: props.proposalData.BudgetCurrency,
      DepositAmount: deposit,
      Unit: props.proposalData.budgetUnit,
      Currency: props.proposalData.BudgetCurrency
    });


    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(global.apiLink + "/api/rzadmin/applyrequirement", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          props.modal(true);
          props.toggle(false);


        } else {

        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setIsLoading(false);
      });

  };

  const addOnType = (type) => {

    if (type === "Feature") {
      return (
        <div className={classes.addonContainer}>
          <FcRating className={classes.addonNameIcon} size={25} />

          <span className={classes.AddonName}>  {"Featured"}</span>
        </div>
      )
    }
    if (type === "Project Manager") {
      return (
        <div className={classes.addonContainer}>
          <FcRules className={classes.addonNameIcon} />

          <span className={classes.AddonName}>  {"Project Manager"}</span>
        </div>
      )

    }
    if (type === "Urgent") {
      return (
        <div className={classes.addonContainer}>
          <img src="/assets/application_detail/Urgent.svg" alt="urgent" className={classes.addonNameIcon} />

          <span className={classes.AddonName}>  {"Urgent"}</span>
        </div>
      );
    }
    if (type === "Non Disclosure Agreement") {
      return (
        <div className={classes.addonContainer}>
          <img src="/assets/application_detail/NDA.svg" alt="NDA" className={classes.addonNameIcon} />
          <span className={classes.AddonName}>  {"NDA"}</span>
        </div>)
    }
    if (type === "Access Waitlist") {
      return (
        <div className={classes.addonContainer}>
          <FcList className={classes.addonNameIcon} />
          <span className={classes.AddonName}>  {"Pro"}</span>
        </div>
      );
    }
  }



  const validation = () => {
    let errors = {};
    let isValid = true;
    if (freelancerBudget === "" || !freelancerBudget) {
      errors.freelancerBudget = "Please enter your proposal";
      isValid = false;

    }
    if (deposit === "" || !deposit) {
      errors.deposit = "Please enter advance amount";
      isValid = false;
    }
    setRadioErrors(errors);
    setError(errors);
    return isValid;
  };

  const timeSince = (date) => {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return interval + " years";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + " months";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + " days";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + " hours";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  };





  return (
    <>
      <div className={classes.mainDiv}>
        <div className={classes.mainHeadingDiv}>
          <div className={classes.mainHeading}>
            <div className={classes.mainHeadingInner}>
              Send Proposal
              <br />
              <div className={classes.mainSubHeading}>
                Match client quote or send your proposal to win the job{" "}
              </div>
            </div>
            <IoIosClose
              onClick={() => props.toggle(false)}
              className={classes.closeIcon}
            />

          </div>
        </div>
        <div className={classes.formDiv}>
          <div>
            <div className={classes.duration}>
              Posted {timeSince(new Date(props.proposalData.UpdatedDate))} ago
            </div>
            <div className={classes.subHeading}>{props.proposalData.Title}</div>

            <div className={classes.addOnsMain}>

              <div className={classes.addOnDiv}>
                {props.proposalData.Addons &&
                  props.proposalData.Addons.map((item, index) => {
                    return <>{addOnType(item.AddonName)}</>;
                  })}
              </div>
            </div>
            <div className={classes.ClientBudgetText}>
              <div className={classes.ClientBudgetHeading}> Client's Budget -&nbsp;</div>

              <span className={classes.units}>
                {props.proposalData.BudgetCurrency}
                {props.proposalData.budget}/
                {props.proposalData.budgetUnit}
              </span>
            </div>

            <div className={classes.ClientBudgetText}>
              <div className={classes.ClientBudgetSubText}> Your proposal </div>
              <div className={classes.ClientBudgetSubTextInput}>
                <RiTextInputs
                  input={"InputWithIcon"}
                  currencyIcon={props.proposalData.BudgetCurrency}
                  placeHolder={"0"}
                  onChange={(e) => {
                    e.target.value === "" || /^-?\d+$/.test(e.target.value)
                      ? setFreelancerBudget(e.target.value)
                      : setError({
                        ...error,
                        freelancerBudget: "Enter Valid Amount",
                      });
                  }}
                  onInput={() => { error.freelancerBudget = " " }}
                  value={freelancerBudget}
                />
                <p className={classes.ErrorMessage}>{error.freelancerBudget}</p>
              </div>
            </div>

            <div className={classes.radioSubLabel}>
              Make a quotation based on what you think the freelance job will
              involve
            </div>

            <div className={classes.ClientBudgetText}>
              <div className={classes.ClientBudgetSubText}>Ask for Advance</div>
              <div className={classes.ClientBudgetSubTextInput}>
                <RiTextInputs
                  input={"InputWithIcon"}
                  currencyIcon={props.proposalData.BudgetCurrency}
                  placeHolder={"0"}

                  onChange={(e) => {
                    e.target.value === "" || /^-?\d+$/.test(e.target.value)
                      ? setDeposit(e.target.value)
                      : setError({
                        ...error,
                        deposit: "Enter Valid Amount",
                      });
                  }}
                  onInput={() => { error.deposit = " " }}
                  value={deposit}
                />
                <p className={classes.ErrorMessage}>{error.deposit}</p>
              </div>
            </div>

            <div className={classes.radioSubLabel}>
              Request an advance payment from client to start the work
            </div>


            <div className={classes.descriptionContainer}>
              <RiTextInputs
                input={"SmallMultiLineInput"}
                label={" Send a message to Client"}
                onChange={(e) =>
                  setProposalDetail(e.target.value)
                }
                placeholder={
                  "Please include a brief description why you're the perfect freelancer for this job."
                }
                value={proposalDetail}
              />
            </div>

            <div className={classes.agreementDiv}>
              <RiTextInputs
                input={"plainCheckBox"}
                onChange={() => setAgreeChecked(!agreeChecked)}
                checked={agreeChecked === true}
                onInput={() => setRadioErrors("")}
              />
              <div className={classes.agreementText}>
                Do you agree to sign a Non disclosure agreement with the buyer?

              </div>
            </div>
            <div className={classes.error_message}>
              {radioError.agreeChecked}
            </div>



            <div>
              {freelancerBudget ? <div className={classes.totalAmountHeading}>
                Total amount :<span className={classes.finalAmount}>
                  ₹ {freelancerBudget}
                </span></div> : <></>}
            </div>
            <div>
              {deposit ? <div className={classes.totalAmountHeading}>
                Ask for deposit :<span className={classes.finalAmount}>
                  ₹ {deposit}
                </span></div> : <></>}
            </div>


            <div className={classes.decisionButtonsDiv}>

              <Link to="/" onClick={(e) => { e.preventDefault(); encodeAndSendToChat(freelancerId, props.proposalData.clientId) }} className={classes.chat}>
                Chat
              </Link>
              <div className={classes.cancel}>or</div>
              {isLoading ? (
                <div className={classes.LoadingBtn}>
                  <Loader
                    type="TailSpin"
                    color="#1778f2"
                    width={20}
                    height={18}
                    className={classes.buttonBoxsmall}
                  />
                </div>
              ) : (
                <ActionButton
                  buttonType={"small"}
                  buttonText={"Send Proposal"}
                  onClick={() => {
                    validation() ? ApplyRequirementAPI() : <></>;
                  }}

                />
              )}

            </div>
          </div>

          <div className={classes.tipDiv}>
            <div className={classes.tipsHeading}>
              <HiLightBulb className={classes.tipIcon} /> Tips
            </div>
            <div className={classes.tipTextMainDiv}>
              <div>
                <BsDot />
              </div>
              <div className={classes.tipText}> Proposal is not bid</div>
            </div>
            <div className={classes.tipTextMainDiv}>
              <div>
                <BsDot />
              </div>
              <div className={classes.tipText}>
                Establish a clear understanding with the Client by sending a
                proposal when you apply
              </div>
            </div>
            <div className={classes.tipTextMainDiv}>
              <div>
                <BsDot />
              </div>
              <div className={classes.tipText}>
                If you have questions-ask on chat
              </div>
            </div>
            <div className={classes.tipTextMainDiv}>
              <div>
                <BsDot />
              </div>
              <div className={classes.tipText}>
                We recommend sending a message why you're the perfect freelancer
                for this job, your completed profile and portfolio will do the
                rest showcasing your abiltity
              </div>
            </div>
            <div className={classes.tipTextMainDiv}>
              <div>
                <BsDot />
              </div>
              <div className={classes.tipText}>
                You can always ask for a deposit from the client before getting
                hired, that could be a certain amount or full amount as your
                wish
              </div>
            </div>
            <div className={classes.tipTextMainDiv}>
              <div>
                <BsDot />
              </div>
              <div className={classes.tipText}>
                To protect your payments we highly recommend taking payments
                through our secure system.
                <br />
                We charge ZERO commission
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateProposal;
