import React, { useState, useEffect, lazy } from "react";
import classes from "./ArticlesRozgaarWeb.module.css";
import { AiOutlineTwitter } from "react-icons/ai";
import { GrFacebookOption } from "react-icons/gr";
import { FaWhatsapp } from "react-icons/fa";
import { FaGlobeAsia } from "react-icons/fa";
import { MdWatchLater } from "react-icons/md";
import RIFooter from "../../Components/RIFooter";
import { useNavigate, useLocation } from "react-router";
import { Helmet } from "react-helmet";
import { pageViewTracker } from "../../Components/GoogleTracking";

const ArticlesRozgaarWeb = () => {
  let navigate = useNavigate();

  const location = useLocation();

  const [articleDetailData, setArticleDetailData] = useState("");
  const [banner, setBanner] = useState("");
  const [articleData, setArticledata] = useState([]);
  const [url, setUrl] = useState(0)

  useEffect(() => {
    setUrl(window.location.href)
  }, [])

  useEffect(() => {
    ArticleDetailAPiFetchData();
    ArticleApiDataFetch();

    pageViewTracker();
  }, []);

  let ArticleId = location.pathname.split("/").pop();

  const ArticleDetailAPiFetchData = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ArticleId: ArticleId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/api/rozgaarapi/GetSingleArticle", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setArticleDetailData(result.data);
          setBanner(result.data.ArticleImage);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const ArticleApiDataFetch = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "ArticleType": "Freelancer"
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(global.apiLink + "/api/rozgaarapi/IspublishedArticle", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setArticledata(result.data);
        }
      })
      .catch((error) => console.log("error", error));
  };



  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div>
     <Helmet>
        <meta charSet="utf-8" />
        <title>{`Frequently Asked Questions about Freelance Jobs  | Rozgaar India`}</title>
        <meta
          name="description"
          content={`Read the Freelancer FAQ's to learn everything about Freelancing, freelance projects, remote work, contract work and gigs.`}
       
       />
           <link rel="canonical" href="https://www.rozgaarindia.com/articlesRozgaar" /> 
      </Helmet>
      <div className={classes.bannerDiv}>

      </div>
      <div className={classes.shadowMainDiv}>
        <div className={classes.shadowDiv}>
          <h1 className={classes.articleTitleMargin}>
            {" "}
            {articleDetailData.Title}
          </h1>
          <div className={classes.iconDiv}> <a href={"https://facebook.com/sharer/sharer.php?u=" + url} target="_blank" rel="noreferrer" className={classes.iconLink}><GrFacebookOption /></a> <a href={"https://twitter.com/intent/tweet?url=" + url} target="_blank" rel="noreferrer" className={classes.iconLink}><AiOutlineTwitter /></a>  <a href={"https://web.whatsapp.com/send?text=" + url} rel="noreferrer" target="_blank" className={classes.iconLink}><FaWhatsapp /></a></div>


          {banner !== null ? (
            <img src={banner.ArticleImage} className={classes.articleBanner} alt={articleDetailData.Title} title={articleDetailData.Title} loading="lazy"
            width={"100%"} height={430}/>
          ) : (
            "No image found"
          )}

          <div className={classes.contentMainDiv}>
            <div className={classes.postedText}>Posted date {articleDetailData.UpdatedAt ? articleDetailData.UpdatedAt.slice(0, 10) : ""}</div>


            <div>{articleDetailData.AboutAuthor}</div>
            <div className={classes.shadowDivContentHeading}>

            </div>
            <div dangerouslySetInnerHTML={{ __html: articleDetailData.ArticleText }} />
          </div>
        </div>
        <h2 className={classes.recommendedArticles}>Recommended Read</h2>
        <h3 className={classes.recommendedArticlesSubHeading}>
          Check whats trending in freelance and remote
        </h3>
        <div className={classes.cardMainDiv}>
          {articleData.map((item, index) => {
            return (
              <div
                className={classes.cardInnerDiv}
                onClick={() => {
                  navigate("/articlesRozgaar/" + item.ArticleId);
                  refreshPage();
                }}
              >
                <div>

                  {item.banner !== null ? (
                    <img
                      src={item.banner.BannerImageUrl}
                      className={classes.image}
                      alt={item.Title}
                      title={item.Title}
                      loading="lazy"
                      width={" 100%"}
                      height={220}
                    />
                  ) : (
                    "No  image found"
                  )}
                </div>
                <h2 className={classes.cardHeading}>{item.Title}</h2>
                <h3 className={classes.cardContent}>
                  {item.ShortDescription}
                </h3>
                <div className={classes.infoMainDiv}>
                  <div className={classes.articleCardInfoDiv}>
                    <FaGlobeAsia className={classes.icon} />
                    <div className={classes.info}>{item.Source}</div>
                  </div>
                  <div className={classes.articleCardInfoDiv}>
                    <MdWatchLater className={classes.watchIcon} />
                    <div className={classes.info}>
                      {item.UpdatedAt ? item.UpdatedAt.slice(0, 10) : ""}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <RIFooter /> */}
    </div>
  );
};

export default ArticlesRozgaarWeb;
