import React, { useState, useEffect, useRef } from 'react';
import classes from './CompanyDetails.module.css';
import Loader from 'react-loader-spinner';
import { BsCameraFill } from 'react-icons/bs';
import { FcOrganization, FcBusinessman } from "react-icons/fc";
import { AiTwotoneEdit } from 'react-icons/ai'
import { Helmet } from "react-helmet";
import NewFreelancerDashBoardMobile from '../../Components/NewFreelancerDashBoard/NewFreelancerDashBoardMobile';
import Tab from '../../Components/FreelancerProfile/EditFreelancerProfile/Tab';
import NewFreelancerDashBoard from '../../Components/NewFreelancerDashBoard/NewFreelancerDashboard';
import SuccessModal from '../../Components/FreelancerProfile/SuccessModal';
import TopAlertNotification from '../../Components/FreelancerProfile/TopAlertNotification';
import ProfileFormErrors from '../../Components/FreelancerProfile/EditFreelancerProfile/ProfileFormErrors';
import { pageViewTracker } from '../../Components/GoogleTracking';
const ActionButton = React.lazy(() => import('../../Components/ActionButton'));
const RiTextInputs = React.lazy(() => import('../../Components/PostRequirement/RiTextInputs'));

const CompanyDetails = (props) => {
 
  const userId = localStorage.getItem("userID");
  const companyNameRef = useRef(null);
  const gstRegEx = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [isCompany, setIsCompany] = useState(false);
  const [cityLoading, setCityLoading] = useState(false);
  const [loading, setLoading] = useState("");
  const [error, setError] = useState({});
  const [cityData, setCityData] = useState([]);
  const [city, setCity] = useState([]);
  const [usesApp, setUsesApp] = useState(null);
  const [pinCode, setPinCode] = useState('');
  const [successModal, setSuccessModal] = useState(false)
  const [profileCompletionStatus, setProfileCompletionStatus] = useState("");
  const [mandatoryFieldsLeft , setMandatoryFieldsLeft] =useState([]);
  const [packageStatus , setPackageStatus] =useState([]);
  const [company, setCompany] = useState({
    companyName: '',
    companyLogo: '',
    companyWebsite: '',
    GSTNumber: ''
  });


  useEffect(() => {
    GetFreelancerProfile();
    pageViewTracker();
  }, []);



  const handleState = (e) => {

    setState(e.target.value);
    cityList(country, e.target.value)

  }

  const formValidation = () => {
    let errors = {};
    let isValid = true;

    if (isCompany && company.companyName === "") {
      errors.companyName = "Company Name can't be empty !";
      companyNameRef.current.scrollIntoView();
      isValid = false;
    }
    if (isCompany && country === "") {
      errors.country = "country can't be empty !";
      companyNameRef.current.scrollIntoView();
      isValid = false;
    }
    if (isCompany && state === "") {
      errors.state = "state can't be empty !";
      companyNameRef.current.scrollIntoView();
      isValid = false;
    }
    if (isCompany && city === "") {
      errors.city = "city can't be empty !";
      companyNameRef.current.scrollIntoView();
      isValid = false;
    }
    if (isCompany && pinCode === "") {
      errors.pinCode = "city can't be empty !";
      companyNameRef.current.scrollIntoView();
      isValid = false;
    }
    setError({ ...error, ...errors })

    if (isValid) {
      SendFreelancerProfileAPI()
    }
  }

  const GetFreelancerProfile = () => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");

    var formdata = new FormData();
    formdata.append("FreelancerId", userId);


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(global.apiLink + "/api/rozgaarapi/FreelancerProfile", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status_code === 200) {
          setProfileCompletionStatus(result.FreelancerProfileresponse.ProfilePercentComplete)
          setCompany({
            companyName: result.data.CompanyName,
            companyLogo: result.data.CompanyLogo,
            companyWebsite: result.data.Website,
            GSTNumber: result.data.GSTNumber
          })
          setIsCompany(result.data.CompanyName ? true : false)
          setUsesApp(result.data.UsingApp);
          setCountry(result.data.CompanyCountry);
          setState(result.data.CompanyState);
          cityList(result.data.CompanyCountry, result.data.CompanyState); 
          setCity(result.data.CompanyCity);
          setPinCode(result.data.CompanyPincode);
          setPackageStatus(result.ActivePackage)

        }
      })
      .catch(error => console.log('error', error));
  }

  const SendFreelancerProfileAPI = () => {
    setLoading('SendFreelancerProfile')
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");

    var formdata = new FormData();
    formdata.append("FreelancerId", userId);
    typeof company.companyLogo === 'object' && formdata.append("CompanyLogo", company.companyLogo);
    formdata.append("CompanyName", company.companyName);
    formdata.append("Website", company.companyWebsite);
    formdata.append("GSTNumber", company.GSTNumber);
    formdata.append("CompanyCountry", country);
    formdata.append("CompanyState", state);
    formdata.append("CompanyCity", city);
    formdata.append("CompanyPincode", pinCode);
    formdata.append("UsingApp", usesApp);


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(global.apiLink + "/api/rozgaarapi/FreelancerProfile", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status_code === 200) {
          if (result.FreelancerProfileresponse.MandatoryFieldsLeft.length) {
            setMandatoryFieldsLeft(result.FreelancerProfileresponse.MandatoryFieldsLeft)
            console.log("ReqMandatoryFieldes")
          } else{
            localStorage.setItem('freelancerProfileStatus', true)
           
          }
          
         
          setSuccessModal(true)
        }
        else {
          alert("Sorry! Failed to submit data.")
        }
      })
      .catch(error => console.log('error', error))
      .finally(() => setLoading(''));

  }



  const cityList = (country, state) => {
    setCityLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "Country": country,
      "State": state
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(global.apiLink + "/api/rozgaarapi/GetCityLocatinList", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status_code === 200 && result.status === "Success") {
          setCityData(result.data)
        }
        else {
          console.log(result.Reason)
        }
      })
      .catch(error => console.log('error', error))
      .finally(() => { setCityLoading(false) });
  }

  const HandleIsCompany = ()=>{
    if(isCompany){
      setIsCompany(false);
      setCompany({
        companyName: '',
        companyLogo: '',
        companyWebsite: '',
        GSTNumber: ''
      })
    }
    else{
      setIsCompany(true);
     
    }
    
      
  }

  const WebOrMobileSwitch = (device) => {
    switch (device) {
      case "Mobile":
        return <NewFreelancerDashBoardMobile >
          <Tab />
          {successModal && <SuccessModal  packageStatus={packageStatus}  mandatoryFieldsLeft = {mandatoryFieldsLeft} BackdropClick={()=>setSuccessModal(false)} CancelButton={()=>setSuccessModal(false)}/>}
          <div className={classes.Maincontainer_border}>

            <div className={classes.web_view}>
              <div className={classes.mainContainer}>
                <TopAlertNotification profileCompletion={profileCompletionStatus} />

                <div className={classes.heading}>Will you work as an individual or as a company?</div>
                <div className={classes.subHeading}>It will showcase your profile as an independent or a business</div>
                <div className={classes.selectContainer}>
                  <div onClick={HandleIsCompany} className={!isCompany ? classes.optionTileSelected : classes.optionTile}>
                    <FcBusinessman size={100} />
                    <div className={classes.TileText}>Individual</div>
                  </div>
                  <div onClick={HandleIsCompany} className={isCompany ? classes.optionTileSelected : classes.optionTile}>
                    <FcOrganization size={100} />
                    <div className={classes.TileText}>Company</div>
                  </div>
                </div>
                {
                  isCompany && <div className={classes.input_container}>


                    {company.companyLogo ?
                      <div className={classes.updatetImageContainer}>
                        <div >
                          <img src={typeof company.companyLogo === 'string' ? company.companyLogo : URL.createObjectURL(company.companyLogo)} alt={company.companyName} className={classes.updateImage} 
                          height={120} width={120} title={company.companyName} loading="lazy"
                          />
                        </div>
                        <div>
                          <div className={classes.uploadButtonContainer}>
                            <div className={classes.imageEditIcon}><AiTwotoneEdit className={classes.uploadIcon} onClick={() => setCompany({ ...company, companyLogo: '' })} /></div>
                          </div>
                        </div>
                      </div>
                      :
                      <div className={classes.companyLogoAndContent}>
                        <div className={classes.uploadContainer}>
                          <div >
                            <label className={classes.form_upload_label} for="upload">
                              <input type="file" id="upload" hidden accept=".png, .jpg, .jpeg" onChange={(e) => setCompany({ ...company, companyLogo: e.target.files[0] })} />
                              <BsCameraFill className={classes.uploadIcon} />
                            </label>
                          </div>

                        </div>
                        <div className={classes.uploadLogoContent} > Upload Company Logo</div>
                      </div>
                    }

                    <div ref={companyNameRef}>
                      <RiTextInputs
                        input={"SingleLineInput"}
                        type={"text"}
                        placeHolder={"Company Name"}
                        value={company.companyName}
                        onChange={(e) => setCompany({ ...company, companyName: e.target.value })}
                        onInput={() => { error.companyName = "" }}
                      />
                      {error.companyName && <ProfileFormErrors errorMessage={error.companyName} />}
                    </div>
                    <RiTextInputs
                      input={"SingleLineInput"}
                      type={"text"}
                      placeHolder={"Company Website"}
                      value={company.companyWebsite}
                      onChange={(e) => setCompany({ ...company, companyWebsite: e.target.value })}
                      onInput={() => { error.companyWebsite = "" }}
                    />
                    <div className={classes.heading}>Company Location<span className={classes.manadatoryFields}> * </span></div>
                    <div className={classes.inputContainer}>

                      <RiTextInputs
                        input={"Dropdown"}
                        placeHolder={"Select Country"}
                        onChange={(e) => setCountry(e.target.value)}
                        onInput={() => { error.country = "" }}
                        value={country}
                        default={country}
                      />
                      {error.country && <ProfileFormErrors errorMessage={error.country} />}
                      <>
                        <RiTextInputs
                          input={"Dropdown"}
                          placeHolder={"Select State"}
                          selectedCountry={country === '' ? 'India' : country}
                          onInput={() => { error.state = "" }}
                          onChange={(e) => { handleState(e) }}
                          value={state}
                          defaultState={state}
                        />
                        {error.state && <ProfileFormErrors errorMessage={error.state} />}
                      </>
                    </div>
                    {

                      (cityLoading ? <div className={classes.inputArea}>
                        <Loader type="TailSpin" color="#1778f2" width={20} height={18} />
                      </div> :
                        <><RiTextInputs
                          input={"Dropdown"}
                          placeHolder={"Select City"}
                          displayData={cityData}
                          onInput={() => { error.city = "" }}
                          onChange={(e) => setCity(e.target.value)}
                          value={city}
                        />
                          {error.city && <ProfileFormErrors errorMessage={error.city} />}
                        </>)}

                    <RiTextInputs
                      input={"SingleLineInput"}
                      placeHolder={"Pin Code"}
                      type={"text"}
                      maxLength="6"
                      onChange={(e) => setPinCode(/^[0-9]+$|^$/.test(e.target.value) || country !== "India" ? e.target.value : pinCode)}
                      value={pinCode}
                    />


                    <RiTextInputs
                      input={"SingleLineInput"}
                      type={"text"}
                      placeHolder={"GST Number"}
                      value={company.GSTNumber}
                      onChange={(e) => setCompany({ ...company, GSTNumber: e.target.value })}
                      
                    />
                    
                   
                  </div>
                }
                
                <div className={classes.ActionBtn}>
                  <ActionButton buttonText={loading === "SendFreelancerProfile" ? <Loader type="TailSpin" color="white" width={20} height={20} /> : "Submit for review"} className={classes.Login_button} onClick={formValidation} />
                </div>
              </div>
             
            </div>
          </div>
        </NewFreelancerDashBoardMobile>
      default:
        return <NewFreelancerDashBoard >
          {successModal && <SuccessModal mandatoryFieldsLeft = {mandatoryFieldsLeft}  packageStatus={packageStatus}  BackdropClick={()=>setSuccessModal(false)} CancelButton={()=>setSuccessModal(false)}/>}
          <div className={classes.Maincontainer_border}>
            <div className={classes.web_view}>
              <div className={classes.mainContainer}>
                <TopAlertNotification profileCompletion={profileCompletionStatus} />
                <h1 className={classes.mainHeading}>Company Details</h1>
                <div className={classes.borderBottomHeading}>    </div>
                <div className={classes.heading}>Will you work as an individual or as a company?</div>
                <div className={classes.subHeading}>It will showcase your profile as an independent or a business</div>
                <div className={classes.selectContainer}>
                  <div onClick={HandleIsCompany} className={!isCompany ? classes.optionTileSelected : classes.optionTile}>
                    <FcBusinessman size={100} />
                    <div className={classes.TileText}>Individual</div>
                  </div>
                  <div onClick={HandleIsCompany} className={isCompany ? classes.optionTileSelected : classes.optionTile}>
                    <FcOrganization size={100} />
                    <div className={classes.TileText}>Company</div>
                  </div>
                </div>
                {
                  isCompany && <div className={classes.input_container}>

                    <div className={classes.companyLogoName}>
                      {company.companyLogo ?

                        <div className={classes.updatetImageContainer}>
                          <div >
                            <img src={typeof company.companyLogo === 'string' ? company.companyLogo : URL.createObjectURL(company.companyLogo)} alt={company.companyName} className={classes.updateImage} 
                            height={120} width={120} title={company.companyName} loading="lazy"
                            />
                          </div>
                          <div>
                            <div className={classes.uploadButtonContainer}>
                              <div className={classes.imageEditIcon}><AiTwotoneEdit className={classes.uploadIcon} onClick={() => setCompany({ ...company, companyLogo: '' })} /></div>
                            </div>

                          </div>
                        </div>


                        :
                        <div className={classes.companyLogoAndContent}>
                          <div className={classes.uploadContainer}>
                            <div >
                              <label className={classes.form_upload_label} for="upload">
                                <input type="file" id="upload" hidden accept=".png, .jpg, .jpeg" onChange={(e) => setCompany({ ...company, companyLogo: e.target.files[0] })} />
                                <BsCameraFill className={classes.uploadIcon} />
                              </label>
                            </div>

                          </div>
                          <div className={classes.uploadLogoContent} > Upload Company Logo</div>
                        </div>
                      }
                     
                    </div>
                    <div ref={companyNameRef}>
                      <RiTextInputs
                        input={"SingleLineInput"}
                        type={"text"}
                        placeHolder={"Company Name"}
                        value={company.companyName}
                        onChange={(e) => setCompany({ ...company, companyName: e.target.value })}
                        onInput={() => { error.companyName = "" }}
                      />
                      {error.companyName && <ProfileFormErrors errorMessage={error.companyName} />}
                    </div>
                    <RiTextInputs
                      input={"SingleLineInput"}
                      type={"text"}
                      placeHolder={"Company Website"}
                      value={company.companyWebsite}
                      onChange={(e) => setCompany({ ...company, companyWebsite: e.target.value })}
                      onInput={() => { error.companyWebsite = "" }}
                    />
                    <div className={classes.heading}>Company Location<span className={classes.manadatoryFields}> * </span></div>
                    <div className={classes.inputContainer}>
                      <div className={classes.twoColoumnContainer}>
                        <div className={classes.twoColoumnField}>
                          <RiTextInputs
                            input={"Dropdown"}
                            placeHolder={"Select Country"}
                            onChange={(e) => {setCountry(e.target.value); error.country = "" }}
                            
                            value={country}
                            default={country}
                          />
                          {error.country && <ProfileFormErrors errorMessage={error.country} />}
                        </div>

                        <div className={classes.twoColoumnField}>
                          <RiTextInputs
                            input={"Dropdown"}
                            placeHolder={"Select State"}
                            selectedCountry={country === '' ? 'India' : country}
                           
                            onChange={(e) => { handleState(e);error.state = ""  }}
                            value={state}
                            defaultState={state}
                          />
                        
                          {error.state && <ProfileFormErrors errorMessage={error.state} />}
                        </div>
                      </div>
                    </div>
                    <div className={classes.twoColoumnContainer}>
                      <div className={classes.twoColoumnField}>
                        {

                          (cityLoading ? <div className={classes.inputArea}>
                            <Loader type="TailSpin" color="#1778f2" width={20} height={18} />
                          </div> :
                            <><RiTextInputs
                              input={"Dropdown"}
                              placeHolder={"Select City"}
                              displayData={cityData}
                            
                              onChange={(e) => {setCity(e.target.value);error.city = "" }}
                              value={city}
                            />
                            
                              {error.city && <ProfileFormErrors errorMessage={error.city} />}
                            </>)}
                      </div>
                      <div className={classes.twoColoumnField}>
                        <RiTextInputs
                          input={"SingleLineInput"}
                          placeHolder={"Pin Code"}
                          type={"text"}
                          maxLength="6"
                          onInput={() => { error.pinCode = "" }}
                          onChange={(e) => setPinCode(/^[0-9]+$|^$/.test(e.target.value) || country !== "India" ? e.target.value : pinCode)}
                          value={pinCode}
                        />
                        
                         {error.pinCode && <ProfileFormErrors errorMessage={error.pinCode} />}
                      </div>
                     
                    </div>
                    <RiTextInputs
                      input={"SingleLineInput"}
                      type={"text"}
                      placeHolder={"GST Number"}
                      value={company.GSTNumber}
                      onChange={(e) => setCompany({ ...company, GSTNumber: e.target.value })}
                  
                    />
                   
                  
                  </div>
                }
               

                <div className={classes.ActionBtn}>
                  <ActionButton buttonText={loading === "SendFreelancerProfile" ? <Loader type="TailSpin" color="white" width={20} height={20} /> : "Submit for review"} className={classes.Login_button} onClick={formValidation} />
                </div>
              </div>

            </div>
          </div>
        </NewFreelancerDashBoard>
    }
  }


  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title>{`Frequently Asked Questions about Freelance Jobs  | Rozgaar India`}</title>
        <meta
          name="description"
          content={`Read the Freelancer FAQ's to learn everything about Freelancing, freelance projects, remote work, contract work and gigs.`}
       
       />
           <link rel="canonical" href="https://www.rozgaarindia.com/companyDetails" /> 
      </Helmet>
      <React.Suspense fallback={<div className="pageLoader"><Loader type="TailSpin" color="red" height={80} width={80} className="text-center my-5" /></div>}>
        {
          WebOrMobileSwitch(props.device)
        }
      </React.Suspense>
    </>
  )
}

export default CompanyDetails
