import React, { useState, useEffect, useRef } from 'react'
import classes from "./AutoCompelete.module.css";
import { data } from '../../RefiningSkillsData';
import { Languages } from '../../JsonFiles/languages';
import { IoClose } from 'react-icons/io5';

const languageFluency = [
  "Basic",
  "Fluent"
]



const AutoCompelete = (props) => {

  const autoCompleteType = props.type;


  const scrollRef = useRef(null);
  const [suggestions, setSuggestions] = useState([])
  const [suggestionShow, setSuggestionShow] = useState(false)
  const [suggestionName, setSuggestionName] = useState([]);
  const [IsPressed] = useState(false);
  const [detectFluencyChange, setDetectFluencyChange] = useState(1);
  const [selected, setSelected] = useState(0);

  const searchContainer = useRef(null);


  useEffect(() => {

    window.addEventListener('mousedown', handleClickOutside)

    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    scrollRef?.current?.scrollIntoView();
    window.scrollTo(0, 0);
  }, [selected]);

  useEffect(() => {
    setSelected(0);
  }, [suggestionShow]);



  const handleClickOutside = event => {
    if (searchContainer.current && !searchContainer.current.contains(event.target)) {
      setSuggestionShow(false)
    }
  }


  const keyBoardNavigation = (e) => {

    if (e.key === "Esc") {
      setSuggestionShow(false)
      setSuggestionName("")
    }
  }



  useEffect(() => {
    if (suggestionName !== "") {

      switch (autoCompleteType) {
        case 'skills':
          setSuggestions(data.filter((val, index) => {
            if (val.name && val.related_1 === suggestionName) {
              val = true
            }
            return (val.name ? val.name.toLowerCase().startsWith(suggestionName.toString().toLowerCase()) : null)
          }))

          break;
        case 'sub-skills':
            setSuggestions(props.subSkillSuggestions.filter((val, index) => {
              if (val === suggestionName) {
                val = true
              }
              return (val ? val.toLowerCase().startsWith(suggestionName.toString().toLowerCase()) : null)
            }))
  
            break;
        case 'language':
          setSuggestions(Languages.filter((val, index) => {
           
            return (val.name ? val.name.toLowerCase().startsWith(suggestionName.toString().toLowerCase()) : null)
          }))

          break;
        default:
          break;
      }


    }
    else {
      setSuggestions([])
    }
  }, [suggestionName])

  const seachValuehandler = (e) => {
    setSuggestionName(e.target.value)
    if (e.target.value.length > 0) {
      setSuggestionShow(true);
    }
  }


  const DeleteItem = (id) => {
    switch (autoCompleteType) {
      case 'skills':
        props.setSkillList((preLocation) => {
          return preLocation.filter((item, index) => {
            return index !== id;
          });
        });

        break;
      case 'sub-skills':
        props.setSubSkillList((preLocation) => {
          return preLocation.filter((item, index) => {
            return index !== id;
          });
        });


        break;
      case 'language':
        props.setLanguages((preLocation) => {
          return preLocation.filter((item, index) => {
            return index !== id;
          });
        });

        break;

      default:
        break;
    }

  };

  const ChangeLanguageFluency = (e, language) => {

    let temp = props.languages;
    temp.forEach((item) => {
      if (item.Name === language) {
        item.Fluency = e.target.value;
      }

    });
    setDetectFluencyChange(detectFluencyChange + 1)

    props.setLanguages(temp)
  }

  const keyup = (e) => {
    if (e.code === "ArrowUp" && selected >= 0) {
      setSelected(selected - 1);
    } else if (e.key === "Enter" && suggestionShow) {
      let item = suggestions[selected];
      props.setSkillList([...props.skillList, item.name.charAt(0).toUpperCase() + item.name.slice(1)]);
      setSuggestionName("");
      setSelected(0);
    }
  };
  const keydown = (e) => {
    if (e.code === "ArrowDown" && selected < suggestions.length) {
      setSelected(selected + 1);
    }
  };

  const AutoCompleteType = () => {
    switch (autoCompleteType) {
      case 'skills':
        return <div onKeyDown={(e) => keydown(e)} onKeyUp={(e) => keyup(e)}>
        <div className={classes.mainDiv} ref={searchContainer}>
          <div className={classes.inputDiv}>
          
            <input type="search" name="suggestion" id="Search"
              placeholder="Select upto 10 skills"
              onInput={props.onInput}
              onChange={(e) => {
                seachValuehandler(e);
              }}
              onKeyDown={(e) => { keyBoardNavigation(e) }}
              value={suggestionName}
              disabled={props.skillList.length >= 10}
              autoFocus={IsPressed === true ? true : false}
              className={classes.input}
              autoComplete="off"
            />
          </div>


          <div className={classes.suggestion}>
            {suggestionShow && suggestions.map((val, index) => {
                return props.skillList.includes(val.name) ? null : (
                  <div>
                    {selected === index + 8? <div ref={scrollRef} /> : <></>}
                    <div
                      key={index}
                      className={`${classes.skillSuggestionList} ${
                        selected === index ? classes.dropdown_items_active : ""
                      }`}
                      onClick={() => {
                    
                        props.setSkillList([...props.skillList, val.name]);
                        setSuggestionName("");
                      }}
                    >
                      {val.name.charAt(0).toUpperCase() + val.name.slice(1)}
                    </div>
                  </div>
                );
              })
            }</div>
        </div>
        <div className={classes.tagMainDiv}>
          {props.skillList.map((item, index) => {
            return (
              <>
                {index === 0 ? (
                  <div className={classes.suggestionTagFirst} key={index}>
                    {item.charAt(0).toUpperCase() + item.slice(1)}

                    <IoClose
                      onClick={() => DeleteItem(index)}
                      className={classes.closeIcon}
                    />
                    <div className={classes.primary}> Primary </div>
                  </div>
                ) : (
                  <div className={classes.suggestionTag} key={index}>
                    {item.charAt(0).toUpperCase() + item.slice(1)}

                    <IoClose
                      onClick={() => DeleteItem(index)}
                      className={classes.closeIcon}
                    />
                  </div>
                )}
              </>
            );
          })}
        </div>
        </div>
      case 'sub-skills':
        return <><div className={classes.mainDiv} ref={searchContainer}>
          <div className={classes.inputDiv}>
            <input type="search" name="suggestion" id="Search"
              placeholder="Select sub-skills that are related to your skill"
              onInput={props.onInput}
              onChange={(e) => {
                seachValuehandler(e);
              }}
              onKeyDown={(e) => { keyBoardNavigation(e) }}
              value={suggestionName}
              autoFocus={IsPressed === true ? true : false}
              className={classes.input}
              autoComplete="off"
            />
          </div>


          <div className={classes.suggestion}>
            {suggestionShow && <div >
              {

                (suggestions.length === 0 ? props.subSkillSuggestions: suggestions).map((val, index) => {
                  return props.subSkillList.includes(val) ? null : <div>
                    <p key={index} onClick={() => {
                      props.setSubSkillList([...props.subSkillList, val]); setSuggestionName("")
                    }}
                    >
                      {val}
                    </p>
                  </div>
                })
              }
            </div>
            }</div>
        </div>
          {props.subSkillList.length >= 1 ? <div className={classes.tagMainDiv}>
            {props.subSkillList.map((item, index) => {
              return <div className={classes.suggestionTag} key={index}>
                {item}
                <IoClose onClick={() => DeleteItem(index)} className={classes.closeIcon} />
              </div>
            })}
          </div> : <></>}
        </>
      case 'language':
        return <><div className={classes.mainDiv} ref={searchContainer}>
          <div className={classes.inputDiv}>
            <input type="search" name="suggestion" id="Search"
              placeholder="Search..."
              onInput={props.onInput}
              onChange={(e) => {
                seachValuehandler(e);
              }}
              onKeyDown={(e) => { keyBoardNavigation(e) }}
              value={suggestionName}
              autoFocus={IsPressed === true ? true : false}
              className={classes.input}
              autoComplete="off"
            />
          </div>


          <div className={classes.suggestion}>
            {suggestionShow && <div >
              {

                suggestions.map((val, index) => {
                  return props.languages.includes(val.name) ? null : <div>
                    <p key={index} onClick={() => {
                      props.setLanguages([...props.languages, { Name: val.name, Fluency: "No proficiency" }]); setSuggestionName("")
                    }}
                    >
                      {val.name}
                    </p>
                  </div>
                })
              }
            </div>
            }</div>
        </div>

          {props.languages.length >= 1 ? <div className={classes.tagMainDiv}>
            {props.languages.map((item, index) => {

              return detectFluencyChange && <div className={classes.suggestionTagLanguage} key={index}>
                {item.Name}
                <select onChange={(e) => ChangeLanguageFluency(e, item.Name)} className={classes.dropdownLanguageFluency} value={item.Fluency} >
                  {
                    languageFluency.map((val) => {
                      return <option selected={val === item.Fluency} value={val}>{val}</option>
                    })
                  }


                </select>

                <IoClose onClick={() => DeleteItem(index)} className={classes.closeIcon} />
              </div>
            })}

          </div> : <></>}
        </>



      default:
        console.log("Invalid type in Component -> PostRequirement -> AutoComplete");
        break;
    }

  }

  return (
    <div >

      {AutoCompleteType()}

    </div>
  )
}

export default AutoCompelete
