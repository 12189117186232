import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import classes from "./RequirementPageHeader.module.css";

const RequirementPageHeader = (props) => {
  const navigate = useNavigate();

  const bgColor = () => {
    if (props.headerDetail.RequirementType === "commission") {
      return "commission";
    } else if (props.headerDetail.RequirementType === "contract") {
      return "contract";
    } else if (props.headerDetail.RequirementType === "monthly-basis") {
      return "monthly";
    } else if (props.headerDetail.RequirementType === "oneTime") {
      return "oneTime";
    }
  };
  const contentCase = (skill) => {
    const newSkill = skill.charAt(0).toUpperCase() + skill.slice(1);
    return newSkill;
  };

  const requirementTypeHandler = (reqType) => {
    if (reqType === "contract") {
      return "Contract job!";
    }
    if (reqType === "onetime") {
      return "One Time gig!";
    }
    if (reqType === "monthly-basis") {
      return "Monthly job!";
    }
    if (reqType === "commission") {
      return "Commision based work!";
    }
  };
  const reqTypeColor = (reqType) => {
    if (reqType === "contract") {
      return "contractGreen";
    }
    if (reqType === "onetime") {
      return "OneTimeBlue";
    }
    if (reqType === "monthly-basis") {
      return "monthlyGrey";
    }
    if (reqType === "commission") {
      return "comYellow";
    }
  };
  const UserName = (firstName, lastName) => {
    return (
      firstName.charAt(0).toUpperCase() +
      firstName.slice(1) +
      " " +
      lastName.charAt(0).toUpperCase()
    );
  };

  return (
    <div className={`${classes.mainContainer} ${classes[bgColor()]}`}>
      <div className={classes.arrowAndMenuOption}>
        <div className={classes.backArrow}>
          {" "}
          <BiArrowBack color="white" onClick={() => navigate(-1)} />
        </div>
        <div className={classes.menuOption}>
          <div className={classes.menuButton} onClick={props.OnSharePOst}>
            {" "}
            Share Post
          </div>
          {props.headerDetail.ClosePostButton ? (
            <div className={classes.menuButton} onClick={props.OnClosePOst}>
              {" "}
              Close Post
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className={classes.workingPolicy}>
        {" "}
        Its a
        <span
          className={classes[reqTypeColor(props.headerDetail.RequirementType)]}
        >
          {" "}
          {requirementTypeHandler(props.headerDetail.RequirementType)}
        </span>
      </div>
      <div className={classes.skillAndStatus}>
        {props.headerDetail.Skills &&
          props.headerDetail.Skills.slice(0, 1).map((item, i) => {
            return (
              <div className={classes.mainSkill}>
                {" "}
                {UserName(
                  props.headerDetail.FirstName,
                  props.headerDetail.LastName
                )}{" "}
                is hiring a{" "}
                <span
                  className={`${classes.skillColor} ${
                    classes[reqTypeColor(props.headerDetail.RequirementType)]
                  }`}
                >
                  {props.headerDetail.FreelancerPolicy} {" "}
                </span>{" "}
                freelancer for{" "}
                <span
                  className={`${classes.skillColor} ${
                    classes[reqTypeColor(props.headerDetail.RequirementType)]
                  }`}
                >
                  {contentCase(item.Skill)}{" "}
                </span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default RequirementPageHeader;
