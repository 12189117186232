import classes from './RITheme.module.css';
import Loader from 'react-loader-spinner';

const ActionButton = (props) => {


        const ButtonSwitch = () => {
                switch (props.buttonType) {
                        case "left":

                                return <button className={props.color ? classes.buttonBoxWhite : classes.buttonBoxLeft} onClick={props.onClick}>
                                        <div className={classes.buttonText}>
                                                {props.buttonText}
                                        </div>
                                </button>
                        case "small":

                                return <button className={props.color ? classes.buttonBoxWhite : classes.buttonBoxsmall} onClick={props.onClick}>
                                        <div className={classes.buttonText}>
                                                {props.buttonText}
                                        </div>
                                </button>
                        case "PlanButton":

                                return <button className={props.color ? classes.buttonBoxWhite : classes.buttonBoxPlan} onClick={props.onClick}>
                                        <div className={classes.buttonText}>
                                                {props.buttonText}
                                        </div>
                                </button>
                        case "medium":

                                return <button className={props.color ? classes.buttonBoxWhite : classes.buttonBoxmedium} onClick={props.onClick}>
                                        <div className={classes.buttonText}>
                                                {props.buttonText}
                                        </div>
                                </button>
                        case "signUp":

                                return <button className={props.color ? classes.buttonBoxWhite : classes.buttonBoxSignup} onClick={props.onClick}>
                                        <div className={classes.buttonTextSignUp}>
                                                {props.buttonText}
                                        </div>
                                </button>
                        case "dual":
                                return <div className={classes.dualButton}>
                                        <button className={classes.buttonBoxdualSecond} onClick={props.onClick}>
                                                <div className={classes.buttonText}>

                                                        {props.isLoading ? <Loader type="TailSpin" color="white" width={20} height={18} /> : <> {props.secondButtonText}</>}
                                                </div>
                                        </button>
                                        <button className={classes.buttonBoxdual} onClick={props.onClick}>
                                                <div className={classes.buttonText}>
                                                        {props.buttonText}
                                                </div>
                                        </button>
                                </div>
                        case "edit":
                                return <button className={classes.editBtnContainer} onClick={props.onClick}>
                                        {props.buttonText}
                                </button>

                        case "full":
                                return <button className={classes.buttonBoxFullWidth} onClick={props.onClick}>
                                        <div className={classes.buttonText}>
                                                {props.buttonText}
                                        </div>
                                </button>

                        default:
                                return <button className={props.color ? classes.buttonBoxWhite : classes.buttonBox} onClick={props.onClick}>
                                        <div className={classes.buttonText}>
                                                {props.buttonText}
                                        </div>
                                </button>
                }
        }

        return (<>{ButtonSwitch()}</>)

}

export default ActionButton;