import { Link } from "react-router-dom";
import classes from "./IncomeCard.module.css";

const IncomeCard = (props) => {

    const companyName = (firstName, lastName) => {
        return firstName.charAt(0).toUpperCase() + firstName.slice(1) + " " + lastName.charAt(0).toUpperCase()
    }

    const mainSkillHandler = (skill) => {
   
        if (!skill.length) {
          return "NO Skill";
        } else{
          let firstSkill = skill[0];
          let mainSkill =
          firstSkill.Skill.charAt(0).toUpperCase() + firstSkill.Skill.slice(1);
          return mainSkill;
        }
       
      };





  return (
    <div className={classes.mainContainer}>
        {props.freelancerWork.map((item , index) => {
      return (
      <div className={classes.mainSection}>
      <div className={classes.mainSkill} key = {index}>{mainSkillHandler(item.RequirementSkills)}</div>
    
      <div className={classes.userName}>{companyName(item.ClientFirstName , item.ClientLastName)}</div>

      <div className={classes.description}>
      <Link to={"/requirementDetail/"+ item.RequirementID} className = {classes.viewReq}> {item.Description === "" ? "N/A" :item.Description }</Link>
      </div>
      <div className={classes.requirementStatus}>
        <div className={classes.reqStatusHeadingFirst}>
          
          Order date :
          <span className={classes.reqStatusData}>{item.UpdatedAt.slice(0, 10).split("-").reverse().join("-")}</span>
        </div>
        <div className={classes.reqStatusHeading}>
       
          Staus : <span className={classes.reqStatusData}>{item.Status === ""? "N/A" :item.Status }</span>
        </div>
        <div className={classes.reqStatusHeading}>
       
          Order ID: <span className={classes.reqStatusData}>{item.PGOrderId === "" ? "N/A" : item.PGOrderId} </span>
        </div>
      </div>

      <div className={classes.paymentAndOtherDetail}>
        <div>
  
          Proposal: <span className={classes.payment}> {item.GrandTotal === ""? "N/A" :  item.BudgetCurrency+ "" +item.Budget } </span>
        </div>
      </div>
      <div className={classes.paymentAndOtherDetail}>
        <div>
  
          Deposite Amount: <span className={classes.payment}> {item.PaymentAmount === ""? "N/A" :  item.BudgetCurrency+ "" +item.PaymentAmount } </span>
        </div>
      </div>
      <Link to={"/requirementDetail/"+ item.RequirementID} className = {classes.viewReq}>
        <div className={classes.viewButtom}> View </div>
      </Link>
      </div>
        )})}
    </div>

  );
};

export default IncomeCard;
