import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import classes from "./AboutUs.module.css";
import { Helmet } from "react-helmet";
import Loader from "react-loader-spinner";
import { pageViewTracker } from "../Components/GoogleTracking";
const RIFooter = React.lazy(() => import("../Components/RIFooter"));

const AboutUs = () => {

  useEffect(() => {
    pageViewTracker();
  }, [])
  
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Best Remote work and freelance projects at RozgaarIndia.com via Times Ascent`}</title>
        <meta
          name="description"
          content={`Rozgaar India is one of India's Leading online service marketplace for remote work and freelance projects. You can find the best skilled online service providers at RozgaarIndia.com`}
        />
         <link rel="canonical" href="https://www.rozgaarindia.com/AboutUs" /> 
      </Helmet>
      <React.Suspense
        fallback={
          <div className="pageLoader">
            <Loader
              type="TailSpin"
              color="red"
              height={80}
              width={80}
              className="text-center my-5"
            />
          </div>
        }
      >
        <div className={classes.top_link}>
          <Link to="/" className={classes.home_link}>
            Home
          </Link>{" "}
          / <span className={classes.home_linkTerm}>About us</span>
        </div>
        <div className={classes.para_text_container}>
          <h1>About Company</h1>
          <div className={classes.para_text}>
            Rozgaarindia.com is revolutionizing the way people work. We connect
            the world with talented verified freelancers faster than ever before
            to collaborate, and get work done in a safe and secure online
            environment.
          </div>
          <div className={classes.para_text}>
            Our mission is to simplify freelancers and remote hiring providing
            most aligned freelancers for a day, month or a year fulfilling your
            professional needs. Our easy-to-use platform enables you to manage
            off-balance sheet talented workers in a single click with confidence
            and trust so you can save time and focus on your success. We match
            professional freelancers with the top freelancing requirements,
            making freelance remote work more efficient than ever before.
          </div>
        </div>

        <div className={classes.core_values_container}>
          <div className={classes.core_values_heading}>Core Values</div>
          <div className={classes.core_values_para}>
            <img
              src="./assets/AboutUs/core_values.svg"
              alt="Core_Values"
              title="Core_Values"
              loading="lazy"
              className={classes.core_image}
              width={"100%"}
              height={150}
            />
            <img
              src="./assets/AboutUs/core_values.svg"
              alt="Core_Values"
              title="Core_Values"
              loading="lazy"
              className={classes.core_imageMob}
              width={"100%"}
              height={100}
            />

            <p className={classes.core_values_text}>
              Our core values are the deeply ingrained principles that guide all
              of a company's actions. They serve as our fundamental beliefs and
              cultural cornerstones.
            </p>
          </div>
        </div>
 
        {/* <RIFooter /> */}
      </React.Suspense>
    </div>
  );
};

export default AboutUs;
