
import classes from './AboutFreelancer.module.css'
import ActionButton from '../ActionButton';

const AboutFreelancer = (props) => {

    return (
        <div className={classes.mainContainer}>
            {props.hasOwnProperty('setEditSection') && <div className={classes.editBtn}>
                <ActionButton buttonType={'edit'} buttonText={'Edit'} onClick={() => props.setEditSection('AboutFreelancer')} />
            </div>}
            <div className={classes.heading}>

                About me </div>

            <div>

                {props.aboutFreelancerData.description}


            </div>

            <div >
                <div className={classes.mainHeading}>
                    Skill
                </div>
                <div className={classes.tabContainer}>
                    {props.aboutFreelancerData.skills.map((item, i) => {
                        return i !== 0 && <div className={classes.SkillTab} key={item.Skills}> {item.Skills}
                        </div>
                    }



                    )}
                </div>
            </div>
        </div>

    )
}

export default AboutFreelancer;