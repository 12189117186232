import React, { useState,useEffect } from "react";
import classes from "./FreelancerMemberShipWeb.module.css";
import { BsCheck } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { pageViewTracker } from '../Components/GoogleTracking';

 
const FreelancerMemberShiplWeb = (props) => {
  const navigate = useNavigate();

  const [activePlan, setActivePlan] = useState("Basic");
  const [packageID, setPackageID] = useState("32131ad2-9954-11ec-b62d-02c01e8e4bda");
  const freelancerId = localStorage.getItem("userID")

useEffect(() => {
 pageViewTracker();
}, [])


  const freelancerPackageOrder = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");
  
    var raw = JSON.stringify({
      "FreelancerId":(freelancerId),
    "PackageId":(packageID)
  
  });
  
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    fetch(
      global.apiLink+"/api/rozgaarapi/FreelancerPackagePaymentInitiate",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
         
          navigate("/payment/" + result.data.PGOrderId)
        } else {
        
        
        }
      })
      .catch((error) => console.log("error", error));
  };


  const planSelection = (type,PackageId ) =>{
    setActivePlan(type)
    setPackageID(PackageId)

  }
  
  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title>{`Frequently Asked Questions about Freelance Jobs  | Rozgaar India`}</title>
        <meta
          name="description"
          content={`Read the Freelancer FAQ's to learn everything about Freelancing, freelance projects, remote work, contract work and gigs.`}
       
       />
           <link rel="canonical" href="https://www.rozgaarindia.com/FreelancerPackage" /> 
      </Helmet>
      <div>
        <h1 className={classes.packageHeading}>Choose Your Plan</h1>

        <div className={classes.planDiv}>
          <div className={classes.plans}>
            <div className={classes.planHeadingMain}>Plans</div>
            <div
              className={
                activePlan === "Basic"
                  ? classes.activePlanType
                  : classes.planType
              }
            >
              BASIC
            </div>
            <div
              className={
                activePlan === "Plus"
                  ? classes.activePlanType
                  : classes.planType
              }
            >
              PLUS
            </div>
            <div
              className={
                activePlan === "Pro" ? classes.activePlanType : classes.planType
              }
            >
              PRO
            </div>
          </div>
        </div>

        <div className={classes.planSelectionDiv}>
          <div className={classes.planDetailsHeadingMainDiv}>
            <div className={classes.planHeading}>Commission</div>
            <div className={classes.planHeading}>Proposal Application credits</div>
            <div className={classes.planHeading}>Apply for Top Hiring Companies</div>
            <div className={classes.planHeading}>
              Share Profile on Social Media
            </div>
            <div className={classes.planHeading}>Withdrawal of Payment</div>
            <div className={classes.planHeading}>Skill Enhancing Courses</div>
            <div className={classes.planHeading}>Personal Account Manager</div>
            <div className={classes.planHeading}>Profile Analysis</div>
          </div>

          {props.packages.map((item, index) => {
            return (
              <>
                {item.Packagename === "BASIC" ? (
                  <div className={classes.conatiner}>
                    <div
                      className={
                        activePlan === "Basic"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Basic", item.PackageId)}
                    >
                      {item.Commission}
                    </div>
                    <div
                      className={
                        activePlan === "Basic"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Basic", item.PackageId)}
                    >
                      {item.ProPosal}
                    </div>
                    <div
                      className={
                        activePlan === "Basic"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Basic", item.PackageId)}
                    >
                      {item.ApplyForTopHiringCompanies}
                    </div>
                    <div
                      className={
                        activePlan === "Basic"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Basic", item.PackageId)}
                    >
                      {item.ShareProfileOnSocialMedia === "1" ? (
                        <BsCheck />
                      ) : (
                        "-"
                      )}
                    </div>
                    <div
                      className={
                        activePlan === "Basic"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Basic", item.PackageId)}
                    >
                      {item.WithdrawalofPayment} days
                    </div>
                    <div
                      className={
                        activePlan === "Basic"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Basic", item.PackageId)}
                    >
                      {item.SkillEnhancingCourses === "1" ? <BsCheck /> : "-"}
                    </div>
                    <div
                      className={
                        activePlan === "Basic"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Basic", item.PackageId)}
                    >
                      {item.PersonalAccountManage === "1" ? <BsCheck /> : "-"}
                    </div>
                    <div
                      className={
                        activePlan === "Basic"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Basic", item.PackageId)}
                    >
                      {item.ProfileAnalysis}
                    </div>
            

                    {activePlan === "Basic" ? (
                      <div className={classes.planDetailsButtonDiv}>
                        <button className={classes.planConfirm}>SELECT</button>{" "}
                      </div>
                    )
                    
                    
                    
                    
                    : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </>
            );
          })}
          {props.packages.map((item, index) => {
            return (
              <>
                {item.Packagename === "PLUS" ? (
                  <div className={classes.conatiner}>
                    <div
                      className={
                        activePlan === "Plus"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Plus", item.PackageId)}
                    >
                      {item.Commission}
                    </div>
                    <div
                      className={
                        activePlan === "Plus"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Plus", item.PackageId)}
                    >
                      {item.ProPosal}
                    </div>
                    <div
                      className={
                        activePlan === "Plus"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Plus", item.PackageId)}
                    >
                      {item.ApplyForTopHiringCompanies}
                    </div>
                    <div
                      className={
                        activePlan === "Plus"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Plus", item.PackageId)}
                    >
                      {item.ShareProfileOnSocialMedia === "1" ? (
                        <BsCheck />
                      ) : (
                        "-"
                      )}
                    </div>
                    <div
                      className={
                        activePlan === "Plus"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Plus", item.PackageId)}
                    >
                      {item.WithdrawalofPayment} days
                    </div>
                    <div
                      className={
                        activePlan === "Plus"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Plus", item.PackageId)}
                    >
                      {item.PremiumCourses === "1" ? <BsCheck /> : "-"}
                    </div>
                    <div
                      className={
                        activePlan === "Plus"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Plus", item.PackageId)}
                    >
                      {item.PersonalAccountManage === "1" ? <BsCheck /> : "-"}
                    </div>
                    <div
                      className={
                        activePlan === "Plus"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Plus", item.PackageId)}
                    >
                      {item.ProfileAnalysis}
                    </div>
                    
                    {activePlan === "Plus" ? (
                      <div className={classes.planDetailsButtonDiv}>
                        <button className={classes.planConfirm}>SELECT</button>{" "}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </>
            );
          })}
          {props.packages.map((item, index) => {
            return (
              <>
                {item.Packagename === "PRO" ? (
                  <div className={classes.conatiner}>
                    <div
                      className={
                        activePlan === "Pro"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Pro", item.PackageId)}
                    >
                      {item.Commission}
                    </div>
                    <div
                      className={
                        activePlan === "Pro"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Pro", item.PackageId)}
                    >
                      {item.ProPosal}
                    </div>
                    <div
                      className={
                        activePlan === "Pro"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Pro", item.PackageId)}
                    >
                      {item.ApplyForTopHiringCompanies}
                    </div>
                    <div
                      className={
                        activePlan === "Pro"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Pro", item.PackageId)}
                    >
                      {item.ShareProfileOnSocialMedia === "1" ? (
                        <BsCheck />
                      ) : (
                        "-"
                      )}
                    </div>
                    <div
                      className={
                        activePlan === "Pro"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Pro", item.PackageId)}
                    >
                      {item.WithdrawalofPayment} hours
                    </div>
                    <div
                      className={
                        activePlan === "Pro"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Pro", item.PackageId)}
                    >
                      {item.PremiumCourses === "1" ? <BsCheck /> : "-"}
                    </div>
                    <div
                      className={
                        activePlan === "Pro"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Pro", item.PackageId)}
                    >
                      {item.PersonalAccountManage === "1" ? <BsCheck /> : "-"}
                    </div>
                    <div
                      className={
                        activePlan === "Pro"
                          ? classes.activePlanDetails
                          : classes.planDetails
                      }
                      onClick={() => planSelection ("Pro", item.PackageId)}
                    >
                      {item.ProfileAnalysis}
                    </div>
                    
                    {activePlan === "Pro" ? (
                      <div className={classes.planDetailsButtonDiv}>
                        <button className={classes.planConfirm}>SELECT</button>{" "}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </>
            );
          })}
        </div>

      

        <div className={classes.upgradePlanMainDiv}>
        
          <div className={classes.upgradePlan}>
            <div className={classes.planPriceDiv}>
              {activePlan === "Basic" ? (
                <div className={classes.totalPrice}>Basic</div>
              ) : activePlan === "Plus" ? (
                <div className={classes.totalPrice}>Plus</div>
              ) : activePlan === "Pro" ? (
                <div className={classes.totalPrice}>Pro</div>
              ) : (
                <></>
              )}
              {activePlan === "Basic" ? (
                <div className={classes.planPrices}>
                  ₹199<span className={classes.perYear}>/Year</span>
                </div>
              ) : activePlan === "Plus" ? (
                <div className={classes.planPrices}>
                  ₹499<span className={classes.perYear}>/Year</span>
                </div>
              ) : activePlan === "Pro" ? (
                <div className={classes.planPrices}>
                  ₹799<span className={classes.perYear}>/Year</span>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className={classes.selectButtonDiv}>
              <button
                className={classes.selectButton}
                onClick={() =>freelancerPackageOrder()}
              >
         
                Buy Plan
              </button>
            </div>
          </div>
        </div>

    
      </div>
    </>
  );
};

export default FreelancerMemberShiplWeb;
