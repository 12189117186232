import { useEffect, useState } from "react";
import ActionButton from "../../Components/ActionButton";
import NewFreelancerDashBoard from "../../Components/NewFreelancerDashBoard/NewFreelancerDashboard";
import { useNavigate } from "react-router-dom";
import NewFreelancerDashBoardMobile from "../../Components/NewFreelancerDashBoard/NewFreelancerDashBoardMobile";
import { BsCheck } from "react-icons/bs";
import classes from "./PackageOption.module.css";
import { pageViewTracker } from "../../Components/GoogleTracking";

const PackageOption = (props) => {
  const [packagedata, setPackagedata] = useState([]);
  const [freelancerPackageSelected, setFreelancerPackageSelected] =
    useState("BASIC");

  const [packageAmount, setPackageAmount] = useState("199");
  const [currPackage, setCurrPackage] = useState("");
  const [availableCredits, setAvailableCredits] = useState("");
  const [packageID, setPackageID] = useState(
    "32131ad2-9954-11ec-b62d-02c01e8e4bda"
  );

  const [buyBtn, setbuyBtn] = useState(false);

  const navigate = useNavigate();
  const freelancerId = localStorage.getItem("userID");

  useEffect(() => {
    pageViewTracker();
  }, []);

  useEffect(() => {
    planData();
    PackageSelected(freelancerPackageSelected, packageAmount, packageID);
  }, []);

  const freelancerPackageOrder = (id) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      FreelancerId: freelancerId,
      PackageId: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      global.apiLink + "/api/rozgaarapi/FreelancerPackagePaymentInitiate",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          navigate("/payment/" + "FreelancerPackage/" + result.data.PGOrderId);
        } else {
        }
      })
      .catch((error) => console.log("error", error));
  };

  const planData = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      FreelancerId: freelancerId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      global.apiLink + "/api/freelancerapp/rozgaarapi/GetPackagesMaster",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setPackagedata(result.data);

        setCurrPackage(result.FreelancerLatestPackage || "");
        setAvailableCredits(result.AvailableCredit);
      })
      .catch((error) => console.log("error", error));
  };

  const packageHeads = [
    {
      Key: "1",
      heading: "Proposal Application credits",
    },
    {
      Key: "2",
      heading: "Commission",
    },
    {
      Key: "3",
      heading: "Apply for Top Hiring Companies",
    },
    {
      Key: "4",
      heading: "Share Profile on Social Media",
    },
    {
      Key: "5",
      heading: "Withdrawal of Payment",
    },
    {
      Key: "6",
      heading: "Skill Enhancing Courses",
    },
    {
      Key: "7",
      heading: "Personal Account Manager",
    },
    {
      Key: "8",
      heading: "Setup Help",
    },
    {
      Key: "9",
      heading: "Profile Video Analysis",
    },
  ];

  const PackageSelected = (Packagename, Amount, packageId) => {
    setFreelancerPackageSelected(Packagename);
    setPackageAmount(Amount);
    setPackageID(packageId);
  };

  const MobileWebHandlerSwitch = (device) => {
    switch (device) {
      case "Mobile":
        return (
          <NewFreelancerDashBoardMobile>
            <div className={classes.mainContainer}>
              <div className={classes.heading}>Choose Your Plan</div>
              {currPackage === "" ? (
                <></>
              ) : (
                <div className={classes.CurrentPlanContainer}>
                  <div>
                    Current Plan :
                    <span className={classes.CurrentPlansection}>
                      {" "}
                      {currPackage.Packagename}
                    </span>
                  </div>
                  <div>
                    {" "}
                    Aviable Credits :
                    <span className={classes.CurrentPlansection}>
                      {" "}
                      {availableCredits}
                    </span>
                  </div>
                </div>
              )}
              <div className={classes.tableFormat}>
                <div className={classes.fistSectionHeads}>
                  <div className={classes.mainHeading}> Plans </div>
                  {packageHeads.map((item, i) => {
                    return (
                      <>
                        <div className={classes.tableHeads}>
                          {item.heading}{" "}
                        </div>
                        <div className={classes.tableHeadsBorder}></div>
                      </>
                    );
                  })}
                </div>
                <div className={classes.displayTable}>
                  {packagedata.map((item, index) => {
                    return (
                      <>
                        {freelancerPackageSelected === item.Packagename &&
                        item.PackageId ? (
                          <div
                            className={
                              freelancerPackageSelected === item.Packagename
                                ? classes.tableContentsSelected
                                : classes.tableContents
                            }
                          >
                            <div className={classes.mainHeadingData}>
                              {item.Packagename}
                            </div>
                            <div className={classes.tableData}>
                              {item.CreditCount}
                            </div>
                            <div className={classes.tableData}>
                              {item.Commission}
                            </div>
                            <div className={classes.tableData}>
                              {item.ApplyForTopHiringCompanies}
                            </div>
                            <div className={classes.tableData}>
                              {item.ShareProfileOnSocialMedia === "1" ? (
                                <BsCheck />
                              ) : (
                                "-"
                              )}
                            </div>
                            <div className={classes.tableData}>
                              {item.WithdrawalofPayment === "24"
                                ? item.WithdrawalofPayment + " " + "hours"
                                : item.WithdrawalofPayment + " " + "days"}
                            </div>
                            <div className={classes.tableData}>
                              {item.SkillEnhancingCourses === "1" ? (
                                <BsCheck />
                              ) : (
                                "-"
                              )}
                            </div>
                            <div className={classes.tableData}>
                              {item.PersonalAccountManage === "1" ? (
                                <BsCheck />
                              ) : (
                                "-"
                              )}
                            </div>
                            <div className={classes.tableData}>
                              {item.ProfileAnalysis === "0" ? <BsCheck /> : "-"}
                            </div>
                            <div className={classes.tableData}>
                              {item.ProfileAnalysisVideoAssistance === "0" ? (
                                "-"
                              ) : (
                                <BsCheck />
                              )}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>

              <div className={classes.planMainDiv}>
                {packagedata.map((item, index) => {
                  return (
                    <div
                      className={
                        freelancerPackageSelected === item.Packagename
                          ? classes.active
                          : classes.planSelection
                      }
                      onClick={() =>
                        PackageSelected(
                          item.Packagename,
                          item.Amount,
                          item.PackageId
                        )
                      }
                    >
                      ₹{item.Amount} {item.Packagename}
                    </div>
                  );
                })}
              </div>
              <ActionButton
                buttonType={"green"}
                buttonText={"Buy Plan"}
                onClick={() => freelancerPackageOrder(packageID)}
              />
            </div>
          </NewFreelancerDashBoardMobile>
        );
      default:
        return (
          <NewFreelancerDashBoard>
            <div className={classes.mainContainer}>
              <div className={classes.heading}>Choose Your Plan</div>
              <div
                className={
                  currPackage !== ""
                    ? classes.inactivePlan
                    : classes.currentPlanMainDiv
                }
              >
                {currPackage === "" ? (
                  <></>
                ) : (
                  <div className={classes.CurrentPlanContainer}>
                    <div className={classes.CurrentPlansection}>
                      Current Plan : {currPackage.Packagename}
                    </div>
                    <div> Available Credits : {availableCredits}</div>
                  </div>
                )}
              </div>
              <div className={classes.tableFormat}>
                <div className={classes.fistSectionHeads}>
                  <div className={classes.mainHeading}> Plans </div>
                  {packageHeads.map((item, i) => {
                    return (
                      <>
                        <div className={classes.tableHeads}>
                          {item.heading}{" "}
                        </div>
                        <div className={classes.tableHeadsBorder}></div>
                      </>
                    );
                  })}
                </div>
                <div className={classes.displayinOrder}>
                  {packagedata.map((item, i) => {
                    return (
                      <>
                        <div
                          className={
                            freelancerPackageSelected === item.Packagename ||
                            (!freelancerPackageSelected && i == 2)
                              ? classes.tableContentsSelected
                              : classes.tableContents
                          }
                        >
                          <div className={classes.contentsBold}>
                            {item.Packagename}{" "}
                          </div>
                          <div className={classes.contents}>
                            {" "}
                            {item.CreditCount}
                          </div>
                          <div className={classes.contents}>
                            {" "}
                            {item.Commission}{" "}
                          </div>
                          <div className={classes.contents}>
                            {item.ApplyForTopHiringCompanies}{" "}
                          </div>
                          <div className={classes.contents}>
                            {item.ShareProfileOnSocialMedia === "1" ? (
                              <BsCheck />
                            ) : (
                              "-"
                            )}{" "}
                          </div>
                          <div className={classes.contents}>
                            {item.WithdrawalofPayment === "24"
                              ? item.WithdrawalofPayment + " " + "hours"
                              : item.WithdrawalofPayment + " " + "days"}{" "}
                          </div>
                          <div className={classes.contents}>
                            {item.SkillEnhancingCourses === "1" ? (
                              <BsCheck />
                            ) : (
                              "-"
                            )}{" "}
                          </div>
                          <div className={classes.contents}>
                            {item.PersonalAccountManage === "1" ? (
                              <BsCheck />
                            ) : (
                              "-"
                            )}{" "}
                          </div>
                          <div className={classes.contents}>
                            {item.ProfileAnalysis === "0" ? "-" : <BsCheck />}{" "}
                          </div>
                          <div className={classes.contents}>
                            {item.ProfileAnalysisVideoAssistance === "0" ? (
                              "-"
                            ) : (
                              <BsCheck />
                            )}{" "}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className={classes.btnBlock}>
                <div className={classes.ActionBtnContainer}>
                  {packagedata.map((item, index) => {
                    return (
                      <div className={classes.buyPlanButton} key={index}>
                        {freelancerPackageSelected === item.Packagename ? (
                          <ActionButton
                            buttonType={"small"}
                            buttonText={"Select ₹" + item.Amount}
                            onClick={() => {
                              // freelancerPackageOrder(item.PackageId);

                              PackageSelected(
                                item.Packagename,
                                item.Amount,
                                item.PackageId
                              );
                            }}
                          />
                        ) : (
                          <ActionButton
                            buttonType={"PlanButton"}
                            buttonText={"Select ₹" + item.Amount}
                            onClick={() => {
                              // freelancerPackageOrder(item.PackageId);
                              setbuyBtn(true);
                              PackageSelected(
                                item.Packagename,
                                item.Amount,
                                item.PackageId
                              );
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                  
                </div>
                <div
                  className={classes.activePlan}
                >
                  <ActionButton
                    buttonType={"small"}
                    buttonText={"Buy Plan"}
                    onClick={() => freelancerPackageOrder(packageID)}
                  />
                </div>
              </div>

              <div
                className={
                  currPackage === ""
                    ? classes.inactivePlan
                    : classes.currentPlanMainDiv
                }
              >
                <div></div>
              </div>
            </div>
          </NewFreelancerDashBoard>
        );
    }
  };

  return <>{MobileWebHandlerSwitch(props.device)}</>;
};

export default PackageOption;
