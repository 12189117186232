import React, { useState, useEffect } from "react";
import classes from "./Notification.module.css";
import Loader from "react-loader-spinner";
import {
  FcMoneyTransfer,
  FcHighPriority,
  FcServices,
  FcRating,
  FcInfo,
  FcOk,
  FcApproval,
  FcBriefcase,
  FcFile,
  FcPuzzle,
} from "react-icons/fc";
import { RiMessage2Fill } from "react-icons/ri";
import { Helmet } from "react-helmet";
import { pageViewTracker } from "../../Components/GoogleTracking";
const Notification = () => {
  const [isdataUnread, setIsDataUnread] = useState([]);
  const [readData, setReadData] = useState([]);
  let userID = localStorage.getItem("userID");

  useEffect(() => {
    
    UpdateNotification();
    GetNotificationAPI();

    pageViewTracker();
  }, []);
  const GetNotificationAPI = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      UserId: userID,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(global.apiLink + "/api/rozgaarapi/GetNotification", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsDataUnread(result.dataunread);
        setReadData(result.dataread);
        console.log(result.dataread);
      })
      .catch((error) => console.log("error", error))
      .finally(() => {});
  };

const UpdateNotification = () => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
  myHeaders.append("Content-Type", "application/json");
  
  var raw = JSON.stringify({
    UserId: userID
  });
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };
  
  fetch(global.apiLink + "/api/rozgaarapi/UpdateNotification", requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.status=== "SUCCESS" && result.status_code===200){
        
      }
    })
    .catch(error => console.log('error', error));
}


  const timeSince = (date) => {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return interval + " years";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + " months";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + " days";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + " hours";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  };

  const SwitchStatus = (imagesfornotification) => {
  

    switch (imagesfornotification) {
      case "Freelancer Profile Live":
        return (
          <div>
            <FcBriefcase className={classes.icon} />
          </div>
        );
      case "Freelancer Profile Rejected":
        return (
          <div>
            <FcHighPriority className={classes.icon} />
          </div>
        );
      case "Freelancer Profile Submitted":
        return (
          <div>
            <FcInfo className={classes.icon} />
          </div>
        );
      case "Freelancer Complete Profile":
        return (
          <div>
            <FcPuzzle className={classes.icon} />
          </div>
        );
        case "Sucess Payment Message":
          return (
            <div>
              <FcOk className={classes.icon} />
            </div>
          );
      case "Freelancer Personal Manager":
        return (
          <div>
            <FcServices className={classes.icon} />
          </div>
        );
      case "Freelancer Proposal Submitted":
        return (
          <div>
            <FcFile className={classes.icon} />
          </div>
        );
      case "Freelancer Proposal Accepted":
        return (
          <div>
            <FcApproval className={classes.icon} />
          </div>
        );
      case "Freelancer Proposal Rejected":
        return (
          <div>
            <FcHighPriority className={classes.icon} />
          </div>
        );
      case "Freelancer Client Payment Received":
        return (
          <div>
            <FcApproval className={classes.icon} />
          </div>
        );
      case "Freelancer Withdraw":
        return (
          <div>
            <FcMoneyTransfer className={classes.icon} />
          </div>
        );
      case "Freelancer KYC Approved":
        return (
          <div>
            <FcOk className={classes.icon} />
          </div>
        );
        case "Freelancer KYC Rejected":
        return (
          <div>
            <FcHighPriority className={classes.icon} />
          </div>
        );
      case "Freelancer Rate & Review":
        return (
          <div>
            <FcRating className={classes.icon} />
          </div>
        );
        case "Freelancer Message":
          return (
            <div>
              <RiMessage2Fill className={classes.icon} />
            </div>
          );
      case "Freelancer Skills Message to freelance":
        return (
          <div>
            <RiMessage2Fill className={classes.icon} />
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title>{`Frequently Asked Questions about Freelance Jobs  | Rozgaar India`}</title>
        <meta
          name="description"
          content={`Read the Freelancer FAQ's to learn everything about Freelancing, freelance projects, remote work, contract work and gigs.`}
       
       />
           <link rel="canonical" href="https://www.rozgaarindia.com/notification" /> 
      </Helmet>
    <React.Suspense
      fallback={
        <div className="pageLoader">
          <Loader
            type="TailSpin"
            color="red"
            height={80}
            width={80}
            className="text-center my-5"
          />
        </div>
      }
    >
      <h1 className={classes.Notification_heading}>Notification</h1>
      <div className={classes.Notification_maindiv}>
        <div className={classes.Notification_container}>
          {isdataUnread || readData ? (
            <div>
              <div>
                {isdataUnread &&
                  isdataUnread.map((item, index) => {
                    return (
                      <>
                        <div key={index} className={classes.Notification_flex}>
                          {SwitchStatus(item.Event)}
                          <div>
                            <div
                              className={
                                Text === "dataunread"
                                  ? classes.notification_textbold
                                  : classes.notification_text
                              }
                            >
                              {item.Text}
                            </div>
                            <div className={classes.Notification_time}>
                              {timeSince(new Date(item.CreatedDate))} ago
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}

                {readData &&
                  readData.map((item, index) => {
                    return (
                      <>
                        <div key={index} className={classes.Notification_flex}>
                      
                          {SwitchStatus(item.Event)}
                          <div>
                            <div
                              className={
                                Text === "dataunread"
                                  ? classes.notification_textbold
                                  : classes.notification_text
                              }
                            >
                              {item.Text}
                            </div>
                            <div className={classes.Notification_time}>
                              {timeSince(new Date(item.CreatedDate))} ago
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          ) : (
            <div className={classes.blank_Notification_div}>
              <img
                src="/assets/notification/Nothing.svg"
                className={classes.blank_Notification_img}
                alt="notification"
              />
              <div className={classes.blank_Notification_heading}>
                Nothing here!
              </div>
              <div className={classes.blank_Notification_text}>
                You do not have any notification yet
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Suspense>
    </>
  );
};
export default Notification;
