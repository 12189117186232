import React from "react";
import classes from "./KycBox.module.css";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
const KycBox = (props) => {

  
  return (
    <div className={classes.mainContainer}>
      <div className={classes.heading}>KYC</div>

      <div className={classes.arrowIcon}>
        <div>You KYC status is {props.KycStatus === "Notstarted"? "Unverified" : props.KycStatus } </div>
       <Link to={"/KycForm"}> 
        <AiOutlineArrowRight className={classes.icon} />
        </Link>
      </div>
    </div>
  );
};

export default KycBox;
