import React, { useEffect } from 'react'
import Notification from "./Notification"
import { Helmet } from "react-helmet";
import NewFreelancerDashBoardMobile from "../../Components/NewFreelancerDashBoard/NewFreelancerDashBoardMobile";
import NewFreelancerDashBoard from "../../Components/NewFreelancerDashBoard/NewFreelancerDashboard";
import { pageViewTracker } from "../../Components/GoogleTracking";


const NotificationDashboard = (props) => {

  useEffect(() => {
    pageViewTracker();
  }, [])
  

  const MobileWebHandlerSwitch = (device) => {
    switch (device) {
      case "Mobile":
        return (
          <NewFreelancerDashBoardMobile>
            <Notification />
          </NewFreelancerDashBoardMobile>

        );

      default:
        return (
          <NewFreelancerDashBoard>
            <Notification />
          </NewFreelancerDashBoard>

        );
    }
  };

  return <>
   <Helmet>
        <meta charSet="utf-8" />
        <title>{`Frequently Asked Questions about Freelance Jobs  | Rozgaar India`}</title>
        <meta
          name="description"
          content={`Read the Freelancer FAQ's to learn everything about Freelancing, freelance projects, remote work, contract work and gigs.`}
       
       />
           <link rel="canonical" href="https://www.rozgaarindia.com/notification" /> 
      </Helmet>
  {MobileWebHandlerSwitch(props.device)}
  </>;


}

export default NotificationDashboard;