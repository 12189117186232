import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import classes from "./WorkRequirementDashboard.module.css";
import PublicRecRequirementCard from "../../Components/ApplyRequirements/PublicRecRequirmentCard";
import ReactGA from 'react-ga';
import NewFreelancerDashBoard from "../../Components/NewFreelancerDashBoard/NewFreelancerDashboard";
import NewFreelancerDashBoardMobile from "../../Components/NewFreelancerDashBoard/NewFreelancerDashBoardMobile";
import { pageViewTracker } from '../../Components/GoogleTracking';



const WorkRequirementDashboard = (props) => {
    const [postArequirementDetails, setPostArequirementDetails] = useState([]);
    const [allPostArequirementDetails, setAllPostArequirementDetails] = useState(
        []
    );
    const [loading, setLoading] = useState(true);

    const [recomendationSort] = useState("");

    const [workType] = useState([]);
    const [workfilter] = useState({
        onetime: false,
        contract: false,
        commission: false,
        monthlybasis: false,
    });
    const [freelancerPolicy] = useState({
        Remote: false,
        Hybrid: false,
        OnSite: false,
    });

    useEffect(() => {
      pageViewTracker();
    }, [])
    

    useEffect(() => {
        ReactGA.initialize('UA-109083878-3', {
            debug: true,
            titleCase: false,
            gaOptions: {
                userId: 123
            }
        });
        ReactGA.pageview(window.location.pathname + window.location.search);

    }, [window]);


    useEffect(() => {
        requirementsApi();



    }, [ recomendationSort]);

    useEffect(() => {
        workTypeFilter();

    }, [workfilter, freelancerPolicy]);

    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        const windowHeight = () => {
            setHeight(window.innerHeight);
        };
        window.addEventListener("resize", windowHeight);

        return () => {
            window.removeEventListener("resize", windowHeight);
        };
    }, []);



    const requirementsApi = () => {
        setLoading(true);
        let userID = localStorage.getItem("userID");
        var myHeaders = new Headers();

        myHeaders.append(
            "Authorization",
            "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
        );
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            FreelancerId: userID,

        });
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        fetch(
            global.apiLink + "/api/freelancerapp/rozgaarapi/rcommendedrequirementsecond",
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "Success" && result.status_code === 200) {

                    setPostArequirementDetails(result.data);
                    setAllPostArequirementDetails(result.data);
                } else {
                }
            })
            .catch((error) => {
                alert("There is some issue , please contact support!");
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const workTypeFilter = () => {
        if (
            !workfilter.onetime &&
            !workfilter.contract &&
            !workfilter.commission &&
            !workfilter.monthlybasis &&
            !freelancerPolicy.Remote
        ) {
            setPostArequirementDetails(allPostArequirementDetails);
        } else {
            setPostArequirementDetails([]);

            const workPolicy = "";
            const workTypeSel = allPostArequirementDetails.filter(
                (item, i) =>
                    workType.includes(item.RequirementType) ||
                    workPolicy.includes(item.FreelancerPolicy)
            );

            setPostArequirementDetails((prevpostArequirementDetails) => [
                ...workTypeSel,
            ]);
        }
    };


    const MobileWebHandlerSwitch = (device) => {
        switch (device) {
            case "Mobile":
                return (
                    <NewFreelancerDashBoardMobile>
                        <div className={classes.pageLayout}>
                            <div className={classes.sortAndRequirmentContainer}>

                                <PublicRecRequirementCard
                                    requirementsCard={postArequirementDetails}
                                    loading={loading}
                                />
                             
                            </div>
                        </div>
                    </NewFreelancerDashBoardMobile>
                );

            default:
                return (
                    <NewFreelancerDashBoard>
                        <div className={classes.pageLayout}>
                            <div style={{ height: height, backgroundColor: 'white', overflow: "scroll", width: '100%', marginLeft: '10px' }}>
                                <PublicRecRequirementCard
                                    requirementsCard={postArequirementDetails}
                                    loading={loading}
                                />
                               
                            </div>
                        </div>

                    </NewFreelancerDashBoard >
                );
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Best Remote work and freelance projects at RozgaarIndia.com via Times Ascent`}</title>
                <meta
                    name="description"
                    content={`Rozgaar India is one of India's Leading online service marketplace for remote work and freelance projects. You can find the best skilled online service providers at RozgaarIndia.com`}
                />
                <link rel="canonical" href="https://www.rozgaarindia.com/WorkRequirementDashboard" /> 

            </Helmet>

            {MobileWebHandlerSwitch(props.device)}
        </>
    );
};

export default WorkRequirementDashboard;
