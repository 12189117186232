import React, { useState, useEffect, useRef } from 'react';
import classes from './WorkProfile.module.css';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import AutoCompelete from '../../Components/PostRequirement/AutoCompelete';
import Loader from 'react-loader-spinner';
import { HiOutlinePlusSm } from "react-icons/hi";
import NewFreelancerDashBoardMobile from '../../Components/NewFreelancerDashBoard/NewFreelancerDashBoardMobile';
import NewFreelancerDashBoard from '../../Components/NewFreelancerDashBoard/NewFreelancerDashboard';
import Tab from '../../Components/FreelancerProfile/EditFreelancerProfile/Tab';
import TopAlertNotification from '../../Components/FreelancerProfile/TopAlertNotification';
import ProfileFormErrors from '../../Components/FreelancerProfile/EditFreelancerProfile/ProfileFormErrors';
import { pageViewTracker } from '../../Components/GoogleTracking';
const ActionButton = React.lazy(() => import('../../Components/ActionButton'));
const RiTextInputs = React.lazy(() => import('../../Components/PostRequirement/RiTextInputs'));


const WorkProfile = (props) => {
    let navigate = useNavigate();

    const userId = localStorage.getItem("userID");
    const policyRef = useRef(null);
    const modelRef = useRef(null);
    const stateRef = useRef(null);
    const pinRef = useRef(null);

    const [country, setCountry] = useState('');
    const [skillList, setSkillList] = useState([]);
    const [subSkillList, setSubSkillList] = useState([]);
    const [relatedSkill, setRelatedSkill] = useState([]);
    const [subSkillSuggestions, setSubSkillSuggestions] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [error, setError] = useState({});
    const [policy, setPolicy] = useState([]);
    const [pinCode, setPinCode] = useState('');
    const [cityData, setCityData] = useState("")
    const [loading, setLoading] = useState("");
    const [cityLoading, setCityLoading] = useState(false);
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [mandatoryFieldsLeft, setMandatoryFieldsLeft] = useState([]);
    const [profileCompletionStatus, setProfileCompletionStatus] = useState("");
    const [typeOfWork, setTypeOfWork] = useState({
        IsOneTime: '0',
        IsMonthly: '0',
        IsOnCommision: '0',
        IsContractual: '0',
    });
    const [description, setDescription] = useState('');



    const handleState = (e) => {
        setState(e.target.value);
        cityList(country, e.target.value)
    }

    useEffect(() => {
        pageViewTracker();
    }, [])

    useEffect(() => {
        GetFreelancerProfileAPI();
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        RelatedSkill();
    }, [skillList]);

    useEffect(() => {
        if (skillList.length === 0) {
            setSubSkillList([])
        }
        else {
            error.skillList = "";
            SubSkillListAPI()
        }

    }, [skillList]);

    useEffect(() => {
        if (languages.length !== 0) error.languages = ""
    }, [languages])


    const formValidation = () => {
        let errors = {};
        let isValid = true;

        if (policy.includes("Hybrid") || policy.includes("Onsite")) {
            if (country === '') {
                errors.country = "Please select your country !";
                isValid = false;

            }
            if (state === '') {
                errors.state = "Please select your state";
                isValid = false;
                stateRef.current.scrollIntoView({ behavior: "smooth" });
            }
            if (city === '') {
                errors.city = "Please select your city";
                isValid = false;

            }
            if (!pinCode || pinCode === '' || pinCode.length !== 6) {
                errors.pinCode = "Please enter a valid pin code";
                isValid = false;
                pinRef.current.scrollIntoView({ behavior: "smooth" });
            }
        }


        if (languages.length === 0) {
            errors.languages = "Please enter your language";
            isValid = false;
        }
        if (typeOfWork.IsOneTime === '0' && typeOfWork.IsMonthly === '0'
            && typeOfWork.IsOnCommision === '0' && typeOfWork.IsContractual === '0') {
            errors.workModel = "Please select atleast one";
            isValid = false;
            modelRef.current.scrollIntoView();
        }
        if (policy.length === 0) {
            errors.policy = "Please select atleast one";
            isValid = false;
            policyRef.current.scrollIntoView();
        }
        if (skillList.length === 0) {
            errors.skillList = "Please select minimum one skill";
            isValid = false;
            window.scrollTo(0, 0)
        }
        if (description.length === 0) {
            errors.description = "Please describe youself briefly";
            isValid = false;

        }

        setError({ ...error, ...errors })
        if (isValid) {
            SendFreelancerProfileAPI()
        }


    }
    const GetFreelancerProfileAPI = () => {

        setLoading('GetFreelancerProfile');
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");

        var formdata = new FormData();
        formdata.append("FreelancerId", userId);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };


        fetch(global.apiLink + "/api/rozgaarapi/FreelancerProfile", requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.status_code === 200) {
                    setProfileCompletionStatus(result.FreelancerProfileresponse.ProfilePercentComplete)
                    setMandatoryFieldsLeft(result.FreelancerProfileresponse.MandatoryFieldsLeft)
                    setTypeOfWork({
                        IsOneTime: result.data.IsOneTime,
                        IsMonthly: result.data.IsMonthly,
                        IsOnCommision: result.data.IsOnCommision,
                        IsContractual: result.data.IsContractual,
                    })
                    setPolicy(result.data.IsAvailable.split(',').filter(i => i !== ''));
                    setCountry(result.data.AvailableCountry);
                    setState(result.data.AvailableState);
                    cityList(result.data.AvailableCountry, result.data.AvailableState); // API call to fetch city list
                    setCity(result.data.AvailableCity);
                    setPinCode(result.data.AvailablePincode);
                    setLanguages(result.data.Languages);
                    setDescription(result.data.Description);
                    setSkillList(result.data.Skills.filter((item) => item.IsSubSkill === "0").map((item) => item.Skills));
                    setSubSkillList(result.data.Skills.filter((item) => item.IsSubSkill === "1").map((item) => item.Skills));

                }
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(''));
    }

    const SendFreelancerProfileAPI = () => {
        setLoading('SendFreelancerProfile')
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");

        var formdata = new FormData();
        formdata.append("FreelancerId", userId);
        formdata.append("Description", description);
        formdata.append("Skills", skillList);
        formdata.append("SubSkills", subSkillList);
        formdata.append("IsAvailable", policy.toString());
        formdata.append("IsOneTime", typeOfWork.IsOneTime);
        formdata.append("IsMonthly", typeOfWork.IsMonthly);
        formdata.append("IsOnCommision", typeOfWork.IsOnCommision);
        formdata.append("IsContractual", typeOfWork.IsContractual);
        formdata.append("FreelancerLanguage", JSON.stringify(languages));
        if (policy.includes("Hybrid") || policy.includes("Onsite")) {
            formdata.append("AvailableCountry", country);
            formdata.append("AvailableState", state);
            formdata.append("AvailableCity", city);
            formdata.append("AvailablePincode", pinCode);
        }


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/api/rozgaarapi/FreelancerProfile", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200) {
                    navigate('/personalDetails')
                }
                else {
                    alert("Sorry! Failed to submit data.")
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(''));

    }

    const RelatedSkill = () => {
        var myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
        );
        myHeaders.append("Content-Type", "application/json");
        let skillForRelated = skillList.toString();

        var raw = JSON.stringify({
            Skill: skillForRelated,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(global.apiLink + "/api/rozgaarapi/SkillFinder", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "SUCCESS") {
                    setRelatedSkill(result.data);
                } else {
                }
            })
            .catch((error) => console.log("error", error));
    };
    const SubSkillListAPI = () => {

        setLoading('sub-skills')
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        myHeaders.append("Content-Type", "application/json");


        var raw = JSON.stringify({
            "Skill": skillList.toString()
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/api/rozgaarapi/SkillFinder", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200) {
                    setSubSkillSuggestions(result.data);

                }
                else {
                    setError({ ...error, subSkills: "Something Went Wrong" })
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(''));

    }



    const cityList = (country, state) => {
        setCityLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "Country": country,
            "State": state
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/api/rozgaarapi/GetCityLocatinList", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200 && result.status === "Success") {
                    setCityData(result.data)
                }
                else {

                }
            })
            .catch(error => console.log('error', error))
            .finally(() => { setCityLoading(false) });
    }


    const HandlePolicy = (item) => {
        error.policy = ""
        if (!policy.includes(item)) {
            setPolicy([...policy, item])
        }
        else {
            setPolicy(policy.filter((i) => i !== item))
        }
    }



    const WebOrMobileSwitch = (device) => {
        switch (device) {
            case "Mobile":
                return <NewFreelancerDashBoardMobile >
                    <Tab />

                    <div className={classes.Maincontainer_border}>
                        <TopAlertNotification profileCompletion={profileCompletionStatus} mandatoryFieldsLeft={mandatoryFieldsLeft} />
                        <div className={classes.web_view}>
                            <div className={classes.mainContainer}>
                                <div className={classes.input_container}>
                                    <div className={classes.inputSections}>
                                        <div className={classes.heading}>What service will you offer on Rozgaar? <span className={classes.manadatoryFields}> * </span></div>
                                        <div className={classes.subHeading}>Enter your skills and we’ll introduce you to professionals ready to hire</div>

                                        <AutoCompelete
                                            type='skills'
                                            skillList={skillList}
                                            setSkillList={setSkillList}
                                            onInput={() => {
                                                error.skillList = " ";
                                            }}
                                        />


                                        {error.skillList && <ProfileFormErrors errorMessage={error.skillList} />}
                                        {(skillList.length !== 0 && skillList.length < 10) && <div>
                                            <div className={classes.heading}>Select sub-skills </div>
                                            {" "}
                                            {relatedSkill && skillList.length > 0 ? (
                                                <div className={classes.relatedSkillContainer}>
                                                    {" "}
                                                    {relatedSkill.map((item, i) => {
                                                        return (
                                                            <div
                                                                className={classes.relatedSkillBox}
                                                                key={i}
                                                                onClick={() => {
                                                                    setSkillList([...skillList, item]);
                                                                }}
                                                            >
                                                                <div> {item} </div>
                                                                <div>
                                                                    {" "}
                                                                    <HiOutlinePlusSm className={classes.addIcon} />{" "}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}{" "}
                                                </div>
                                            ) : (
                                                <></>
                                            )}{" "}
                                        </div>}
                                    </div>
                                    <div className={classes.inputSections}>
                                        <div ref={policyRef} className={classes.heading}>Select working policy  <span className={classes.manadatoryFields}> * </span></div>
                                        <div className={classes.subHeading}>How would you like to work with the client </div>
                                        <div className={classes.CheckboxStyle}>
                                            <RiTextInputs
                                                input={"checkbox"}
                                                placeHolder={""}
                                                text={"Remote"}
                                                subText={"Work from anywhere"}
                                                onClick={() => HandlePolicy('Remote')}
                                                checked={policy.includes('Remote')}
                                            />

                                            <RiTextInputs
                                                input={"checkbox"}
                                                placeHolder={""}
                                                text={"Hybrid"}
                                                subText={"Work on site and remote"}
                                                onClick={() => HandlePolicy('Hybrid')}
                                                checked={policy.includes('Hybrid')}
                                            />
                                            <RiTextInputs
                                                input={"checkbox"}
                                                placeHolder={""}
                                                text={"Onsite"}
                                                subText={"Work at location"}
                                                onClick={() => HandlePolicy('Onsite')}
                                                checked={policy.includes('Onsite')}
                                            />
                                        </div>
                                        {error.policy && <ProfileFormErrors errorMessage={error.policy} />}

                                        {(policy.includes("Hybrid") || policy.includes("Onsite")) ?
                                            <>

                                                <RiTextInputs
                                                    input={"Dropdown"}
                                                    placeHolder={"Select Country"}
                                                    onChange={(e) => {
                                                        setCountry(e.target.value);
                                                        error.country = "";
                                                        setState("");
                                                        setCity("");
                                                        error.state = "";
                                                        error.city = ""
                                                    }}
                                                    onInput={() => { error.country = "" }}
                                                    value={country}
                                                    default={country}
                                                />
                                                {error.country && <ProfileFormErrors errorMessage={error.country} />}



                                                <div ref={stateRef}>
                                                    <RiTextInputs
                                                        input={"Dropdown"}
                                                        placeHolder={"Select State"}
                                                        selectedCountry={country}
                                                        onInput={() => { error.state = "" }}
                                                        onChange={(e) => { handleState(e) }}
                                                        value={state}
                                                        defaultState={state}
                                                        ref={stateRef}
                                                    />
                                                    {error.state && <ProfileFormErrors errorMessage={error.state} />}

                                                </div>
                                                {

                                                    (cityLoading ? <div className={classes.inputArea}>
                                                        <Loader type="TailSpin" color="#1778f2" width={20} height={18} />
                                                    </div> :
                                                        <><RiTextInputs
                                                            input={"Dropdown"}
                                                            placeHolder={"Select City"}
                                                            displayData={cityData}
                                                            onInput={() => { error.city = "" }}
                                                            onChange={(e) => setCity(e.target.value)}
                                                            value={city}
                                                        />
                                                            {error.city && <ProfileFormErrors errorMessage={error.city} />}


                                                        </>)}
                                                        <div ref={pinRef}>
                                                <RiTextInputs
                                                    input={"SingleLineInput"}
                                                    type={"text"}
                                                    maxLength="6"
                                                    minLength="6"
                                                    placeHolder={"Pincode"}
                                                    value={pinCode}
                                                    onInput={() => { error.pinCode = "" }}
                                                    onChange={(e) => setPinCode(/^[0-9]+$|^$/.test(e.target.value) || country !== "India" ? e.target.value : pinCode)} />
                                                    </div>
                                                {error.pinCode && <ProfileFormErrors errorMessage={error.pinCode} />}

                                            </> : <></>
                                        }
                                    </div>
                                    <div className={classes.inputSections}>
                                        <div ref={modelRef} className={classes.heading}>Select work model <span className={classes.manadatoryFields}> * </span></div>
                                        <div className={classes.subHeading}>What is your work preference </div>
                                        <div className={classes.CheckboxStyle}>
                                            <RiTextInputs
                                                input={"checkbox"}
                                                placeHolder={""}
                                                text={"One-Time"}
                                                onClick={() => {
                                                    setTypeOfWork({
                                                        ...typeOfWork,
                                                        IsOneTime: typeOfWork.IsOneTime === "1" ? "0" : "1"
                                                    })
                                                    error.workModel = ""
                                                }}
                                                checked={typeOfWork.IsOneTime === "1"}
                                            />

                                            <RiTextInputs
                                                input={"checkbox"}
                                                placeHolder={""}
                                                text={"Monthly"}
                                                onClick={() => {
                                                    setTypeOfWork({
                                                        ...typeOfWork,
                                                        IsMonthly: typeOfWork.IsMonthly === "1" ? "0" : "1"
                                                    })
                                                    error.workModel = ""
                                                }}
                                                checked={typeOfWork.IsMonthly === "1"}
                                            />
                                            <RiTextInputs
                                                input={"checkbox"}
                                                placeHolder={""}
                                                text={"Commission"}
                                                onClick={() => {
                                                    setTypeOfWork({
                                                        ...typeOfWork,
                                                        IsOnCommision: typeOfWork.IsOnCommision === "1" ? "0" : "1"
                                                    })
                                                    error.workModel = ""
                                                }}
                                                checked={typeOfWork.IsOnCommision === "1"}
                                            />
                                            <RiTextInputs
                                                input={"checkbox"}
                                                placeHolder={""}
                                                text={"Contract"}
                                                onClick={() => {
                                                    setTypeOfWork({
                                                        ...typeOfWork,
                                                        IsContractual: typeOfWork.IsContractual === "1" ? "0" : "1"
                                                    })
                                                    error.workModel = ""
                                                }}
                                                checked={typeOfWork.IsContractual === "1"}
                                            />
                                        </div>
                                        {error.workModel && <ProfileFormErrors errorMessage={error.workModel} />}

                                    </div>
                                    <div className={classes.inputSections}>
                                        <div className={classes.heading}>Language<span className={classes.manadatoryFields}> * </span></div>
                                        <div className={classes.subHeading}>Mention the languages you are fluent at</div>

                                        <AutoCompelete type='language'
                                            languages={languages} setLanguages={setLanguages} onInput={() => { error.languages = "" }} />
                                        {error.languages && <ProfileFormErrors errorMessage={error.languages} />}

                                        <div className={classes.heading}>Describe about yourself <span className={classes.manadatoryFields}> * </span> </div>
                                        <div className={classes.subHeading}>Enter details that you want your client to know about you</div>
                                        <RiTextInputs
                                            input={"MultiLineInput"}
                                            type={"text"}
                                            placeHolder={"Description"}
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            label={""}
                                        />
                                    </div>
                                    {error.description && <ProfileFormErrors errorMessage={error.description} />}
                                </div>
                                <div className={classes.ActionBtn}>
                                    <ActionButton buttonText={loading === "SendFreelancerProfile" ? <Loader type="TailSpin" color="white" width={20} height={20} /> : "Next"} className={classes.Login_button} onClick={formValidation} />
                                </div>
                            </div>

                        </div>
                    </div>
                </NewFreelancerDashBoardMobile>
            default:
                return <NewFreelancerDashBoard >
                    <div className={classes.Maincontainer_border}>
                        <div className={classes.web_view}>
                            <div className={classes.mainContainer}>

                                <TopAlertNotification profileCompletion={profileCompletionStatus} mandatoryFieldsLeft={mandatoryFieldsLeft} />
                                <div className={classes.input_container}>

                                    <h1 className={classes.mainHeading} >
                                        Work Profile</h1>
                                    <div className={classes.borderBottomHeading}>    </div>
                                    <div className={classes.pageArealayout}>
                                        <div className={classes.inputSections}>
                                            <div className={classes.heading}>What service will you offer on Rozgaar?<span className={classes.manadatoryFields}> * </span></div>
                                            <div className={classes.subHeading}>Enter your skills and we’ll introduce you to professionals ready to hire</div>
                                            <AutoCompelete
                                                type='skills'
                                                skillList={skillList}
                                                setSkillList={setSkillList}
                                                onInput={() => {
                                                    error.skillList = "";
                                                }}
                                            />
                                            {error.skillList && <ProfileFormErrors errorMessage={error.skillList} />}

                                            {(skillList.length !== 0 && skillList.length < 10) && <div>
                                                <div className={classes.subskillHeading}>You can select among following sub-skills</div>
                                                {" "}
                                                {relatedSkill && skillList.length > 0 ? (
                                                    <div className={classes.relatedSkillContainer}>
                                                        {" "}
                                                        {relatedSkill.map((item, i) => {
                                                            return (
                                                                <div
                                                                    className={classes.relatedSkillBox}
                                                                    key={i}
                                                                    onClick={() => {
                                                                        setSkillList([...skillList, item]);
                                                                    }}
                                                                >
                                                                    <div> {item} </div>
                                                                    <div>
                                                                        {" "}
                                                                        <HiOutlinePlusSm className={classes.addIcon} />{" "}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}{" "}
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}{" "}
                                            </div>}
                                        </div>
                                        <div className={classes.inputSections}>
                                            <div ref={policyRef} className={classes.heading}>Select working policy <span className={classes.manadatoryFields}> * </span></div>
                                            <div className={classes.subHeading}>How would you like to work with the client </div>
                                            <div className={classes.CheckboxStyle}>
                                                <div className={classes.workPolicyCheck}>
                                                    <RiTextInputs
                                                        input={"checkbox"}
                                                        placeHolder={""}
                                                        text={"Remote"}
                                                        subText={"Work from anywhere"}
                                                        onClick={() => HandlePolicy('Remote')}
                                                        checked={policy.includes('Remote')}
                                                    />
                                                </div>
                                                <div className={classes.workPolicyCheck}>
                                                    <RiTextInputs
                                                        input={"checkbox"}
                                                        placeHolder={""}
                                                        text={"Hybrid"}
                                                        subText={"Work on site and remote"}
                                                        onClick={() => HandlePolicy('Hybrid')}
                                                        checked={policy.includes('Hybrid')}
                                                    />
                                                </div>
                                                <div className={classes.workPolicyCheck}>
                                                    <RiTextInputs
                                                        input={"checkbox"}
                                                        placeHolder={""}
                                                        text={"Onsite"}
                                                        subText={"Work at location"}
                                                        onClick={() => HandlePolicy('Onsite')}
                                                        checked={policy.includes('Onsite')}
                                                    />
                                                </div>
                                            </div>
                                            {error.policy && <ProfileFormErrors errorMessage={error.policy} />}
                                            {(policy.includes("Hybrid") || policy.includes("Onsite")) ?
                                                <>
                                                    <div className={classes.countryStateContainer}>
                                                        <div className={classes.cityDropdown}>
                                                            <RiTextInputs
                                                                input={"Dropdown"}
                                                                placeHolder={"Select Country"}
                                                                onChange={(e) => {
                                                                    setCountry(e.target.value);
                                                                    error.country = "";
                                                                    setState("");
                                                                    setCity("");
                                                                    error.state = "";
                                                                    error.city = ""
                                                                }}

                                                                value={country}
                                                                default={country}
                                                            />
                                                            {error.country && <ProfileFormErrors errorMessage={error.country} />}
                                                        </div>

                                                        <div className={classes.cityDropdown} ref={stateRef}>
                                                            <RiTextInputs
                                                                input={"Dropdown"}
                                                                placeHolder={"Select State"}
                                                                selectedCountry={country}

                                                                onChange={(e) => { handleState(e); error.state = "" }}
                                                                value={state}
                                                                defaultState={state}
                                                            />
                                                            {error.state && <ProfileFormErrors errorMessage={error.state} />}
                                                        </div>
                                                    </div>
                                                    <div className={classes.cityPincodeContainer}>
                                                        {

                                                            (cityLoading ? <div className={classes.inputArea}>
                                                                <Loader type="TailSpin" color="#1778f2" width={20} height={18} />
                                                            </div> :
                                                                <div className={classes.cityDropdown}><RiTextInputs
                                                                    input={"Dropdown"}
                                                                    placeHolder={"Select City"}
                                                                    displayData={cityData}

                                                                    onChange={(e) => { setCity(e.target.value); error.city = "" }}
                                                                    value={city}
                                                                />
                                                                    {error.city && <ProfileFormErrors errorMessage={error.city} />}

                                                                </div>)}
                                                        <div className={classes.cityDropdown} ref={pinRef}>
                                                            <RiTextInputs
                                                                input={"SingleLineInput"}
                                                                type={"text"}
                                                                maxLength="6"
                                                                minLength="6"
                                                                placeHolder={"Pincode"}
                                                                value={pinCode}
                                                                onInput={() => { error.pinCode = "" }}
                                                                onChange={(e) => setPinCode(/^[0-9]+$|^$/.test(e.target.value) || country !== "India" ? e.target.value : pinCode)} />
                                                            {error.pinCode && <ProfileFormErrors errorMessage={error.pinCode} />}
                                                        </div>
                                                    </div>
                                                </> : <></>
                                            }
                                        </div>
                                        <div className={classes.inputSections}>
                                            <div ref={modelRef} className={classes.heading}>Select work model <span className={classes.manadatoryFields}> * </span> </div>
                                            <div className={classes.subHeading}>What is your work preference </div>
                                            <div className={classes.CheckboxStyle}>
                                                <div className={classes.workPolicyCheckbox}>
                                                    <RiTextInputs
                                                        input={"checkbox"}
                                                        placeHolder={""}
                                                        text={"One-Time"}
                                                        onClick={() => {
                                                            setTypeOfWork({
                                                                ...typeOfWork,
                                                                IsOneTime: typeOfWork.IsOneTime === "1" ? "0" : "1"
                                                            })
                                                            error.workModel = ""
                                                        }}
                                                        checked={typeOfWork.IsOneTime === "1"}
                                                    />
                                                </div>
                                                <div className={classes.workPolicyCheckbox}>
                                                    <RiTextInputs
                                                        input={"checkbox"}
                                                        placeHolder={""}
                                                        text={"Monthly"}
                                                        onClick={() => {
                                                            setTypeOfWork({
                                                                ...typeOfWork,
                                                                IsMonthly: typeOfWork.IsMonthly === "1" ? "0" : "1"
                                                            })
                                                            error.workModel = ""
                                                        }}
                                                        checked={typeOfWork.IsMonthly === "1"}
                                                    />
                                                </div>
                                                <div className={classes.workPolicyCheckbox}>
                                                    <RiTextInputs
                                                        input={"checkbox"}
                                                        placeHolder={""}
                                                        text={"Commission"}
                                                        onClick={() => {
                                                            setTypeOfWork({
                                                                ...typeOfWork,
                                                                IsOnCommision: typeOfWork.IsOnCommision === "1" ? "0" : "1"
                                                            })
                                                            error.workModel = ""
                                                        }}
                                                        checked={typeOfWork.IsOnCommision === "1"}
                                                    />
                                                </div>
                                                <div className={classes.workPolicyCheckbox}>
                                                    <RiTextInputs
                                                        input={"checkbox"}
                                                        placeHolder={""}
                                                        text={"Contract"}
                                                        onClick={() => {
                                                            setTypeOfWork({
                                                                ...typeOfWork,
                                                                IsContractual: typeOfWork.IsContractual === "1" ? "0" : "1"
                                                            })
                                                            error.workModel = ""
                                                        }}
                                                        checked={typeOfWork.IsContractual === "1"}
                                                    />
                                                </div>
                                            </div>
                                            {error.workModel && <ProfileFormErrors errorMessage={error.workModel} />}
                                        </div>
                                        <div className={classes.inputSections}>
                                            <div className={classes.formContainer}>
                                                <div className={classes.heading}>Language<span className={classes.manadatoryFields}> * </span></div>
                                                <div className={classes.subHeading}>Mention the languages you are fluent at</div>
                                            </div>
                                            <AutoCompelete type='language'
                                                languages={languages} setLanguages={setLanguages} onInput={() => { error.languages = "" }} />

                                        </div>
                                        {error.languages && <ProfileFormErrors errorMessage={error.languages} />}
                                        <div className={classes.inputSections}>
                                            <div className={classes.heading}>Describe about yourself  <span className={classes.manadatoryFields}> * </span></div>
                                            <div className={classes.subHeading}>Enter details that you want your client to know about you</div>
                                            <RiTextInputs
                                                input={"MultiLineInput"}
                                                type={"text"}
                                                placeHolder={"Description"}
                                                value={description}
                                                onChange={(e) => { setDescription(e.target.value); error.description = "" }}
                                                label={""}
                                            />
                                            {error.description && <ProfileFormErrors errorMessage={error.description} />}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.ActionBtn}>
                                    <ActionButton buttonText={loading === "SendFreelancerProfile" ? <Loader type="TailSpin" color="white" width={20} height={20} /> : "Next"} className={classes.Login_button} onClick={formValidation} />
                                </div>
                            </div>
                        </div>
                    </div>
                </NewFreelancerDashBoard>
        }
    }



    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Best Remote work and freelance projects at RozgaarIndia.com via Times Ascent`}</title>
                <meta name="description" content={`Rozgaar India is one of India's Leading online service marketplace for remote work and freelance projects. You can find the best skilled online service providers at RozgaarIndia.com`} />
                <link rel="canonical" href="https://www.rozgaarindia.com/workProfile" />

            </Helmet>

            <React.Suspense fallback={<div className="pageLoader"><Loader type="TailSpin" color="red" height={80} width={80} className="text-center my-5" /></div>}>
                {
                    WebOrMobileSwitch(props.device)
                }
            </React.Suspense>

        </>
    )
}

export default WorkProfile
