
import { BiErrorCircle } from 'react-icons/bi';
import classes from './ProfileFormErrors.module.css'


const ProfileFormErrors = (props) =>{


    return(
<div className={classes.mainContainer}> 
<div className={classes.iconAlignment}> <BiErrorCircle />
<div className={classes.errorMessage}> 
    {props.errorMessage}
</div>
</div>
</div>

    )
}

export default ProfileFormErrors;