import React, { useEffect, useState } from "react";
import ArticlesFreelancers from "../../Components/FreelancerHomePageComp/ArticlesFreelancers";
import BannerArea from "../../Components/FreelancerHomePageComp/BannerArea";
import FutureOfWorkFreelancer from "../../Components/FreelancerHomePageComp/FutureOfWorkFreelancer";
import HelpFreelancers from "../../Components/FreelancerHomePageComp/HelpFreelancers";
import LogoComp from "../../Components/FreelancerHomePageComp/LogoComp";
import RemoteWorkCareerBoxes from "../../Components/FreelancerHomePageComp/RemoteWorkCareerBoxes";
import { pageViewTracker } from '../../Components/GoogleTracking';
import NewRequirementCard from "../../Components/FreelancerHomePageComp/NewRequirementCard";
import RIFooter from "../../Components/RIFooter";
import { Helmet } from "react-helmet";

const FreelancerHomePage = () => {
    const [articleData, setArticledata] = useState([]);
    const [isLoading, setIsLoading] = useState()
    const [requirementData, setRequirementData] = useState([])

    useEffect(() => {
      ArticleApiDataFetch();
      RequirementData()
    }, []);

    useEffect(() => {
      pageViewTracker();
    }, [])
    


    const RequirementData = () => {
      setIsLoading(true)
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
      myHeaders.append("Content-Type", "application/json");
      var raw = "";
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      fetch(global.apiLink + "/api/client/RequirementListPublicView", requestOptions)
        .then(response => response.json())
        .then(result => {
          setRequirementData(result.data);
        })
        .catch(error => console.log('error', error))
        .finally(() => { setIsLoading(false) })
    }
  
    const ArticleApiDataFetch = () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "ArticleType": "Freelancer"
      });
  
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body:raw,
        redirect: "follow",
      };
  
      fetch(
        global.apiLink+"/api/rozgaarapi/IspublishedArticle",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.status_code === 200) {
            setArticledata(result.data);
          }
        })
        .catch((error) => console.log("error", error));
    };
  

  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>{`Frequently Asked Questions about Freelance Jobs  | Rozgaar India`}</title>
        <meta
          name="description"
          content={`Read the Freelancer FAQ's to learn everything about Freelancing, freelance projects, remote work, contract work and gigs.`}
       
       />
           <link rel="canonical" href="https://www.rozgaarindia.com/" /> 
      </Helmet>
      <BannerArea />
      <NewRequirementCard RequirementData={requirementData} isLoading={isLoading} />
      <LogoComp />
      <FutureOfWorkFreelancer />
      <RemoteWorkCareerBoxes />
      <HelpFreelancers />
      <ArticlesFreelancers articleCard = {articleData} device={"Mobile"}/>
      {/* <RIFooter/> */}
    </>
  );
};

export default FreelancerHomePage;
