import React, { useEffect, useState } from 'react'

import { countryCode } from '../../JsonFiles/ContryCodes';

import classes from './SignUp.module.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Loader from 'react-loader-spinner';
import { pageViewTracker } from '../../Components/GoogleTracking';




const ActionButton = React.lazy(() => import('../../Components/ActionButton'));
const RiTextInputs = React.lazy(() => import('../../Components/PostRequirement/RiTextInputs'));
const LoginOptions = React.lazy(() => import('../../Components/RegistrationComponents/LoginOptions'));

const SignUp = (props) => {


    const [isLoading, setIsLoading] = useState(false)
    const [country, setCountry] = useState("India");
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState();
    const [password, setPassword] = useState([]);
    const [dialCode, setDialCode] = useState("+91");
    const [checkMObileVal, setCheckMObileVal] = useState(false);
    const [countryObject, setCountryObject] = useState();
    const [error, setError] = useState({});
    const [isExist, setAlreadyExist] = useState()

    const [term] = useState(true)

    let navigate = useNavigate();

    useEffect(() => {
        pageViewTracker();
    }, [])

    useEffect(() => {
        if (mobile.length >= 10) {
            setCheckMObileVal(true);
        }
        if (mobile.length < 10) {
            setCheckMObileVal(false);
        }
    }, [mobile])
    const handleMobileChange = (e) => {
        const re = /^[0-9\b]+$/;

        if (e.target.value === "" || re.test(e.target.value)) {
            setMobile(e.target.value);
        }

    }
    const OtpVerification = (userID) => {

        if (country === "India") {
            navigate("/mobileverification", { state: { userId: userID, Mobile: mobile } });
        }
        else {
            navigate("/emailverification", { state: { userId: userID, Mobile: email } });
        }
    }
    const UserRegistration = () => {
        setIsLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "Country": country,
            "FirstName": firstName,
            "LastName": lastName,
            "Email": email,
            "Phone": (mobile ? mobile : ""),
            "CountryCode": dialCode,
            "Password": password,
            "ConfirmPassword": password,
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(global.apiLink + "/api/freelancerapp/rozgaarapi/UserFreelancerSignup", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "SUCCESS" && result.status_code === 200) {
                    OtpVerification(result.data.FreelancerId);
                    sessionStorage.setItem('userID', result.data.FreelancerId)
                    sessionStorage.setItem('USERMOBILENO', result.data.Mobile)
                    sessionStorage.setItem('USEREMAIL', result.data.Email)
                    localStorage.setItem('userID', result.data.FreelancerId)
                    localStorage.setItem('USERMOBILENO', result.data.Mobile)
                    localStorage.setItem("IsLoginType", "Freelancer")
                    localStorage.setItem('USEREMAIL', result.data.Email)
                    localStorage.setItem('FIRSTNAME', firstName)
                    localStorage.setItem('LASTNAME', lastName)


                }
                else if (result.data === "Mobile OR Email exist") {
                    setAlreadyExist(true)
                }
            })
            .catch(error => {
                alert("There is some issue , please contact support!")
            })
            .finally(() => { setIsLoading(false) });
    }
    // const SocialLogin = (res) => {

    //     var myHeaders = new Headers();
    //     myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
    //     myHeaders.append("Content-Type", "application/json");

    //     var raw = JSON.stringify({
    //         "Email": ('profileObj' in res ? res.profileObj.email : res.email),
    //         "RequestUrl": "url"
    //     });

    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: 'follow'
    //     };

    //     fetch(global.apiLink + "/api/rozgaarapi/SocialLogin", requestOptions)
    //         .then(response => response.json())
    //         .then(result => {
    //             if (result.status === "SUCCESS" && result.status_code === 200 && result.message === "data successfully created") {
    //                 sessionStorage.setItem("googleBiEmail", ('profileObj' in res ? res.profileObj.email : res.email))
    //                 sessionStorage.setItem("userId", result.data.UserId)
    //                 navigate("/SocialLogin")
    //             }
    //             if (result.status === "SUCCESS" && result.status_code === 200 && result.message === "data successfully updated" && result.data.Country === "") {
    //                 sessionStorage.setItem("googleBiEmail", ('profileObj' in res ? res.profileObj.email : res.email))
    //                 sessionStorage.setItem("userId", result.data.UserId)
    //                 navigate("/SocialLogin")
    //             }
    //             if (result.status === "SUCCESS" && result.status_code === 200 && result.message === "data successfully updated" && result.data.Country !== "") {
    //                 sessionStorage.setItem("googleBiEmail", ('profileObj' in res ? res.profileObj.email : res.email))
    //                 sessionStorage.setItem("userId", result.data.UserId)
    //                 sessionStorage.setItem('USERMOBILENO', result.data.Mobile)
    //                 sessionStorage.setItem('USEREMAIL', result.data.Email)
    //                 sessionStorage.setItem('FirstName', result.data.FirstName)
    //                 sessionStorage.setItem('LastName', result.data.LastName)
    //                 sessionStorage.setItem('ProfilePicture', result.data.ProfilePicture)
    //                 localStorage.setItem('userLoggedIn', true)
    //                 localStorage.setItem("googleBiEmail", ('profileObj' in res ? res.profileObj.email : res.email))
    //                 localStorage.setItem('USERMOBILENO', result.data.Mobile)
    //                 localStorage.setItem('USEREMAIL', result.data.Email)
    //                 localStorage.setItem('FirstName', result.data.FirstName)
    //                 localStorage.setItem('LastName', result.data.LastName)
    //                 localStorage.setItem('UserName', result.data.UserName)
    //                 localStorage.setItem("userID", result.data.UserId)
    //                 localStorage.setItem("IsLoginType","Freelancer")
    //                 localStorage.setItem('ProfilePicture', result.data.ProfilePicture)
    //                 navigate("/FreelancerWorkPlace")
    //                 window.location.reload()
    //             }
    //         }
    //         )
    //         .catch(error => console.log('error', error));
    // }
    let regEmail = /^(([^<>()/.,;:\s@"]+(\.[^<>()/.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const nameregx = /^.*[a-zA-Z]+.*$/;
    const formValidation = () => {
        let errors = {};
        let isValid = true;

        if (!country || country === '') {
            errors.country = "Please select your country"
            isValid = false;
        }
        if (!firstName || firstName === '' || !nameregx.test(firstName)) {
            errors.firstName = "Please enter your first name"
            isValid = false;
        }
        // if (!firstName.match(/^[a-z ,.'-]+$/i)) {
        //     errors.firstName = "Please enter a valid first name";
        //     isValid = false;
        //   }
        if (!lastName || lastName === '' || !nameregx.test(lastName)) {
            errors.lastName = "Please enter your last name"
            isValid = false;
        }
        // if (!lastName.match(/^[a-z ,.'-]+$/i)) {
        //     errors.lastName = "Please enter a valid first name";
        //     isValid = false;
        //   }
        if (!email || email === '' || (!regEmail.test(email))) {
            errors.email = "Please enter a valid email address "
            isValid = false;
        }

        if ((!mobile || mobile === '' || mobile.length < 10) && country === "India") {
            errors.mobile = "Please enter a valid mobile number "
            isValid = false
        }
        if (!password || password === '' || password.length < 6) {
            errors.password = "Password must be of 6 characters atleast "
            isValid = false;
        }


        if (!term) {
            errors.term = "By creating an account, you agree to receive communication and accept the Terms of Service and Privacy Policy"
            isValid = false;
        }

        else {
        }
        setError(errors)
        return isValid;
    }

    const onlyNumericMobile = (e) => {
        var reg = /^-?\d+$/;
        reg.test(e.target.value) || e.target.value === ""
            ? setMobile(e.target.value)
            : console.log("");
    };

    useEffect(() => {
        if (countryObject) {

            setDialCode(countryObject.dial_code)
        }
    }, [countryObject])
    useEffect(() => {

    }, [country])

    const handleSubmit = (e) => {

        if (e.key === 'Enter') {
            formValidation()
                ? UserRegistration()
                : console.log("something went wrong");
        }
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Best Remote work and freelance projects at RozgaarIndia.com via Times Ascent`}</title>
                <meta name="description" content={`Rozgaar India is one of India's Leading online service marketplace for remote work and freelance projects. You can find the best skilled online service providers at RozgaarIndia.com`} />
                <link rel="canonical" href="https://www.rozgaarindia.com/Signup" />
            </Helmet>
            <React.Suspense fallback={<div className="pageLoader"><Loader type="TailSpin" color="red" height={80} width={80} className="text-center my-5" /></div>}>
                <div className={classes.Pagewrapper}>
                    <div className={classes.Maincontainer_border}>
                        <div className={classes.mainContainer}>
                            <h1>Create a free account</h1>
                            <div className={classes.loginLink}>Already have an account?<Link to="/login" className={classes.terms_link}> Login</Link></div>

                            <div className={classes.input_container}>

                                <RiTextInputs
                                    input={"CountryDropdown"}
                                    placeHolder={"Country"}
                                    displayData={countryCode}
                                    onChange={(e) => setCountry(e.target.value)}
                                    value={country}
                                    setCountry={setCountry}

                                    setCountryObject={setCountryObject}
                                />
                                <div className={classes.ErrorMessage}>{error.country}</div>
                                <div className={classes.inputFieldsWrapper}>
                                    <div className={classes.inputFields}>
                                        <RiTextInputs
                                            input={"SingleLineInput"}
                                            type={"text"}
                                            onInput={() => { error.firstName = " " }}
                                            placeHolder={"First Name"}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            value={firstName}
                                            onKeyPress={handleSubmit}
                                        />
                                        <div className={classes.ErrorMessage}>{error.firstName}</div>
                                    </div>

                                    <div className={classes.inputFields}>
                                        <RiTextInputs
                                            input={"SingleLineInput"}
                                            type={"text"}
                                            onInput={() => { error.lastName = " " }}
                                            placeHolder={"Last Name"}
                                            onChange={(e) => setLastName(e.target.value)}
                                            value={lastName}
                                            onKeyPress={handleSubmit}
                                        />

                                        <div className={classes.ErrorMessage}>{error.lastName}</div>
                                    </div>

                                </div>
                                <RiTextInputs
                                    input={"mobile"}
                                    maxLength="10"
                                    minLength="10"
                                    onInput={() => { error.mobile = " " }}
                                    dialCode={dialCode}
                                    checkMObileVal={checkMObileVal}
                                    onChange={handleMobileChange}
                                    value={mobile}
                                    placeHolder={country === "India" ? "Phone Number" : "Phone Number(optional)"}
                                    required={country === "India" ? true : false}
                                    onKeyPress={handleSubmit}
                                />
                                <div className={classes.ErrorMessage}>{error.mobile}</div>

                                <RiTextInputs
                                    input={"SingleLineInput"}
                                    type={"email"}
                                    onInput={() => { error.email = " " }}
                                    placeHolder={"Email"}
                                    onChange={(e) => {
                                        let keyword = e.target.value.toLowerCase();
                                        var re = /^[a-z@A-Z.0-9_]*$/;
                                        if (keyword === '' || re.test(keyword)) {
                                            setEmail(keyword)
                                        }
                                    }}
                                    onKeyPress={handleSubmit}
                                    value={email}
                                />
                                <div className={classes.ErrorMessage}>{error.email}</div>
                                <RiTextInputs
                                    input={"password"}
                                    placeHolder={"Create Password"}
                                    onInput={() => { error.password = " " }}
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    onKeyPress={handleSubmit}
                                />
                                <div className={classes.ErrorMessage}>{error.password}</div>
                                {isExist &&
                                    <div className={classes.error_message}>

                                        <div >{"Email or Mobile number already exist"}</div>
                                    </div>}
                            </div>
                            <div className={classes.ActionBtn}>
                                <div onClick={(e) => { e.preventDefault(); formValidation() ? UserRegistration() : console.log("Something's wrong"); }}>
                                    {isLoading ?
                                        (
                                            <div className={classes.LoadingBtn}>
                                                <Loader type="TailSpin" color="white" width={20} height={18} />
                                            </div>
                                        ) : (
                                            <ActionButton buttonText={"Signup"} />
                                        )}
                                </div>
                            </div>

                            <div className={classes.checkbox_text}>By creating an account, you agree to receive communication and accept the &nbsp;<a href="/termsConditions" target="_blank" className={classes.LinkText}>Terms of Service</a> and <a href="/privacyPolicy" target="_blank" className={classes.LinkText}>Privacy Policy.</a>
                            </div>

                            <div className={classes.forgot_password}>Already have an account?<Link to="/login" className={classes.terms_link}> Login</Link></div>
                            <a href="https://rozgaarindia.com/" className={classes.freelancerlink} target="blank"> Signup as Client</a>
                        </div>
                    </div>
                </div>
            </React.Suspense>
        </>
    )
}

export default SignUp
