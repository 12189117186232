
import classes from './FreelancerTheme.module.css'
const FrelancerButton =(props) =>{


    return(
        <button className={props.color? classes.buttonBoxWhite : props.position ==="left"? classes.buttonBoxleft :  classes.buttonBoxCenter } onClick={props.onClick}>
        <div className={classes.buttonText}> 
        {props.buttonText}
</div> 
</button>

    )
}

export default FrelancerButton;