import React, { useEffect, useState } from 'react';
import LeftMenu from './LeftMenu';
import LeftMenuDisabled from './LeftMenuDisabled';

import classes from './NewFreelancerDashBoardMobile.module.css'
import { useNavigate, useLocation } from 'react-router-dom';
const NewFreelancerDashBoardMobile = (props) => {
  let navigate = useNavigate();
  const riPath = useLocation();
  const [optionSelected, setOptionSelected] = useState();
  const freelancerProfileStatus = localStorage.getItem("freelancerProfileStatus")
  const menuOptions = (menu) => {
    setOptionSelected(menu)

  }

  useEffect(() => {
    if (riPath.pathname !== "/workProfile" && riPath.pathname !== "/personalDetails" && riPath.pathname !== "/personalDetails" && riPath.pathname !== "/professionAndEducation" && riPath.pathname !== "/portfolio" && riPath.pathname !== "/companyDetails" && riPath.pathname !== "/FreelancerPackage" && riPath.pathname !== "/KycForm" && riPath.pathname !== "/DashboardContact" && localStorage.getItem("freelancerProfileStatus") === "false") {

      navigate("/workProfile");
    }


  }, []);
  return (

    <div className={classes.pageLayout}>
      {freelancerProfileStatus === "false" ? <LeftMenuDisabled option={menuOptions} /> :
        <LeftMenu option={menuOptions} />}
      <div className={classes.widgetsContainer}>


        {props.children}

      </div>
    </div>



  )

};


export default NewFreelancerDashBoardMobile;
