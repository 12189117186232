import React, { useEffect, useState } from 'react';
import LeftMenu from './LeftMenu';
import LeftMenuDisabled from './LeftMenuDisabled';
import { useNavigate, useLocation } from 'react-router-dom';
import classes from './NewFreelancerDashBoard.module.css'

const NewFreelancerDashBoard = (props) =>{
   const [optionSelected, setOptionSelected] = useState();
   const freelancerProfileStatus =localStorage.getItem("freelancerProfileStatus")
   let navigate = useNavigate();
   const riPath = useLocation();
   
 
   useEffect(() => {
      if ( riPath.pathname !== "/workProfile" && riPath.pathname !== "/personalDetails" && riPath.pathname !== "/personalDetails" && riPath.pathname !== "/professionAndEducation" && riPath.pathname !== "/portfolio" && riPath.pathname !== "/companyDetails" && riPath.pathname !== "/FreelancerPackage" && riPath.pathname !== "/KycForm" && riPath.pathname !== "/DashboardContact"  && localStorage.getItem("freelancerProfileStatus")  === "false" ) {
      
       navigate("/workProfile");
      }
 
      
    }, []);

   const menuOptions = (menu) =>{
      setOptionSelected(menu)
   
    }
return(
   <div> 
<div className={classes.pageLayout}>
 

    {freelancerProfileStatus === "false"  ?   <LeftMenuDisabled option={menuOptions} />  :
    <LeftMenu option={menuOptions} /> }
    <div className={classes.widgetsContainer}>


{props.children}

   </div>
   </div>
   </div>
)

};


export default NewFreelancerDashBoard;
