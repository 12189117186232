import React from "react";
import classes from "./HowToHireSec.module.css";

const HowToHireSec = () => {
  return (
    <div className={classes.mainDiv}>
      <div className={classes.firstContentDiv}>
        <div className={classes.firstSerial}>01</div>
        <div className={classes.contentInnerDiv}>
          <div className={classes.contentHeading}>
            Create Profile {" "}
          </div>
          <div className={classes.firstParagraph}>
            {" "}
            Build a great profile that makes you stand out from other freelancers. Describe the skills you offer, showcase your portfolios and include other details. Include accurate and genuine information about your work history, your skills, qualifications. Add more, like, profile picture, an introduction video, to create a profile that really stands out. Clients browse your profile when deciding who to work with on a project, so it’s essential that you present yourself in the best way possible.
          </div>
        </div>
      </div>

      <div className={classes.firstContentDiv}>
        <div className={classes.contentInnerDiv}>
          <div className={classes.contentHeadingSecond}>

            Apply to Freelance Jobs
          </div>
          <div className={classes.secondParagraph}>
            View freelance listings and filter your interest project to apply on. As a member of our freelance community, we’ll send you recommendations based on your skills with Top Matched freelance works. Choose from our three Freelancer packages. Our artificial intelligence system matches you to the most suitable work based on your profile and skills. You can search for freelance work manually or we will notify you on each client posting.
          </div>
        </div>
        <div className={classes.secondSerial}>02</div>
      </div>

      <div className={classes.firstContentDiv}>
        <div className={classes.thirdSerial}>03</div>
        <div className={classes.contentInnerDiv}>
          <div className={classes.contentHeading}>Send Proposal </div>
          <div className={classes.firstParagraph}>
            {" "}
            Find projects that match your skill and ability, submit a compelling proposal to win the project, your completed profile will do the rest of the work in showcasing your ability to do the job. Understand the scope of work, agree to client’s offered budget or create your own self-tailored proposal. Send your custom quote and start building lasting relationships with clients and get hired. We suggest to ask for a deposit before starting working for the client.

          </div>
        </div>
      </div>

      <div className={classes.firstContentDiv}>
        <div className={classes.contentInnerDiv}>
          <div className={classes.contentHeadingSecond}>Chat with Client</div>
          <div className={classes.secondParagraph}>
            It is important to communicate and stay organised with your client. Our chat system allows you to easily communicate with your clients, share files and gather feedback, all in one place. We highly recommend connecting with the client on our chat so that you have records of your work agreements, all at a single platform. This enhances productivity and track work process.
          </div>
        </div>
        <div className={classes.fourthSerial}>04</div>
      </div>
      <div className={classes.firstContentDiv}>
        <div className={classes.thirdSerial}>05</div>
        <div className={classes.contentInnerDiv}>
          <div className={classes.contentHeading}>Request Payment</div>
          <div className={classes.firstParagraph}>
            To protect your payment, we highly recommend using our secure payment to get paid especially when you are working with an Employer for the first time. We do not charge any commission or fees on your income. Once your work duration is completed, simply click on withdraw and take payments directly to your bank account. Our Income segment help you keep a track of all the payments received.
          </div>
        </div>
      </div>



      <div className={classes.secondParagraph}>
        Join our growing freelance community  Get matched to the most suitable freelance projects
      </div>



    </div>
  );
};

export default HowToHireSec;
