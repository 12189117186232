
import classes from './LogoComp.module.css'

const LogoCompWeb = () => {


    return (
        <>
            <div className={classes.mainContainer}>
                <div className={classes.mainLogoDiv}>
                    <img src="/assets/TrustedCompaniesLogo/Hero_Insurance.svg" alt="Hero_Insurance" width={"100%"} height={90} className={classes.logoAreaWeb} title="Hero_Insurance" loading='lazy' />
                    <img src="/assets/TrustedCompaniesLogo/Hyundai.svg" alt="Hyundai" width={"100%"} height={90} className={classes.logoAreaWeb} title="Hyundai" loading='lazy' />
                    <img src="/assets/TrustedCompaniesLogo/Hero.svg" alt="Hero" width={"100%"} height={90} className={classes.logoAreaWeb} title="Hero" loading='lazy' />
                    <img src="/assets/TrustedCompaniesLogo/Amazon_logo.svg" alt="Amazon_logo" width={"100%"} height={90} className={classes.logoAreaWeb} title="Amazon_logo" loading='lazy' />
                    <img src="/assets/TrustedCompaniesLogo/TimesAscent.svg" alt="TimesAscent" width={"120%"} height={90} className={classes.logoAreaWeb} title="TimesAscent" loading='lazy' />
                    <img src="/assets/TrustedCompaniesLogo/Skill_India.svg" alt="Skill_India" width={"100%"} height={90} className={classes.logoAreaWeb} title="Skill_India" loading='lazy' />
                    <img src="/assets/TrustedCompaniesLogo/Digital_India.svg" alt="Digital_India" width={"100%"} height={90} className={classes.logoAreaWeb} title="Digital_India" loading='lazy' />
                </div>
            </div>

        </>
    )

}

export default LogoCompWeb;