import React, { useState, useEffect } from 'react';
import classes from './Portfolio.module.css';
import { BsLink45Deg, BsFillPlusCircleFill } from 'react-icons/bs';
import Loader from 'react-loader-spinner';
import Step5Freelancer from './MyProfileSuccess';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { BiXCircle } from "react-icons/bi";
import { RiTapeFill, RiVideoFill, RiFolderFill } from "react-icons/ri";
import { AiFillCheckCircle } from 'react-icons/ai'
import NewFreelancerDashBoardMobile from '../../Components/NewFreelancerDashBoard/NewFreelancerDashBoardMobile';
import Tab from '../../Components/FreelancerProfile/EditFreelancerProfile/Tab';
import NewFreelancerDashBoard from '../../Components/NewFreelancerDashBoard/NewFreelancerDashboard';
import TopAlertNotification from '../../Components/FreelancerProfile/TopAlertNotification';
import ProfileFormErrors from '../../Components/FreelancerProfile/EditFreelancerProfile/ProfileFormErrors';
import { pageViewTracker } from '../../Components/GoogleTracking';
const ActionButton = React.lazy(() => import('../../Components/ActionButton'));
const RiTextInputs = React.lazy(() => import('../../Components/PostRequirement/RiTextInputs'));


const Portfolio = (props) => {


    const userId = localStorage.getItem("userID");

    const [showModal, setShowModal] = useState(false);
    const [freelancerProfileId, setFreelancerProfileId] = useState();
    const [loading, setLoading] = useState('');
    const [video, setVideo] = useState('');
    const [profileCompletionStatus, setProfileCompletionStatus] = useState("");
    const [portfolioList, setPortfolioList] = useState([]);
    const [error, setError] = useState({});


    let navigate = useNavigate();

    useEffect(() => {
      pageViewTracker();
    }, [])
    

    useEffect(() => {
        GetFreelancerProfileAPI();
        window.scrollTo(0, 0)
    }, []);


    const GetFreelancerProfileAPI = () => {

        setLoading('GetFreelancerProfile');
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");

        var formdata = new FormData();
        formdata.append("FreelancerId", userId);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };


        fetch(global.apiLink + "/api/rozgaarapi/FreelancerProfile", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200) {
                    setProfileCompletionStatus(result.FreelancerProfileresponse.ProfilePercentComplete)
                    setPortfolioList(result.data.Portfolio);
                    setFreelancerProfileId(result.data.FreelancerProfileId)
                    setVideo(result.data.VideoProfile)
                }
                else {
                    alert('Sorry! Data could not be fetched');
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(''));
    }

    const SendFreelancerProfileAPI = () => {
        setLoading('SendFreelancerProfile')
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");

        var formdata = new FormData();
        formdata.append("FreelancerId", userId);
        typeof video === 'object' && formdata.append("VideoProfile", video);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/api/rozgaarapi/FreelancerProfile", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200) {

                    navigate('/companyDetails')
                }
                else {
                    alert("Sorry! Failed to submit data.")
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(''));

    }

    const formValidation = () => {
        SendFreelancerProfileAPI();
    }

    const AddFreelancerPortfolioAPI = (file, type) => {

        error.portfolio = '';

        setLoading('SendFreelancerPortfoilio');
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");

        var formdata = new FormData();
        formdata.append("FreelancerProfileId", freelancerProfileId);
        formdata.append("Portfolio", "Test");
        formdata.append("PortfolioType", type);
        formdata.append("PortfolioFile", file);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/api/rozgaarapi/FreelancerPortfolio", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200) {
                    setPortfolioList(result.data);
                }
                else {
                    alert('Sorry! Portfoilio could not be uploaded')
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(''));

    }

    const DeleteFreelancerPortfolioAPI = (portfolioId) => {

        setLoading('DeleteFreelancerPortfolio');
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");

        var formdata = new FormData();
        formdata.append("PortfolioId", portfolioId);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/api/rozgaarapi/DeleterzFreelancerPortfolio", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200) {
                    setPortfolioList(portfolioList.filter((item) => item.PortfolioId !== portfolioId));
                }

            })
            .catch(error => console.log('error', error))
            .finally(() => setLoading(''));

    }




    const PortfolioFileView = (item, index) => {

        switch (item.PortfolioType) {
            case 'Image':

                return <div className={classes.CrossIconContainer}>
                    <img src={typeof item.PortfolioURL === 'object' ? URL.createObjectURL(item.PortfolioURL) : item.PortfolioURL} className={classes.updateImage} alt="portfolioImage" 
                     height= {100}
                     width= {100}
                     />
                    <BiXCircle onClick={() => DeleteFreelancerPortfolioAPI(item.PortfolioId)} size={20} className={classes.crossIcon} />
                </div>

            case 'Audio/Video':

                return <div className={classes.CrossIconContainer}>
                    <RiTapeFill className={classes.fileIcon} size={30} />
                    <BiXCircle onClick={() => DeleteFreelancerPortfolioAPI(item.PortfolioId)} size={20} className={classes.crossIcon} />
                </div>
            case 'video':

                return <div className={classes.CrossIconContainer}>
                    <RiVideoFill className={classes.fileIcon} size={30} />
                    <BiXCircle onClick={() => DeleteFreelancerPortfolioAPI(item.PortfolioId)} size={20} className={classes.crossIcon} />
                </div>
            case 'Document':

                return <div className={classes.CrossIconContainer}>
                    <RiFolderFill className={classes.fileIcon} size={30} />
                    <BiXCircle onClick={() => DeleteFreelancerPortfolioAPI(item.PortfolioId)} size={20} className={classes.crossIcon} />
                </div>


            default:
                break;
        }

    }


    const WebOrMobileSwitch = (device) => {
        switch (device) {
            case "Mobile":
                return <NewFreelancerDashBoardMobile >
                    <Tab />
                    <div className={classes.Maincontainer_border}>
                        <div className={classes.web_view}>
                            <div className={classes.mainContainer}>
                                <TopAlertNotification profileCompletion={profileCompletionStatus} />
                                <div className={classes.heading}>Upload Work Samples</div>
                                <div className={classes.subHeading}>Adding as many work samples increases your chances of getting hired</div>
                                <div className={classes.portfolioImageContainer}>
                                    {
                                        portfolioList.length !== 0 && portfolioList.map((item, index) => { return PortfolioFileView(item, index) })
                                    }
                                </div>
                                <div className={classes.portfolioContainer}>

                                    <RiTextInputs
                                        input={"file"}
                                        placeHolder={"Image"}
                                        label={""}
                                        accept="image/*"
                                        onChange={(e) => AddFreelancerPortfolioAPI(e.target.files[0], "Image")}
                                        icon={<BsFillPlusCircleFill size="25" />}
                                    />
                                    <RiTextInputs
                                        input={"file"}
                                        placeHolder={'Audio/Video'}
                                        label={""}
                                        accept="audio/*, video/*"
                                        onChange={(e) => AddFreelancerPortfolioAPI(e.target.files[0], "Audio/Video")}
                                        icon={<BsFillPlusCircleFill size="25" />}
                                    />
                                    <RiTextInputs
                                        input={"file"}
                                        placeHolder={'Document'}
                                        label={""}
                                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                            text/plain, application/pdf,"
                                        onChange={(e) => AddFreelancerPortfolioAPI(e.target.files[0], "Document")}
                                        icon={<BsFillPlusCircleFill size="25" />}
                                    />
                                </div>

                                <div className={classes.input_container}>
                                    <div className={classes.heading}>Upload a Video of  yourself</div>
                                    <div className={classes.subHeading}>Here’s a chance to record a video of ‘about yourself’. This increases the chances of getting hired by 50%
                                        Record in clean background and clear voice</div>
                                    <RiTextInputs
                                        input={"file"}
                                        placeHolder={typeof video === 'object' ? video.name : (video === '' ? "Video" : <a target='_blank' href={video} rel="noreferrer">{video.split('/').pop()}</a>)}
                                        label={""}
                                        accept="video/*"
                                        icon={typeof video === 'object' ? <AiFillCheckCircle size="25" color='green' /> : <BsLink45Deg size="25" />}
                                        onChange={(e) => setVideo(e.target.files[0])}
                                    />
                                    <div className={classes.subHeading}>Please note, do not mention your personal details including mobile, email or address in the video</div>

                                </div>

                                <div className={classes.ActionBtn}>
                                    <ActionButton buttonText={loading === "SendFreelancerProfile" ? <Loader type="TailSpin" color="white" width={20} height={20} /> : "Next"} className={classes.Login_button} onClick={formValidation} />
                                   
                                </div>
                                <div className={classes.SkipBtnContainer} onClick={()=>navigate('/companyDetails')}>Skip</div>
                            </div>

                        </div>
                    </div>
                </NewFreelancerDashBoardMobile>

            default:
                return <NewFreelancerDashBoard>
                    <div className={classes.Maincontainer_border}>
                        <div className={classes.web_view}>
                            <div className={classes.mainContainer}>
                                <TopAlertNotification profileCompletion={profileCompletionStatus} />
                              
                                   
                                        <h1 className={classes.mainHeading}>Portfolio</h1>
                                        <div className={classes.borderBottomHeading}></div>
                                  
                                    
                                


                                <div className={classes.inputSections}>
                                    <div className={classes.heading}>Upload Work Samples</div>
                                    <div className={classes.subHeading}>Adding as many work samples increases your chances of getting hired</div>
                                    <div className={classes.portfolioImageContainer}>
                                        {
                                            portfolioList.length !== 0 && portfolioList.map((item, index) => { return PortfolioFileView(item, index) })
                                        }
                                    </div>
                                </div>
                                <div className={classes.inputSections}>
                                    <div className={classes.portfolioContainer}>

                                        <RiTextInputs
                                            input={"file"}
                                            placeHolder={"Image"}
                                            label={""}
                                            accept="image/*"
                                            onChange={(e) => AddFreelancerPortfolioAPI(e.target.files[0], "Image")}
                                            icon={<BsFillPlusCircleFill size="25" />}
                                        />
                                        <RiTextInputs
                                            input={"file"}
                                            placeHolder={'Audio/Video'}
                                            label={""}
                                            accept="audio/*, video/*"
                                            onChange={(e) => AddFreelancerPortfolioAPI(e.target.files[0], "Audio/Video")}
                                            icon={<BsFillPlusCircleFill size="25" />}
                                        />
                                        <RiTextInputs
                                            input={"file"}
                                            placeHolder={'Document'}
                                            label={""}
                                            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                            text/plain, application/pdf,"
                                            onChange={(e) => AddFreelancerPortfolioAPI(e.target.files[0], "Document")}
                                            icon={<BsFillPlusCircleFill size="25" />}
                                        />
                                    </div>


                                </div>
                                <div className={classes.inputSections}>
                                    <div className={classes.input_container}>
                                        <div className={classes.heading}>Upload a Video of  yourself</div>
                                        <div className={classes.subHeading}>Here’s a chance to record a video of ‘about yourself’. This increases the chances of getting hired by 50%
                                            Record in clean background and clear voice</div>
                                        <RiTextInputs
                                            input={"file"}
                                            placeHolder={typeof video === 'object' ? video.name : (video === '' ? "Video" : <a target='_blank' href={video} rel="noreferrer">{video.split('/').pop()}</a>)}
                                            label={""}
                                            accept="video/*"
                                            icon={typeof video === 'object' ? <AiFillCheckCircle size="25" color='green' /> : <BsLink45Deg size="25" />}
                                            onChange={(e) => setVideo(e.target.files[0])}
                                        />
                                        <div className={classes.subHeading}>Please note, do not mention your personal details including mobile, email or address in the video</div>
                                    </div>
                                </div>
                                <div className={classes.ActionBtn}>
                                    <ActionButton buttonType="small" buttonText={loading === "SendFreelancerProfile" ? <Loader type="TailSpin" color="white" width={20} height={20} /> : "Next"} className={classes.Login_button} onClick={formValidation} />
                                    <div className={classes.SkipBtnContainer} onClick={()=>navigate('/companyDetails')}>Skip</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </NewFreelancerDashBoard>
        }
    }



    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Frequently Asked Questions about Freelance Jobs  | Rozgaar India`}</title>
                <meta
                    name="description"
                    content={`Read the Freelancer FAQ's to learn everything about Freelancing, freelance projects, remote work, contract work and gigs.`}

                />
                <link rel="canonical" href="https://www.rozgaarindia.com/portfolio" />
            </Helmet>
            <React.Suspense fallback={<div className="pageLoader"><Loader type="TailSpin" color="red" height={80} width={80} className="text-center my-5" /></div>}>
                {
                    WebOrMobileSwitch(props.device)
                }
                {showModal ?
                    <Step5Freelancer onClick={() => setShowModal(false)} /> : <></>}
            </React.Suspense>
        </>
    )
}

export default Portfolio
