import React from 'react'
import { BsCheck } from 'react-icons/bs'
import classes from './PackageDetail.module.css'

const PackageDetail = (props) => {


  const details = (type) => {
    switch (type) {
      case ("Basic"):
        return (

          <div className={classes.mainDiv}>
            <div className={classes.planType}>BASIC</div>
            <div className={classes.dataDiv}>
              <div className={classes.headingMainDiv}>
                <div className={classes.planHeading}>Commission</div>
                <div className={classes.planHeading}>Proposal</div>
                <div className={classes.planHeading}>Apply for bulk posting</div>
                <div className={classes.planHeading}>Share profile on Social Media</div>
                <div className={classes.planHeading}>Same Day Withdraw</div>
                <div className={classes.planHeading}>Premium Courses</div>
                <div className={classes.planHeading}>Personal Account Manager</div>
                <div className={classes.planHeading}>Profile Analysis Basic</div>
                <div className={classes.planHeading}>Profile Analysis Video Assistance</div>
              </div>
              <div className={classes.mainMapDiv}>
                {props.packages.map((item, index) => {
                  return (<div >
                    {item.Packagename === "BASIC" ?
                      <>
                        <div className={classes.planDetailsDiv}>{item.Commission}</div>
                        <div className={classes.planDetailsDiv}>{item.ProPosal}</div>
                        <div className={classes.planDetailsDiv}>{item.ApplyForBulkPosting}</div>
                        <div className={classes.planDetailsDiv}>{item.ShareProfileOnSocialMedia === "1" ? <BsCheck /> : "-"}</div>
                        <div className={classes.planDetailsDiv}>{item.SameDayWithdrad === "1" ? <BsCheck /> : "-"}</div>
                        <div className={classes.planDetailsDiv}>{item.PremiumCourses === "1" ? <BsCheck /> : "-"}</div>
                        <div className={classes.planDetailsDiv}>{item.PersonalAccountManage === "1" ? <BsCheck /> : "-"}</div>
                        <div className={classes.planDetailsDiv}>{item.ProfileAnalysis === "1" ? <BsCheck /> : "-"}</div>
                        <div className={classes.planDetailsDiv}>{item.ProfileAnalysisVideoAssistence === "1" ? <BsCheck /> : "-" === "1" ? <BsCheck /> : "-" === "1" ? <BsCheck /> : "-"}</div>
                      </>
                      :
                      <></>
                    }
                  </div>)
                })}
              </div>
            </div>
          </div>

        )
      case ("Plus"):
        return (
          <div className={classes.mainDiv}>
            <div className={classes.planType}>PLUS</div>
            <div className={classes.dataDiv}>
              <div className={classes.headingMainDiv}>
                <div className={classes.planHeading}>Commission</div>
                <div className={classes.planHeading}>Proposal</div>
                <div className={classes.planHeading}>Apply for bulk posting</div>
                <div className={classes.planHeading}>Share profile on Social Media</div>
                <div className={classes.planHeading}>Same Day Withdraw</div>
                <div className={classes.planHeading}>Premium Courses</div>
                <div className={classes.planHeading}>Personal Account Manager</div>
                <div className={classes.planHeading}>Profile Analysis Basic</div>
                <div className={classes.planHeading}>Profile Analysis Video Assistance</div>
              </div>
              <div className={classes.mainMapDiv}>
                {props.packages.map((item, index) => {
                  return (<div >
                    {item.Packagename === "PLUS" ?
                      <>
                        <div className={classes.planDetailsDiv}>{item.Commission}</div>
                        <div className={classes.planDetailsDiv}>{item.ProPosal}</div>
                        <div className={classes.planDetailsDiv}>{item.ApplyForBulkPosting}</div>
                        <div className={classes.planDetailsDiv}>{item.ShareProfileOnSocialMedia === "1" ? <BsCheck /> : "-" === "1" ? <BsCheck /> : "-"}</div>
                        <div className={classes.planDetailsDiv}>{item.SameDayWithdrad === "1" ? <BsCheck /> : "-" === "1" ? <BsCheck /> : "-"}</div>
                        <div className={classes.planDetailsDiv}>{item.PremiumCourses === "1" ? <BsCheck /> : "-" === "1" ? <BsCheck /> : "-"}</div>
                        <div className={classes.planDetailsDiv}>{item.PersonalAccountManage === "1" ? <BsCheck /> : "-" === "1" ? <BsCheck /> : "-"}</div>
                        <div className={classes.planDetailsDiv}>{item.ProfileAnalysis === "1" ? <BsCheck /> : "-" === "1" ? <BsCheck /> : "-"}</div>
                        <div className={classes.planDetailsDiv}>{item.ProfileAnalysisVideoAssistence === "1" ? <BsCheck /> : "-" === "1" ? <BsCheck /> : "-"}</div>
                      </>
                      :
                      <></>
                    }
                  </div>)
                })}
              </div>
            </div>
          </div>
        )
      case ("Pro"):
        return (
          <div className={classes.mainDiv}>
            <div className={classes.planType}>PRO</div>
            <div className={classes.dataDiv}>
              <div className={classes.headingMainDiv}>
                <div className={classes.planHeading}>Commission</div>
                <div className={classes.planHeading}>Proposal</div>
                <div className={classes.planHeading}>Apply for bulk posting</div>
                <div className={classes.planHeading}>Share profile on Social Media</div>
                <div className={classes.planHeading}>Same Day Withdraw</div>
                <div className={classes.planHeading}>Premium Courses</div>
                <div className={classes.planHeading}>Personal Account Manager</div>
                <div className={classes.planHeading}>Profile Analysis Basic</div>
                <div className={classes.planHeading}>Profile Analysis Video Assistance</div>
              </div>
              <div className={classes.mainMapDiv}>
                {props.packages.map((item, index) => {
                  return (<div >
                    {item.Packagename === "PRO" ?
                      <>
                        <div className={classes.planDetailsDiv}>{item.Commission}</div>
                        <div className={classes.planDetailsDiv}>{item.ProPosal}</div>
                        <div className={classes.planDetailsDiv}>{item.ApplyForBulkPosting}</div>
                        <div className={classes.planDetailsDiv}>{item.ShareProfileOnSocialMedia === "1" ? <BsCheck /> : "-"}</div>
                        <div className={classes.planDetailsDiv}>{item.SameDayWithdrad === "1" ? <BsCheck /> : "-"}</div>
                        <div className={classes.planDetailsDiv}>{item.PremiumCourses === "1" ? <BsCheck /> : "-"}</div>
                        <div className={classes.planDetailsDiv}>{item.PersonalAccountManage === "1" ? <BsCheck /> : "-"}</div>
                        <div className={classes.planDetailsDiv}>{item.ProfileAnalysis === "1" ? <BsCheck /> : "-"}</div>
                        <div className={classes.planDetailsDiv}>{item.ProfileAnalysisVideoAssistence === "1" ? <BsCheck /> : "-"}</div>
                      </>
                      :
                      <></>
                    }
                  </div>)
                })}
              </div>
            </div>
          </div>
        )
      default:
        console.log("Invalid Type");
        break;
    }
  }
  return (
    <div>{details(props.packageType)}</div>
  )
}

export default PackageDetail