import React,{useState} from 'react';
import classes from "./FrequentlyAskedQuestions.module.css";
import Loader from 'react-loader-spinner';
import { Helmet } from "react-helmet";
import Faqdropdown from '../FrequentlyAskedQuestions/Faqdropdown';
import { useEffect } from 'react';
import { pageViewTracker } from '../../Components/GoogleTracking';
const RIFooter = React.lazy(() => import('../../Components/RIFooter'));

const ActionButton = React.lazy(() => import('../../Components/ActionButton'));

const FrequentlyAskedQuestions = () => {
    const [show,setShow] = useState()
    const [Data, setData] = useState([])
    const [question] = useState([])
    const [answer] = useState([])

    useEffect(() => {
     pageViewTracker();
    }, [])
    

    const FrequentlyAskedQuestions = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "Question": question,
            "Answer": answer,
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(global.apiLink+"/api/rozgaarapi/GetFaq", requestOptions)
            .then(response => response.json())
            .then(result => {
                setData(result.data)
            })
            .catch(error => console.log('error', error));
    }
    useEffect(() => {
        FrequentlyAskedQuestions();
    }, [])


    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Best Remote work and freelance projects at RozgaarIndia.com via Times Ascent`}</title>
                <meta name="description" content={`Rozgaar India is one of India's Leading online service marketplace for remote work and freelance projects. You can find the best skilled online service providers at RozgaarIndia.com`} />
                <link rel="canonical" href="https://www.rozgaarindia.com/FAQ" /> 
            </Helmet>
            <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
           

          }
          )
        }}
      /> 
      <script type="application/ld+json" 
      
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "How do I pay the freelancer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Review proposal and make payments securely on rozgaarindia platform <li> We encourage you to pay your freelancers on our platform in order to assure maximum safety of your payments </li> <li>We release payment to the freelancer within 7 days of payment and Premium freelancers get payments within 24 hours of the payment is made.</li> <li> Any work or payment you do outside rozgaarindia is at your own risk. We do not recommend making upfront payments to Freelancers.</li></p>"
              }
            },
            {
              "@type": "Question",
              "name": "How can i hire freelancers in bulk?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Simply, <li> Click on Post a Requirement and follow the steps</li> <li>Be as descriptive as possible so we can understand your requirement and connect you with the relevant team </li></p>"
              }
            }]
          

        }
        )
      }}
      
      />
            <React.Suspense fallback={<div className="pageLoader"><Loader type="TailSpin" color="red" height={80} width={80} className="text-center my-5" /></div>}>
            <div className={classes.faq_question_mainhead}>
                <div className={classes.faq_head}>
                    <div className={classes.faq_firstheading}>Frequently asked questions</div>
                    <div className={classes.faq_secondheading}>Everything you need to know on posting requirement and hiring, signup, selecting the right freelancer, and releasing your payments.</div>
                    <div className={classes.faq_padding}>
                         {Data.slice(0, show ? show.length : 5).map((item, value) => {
                            return (
                                <Faqdropdown question={item.Question} answer={item.Answer} />
                          ) })}     
                    </div>
                    <div className={classes.faq_ActionButton}>
                            {!show ? <ActionButton buttonText={"View More"} buttonType={"small"}  onClick={() => setShow(true)} /> : <ActionButton buttonText={"View less"} buttonType={"small"}   onClick={() => setShow(false)} />}
                        </div>


                </div>
            </div>
            {/* <RIFooter /> */}
        </React.Suspense>
            </>
    )
}
export default FrequentlyAskedQuestions;