import React, { useState } from "react";
import classes from "./RIFooter.module.css";
import { BiPlus } from "react-icons/bi";
import { BiMinus } from "react-icons/bi";
import {
  AiOutlineInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";
import { GrLinkedinOption } from "react-icons/gr";
import { FaFacebookF } from "react-icons/fa";

import { Link } from "react-router-dom";

const RIFooter = () => {
  const [showAbout, setShowAbout] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const [showPolicies, setShowPolicies] = useState(false);


  return (
    <div className={classes.footer_container}>
      <div className={classes.mob_view}>
        {!showAbout ? (
          <div className={classes.footer_top}>
            <div className={classes.footer_contact}>
              <button
                onClick={() => setShowAbout(true)}
                className={classes.plus_iconText}
              >
                {" "}
                COMPANY
              </button>
              <button
                onClick={() => setShowAbout(true)}
                className={classes.plus_icon}
              >
                {" "}
                <BiPlus />
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className={classes.footer_top}>
              <div className={classes.footer_contact}>
                <button
                  onClick={() => setShowAbout(false)}
                  className={classes.plus_iconText}
                >
                  {" "}
                  COMPANY
                </button>
                <button
                  onClick={() => setShowAbout(false)}
                  className={classes.plus_icon}
                >
                  {" "}
                  <BiMinus />
                </button>
              </div>
            </div>
            <div className={classes.aboutUs_list}>
              <Link to="/aboutUs" className={classes.Footer_Link}>
                <div className={classes.list_open}>About</div>
              </Link>
              <Link
                to={localStorage.getItem("userLoggedIn") ? "/FreelancerWorkPlace" : "/SignUp"}
                className={classes.Footer_Link}

              >
                <div className={classes.list_open}>Become a Freelancer</div>
              </Link>
              <Link to="HowToHire" className={classes.Footer_Link}>
                <div className={classes.list_open}>How it Works</div>
              </Link>
              <Link to="/ExploreSkills" className={classes.Footer_Link}>
                <div className={classes.list_open}>Explore Skills</div>
              </Link>
              <a href={"http://sasone.in"} className={classes.Footer_Link} target={"_blank"}>
                <div className={classes.list_open}>SAS ONE</div>
              </a>

            </div>
            <div className={classes.BottomBorder}></div>
          </>
        )}
        {!showPolicies ? (
          <div className={classes.footer_top}>
            <div className={classes.footer_contact}>
              <button
                onClick={() => setShowPolicies(true)}
                className={classes.plus_iconText}
              >
                {" "}
                POLICIES
              </button>
              <button
                onClick={() => setShowPolicies(true)}
                className={classes.plus_icon}
              >
                {" "}
                <BiPlus />
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className={classes.footer_top}>
              <div className={classes.footer_contact}>
                <button
                  onClick={() => setShowPolicies(false)}
                  className={classes.plus_iconText}
                >
                  {" "}
                  POLICIES
                </button>
                <button
                  onClick={() => setShowPolicies(false)}
                  className={classes.plus_icon}
                >
                  {" "}
                  <BiMinus />
                </button>
              </div>
            </div>
            <div className={classes.aboutUs_list}>
              <Link to="/termsConditions" className={classes.Footer_Link}>
                <div className={classes.list_open}>Terms & Conditions</div>
              </Link>

              <Link to="/privacyPolicy" className={classes.Footer_Link}>
                {" "}
                <div className={classes.list_open}>Privacy Policy</div>
              </Link>
              <Link to="/FAQ" className={classes.Footer_Link}>
                <div className={classes.list_open}>FAQ's</div>
              </Link>

            </div>
            <div className={classes.BottomBorder}></div>
          </>
        )}
        {!showContactUs ? (
          <div className={classes.footer_top}>
            <div className={classes.footer_contact}>
              <button
                onClick={() => setShowContactUs(true)}
                className={classes.plus_iconText}
              >
                {" "}
                CONTACT
              </button>
              <button
                onClick={() => setShowContactUs(true)}
                className={classes.plus_icon}
              >
                {" "}
                <BiPlus />
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className={classes.footer_top}>
              <div className={classes.footer_contact}>
                <button
                  onClick={() => setShowContactUs(false)}
                  className={classes.plus_iconText}
                >
                  {" "}
                  CONTACT
                </button>
                <button
                  onClick={() => setShowContactUs(false)}
                  className={classes.plus_icon}
                >
                  {" "}
                  <BiMinus />
                </button>
              </div>
            </div>
            <div className={classes.aboutUs_list}>
              <Link to="/contactus" className={classes.Footer_Link}>
                <div className={classes.list_open}>Help & Support</div>
              </Link>

              <Link to="/reportabug" className={classes.Footer_Link}>
                {" "}
                <div className={classes.list_open}>Career</div>
              </Link>
              <Link to="/contactus" className={classes.Footer_Link}>
                {" "}
                <div className={classes.list_open}>Partners</div>
              </Link>
              <Link to="/contactus" className={classes.Footer_Link}>
                {" "}
                <div className={classes.list_open}>Advertisement</div>
              </Link>
              <Link to="/reportabug" className={classes.Footer_Link}>
                <div className={classes.list_open}>Report A Bug</div>
              </Link>
              <Link to="/contactus" className={classes.Footer_Link}>
                <div className={classes.list_open}>Feedback</div>
              </Link>

            </div>
            <div className={classes.BottomBorder}></div>
          </>
        )}
        <div className={classes.aboutUs_list}>
          <img
            src="/assets/IPassets/RozgaarWhiteLogo.svg"
            alt="Rozgaar_India_Logo"
            className={classes.riLogo}
            height={50}
            width={"100%"}
            loading="lazy"
            title="Rozgaar_India_Logo"
          />
          <div>
            <div>Follow Us</div>
          </div>
          <div className={classes.social_icons}>
            <a
              href="https://www.facebook.com/HireFreelancersRemotely"
              className={classes.icons_web_Link}
            >
              <FaFacebookF className={classes.icons_web} />
            </a>
            <a
              href="https://www.instagram.com/accounts/login/?next=/rozgaar_india/"
              className={classes.icons_web_Link}
            >
              <AiOutlineInstagram className={classes.icons_web} />
            </a>
            <a
              href="https://twitter.com/RozgaarI"
              className={classes.icons_web_Link}
            >
              <AiOutlineTwitter className={classes.icons_web} />
            </a>
            <a
              href="https://www.youtube.com/channel/UCyeAcFCvAo4ZVdUfpBrn4hQ"
              className={classes.icons_web_Link}
            >
              <AiFillYoutube alt="" className={classes.icons_web} />
            </a>
            <a
              href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQFYH0Ik6vc73wAAAX8_jAPY2W4EF1cWECFh7PsI0_N2J2C3-ks5Dqhi1D56w8w5YR9DBzZBXBU2y1nuJGdpfoKW_2GJ1e1l6Pb8FhU_F70xYAvLujfYFLsD5k1D9sKkcgYxeDc=&originalReferer=https://rozgaarindia.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Frozgaar-india"
              className={classes.icons_web_Link}
            >
              <GrLinkedinOption alt="" className={classes.icons_web} />
            </a>
          </div>
        </div>

        <div className={classes.all_rights}>
          © SAS One {new Date().getFullYear()}. All Rights Reserved
        </div>
      </div>
      <div className={classes.web_view_container}>
        <div className={classes.web_view}>
          <div className={classes.aboutUs_list_web}>
            <div className={classes.haedingName}>COMPANY</div>


            <Link to="/aboutUs" className={classes.Footer_Link}>
              <div className={classes.list_open}>About Us</div>
            </Link>
            <Link to={localStorage.getItem("userLoggedIn") ? "/FreelancerWorkPlace" : "/SignUp"}
              className={classes.Footer_Link}>
              <div className={classes.list_open}>Become a Freelancer</div>
            </Link>
            <Link to="/HowToHire" className={classes.Footer_Link}>
              <div className={classes.list_open}>How it Works</div>
            </Link>


            <Link to="/ExploreSkills" className={classes.Footer_Link}>
              <div className={classes.list_open}>Explore skills</div>
            </Link>
            <a href={"http://sasone.in"} className={classes.Footer_Link}>
              <div className={classes.list_open}>SAS ONE</div>
            </a>

          </div>
          <div className={classes.aboutUs_list_web}>
            <div className={classes.haedingName}>POLICIES</div>
            <Link to="/termsConditions" className={classes.Footer_Link}>
              <div className={classes.list_open}>Terms & Conditions</div>
            </Link>

            <Link to="/privacyPolicy" className={classes.Footer_Link}>
              <div className={classes.list_open}>Privacy Policy</div>
            </Link>
            <Link to="/FAQ" className={classes.Footer_Link}>
              <div className={classes.list_open}>FAQ's</div>
            </Link>





          </div>

          <div className={classes.aboutUs_list_web}>
            <div className={classes.haedingName}>CONTACT</div>
            <Link to="/contactus" className={classes.Footer_Link}>
              <div className={classes.list_open}>Help & Support</div>
            </Link>

            <Link to="/reportabug" className={classes.Footer_Link}>
              {" "}
              <div className={classes.list_open}>Career</div>
            </Link>
            <Link to="/reportabug" className={classes.Footer_Link}>
              <div className={classes.list_open}>Report A Bug</div>
            </Link>
            <Link to="/contactus" className={classes.Footer_Link}>
              <div className={classes.list_open}>Partnership</div>
            </Link>
            <Link to="/contactus" className={classes.Footer_Link}>
              <div className={classes.list_open}>Advertisement</div>
            </Link>
            <Link to="/contactus" className={classes.Footer_Link}>
              <div className={classes.list_open}>Feedback</div>
            </Link>


          </div>
        </div>

        <div className={classes.footer_bottom}>
          <img
            src="/assets/IPassets/RozgaarWhiteLogo.svg"
            alt="Rozgaar_India_Logo"
            title="Rozgaar_India_Logo"
            loading="lazy"
            className={classes.riLogo_web}
            height={50}
            width={150}
          />
          <div>© SAS One {new Date().getFullYear()}. All Rights Reserved</div>
          <div className={classes.social_icons_web}>
            <div className={classes.followUsText}>Follow us :&nbsp;</div>
            <a
              href="https://www.facebook.com/HireFreelancersRemotely"
              className={classes.icons_web_Link}
            >
              <FaFacebookF className={classes.icons_web} />
            </a>
            <a
              href="https://www.instagram.com/accounts/login/?next=/rozgaar_india/"
              className={classes.icons_web_Link}
            >
              <AiOutlineInstagram className={classes.icons_web} />
            </a>
            <a
              href="https://twitter.com/RozgaarI"
              className={classes.icons_web_Link}
            >
              <AiOutlineTwitter className={classes.icons_web} />
            </a>
            <a
              href="https://www.youtube.com/channel/UCyeAcFCvAo4ZVdUfpBrn4hQ"
              className={classes.icons_web_Link}
            >
              <AiFillYoutube alt="" className={classes.icons_web} />
            </a>
            <a
              href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQFYH0Ik6vc73wAAAX8_jAPY2W4EF1cWECFh7PsI0_N2J2C3-ks5Dqhi1D56w8w5YR9DBzZBXBU2y1nuJGdpfoKW_2GJ1e1l6Pb8FhU_F70xYAvLujfYFLsD5k1D9sKkcgYxeDc=&originalReferer=https://rozgaarindia.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Frozgaar-india"
              className={classes.icons_web_Link}
            >
              <GrLinkedinOption alt="" className={classes.icons_web} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RIFooter;
