import { useEffect,useState } from 'react';
import Loader from 'react-loader-spinner';
import AccountBalance from '../../Components/FreelancerIncome/AccountBalance';
import IncomeCard from '../../Components/FreelancerIncome/IncomeCard';
import NewFreelancerDashBoard from '../../Components/NewFreelancerDashBoard/NewFreelancerDashboard';
import NewFreelancerDashBoardMobile from '../../Components/NewFreelancerDashBoard/NewFreelancerDashBoardMobile';
import classes from './Income.module.css'
import NoIncomeData from './NoIncomeData';
import { Helmet } from "react-helmet";
import { pageViewTracker } from '../../Components/GoogleTracking';


const Income = (props) =>{

 
  const [isLoading, setIsLoading] = useState();
  const [workData, setWorkData] = useState([])
  const [freelancerIncome, setFreelancerIncome] = useState("")

  const freelancerId = localStorage.getItem("userID");
  const [showModal,setShowModal] =useState(false)


  useEffect(() => {
    incomeDataApi();

    pageViewTracker();
  }, []);
  const incomeDataApi = () => {
    setIsLoading(true);

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      FreelancerId: freelancerId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(  global.apiLink+"/api/client/FreelancerGetClientFreelancerPayment", requestOptions)
      .then((response) => response.json())
      .then((result) => {
       setWorkData(result.data)
       setFreelancerIncome(result)
      })

     
      .catch((error) => console.log("error", error))
      .finally(() => {
        setIsLoading(false);
      });
  };

    const MobileWebHandlerSwitch = (device) => {
        switch (device) {
          case "Mobile":
            return (
              <NewFreelancerDashBoardMobile>
                {isLoading ? <div className={classes.loaderDiv}><Loader type="TailSpin"/></div> : workData.length? 
                <>
               <AccountBalance incomeDetail = {freelancerIncome}/>

               <IncomeCard freelancerWork = {workData} />
               </>:<NoIncomeData/>
                }
              </NewFreelancerDashBoardMobile>
            );
    
          default:
            return (
              <NewFreelancerDashBoard>
                 {isLoading ? <div className={classes.loaderDiv}><Loader type="TailSpin"/></div> : workData.length   ?
                <>
 
                  <AccountBalance incomeDetail = {freelancerIncome} showModal={showModal} ModalShow={()=>setShowModal(true)} CloseModal={()=>setShowModal(false)}/>
               <IncomeCard freelancerWork = {workData}/>
               </>:<NoIncomeData/>
                 }
              </NewFreelancerDashBoard>
              
            );
        }
      };
    
      return <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Frequently Asked Questions about Freelance Jobs  | Rozgaar India`}</title>
        <meta
          name="description"
          content={`Read the Freelancer FAQ's to learn everything about Freelancing, freelance projects, remote work, contract work and gigs.`}
       
       />
           <link rel="canonical" href="https://www.rozgaarindia.com/Income" /> 
      </Helmet>
      {MobileWebHandlerSwitch(props.device)}
      </>;


}

export default Income