import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BsSearch } from "react-icons/bs";
import classes from "./PublicClientRequirementList.module.css";
import Loader from "react-loader-spinner";
import PublicRecRequirementCard from "../../Components/ApplyRequirements/PublicRecRequirmentCard";
import AutoSuggestionSkills from "../../Components/ApplyRequirements/AutoSuggestionSkills";
import ReactGA from 'react-ga';
import { pageViewTracker } from "../../Components/GoogleTracking";


const PublicClientRequirementList = (props) => {
  const [postArequirementDetails, setPostArequirementDetails] = useState([]);
  const [allPostArequirementDetails, setAllPostArequirementDetails] = useState(
    []
  );
  const [loading, setLoading] = useState(true);


  const [searchSkill, setSearchSkill] = useState("");
  const [recomendationSort, setRecomendationSort] = useState("");

  const [workType, setWorkType] = useState([]);
  const [workfilter, setworkFilter] = useState({
    onetime: false,
    contract: false,
    commission: false,
    monthlybasis: false,
  });
  const [freelancerPolicy, setFreelancerPolicy] = useState({
    Remote: false,
    Hybrid: false,
    OnSite: false,
  });

  const [selectedFilter, setSelectedFilter] = useState();
  const [searchLoading, setSearchLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    ReactGA.initialize('UA-109083878-3', {
      debug: true,
      titleCase: false,
      gaOptions: {
        userId: 123
      }
    });
    ReactGA.pageview(window.location.pathname + window.location.search);

  }, [window]);

  useEffect(()=>{
      pageViewTracker();
  },[])

  useEffect(() => {
    requirementsApi();



  }, [recomendationSort, currentPage]);

  useEffect(() => {
    workTypeFilter();

  }, [workfilter, freelancerPolicy]);

  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const windowHeight = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", windowHeight);

    return () => {
      window.removeEventListener("resize", windowHeight);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage])

  const requirementsApi = () => {
    setLoading(true);
    // let userID = localStorage.getItem("userID");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      // UserId: userID,
      Page: currentPage,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      global.apiLink + "/api/client/RequirementListPublicView",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {

          setPostArequirementDetails(result.data);
          setAllPostArequirementDetails(result.data);
          window.scrollTo(0, 0)
        } else {
        }
      })
      .catch((error) => {
        alert("There is some issue , please contact support!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const SkillsSearchApi = () => {
    setSearchLoading(true)
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Skills: searchSkill,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      global.apiLink + "/api/client/SearchSkillsRequirement",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setPostArequirementDetails(result.data);
        } else if (result.status_code === 300 && result.status === "Fail") {
          alert("Please enter valid skill");
        } else {
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setSearchLoading(false);
      })
  };

  const FilterType = [
    {
      WorkType: "One Time task",
      work: "onetime",
      key: "1",
    },
    {
      WorkType: "Monthly Basis",
      work: "monthly-basis",
      key: "2",
    },
    {
      WorkType: "Contract work",
      work: "contract",
      key: "3",
    },
    {
      WorkType: "Commission Based",
      work: "commission",
      key: "4",
    },
  ];






  const filterSelectedHandler = (e, key, work) => {

    if (workType.indexOf(work) === -1) {
      workType.push(work);
    }

    if (work === "onetime" && e) {
      const value = workfilter;
      setworkFilter((prevState) => {
        return { ...value, onetime: true };
      });
    }
    if (work === "onetime" && !e) {
      const value = workfilter;
      const removeWork = workType.filter((item, i) => item !== work);
      setWorkType(removeWork);
      setworkFilter((prevState) => {
        return { ...value, onetime: false };
      });
    }

    if (work === "contract" && e) {
      const value = workfilter;

      setworkFilter((prevState) => {
        return { ...value, contract: true };
      });
    }
    if (work === "contract" && !e) {
      const value = workfilter;
      const removeWork = workType.filter((item, i) => item !== work);
      setWorkType(removeWork);
      setworkFilter((prevState) => {
        return { ...value, contract: false };
      });
    }
    if (work === "commission" && e) {
      setworkFilter({ ...workfilter, commission: true });
    }
    if (work === "commission" && !e) {
      const removeWork = workType.filter((item, i) => item !== work);
      setWorkType(removeWork);
      setworkFilter({ ...workfilter, commission: false });
    }
    if (work === "monthly-basis" && e) {
      setworkFilter({ ...workfilter, monthlybasis: true });
    }
    if (work === "monthly-basis" && !e) {
      const removeWork = workType.filter((item, i) => item !== work);
      setWorkType(removeWork);
      setworkFilter({ ...workfilter, monthlybasis: false });
    }
  };


  const workTypeFilter = () => {
    const work = workType;

    if (
      !workfilter.onetime &&
      !workfilter.contract &&
      !workfilter.commission &&
      !workfilter.monthlybasis &&
      !freelancerPolicy.Remote
    ) {
      setPostArequirementDetails(allPostArequirementDetails);
    } else {
      setPostArequirementDetails([]);

      const workPolicy = "";
      const workTypeSel = allPostArequirementDetails.filter(
        (item, i) =>
          workType.includes(item.RequirementType) ||
          workPolicy.includes(item.FreelancerPolicy)
      );

      setPostArequirementDetails(() => [
        ...workTypeSel,
      ]);
    }
  };

  const AllSkills = () => {
    setSelectedFilter("All");

    requirementsApi();
  };

  const sortByPrice = () => {
    setSelectedFilter("Price");
    const DataByPrice = postArequirementDetails.sort(
      (a, b) => parseFloat(a.Budget) - parseFloat(b.Budget)
    );

    setPostArequirementDetails(() => [
      ...DataByPrice,
    ]);
  };

  const sortByDate = () => {
    setSelectedFilter("Newest");
    const DataByDate = postArequirementDetails.sort(
      (a, b) => new Date(b.UpdatedDate) - new Date(a.UpdatedDate)
    );

    setPostArequirementDetails(() => [
      ...DataByDate,
    ]);
  };

  const sortByRecommendation = () => {
    setSelectedFilter("Recommended");
    setRecomendationSort(!recomendationSort);
  };



  const MobileWebHandlerSwitch = (device) => {
    switch (device) {
      case "Mobile":
        return (
          <div className={classes.pageLayout}>
            <div className={classes.sortAndRequirmentContainer}>
              <div className={classes.searchBarContainer}>
                <div className={classes.searchInput}>
                  <AutoSuggestionSkills
                    setSearchSkill={setSearchSkill}
                    placeholder={"Search by skill"}
                  />
                </div>
                <div
                  className={classes.searchIcon}
                  onClick={() => { searchSkill === "" ? requirementsApi() : SkillsSearchApi(); setSearchSkill("") }}
                >
                  {" "}
                  <BsSearch />{" "}
                </div>
              </div>
              <div className={classes.sortAndPageContainer}>
                <div className={classes.sortContainer}>
                  <div className={classes.sortHeading}>Sort by</div>
                  <div
                    className={
                      selectedFilter === "All"
                        ? classes.sortOptionSelected
                        : classes.sortOptions
                    }
                    onClick={AllSkills}
                  >
                    All
                  </div>
                  <div
                    className={selectedFilter === "Newest"
                      ? classes.sortOptionSelected
                      : classes.sortOptions}
                    onClick={() => sortByDate()}
                  >
                    Newest
                  </div>
                  <div
                    className={selectedFilter === "Recommended"
                      ? classes.sortOptionSelected
                      : classes.sortOptions}
                    onClick={() => sortByRecommendation()}
                  >
                    Recommended
                  </div>
                  <div
                    className={selectedFilter === "Price"
                      ? classes.sortOptionSelected
                      : classes.sortOptions}
                    onClick={() => sortByPrice()}
                  >
                    Price
                  </div>
                </div>
              </div>
              <PublicRecRequirementCard
                requirementsCard={postArequirementDetails}
                loading={loading}
              />
              <div className={classes.PaginationContainer}>
                {currentPage !== 1 ? <button onClick={() => { setCurrentPage(currentPage - 1) }} className={classes.buttonone}>Prev</button> : <span className={classes.buttononePre}>Prev</span>}
                {currentPage !== 1 ? <span className={classes.CurrentPageTextNext} onClick={() => { setCurrentPage(currentPage - 1) }}>{currentPage - 1}</span> : <></>}
                {postArequirementDetails.length >= 20?<span className={classes.CurrentPageNext} onClick={() => { setCurrentPage(currentPage + 1) }}>{currentPage}</span>:<span className={classes.CurrentPageText}>{currentPage}</span>}
                {postArequirementDetails.length >= 20 ? <><button onClick={() => { setCurrentPage(currentPage + 1) }} className={classes.buttonone}>Next</button></> : <span className={classes.buttononePre}>Next</span>}
              </div>
            </div>
          </div>
        );

      default:
        return (
          <div className={classes.pageLayout}>
            <div className={classes.pageDivider}>


              <div style={{ height: height, backgroundColor: 'white', overflow: "scroll", width: '25%', padding: '20px' }}>
                <div className={classes.searchBarContainer}>
                  <div className={classes.searchInput}>
                    <AutoSuggestionSkills
                      setSearchSkill={setSearchSkill}
                      placeholder={"Search by skill"}
                    />
                  </div>
                  <div
                    className={classes.searchIcon}
                    onClick={() => { searchSkill === "" ? requirementsApi() : SkillsSearchApi(); setSearchSkill("") }}
                  >{searchLoading ?
                    <Loader type="TailSpin" color="white" width={20} height={18} /> :

                    <BsSearch />}
                  </div>
                </div>

                <div className={classes.sortContainer}>
                  <div className={classes.filterOptionHeadings}>Sort by</div>
                  <div
                    className={
                      selectedFilter === "All"
                        ? classes.sortOptionSelected
                        : classes.sortOptions
                    }
                    onClick={AllSkills}
                  >
                    All
                  </div>
                  <div
                    className={
                      selectedFilter === "Recommended"
                        ? classes.sortOptionSelected
                        : classes.sortOptions
                    }
                    onClick={() => sortByRecommendation()}
                  >
                    Recommended
                  </div>
                  <div
                    className={
                      selectedFilter === "Newest"
                        ? classes.sortOptionSelected
                        : classes.sortOptions
                    }
                    onClick={() => sortByDate()}
                  >
                    Newest
                  </div>
                  <div
                    className={
                      selectedFilter === "Price"
                        ? classes.sortOptionSelected
                        : classes.sortOptions
                    }
                    onClick={() => sortByPrice()}
                  >
                    Price
                  </div>
                </div>
                <div className={classes.filterOptionHeadings}>Work Type </div>
                {FilterType.map((item, i) => {
                  return (
                    <div className={classes.checkBoxContainer} key={item.key}>
                      <input
                        type="checkbox"
                        id={item.key}
                        onChange={(e) =>
                          filterSelectedHandler(
                            e.target.checked,
                            item.key,
                            item.work
                          )
                        }
                      />
                      <div>{item.WorkType} </div>
                    </div>
                  );
                })}

              </div>


              <div style={{ height: height, backgroundColor: 'white', overflow: "scroll", width: '90%', marginLeft: '10px' }}>
                <div className={classes.PaginationContainer}>
                  {currentPage !== 1 ? <button onClick={() => { setCurrentPage(currentPage - 1) }} className={classes.buttonone}>Prev</button> : <span className={classes.buttononePre}>Prev</span>}
                  {currentPage !== 1 ? <span className={classes.CurrentPageTextNext} onClick={() => { setCurrentPage(currentPage - 1) }}>{currentPage - 1}</span> : <></>}
                  {postArequirementDetails.length >= 20?<span className={classes.CurrentPageNext} onClick={() => { setCurrentPage(currentPage + 1) }}>{currentPage}</span>:<span className={classes.CurrentPageText}>{currentPage}</span>}
                  {postArequirementDetails.length >= 20 ? <><button onClick={() => { setCurrentPage(currentPage + 1) }} className={classes.buttonone}>Next</button></> : <span className={classes.buttononePre}>Next</span>}
                </div>
                <PublicRecRequirementCard
                  requirementsCard={postArequirementDetails}
                  loading={loading}
                />
                <div className={classes.PaginationContainer}>
                  {currentPage !== 1 ? <button onClick={() => { setCurrentPage(currentPage - 1) }} className={classes.buttonone}>Prev</button> : <span className={classes.buttononePre}>Prev</span>}
                  {currentPage !== 1 ? <span className={classes.CurrentPageTextNext} onClick={() => { setCurrentPage(currentPage - 1) }}>{currentPage - 1}</span> : <></>}
                  {postArequirementDetails.length >= 20?<span className={classes.CurrentPageNext} onClick={() => { setCurrentPage(currentPage + 1) }}>{currentPage}</span>:<span className={classes.CurrentPageText}>{currentPage}</span>}
                  {postArequirementDetails.length >= 20 ? <><button onClick={() => { setCurrentPage(currentPage + 1) }} className={classes.buttonone}>Next</button></> : <span className={classes.buttononePre}>Next</span>}
                </div>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Best Remote work and freelance projects at RozgaarIndia.com via Times Ascent`}</title>
        <meta
          name="description"
          content={`Rozgaar India is one of India's Leading online service marketplace for remote work and freelance projects. You can find the best skilled online service providers at RozgaarIndia.com`}
        />
         <link rel="canonical" href="https://www.rozgaarindia.com/requirementList" />
      </Helmet>

      {MobileWebHandlerSwitch(props.device)}
    </>
  );
};

export default PublicClientRequirementList;
