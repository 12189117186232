import React from "react";
import ActionButton from "../ActionButton";
import classes from "./Package.module.css";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
const Package = (props) => {
  
  let navigate = useNavigate();
  return (
    <div className={classes.Container}>
      {props.freelancerPackage &&
        <div >
          <div className={classes.packageTitle}>{props.freelancerPackage.activePackage.Packagename ? props.freelancerPackage.activePackage.Packagename : <Link to="/FreelancerPackage" className={classes.GetStarted}>GET STARTED</Link>} </div>
          <div className={classes.priceWrapper}>
            <div className={classes.currentPackage}>Current Package</div>

            <div className={classes.creditCount}>  {props.freelancerPackage.availableCredit ? props.freelancerPackage.availableCredit : "0"}
              {props.freelancerPackage.availableCredit<2 ? <span className={classes.creditLeft}> Credit left</span> : <span className={classes.creditLeft}> Credits left</span>}
            </div>
          </div>
        </div>
      }
      <ActionButton
        buttonText="Upgrade Plan"
        buttonType={"small"}
        onClick={() => { navigate('/FreelancerPackage') }}
      />

    </div>
  );
};

export default Package;
