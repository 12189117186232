import classes from "./RemoteWorkCareerBoxes.module.css";
 
const RemoteWorkCareerBoxes = (props) => {
  return (
    <div className={classes.mainContainer}>
      <div className={classes.boxesAlingment}>
        <div className={classes.headingBox}>
          <b> The #1 Source of</b> Freelance work
        </div>
        <div className={classes.BoxA}>
          <div className={classes.contentBoxHeading}> Zero Commission </div>
        
          <div className={classes.contentBoxMainContent}>
          We do not charge any commission from freelancer earnings.  
          </div>
        </div>
      </div>
       <div className={classes.boxesAlingment}> 

<div className={classes.BoxColorChange}> 

<div className={classes.contentBoxHeading}> Matched Freelance Jobs </div>

    <div className={classes.contentBoxMainContent}> Apply on best suited freelance jobs matched with your skill. </div>
</div> 
<div className={classes.imageBox}> 
<img src="./assets/FreelancerAssets/banners/Career_image1_Mobile.jpg"  alt="Remote_Work_Career" title="Remote_Work_Career" loading="lazy" width={"100%"} height={180} className={classes.boxImage}/>

</div> 
</div>
<div className={classes.boxesAlingment}> 
<div className={classes.BoxA}>
<div className={classes.contentBoxHeading}> Payment Protection </div>

    <div className={classes.contentBoxMainContent}>Ensure to take a certain amount from client before starting the work. </div>
    
     </div> 
<div className={classes.BoxA}> 
<div className={classes.contentBoxHeading}> Express Withdraw</div>
   
    <div className={classes.contentBoxMainContent}>Get paid for the work you do in a timely manner in a click.  </div>

</div> 
    
</div>
<div className={classes.wideImageAlingment}>
<img src="./assets/FreelancerAssets/banners/Career_image2_Mobile.jpg" alt="Remote_Work_Career" title="Remote_Work_Career" loading="lazy" width={"100%"} height={180} className={classes.logoArea}/>

    </div> 
</div>
    
  );
};

export default RemoteWorkCareerBoxes;
