import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "./ArticlesFreelancers.module.css";
import HeadingFreelancer from "./HeadingFreelancer";
const ArticlesFreelancers = (props) => {
  
  const navigate = useNavigate()


 
  const dateConvert = (data) => {
    var date = new Date(data);
    var newDate = date.toString()
 return newDate.substring(0,15)
 
  } 


const MobileWebHandlerSwitch = (device) => {
  switch (device) {
    case "Mobile":
      return (
       
<div className={classes.ArticlesFreelancers_maindiv}>
     
     <HeadingFreelancer heading="Trending Read" secondLine="on Gig Freelancer and Remote Working" />
           <div>
     
             <div className={classes.ArticlesFreelancers_Display_Web}>
               {props.articleCard.slice(0,6).map((item, i) => (
                 <div key={item.key} className={classes.ArticlesFreelancers_Container_Web} onClick = {() => navigate("/articlesRozgaar/" + item.ArticleId)} >
                <div className={classes.imageAndArticleText_Mob}> 
                <img
                        src={item.banner.BannerImageUrl}
                        alt={item.Title.split(" ").join("_")}
                        className={classes.ArticlesFreelancers_imageMob}
                        title={item.Title.split(" ").join("_")}
                        loading="lazy"
                        width={"120px"}
                        height={100}
                      />
                   <div className={classes.articlesHeadingSubHeading}> 
                   <div className={classes.subhedingArticle_Web}> {dateConvert(item.UpdatedAt)} </div>
                   <div className={classes.ArticlesFreelancers_headingBold_Web}>{item.Title}</div>
                  
                   
                   <div className={classes.subhedingArticle_Web}>{item.ShortDescription.substring(0, 60)}... </div>
                   </div>
                 </div>
                 </div>
               ))}
             </div>
            
           </div>
         </div>

      );

    default:
      return (
        <div className={classes.ArticlesFreelancers_maindiv}>
     
        <HeadingFreelancer heading="Trending Read" secondLine="on Gig Freelancer and Remote Working" />
              <div>
        
                <div className={classes.ArticlesFreelancers_Display_Web}>
                  {props.articleCard.slice(0,3).map((item, i) => (
                    <div key={item.key} className={classes.ArticlesFreelancers_Container_Web} onClick = {() => navigate("/articlesRozgaar/" + item.ArticleId)} >
                   <div className={classes.imageAndArticleText_Web}> 
                    <img
                        src={item.banner.BannerImageUrl}
                        alt={item.Title.split(" ").join("_")}
                        className={classes.ArticlesFreelancers_image}
                        title={item.Title.split(" ").join("_")}
                        loading="lazy"
                        width={"250px"}
                        height={180}
                      />
                      
                      <div > 
                      <div className={classes.subhedingArticle_Web}> {dateConvert(item.UpdatedAt)} </div>
                      <div className={classes.ArticlesFreelancers_headingBold_Web}>{item.Title}</div>

                      
                      <div className={classes.subhedingArticle_Web}>{item.ShortDescription.substring(0, 60)}... </div>
                      </div>
                    </div>
                    </div>
                  ))}
                </div>
                <div className={classes.ArticlesFreelancers_Display_Web}>
                  {props.articleCard.slice(3,6).map((item, i) => (
                    <div key={item.key} className={classes.ArticlesFreelancers_Container_Web} onClick = {() => navigate("/articlesRozgaar/" + item.ArticleId)} >
                   <div className={classes.imageAndArticleText_Web}> 
                    <img
                        src={item.banner.BannerImageUrl}
                        alt={item.Title.split(" ").join("_")}
                        className={classes.ArticlesFreelancers_image}
                        title={item.Title.split(" ").join("_")}
                        loading="lazy"
                        width={"250px"}
                        height={180}
                      />
                     
                      <div> 
                      <div className={classes.subhedingArticle_Web}> {dateConvert(item.UpdatedAt)} </div>
                      <div className={classes.ArticlesFreelancers_headingBold_Web}>{item.Title}</div>
                      
                      <div className={classes.subhedingArticle_Web}>{item.ShortDescription.substring(0, 60)}... </div>
                      </div>
                    </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
      );
  }
};

return <>{MobileWebHandlerSwitch(props.device)}</>
}
export default ArticlesFreelancers;
