import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import HireType from "../../Components/HowToHireComps/HireType";
import HowToHireSec from "../../Components/HowToHireComps/HowToHireSec";
import RIFooter from "../../Components/RIFooter";
import classes from "./HowToHire.module.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Faqdropdown from "../FrequentlyAskedQuestions/Faqdropdown";
import { pageViewTracker } from "../../Components/GoogleTracking";
const ActionButton = React.lazy(() => import("../../Components/ActionButton"));

const HowToHire = () => {
  const [show, setShow] = useState();
  const [Data, setData] = useState([]);
  const [question, setQuestion] = useState([]);
  const [answer, setAnswer] = useState([]);

  useEffect(() => {
    FrequentlyAskedQuestions();
    pageViewTracker();
  }, []);

  const FrequentlyAskedQuestions = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Question: question,
      Answer: answer,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/api/rozgaarapi/GetFaq", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title>{`Frequently Asked Questions about Freelance Jobs  | Rozgaar India`}</title>
        <meta
          name="description"
          content={`Read the Freelancer FAQ's to learn everything about Freelancing, freelance projects, remote work, contract work and gigs.`}
       
       />
           <link rel="canonical" href="https://www.rozgaarindia.com/HowToHire" /> 
      </Helmet>
      <React.Suspense
        fallback={
          <div className="pageLoader">
            <Loader
              type="TailSpin"
              color="red"
              height={80}
              width={80}
              className="text-center my-5"
            />
          </div>
        }
      >
        <div className={classes.contentDiv}>
          <h1 className={classes.heading}>
          Find Remote, Hybrid, Onsite, Flexible Work 
          </h1>
          <Link to={localStorage.getItem("userLoggedIn")?"/FreelancerWorkPlace":"/SignUp"} className={classes.PARBtn_Link}>
            {" "}
            <ActionButton
              buttonText={"Join as a Freelancer"}
              buttonType={"left"}
            />
          </Link>
          <img
            src="/assets/banners/how_to_hire_mobile_banner.jpg"
            className={classes.bannerMob}
            alt="Find_Remote_Hybrid_Onsite_Flexible_Work" title="Find_Remote_Hybrid_Onsite_Flexible_Work" loading="lazy" width={"100%"} height={300}
          />
          <h2 className={classes.workTypeHeading}>Work the way you want</h2>
        
          <HireType />
          <h2 className={classes.workTypeHeading}>Find freelance jobs online and get paid using our secure, flexible and platform </h2>
         
          <HowToHireSec />
          <h2 className={classes.faqHeading}>FAQ</h2>

          <div className={classes.faqMainDiv}>
            {Data.slice(0, show ? show.length : 5).map((item, value) => {
              return (
                <>
                  <Faqdropdown question={item.Question} answer={item.Answer} />
                </>
              );
            })}
          </div>
          <div className={classes.faq_ActionButton}>
            {!show ? (
              <ActionButton
                buttonText={"View More"}
                buttonType={"small"}
                onClick={() => setShow(true)}
              />
            ) : (
              <ActionButton
                buttonText={"View less"}
                buttonType={"small"}
                onClick={() => setShow(false)}
              />
            )}
          </div>
        </div>
        {/* <RIFooter /> */}
      </React.Suspense>
    </>
  );
};

export default HowToHire;
