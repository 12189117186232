
import { GrAlert } from 'react-icons/gr';
import classes from './TopAlertNotification.module.css'

const TopAlertNotification = (props) => {

  const profileRoundOff = (profilePercent) => {
    let profileNum = parseInt(profilePercent)
    let roundOff = Math.round(profileNum)

    return roundOff;

  }

  return (
    <>
      {props.profileCompletion !== "100.0%" ?
        <div className={classes.mainContainer}>
          <div className={classes.iconAndContent}>
            <GrAlert color='red' className={classes.iconDesign} />
            <div>
              <div className={classes.heading}> Account Activation Pending </div>
              <div className={classes.content}>
                Please complete your freelancer profile to activate your account
              </div>

            </div>
          </div>
          <div className={classes.progressbar}>
            <div
              style={{
                width: props.profileCompletion,
                backgroundColor: "rgb(184, 184, 255)",
                padding: 3,
                fontSize: 12,
                fontWeight: "bold",
                paddingLeft: 10,
                justifyContent: "center",
              }}
            >
              {profileRoundOff(props.profileCompletion)} %

            </div>

          </div>

        </div>
        :
        <div className={classes.marginForHundred}> </div>
      }

    </>
  )
}

export default TopAlertNotification;