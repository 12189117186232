import React from "react";

import HeadingFreelancer from "./HeadingFreelancer";
import classes from "./HelpFreelancers.module.css";
const HelpFreelancers = () => {
  return (
    <div className={classes.HelpFreelancers_mainheader}>
   
      <HeadingFreelancer heading="How can we help you" secondLine="get started with Freelancing" />
      <div className={classes.HelpFreelancers_flex}>
    
        <img
          src="/assets/FreelancerAssets/HomePage/howcanhelp_banner.jpg"
          alt="How_We_Can_Help_You"
          title="How_We_Can_Help_You"
          loading="lazy"
          width={"25%"}
          height={400}
          className={classes.HelpFreelancers_image}
        />
        <img
          src="/assets/FreelancerAssets/HomePage/howcanhelp_bannermob.jpg"
          alt="How_We_Can_Help_You"
          title="How_We_Can_Help_You"
          loading="lazy"
          width={"50%"}
          height={150}
          className={classes.HelpFreelancers_imagemob}
        />
   
        <div className={classes.HelpFreelancers_maindiv}>
          <div className={classes.HelpFreelancers_textflex}>
            <div className={classes.HelpFreelancers_mapdiv}>
              <div className={classes.HelpFreelancers_iconradiusYellow}>
              <img
                src="/assets/FreelancerAssets/HomePage/Create_Your_Profile.svg"
                width={"70%"}
                height={40}
                alt="Create_Freelancer_Profile"
                title="Create_Freelancer_Profile"
                loading="lazy"
                className={classes.HelpFreelancers_icons}
              />
              </div>
              <div className={classes.mainHeading}>Create Freelancer profile</div>
              <div className={classes.HeadingText}>
              Showcase your portfolios and include details that will make your profile stand out.
          
              </div>
            </div>

            <div className={classes.HelpFreelancers_mapdiv}>
            <div className={classes.HelpFreelancers_iconradiusblue}>
              <img
                src="/assets/FreelancerAssets/HomePage/Apply_for_Clients_Requirement.svg"
                width={"70%"}
                height={40}
                alt="Find_The_Right_Freelance_Job"
                title="Find_The_Right_Freelance_Job"
                loading="lazy"
                className={classes.HelpFreelancers_icons}
              />
               </div>
               <div className={classes.mainHeading}>Find the right Freelance Job</div>
              <div className={classes.HeadingText}>
              We make it easy to identify and apply for the jobs that match your skills and services. 
               
              </div>
            </div>
          </div>

          <div className={classes.HelpFreelancers_textflex}>
            <div className={classes.HelpFreelancers_mapdiv}>
            <div className={classes.HelpFreelancers_iconradiusBlueOther}>
              <img
                src="/assets/FreelancerAssets/HomePage/Contact_the_Client_Directly.svg"
                width={"70%"}
                height={40}
                alt="Send_Proposal"
                title="Send_Proposal"
                loading="lazy"
                className={classes.HelpFreelancers_icons}
              />
               </div>
               <div className={classes.mainHeading}>Send Proposal </div>
              <div className={classes.HeadingText}>
              Discuss the work in detail, share your quote and request a deposit to start the work 
              
              </div>
            </div>

            <div className={classes.HelpFreelancers_mapdiv}>
            <div className={classes.HelpFreelancers_iconradiusGreen}>
              <img
                src="/assets/FreelancerAssets/HomePage/Get_Paid.svg"
                width={"70%"}
                height={40}
                alt="Get_Hired"
                title="Get_Hired"
                loading="lazy"
                className={classes.HelpFreelancers_icons}
              />
              </div>
              <div className={classes.mainHeading}>Get Hired  </div>
              <div className={classes.HeadingText}>
              Use Rozgaar Chat to communicate and share files with the client. Withdraw money securely

        
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

   
  );
};

export default HelpFreelancers;
