import React, { useState , useEffect } from "react";
import classes from "./FreelancerMembership.module.css";
import PackageDetail from "../Components/PackageDetail";
import { useNavigate } from "react-router-dom";
import NewFreelancerDashBoardMobile from "../Components/NewFreelancerDashBoard/NewFreelancerDashBoardMobile";
import NewFreelancerDashBoard from "../Components/NewFreelancerDashBoard/NewFreelancerDashboard";
import FreelancerMemberShiplWeb from "./FreelancerMemberShiplWeb";
import { Helmet } from "react-helmet";
import { pageViewTracker } from "../Components/GoogleTracking";

const FreelancerMembership = (props) => {
  const navigate = useNavigate();

  const [activeContainer, setActiveContainer] = useState("Basic");
  const [packagedata, setPackagedata] = useState([])
  const [packageID, setPackageID] = useState("32131ad2-9954-11ec-b62d-02c01e8e4bda");
  const freelancerId = localStorage.getItem("userID")
  useEffect(() => {
    planData();
    pageViewTracker();
  }, [])
  
 const freelancerPackageOrder = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");
  
    var raw = JSON.stringify({
      "FreelancerId":(freelancerId),
    "PackageId":(packageID)
  
  });
  
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    fetch(
      global.apiLink+"/api/rozgaarapi/FreelancerPackagePaymentInitiate",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
     
          navigate("/payment/" + result.data.PGOrderId)
        } else {
        
        }
      })
      .catch((error) => console.log("error", error));
  };


  const planSelection = (type,PackageId ) =>{
    setActiveContainer(type)
    setPackageID(PackageId)

  }



  const planData = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      global.apiLink +"/api/freelancerapp/rozgaarapi/GetPackagesMaster",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setPackagedata(result.data)
   

       
      })
      .catch((error) => console.log("error", error));
  };

  const MobileWebHandlerSwitch = (device) =>{
    switch(device){
      case "Mobile" : 
      return(
        <NewFreelancerDashBoardMobile>
        <div className={classes.packageMainDiv}>
        <div className={classes.packageHeading}>Chooose Your Plan</div>
        {activeContainer === "Basic" ? (
          <PackageDetail packageType={"Basic"} packages = {packagedata}/>
        ) : activeContainer === "Plus" ? (
          <PackageDetail packageType={"Plus"} packages = {packagedata}/>
        ) : activeContainer === "Pro" ? (
          <PackageDetail packageType={"Pro"} packages = {packagedata}/>
        ) : (
          <></>
        )}
  
        <div className={classes.planMainDiv}>
          <div
            className={
              activeContainer === "Basic" ? classes.active : classes.planSelection
            }
            onClick={() => planSelection ("Basic", "32131ad2-9954-11ec-b62d-02c01e8e4bda")}
          >
            ₹199/Year Basic
          </div>
          <div
            className={
              activeContainer === "Plus" ? classes.active : classes.planSelection
            }
            onClick={() =>planSelection ("Plus", "ba9c81d3-9954-11ec-b62d-02c01e8e4bda")}
          >
            ₹499/Year Plus
          </div>
          <div
            className={
              activeContainer === "Pro" ? classes.active : classes.planSelection
            }
            onClick={() => planSelection ("Pro", "d32e3c9a-9954-11ec-b62d-02c01e8e4bda")}
          >
            ₹799/Year Pro
          </div>
        </div>
        <div className={classes.selectButtonDiv}>
          <button
            className={classes.selectButton}
            onClick={() => freelancerPackageOrder()}
          >
            CONFIRM
          </button>
        </div>
    
      </div>
      </NewFreelancerDashBoardMobile>
      )
      default :
      return(
        <NewFreelancerDashBoard>
          <FreelancerMemberShiplWeb packages = {packagedata}/>
        </NewFreelancerDashBoard>
      )
    }
  }
  

  return (
 <>
  <Helmet>
        <meta charSet="utf-8" />
        <title>{`Frequently Asked Questions about Freelance Jobs  | Rozgaar India`}</title>
        <meta
          name="description"
          content={`Read the Freelancer FAQ's to learn everything about Freelancing, freelance projects, remote work, contract work and gigs.`}
       
       />
           <link rel="canonical" href="https://www.rozgaarindia.com/FreelancerPackage" /> 
      </Helmet>
 {MobileWebHandlerSwitch(props.device)}
 </>
  );
};

export default FreelancerMembership;
