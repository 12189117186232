import classes from './FreelancerTheme.module.css'

const HeadingFreelancer = (props) =>{


    return(
<div className={classes.headingSubheadingContainer}>
<div className={classes.centerHeading}>  
{props.heading}
    </div>
    <div className={classes.subHeading}> 
{props.secondLine}
    </div>
    </div>
    )


}

export default HeadingFreelancer;