

import classes from './BannerArea.module.css'
import FrelancerButton from './FreelancerButton';
import { useNavigate } from 'react-router';

const BannerAreaWeb = () => {

    let navigate = useNavigate();

    return (

        <div className={classes.imageAndContentContainerWeb}>
            <div className={classes.mainContent}>
                <div className={classes.yellowLine}> </div>
                <h1 className={classes.mainContentStyle}> Everything you need to <br/><span className={classes.kickStart}>KICKSTART</span><br/> <span className={classes.mainContentStyle}>your Freelance career</span></h1>
                

              
                {localStorage.getItem("userLoggedIn") ? "" : <FrelancerButton buttonText={"Join as a Freelancer "} position="left" onClick={() => navigate("/signup")} />}
            </div>

            <img className={classes.MobviewBanner_image} src='./assets/FreelancerAssets/banners/Web_main_banner.jpg' alt="Your_Freelancer_Career_RozgaarIndia" loading='lazy' title='Your_Freelancer_Career_RozgaarIndia' width={"100%"} height={"500px"} />

        </div>


    )
}

export default BannerAreaWeb;