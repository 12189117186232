import React, { useState, useEffect } from "react";
import classes from "./DashboardContact.module.css";
import NewFreelancerDashBoard from "../../Components/NewFreelancerDashBoard/NewFreelancerDashboard";
import NewFreelancerDashBoardMobile from "../../Components/NewFreelancerDashBoard/NewFreelancerDashBoardMobile";
import ContactUs from "../ContactUs";
import { Helmet } from "react-helmet";
import { pageViewTracker } from "../../Components/GoogleTracking";


const DashboardContact = (props) => {
  const [ dayTime,setDayTime] = useState();
  


  var today = new Date();
  var curHr = today.getHours();

  useEffect(() => {
    if (curHr < 12) {
      setDayTime("Good Morning");
    } else if (curHr < 18) {
      setDayTime("Good Afternoon");
    } else {
      setDayTime("Good Evening");
    }
  }, []);

 useEffect(() => {
  pageViewTracker();
 }, [])
 

  const MobileWebHandlerSwitch = (device) => {
    switch (device) {
      case "Mobile":
        return (
          <NewFreelancerDashBoardMobile>
            <div className={classes.welcomeMessage}>
              <div>
                <div className={classes.secondLine}>
                  {" "}
                  Hope you are having a lovely day!{" "}
                </div>
              </div>
            </div>

            <ContactUs />
          </NewFreelancerDashBoardMobile>
        );

      default:
        return (
          <NewFreelancerDashBoard>
            <ContactUs />
          </NewFreelancerDashBoard>
        );
    }
  };

  return <>
  <Helmet>
        <meta charSet="utf-8" />
        <title>{`Frequently Asked Questions about Freelance Jobs  | Rozgaar India`}</title>
        <meta
          name="description"
          content={`Read the Freelancer FAQ's to learn everything about Freelancing, freelance projects, remote work, contract work and gigs.`}
       
       />
           <link rel="canonical" href="https://www.rozgaarindia.com/DashboardContact" /> 
      </Helmet>
  {MobileWebHandlerSwitch(props.device)}
  </>;
};
export default DashboardContact;
