import React, { useEffect, useState } from "react";
import classes from "./ArticlesRozgaar.module.css";
import { AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai";
import { GrFacebookOption } from "react-icons/gr";
import { FaWhatsapp } from "react-icons/fa";
import { FaGlobeAsia } from "react-icons/fa";
import { MdWatchLater } from "react-icons/md";
import RIFooter from "../../Components/RIFooter";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import { pageViewTracker } from "../../Components/GoogleTracking";

const ArticlesRozgaar = () => {
 
  const location = useLocation();
  const navigate = useNavigate();

  const [url, setUrl] = useState(0);
  const [articleData, setArticleData] = useState([]);
  const [articleDetailData, setArticleDetailData] = useState("");
  const [banner, setBanner] = useState("");
  const [loading, setLoading] = useState(true);
  const [skeletonDefaultArr, setSkeletonDefaultArr] = useState([]);
  useEffect(() => {
    let arr = [];
    for (var i = 1; i <= 7; i++) {
      arr.push(i);
    }
    setSkeletonDefaultArr(arr);
  }, []);

  useEffect(() => {
    ArticleApiDataFetch();
    ArticleDetailAPiFetchData();

    pageViewTracker();
  }, []);

  let ArticleId = location.pathname.split("/").pop();

  const ArticleDetailAPiFetchData = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ArticleId: ArticleId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/api/rozgaarapi/GetSingleArticle", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setArticleDetailData(result.data);
          setBanner(result.data.ArticleImage);
          console.log("banner", result.data.banner);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setLoading(false);
      });
  };

  const ArticleApiDataFetch = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "ArticleType": "Freelancer"
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body:raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/api/rozgaarapi/IspublishedArticle", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setArticleData(result.data);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setLoading(false);
      });
  };

  let date = articleDetailData.UpdatedAt
    ? articleDetailData.UpdatedAt.slice(0, 10)
    : "";

 

  const refreshPage = () => {
    window.location.reload();
  };
  useEffect(() => {
    setUrl(window.location.href);
  }, []);
  return (
    <div>
     <Helmet>
        <meta charSet="utf-8" />
        <title>{`Frequently Asked Questions about Freelance Jobs  | Rozgaar India`}</title>
        <meta
          name="description"
          content={`Read the Freelancer FAQ's to learn everything about Freelancing, freelance projects, remote work, contract work and gigs.`}
       
       />
           <link rel="canonical" href="https://www.rozgaarindia.com/articlesRozgaar" /> 
      </Helmet>
      {loading ? (
        <div>
          <div className={classes.bannerDiv}>
            <Skeleton className={classes.banner} />
          </div>
          <div className={classes.shadowMainDiv}>
            <div className={classes.shadowDiv}>
              <div className={classes.shadowDivHeading}>
                <Skeleton />
                <div className={classes.postedDateDiv}>
                  <div className={classes.postedText}>
                    Posted date <Skeleton />
                  </div>
                  <div className={classes.iconDiv}>
                    <AiOutlineInstagram /> <GrFacebookOption />
                    <AiOutlineTwitter />
                    <FaWhatsapp />
                  </div>
                </div>
              </div>
              <Skeleton className={classes.articleBanner} height={150} />
              <div className={classes.contentMainDiv}>
                <div className={classes.shadowDivContentHeading}>
                  By <Skeleton width={150} />
                </div>
                <div>
                  <Skeleton count={7} />
                </div>
              
                <div>
                  <Skeleton count={7} />
                </div>
              </div>
            </div>
            <div className={classes.cardMainDiv}>
              <div className={classes.cardHeading}>Recommend Read</div>
              <div>Check whats trending in freelance and remote</div>
              <div className={classes.articleDiv}>
                {skeletonDefaultArr.map(() => {
                  return (
                    <div className={classes.articleCard}>
                      <div className={classes.cardImageDiv}>
                        <Skeleton className={classes.imageCard} height={150} />
                      </div>
                      <div className={classes.cardHeading}>
                        <Skeleton />
                      </div>
                      <div className={classes.cardContent}>
                        <Skeleton />
                      </div>
                      <div className={classes.infoMainDiv}>
                        <div className={classes.articleCardInfoDiv}>
                          <FaGlobeAsia className={classes.icon} />
                          <div className={classes.info}>
                            <Skeleton width={50} />
                          </div>
                        </div>
                        <div className={classes.articleCardInfoDiv}>
                          <MdWatchLater className={classes.watchIcon} />
                          <div className={classes.info}>
                            <Skeleton width={50} />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          
          <div className={classes.shadowMainDiv}>
            <div className={classes.shadowDiv}>
              <div className={classes.shadowDivHeading}>
               <h1> {articleDetailData.Title}</h1>
                <div className={classes.postedDateDiv}>
                  <div className={classes.postedText}>Posted date {date}</div>
                  <div className={classes.iconDiv}>
                    <a
                      href={"https://facebook.com/sharer/sharer.php?u=" + url}
                      target="_blank"
                      className={classes.iconLink}
                      rel="noreferrer"
                    >
                      <GrFacebookOption />
                    </a>
                    <a
                      href={"https://twitter.com/intent/tweet?url=" + url}
                      target="_blank"
                      className={classes.iconLink}
                      rel="noreferrer"
                    >
                      <AiOutlineTwitter />
                    </a>
                    <a
                      href={"https://web.whatsapp.com/send?text=" + url}
                      target="_blank"
                      className={classes.iconLink}
                      rel="noreferrer"
                    >
                      <FaWhatsapp />
                    </a>
                  </div>
                </div>
              </div>
              {banner !== null ? (
                <img
                  src={banner.ArticleImage}
                  className={classes.articleBanner}
                  alt={articleDetailData.Title}
                  title={articleDetailData.Title}
                  loading="lazy"
                  width={"90%"}
                  height={130}
                />
              ) : (
                ""
              )}

              <div className={classes.contentMainDiv}>
              
                <div dangerouslySetInnerHTML={{ __html: articleDetailData.ArticleText }}  />
                              
              
              </div>
            </div>
            <div className={classes.cardMainDiv}>
              <h2 className={classes.cardHeading}>Recommend Read</h2>
              <h3>
              Check whats trending in freelance and remote

              </h3>
              <div className={classes.articleDiv}>
                {articleData.map((item, index) => {
                  return (
                    <div
                      className={classes.articleCard}
                      onClick={() => {
                        navigate("/articlesRozgaar/" + item.ArticleId);
                        refreshPage();
                      }}
                    >
                      <div className={classes.cardImageDiv}>
                       
                        {item.banner !== null ? (
                          <img
                            src={item.banner.BannerImageUrl}
                            className={classes.imageCard}
                            alt={item.Title}
                            title={item.Title}
                            loading="lazy"
                            width={"100%"}
                            height={200}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <h2 className={classes.cardHeading}>{item.Title}</h2>
                      <h3 className={classes.cardContent}>
                        {item.ShortDescription}
                      </h3>
                      <div className={classes.infoMainDiv}>
                        <div className={classes.articleCardInfoDiv}>
                          <FaGlobeAsia className={classes.icon} />
                          <div className={classes.info}>{item.Source}</div>
                        </div>
                        <div className={classes.articleCardInfoDiv}>
                          <MdWatchLater className={classes.watchIcon} />
                          <div className={classes.info}>
                            {item.UpdatedAt ? item.UpdatedAt.slice(0, 10) : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <RIFooter /> */}
    </div>
  );
};

export default ArticlesRozgaar;
