import React from 'react';
import classes from './AccountBalance.module.css';
import { GiWallet } from 'react-icons/gi';
import { AiFillWallet } from 'react-icons/ai';
import { BsFillCreditCardFill } from 'react-icons/bs';



const AccountBalance = (props) => {



    return (
        <div className={classes.mainContainer}>
            <div className={classes.incomeBox}>
                <AiFillWallet size={'30px'} />

                <div className={classes.incomeAmount}>
                    <div className={classes.amount}>
                        {props.incomeDetail.Currency} {props.incomeDetail.TotalIncome}

                    </div>
                    <div className={classes.amountHeading}>
                        Total Income
                    </div>
                </div>
            </div>
            <div className={classes.incomeBox}>
                <GiWallet size={'30px'} />
                <div className={classes.incomeAmount}>
                    <div className={classes.amount}>
                        {props.incomeDetail.Currency} {props.incomeDetail.Withdrawal}
                    </div>
                    <div className={classes.amountHeading}>
                        Withdrawn
                    </div>
                </div>
            </div>
            <div className={classes.incomeBox}>
                <BsFillCreditCardFill size={'30px'} />
                <div className={classes.incomeAmount}>
                    <div className={classes.amount}>
                        {props.incomeDetail.Currency} {props.incomeDetail.Balance}
                    </div>
                    <div className={classes.amountHeadingSection}>
                    <div className={classes.amountHeading}>
                        Balance
                    </div>
                   
                    </div>
                </div>
               
            </div>
            
        </div>

    )


}
export default AccountBalance;