import classes from "./PublicRecRequirementCard.module.css";
import { Link } from "react-router-dom";
import ActionButton from "../ActionButton";
import { FcRules, FcList } from "react-icons/fc";
import NoApplication from "../../Screens/NoApplication";

const PublicRecRequirementCard = (props) => {
  const workTypeColor = (workType) => {
    if (workType === "commission") {
      return "Commission";
    }
    if (workType === "monthly-basis") {
      return "Monthly";
    }
    if (workType === "onetime") {
      return "One-Time";
    }

    if (workType === "contract") {
      return "Contract";
    }
  };

  const statusHandler = (status) => {
    if (status === "Pending") {
      return "Under Review";
    }
    if (status === "Draft") {
      return "Draft";
    }
    if (status === "Approved") {
      return "Active";
    }
    if (status === "Closed") {
      return "Closed";
    }
    if (status === "Disapproved") {
      return "Rejected";
    }
  };

  const timeSince = (date) => {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return interval + " years";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + " months";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + " days";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + " hours";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  };


  const budget = (currency, budget, unit) => {
    return (
      <>
        {currency}
        {budget}/{unit}
      </>
    );
  };
  const UserName = (firstName, lastName) => {
    return (
      firstName.charAt(0).toUpperCase() +
      firstName.slice(1) +
      " " +
      lastName.charAt(0).toUpperCase()
    );
  };
  const mainSkillHandler = (skill) => {
    let firstSkill = skill[0];

    let mainSkill =
      firstSkill.Skill.charAt(0).toUpperCase() + firstSkill.Skill.slice(1);
    return mainSkill;
  };

  const addOnType = (type) => {
    if (type === "Feature") {
      return (
        <div className={classes.addonContainer}>
          <img
            src="/assets/application_detail/featured.svg"
            alt="featured"
            className={classes.addonNameIcon}
            title="featured"
            loading="lazy"
            width={20}
            height={20}
          />

          <span className={classes.AddonName}> {"Featured"}</span>
        </div>
      );
    }
    if (type === "Urgent") {
      return (
        <div className={classes.addonContainer}>
          <img
            src="/assets/application_detail/Urgent.svg"
            alt="urgent"
            className={classes.addonNameIcon}
            title="urgent"
            loading="lazy"
            width={20}
            height={20}
          />

          <span className={classes.AddonName}> {"Urgent"}</span>
        </div>
      );
    }
    if (type === "Project Manager") {
      return (
        <div className={classes.addonContainer}>
          <FcRules className={classes.addonNameIcon} />
          <span className={classes.AddonName}> {"Project Manager"}</span>
        </div>
      );
    }
    if (type === "Non Disclosure Agreement") {
      return (
        <div className={classes.addonContainer}>
          <img
            src="/assets/application_detail/NDA.svg"
            alt="NDA"
            className={classes.addonNameIcon}
            title="NDA"
            loading="lazy"
            width={20}
            height={20}
          />
          <span className={classes.AddonName}> {"NDA"}</span>
        </div>
      );
    }
    if (type === "Access Waitlist") {
      return (
        <div className={classes.addonContainer}>
          <FcList className={classes.addonNameIcon} />
          <span className={classes.AddonName}> {"Pro"}</span>
        </div>
      );
    }
  };
  const MobileWebHandlerSwitch = (device) => {
    switch (device) {
      case "Mobile":
        return (
          <>
            <div className={classes.mainContainer}>
              
              {props.requirementsCard.length > 0 ? (
                <div className={classes.webLayout}>
                  {props.requirementsCard.map((item, index) => {
                    return (
                      <div className={classes.mainCardContainer}>
                        <div className={classes.workingType}>
                          <div className={classes.mobileMenuOption}>
                            <div className={classes.workPolicy}>
                              {item.FreelancerPolicy}
                            </div>
                            <div
                              className={`${classes.workType} ${classes[workTypeColor(item.RequirementType)]}`}
                            >
                              {" "}
                              {workTypeColor(item.RequirementType)}
                            </div>
                          </div>
                          <div className={classes.statusAndMenu}> Rs. 3000</div>
                        </div>
                        <Link
                          class="linkTag"
                          to={
                            "/PublicClientRequirementDetail/" +
                            item.RequirementID
                          }
                        >
                          <div className={classes.skillContainer}>
                            {mainSkillHandler(item.Skills)}
                          </div>
                        </Link>
                        <div></div>
                        <Link
                          class="linkTag"
                          to={
                            "/PublicClientRequirementDetail/" +
                            item.RequirementID
                          }
                        >
                          <div className={classes.titleArea}>{item.Title}</div>
                        </Link>
                        <div className={classes.statusAnddate}>
                          <div className={classes.postedDate}>
                            {" "}
                            {timeSince(
                              new Date(item.UpdatedDate || item.UpdatedAt)
                            )}{" "}
                            ago
                          </div>
                          <div className={classes.postType}>| Free Post </div>
                        </div>
                        <div className={classes.skilltagsContainer}>
                          {item.Skills &&
                            item.Skills.slice(0, 4).map((item, i) => {
                              return (
                                <div className={classes.skillTags}>
                                  {" "}
                                  {item.Skill.charAt(0).toUpperCase() +
                                    item.Skill.slice(1)}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <NoApplication
                  Text={
                    "You do not have any recommend requirement based on your skill"
                  }
                />
              )}
            </div>
          </>
        );

      default:
        return (
          <>
            <div className={classes.mainContainer}>
              {props.requirementsCard.length > 0 ? (
                <div className={classes.webLayout}>
                  {props.requirementsCard.map((item, index) => {
                    return (
                      <div className={classes.mainCardContainer}>
                        <div className={classes.cardHeightSetting}>
                          <div className={classes.workingType}>
                            <div className={classes.workPolicy}>
                              {item.FreelancerPolicy}{" "}
                            </div>
                            <div
                              className={`${classes.workType} ${
                                classes[workTypeColor(item.RequirementType)]
                              }`}
                            >
                              {" "}
                              {workTypeColor(item.RequirementType)}
                            </div>
                          </div>

                          <div className={classes.twoSectionDivider}>
                            <div className={classes.leftSectionWidth}>
                              {item.IsCompany === "0"
                                ? UserName(item.FirstName, item.LastName)
                                : item.CompanyName}
                              <Link
                                to={"/requirementDetail/" + item.RequirementID}
                                class="linkTag"
                              >
                                <div className={classes.skillContainer}>
                                  {mainSkillHandler(item.Skills)}
                                </div>
                              </Link>

                              <Link
                                to={"/requirementDetail/" + item.RequirementID}
                                class="linkTag"
                              >
                                <div className={classes.titleArea}>
                                  {item.Title}
                                </div>
                              </Link>

                              <div className={classes.skilltagsContainer}>
                                {item.Skills &&
                                  item.Skills.slice(0, 4).map((item, i) => {
                                    return (
                                      <div className={classes.skillTags}>
                                        {" "}
                                        {item.Skill.charAt(0).toUpperCase() +
                                          item.Skill.slice(1)}
                                      </div>
                                    );
                                  })}
                              </div>
                              <div className={classes.statusAnddate}>
                                <div className={classes.workStatus}>
                                  {" "}
                                  {statusHandler(item.Status)}{" "}
                                </div>
                                <div className={classes.postedDate}>
                                  {timeSince(
                                    new Date(item.UpdatedDate || item.UpdatedAt)
                                  )}{" "}
                                  ago
                                </div>
                              </div>
                            </div>
                            <div className={classes.menuWithStatus}>
                              <div className={classes.featuredListing}>
                                {(item.Addon || item.Addons) &&
                                  (item.Addon ? item.Addon : item.Addons).map(
                                    (item, index) => {
                                      return (
                                        <div className={classes.tagFeatured}>
                                          {" "}
                                          {addOnType(item.AddonName)}
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                              <div className={classes.ViewBtnContainer}>
                                <div className={classes.statusAndMenu}>
                                  {budget(
                                    item.BudgetCurrency,
                                    item.Budget,
                                    item.BudgetUnit
                                  )}{" "}
                                </div>
                                {item.Status!=="Disapproved"&&<Link
                                  to={
                                    "/requirementDetail/" + item.RequirementID
                                  }
                                  class="linkTag"
                                >
                                  <ActionButton
                                    buttonText="View"
                                    buttonType="small"
                                  />
                                </Link>}
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <NoApplication
                  Text={
                    "You do not have any recommend requirement based on your skill"
                  }
                />
              )}
            </div>
          </>
        );
    }
  };

  return <>{MobileWebHandlerSwitch(props.device)}</>;
};

export default PublicRecRequirementCard;
