import React, { useState } from "react";
import classes from "./ApplicationDetail.module.css";
import { FcRating, FcRules, FcList } from "react-icons/fc";
import ActionButton from "../ActionButton";
import CustomModalTheme from "./CustomModalTheme";
import { AiFillCheckCircle } from "react-icons/ai";
import Loader from "react-loader-spinner";
import RiTextInputs from "../PostRequirement/RiTextInputs";
import { Link, useNavigate } from "react-router-dom";
import { encodeAndSendToChat } from "../../App";

const ApplicationDetail = (props) => {
  const [loading, setLoading] = useState("");
  const [applyModal, setApplyModal] = useState(false);
  const [error, setError] = useState({});
  const [amount, setAmount] = useState({ value: "", currency: "INR" });
  const requirementId = props.applicationDetails.RequirementID;

  const navigate = useNavigate();
  const freelancerId = localStorage.getItem("userID");

  const ApplyRequirementAPI = () => {
    setLoading("loading");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    var formdata = new FormData();
    formdata.append("FreelancerId",freelancerId);
    formdata.append("RequirementId", requirementId);
    formdata.append("ProposalAmount", amount.value);
    formdata.append("Currency", amount.currency);
    formdata.append("Unit", "");
    formdata.append("Status", "Applied");
    formdata.append("PaymentStatus", "Paid");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(global.apiLink + "/api/rzadmin/applyrequirement", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setLoading("success");
          setTimeout(() => {
            setApplyModal(false);
            setLoading("");
          }, 1000);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const budget = () => {
    return (
      <>
        {props.applicationDetails.Currency}
        {props.applicationDetails.PraposalAmount}/
        {props.applicationDetails.Unit}
      </>
    );
  };

  const addOnType = (type) => {
    if (type === "Feature") {
      return (
        <>
          <FcRating className={classes.addonNameIcon} size={25} />
          {"Featured"}
        </>
      );
    }
    if (type === "Project Manager") {
      return (
        <>
          <FcRules className={classes.addonNameIcon} />
          {"Project Manager"}
        </>
      );
    }
    if (type === "Urgent") {
      return (
        <div className={classes.addonContainer}>
          <img
            src="/assets/application_detail/Urgent.svg"
            alt="urgent"
            className={classes.addonNameIcon}
          />

          <span className={classes.AddonName}> {"Urgent"}</span>
        </div>
      );
    }
    if (type === "Non Disclosure Agreement") {
      return (
        <div className={classes.addonContainer}>
          <img
            src="/assets/application_detail/NDA.svg"
            alt="NDA"
            className={classes.addonNameIcon}
          />
          <span className={classes.AddonName}> {"NDA"}</span>
        </div>
      );
    }
    if (type === "Access Waitlist") {
      return (
        <div className={classes.addonContainer}>
          <FcList className={classes.addonNameIcon} />
          <span className={classes.AddonName}> {"Pro"}</span>
        </div>
      );
    }
  };
  const applied = () => {
    if (props.applicationDetails.Applied >= 1) {
      return `Top ${props.applicationDetails.Applied} Applications received`;
    } else {
      return ` ${props.applicationDetails.Applied} Applications received`;
    }
  };

  const workTypeColor = (workType) => {
    if (props.applicationDetails.RequirementType === "commission") {
      return "Commission";
    }
    if (props.applicationDetails.RequirementType === "monthly-basis") {
      return "Monthly";
    }
    if (props.applicationDetails.RequirementType === "onetime") {
      return "One-Time";
    }

    if (props.applicationDetails.RequirementType === "contract") {
      return "Contract";
    }
  };
  const ModalView = () => {
    switch (loading) {
      case "":
        return (
          <>
            <RiTextInputs
              input={"SingleLineInput"}
              type={"text"}
              placeHolder={"Enter proposed amount"}
              label={"Amount"}
              value={amount.value}
              onInput={() => {
                error.value = " ";
              }}
              onChange={(e) => {
                e.target.value === "" || /^-?\d+$/.test(e.target.value)
                  ? setAmount({ ...amount, value: e.target.value })
                  : setError({ ...error, value: "Enter Valid Amount" });
              }}
            />
            <div className={classes.error_message}>{error.value}</div>
            <RiTextInputs
              input={"Dropdown"}
              placeHolder={"Choose your currency"}
              label={"Select Currency"}
              onChange={(e) =>
                setAmount({ ...amount, currency: e.target.value })
              }
              value={amount.currency}
            />
            <ActionButton
              onClick={() => ApplyRequirementAPI()}
              buttonText="Apply Now"
              buttonType="green"
            />
          </>
        );

      case "loading":
        return (
          <Loader
            className={classes.Loader}
            type="TailSpin"
            color="#1778f2"
            width={50}
            height={50}
          />
        );
      case "success":
        return (
          <div className={classes.Loader}>
            <AiFillCheckCircle size="50" color="green" />
          </div>
        );
      default:
        break;
    }
  };

  const MobileWebHandlerSwitch = (device) => {
    switch (device) {
      case "Mobile":
        return (
          <div className={classes.mainContainer}>
            <div className={classes.budgetContainer}>
              <div className={classes.budgetAndWork}>
                <img
                  src="/assets/application_detail/Budget.svg"
                  alt="Budget"
                  className={classes.ApplicationDetail_image}
                />
                <div className={classes.budgetAmount}>{budget()} </div>
                <div className={classes.budgetHeading}> Budget </div>
              </div>
              <div className={classes.budgetAndWork}>
                <img
                  src="/assets/application_detail/Wrok_type.svg"
                  alt="Work type"
                  className={classes.ApplicationDetail_image}
                />
                <div className={classes.workType}> {workTypeColor()} </div>
                <div className={classes.budgetHeading}> Work Type </div>
              </div>
              <div className={classes.budgetAndWork}>
                <img
                  src="/assets/application_detail/Clinet_type.svg"
                  alt="client type"
                  className={classes.ApplicationDetail_image}
                />
                <div className={classes.budgetAmount}>
                  {props.applicationDetails.IsCompany &&
                    props.applicationDetails.IsCompany === "0"
                    ? "Individual"
                    : props.applicationDetails.CompanyName}{" "}
                </div>
                <div className={classes.budgetHeading}> Client Type </div>
              </div>
            </div>
            <div className={classes.alignButton}>
              {props.applicationDetails.Status === "Closed" ? (
                         <></>

              ) : (
                <>
                  {props.applicationDetails.ClientRStatus === "Accept" && props.applicationDetails.hasFreelancerApplied === "True" ?
                    <div className={classes.AccpetedProposal}> <div className={classes.ClientRStatusText}>Proposal accepted</div>
                      <Link
                        to="/"
                        onClick={(e) => {
                          e.preventDefault();
                          encodeAndSendToChat(
                            freelancerId,
                            props.applicationDetails.clientId
                          );
                        }}
                        className={classes.AppliedCaseChat}
                      >
                        Chat
                      </Link>
                    </div>
                    :<>{props.applicationDetails.ClientRStatus === "" && props.applicationDetails.hasFreelancerApplied === "True"?
                    <div className={classes.AccpetedProposal}> <div className={classes.ClientRStatusText}>Applied</div></div>:
                    <>
                      {props.applicationDetails.ClientRStatus === "Reject" ? (
                        <>
                          <div className={classes.ClientRStatusTextR}>Proposal rejected</div>
                          <div className={classes.AppliedCase}>
                            <ActionButton
                              buttonText="Resend proposal"
                              buttonType="green"
                              onClick={props.openForm}
                            />
                            {/* <Link
                                to="/"
                                onClick={(e) => {
                                  e.preventDefault();
                                  encodeAndSendToChat(
                                    freelancerId,
                                    props.applicationDetails.clientId
                                  );
                                }}
                                className={classes.AppliedCaseChat}
                              >
                                Chat
                              </Link> */}
                          </div>
                        </>
                      ) : (<div className={classes.AppliedCase}>
                        <ActionButton
                          buttonText="Apply Now"
                          buttonType="green"
                          onClick={
                            localStorage.getItem("userLoggedIn")
                              ? props.openForm
                              : () => navigate("/login")
                          }
                        />
                        {/* <Link
                            to="/"
                            onClick={(e) => {
                              e.preventDefault();
                              encodeAndSendToChat(
                                freelancerId,
                                props.applicationDetails.clientId
                              );
                            }}
                            className={classes.AppliedCaseChat}
                          >
                            Chat
                          </Link> */}
                      </div>
                      )}
                    </>}
                    </>
                  }
                </>
              )}

            </div>

            {applyModal && (
              <CustomModalTheme onClose={() => setApplyModal(false)}>
                {ModalView()}
              </CustomModalTheme>
            )}
          </div>
        );
      default:
        return (
          <div className={classes.mainContainer}>
            <div className={classes.sponsoredPost}>
              <div className={classes.featuredListing}>
                {props.applicationDetails.Addons &&
                  props.applicationDetails.Addons.length > 0 &&
                  props.applicationDetails.Addons.map((item, index) => {
                    return (
                      <div className={classes.tagFeatured}>
                        {" "}
                        {addOnType(item.AddonName)}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className={classes.budgetContainer}>
              <div className={classes.budgetHeading}> Budget </div>
              <div className={classes.budgetAndWork}>
                <div className={classes.budgetAmount}>{budget()} </div>
                <div
                  className={`${classes.workType} ${classes[workTypeColor()]}`}
                >
                  {" "}
                  {workTypeColor()}
                </div>
              </div>
            </div>
            <div className={classes.contentAlign}>
              <div>{applied()} </div>

            </div>
            <div className={classes.alignButton}>
              {props.applicationDetails.Status === "Closed" ? (
                <div className={classes.closePostSection}><div className={classes.closePostStatus}>Closed</div></div>
              ) : (
                <>
                  {props.applicationDetails.ClientRStatus === "Accept" && props.applicationDetails.hasFreelancerApplied === "True" ?
                    <div className={classes.AccpetedProposal}> <div className={classes.ClientRStatusText}>Proposal accepted</div>
                      <Link
                        to="/"
                        onClick={(e) => {
                          e.preventDefault();
                          encodeAndSendToChat(
                            freelancerId,
                            props.applicationDetails.clientId
                          );
                        }}
                        className={classes.AppliedCaseChat}
                      >
                        Chat
                      </Link>
                    </div>
                    
                    :<>{props.applicationDetails.ClientRStatus === "" && props.applicationDetails.hasFreelancerApplied === "True"?
                    <div className={classes.AccpetedProposal}> <div className={classes.ClientRStatusText}>Applied</div></div>:
                    <>
                      {
                        (props.applicationDetails.ClientRStatus === "Reject") ? (
                          <>
                            <div className={classes.ClientRStatusTextR}>Proposal rejected</div>
                            <div className={classes.AppliedCase}>
                              <ActionButton
                                buttonText="Resend proposal"
                                buttonType="green"
                                onClick={props.openForm}
                              />
                              {/* <Link
                                to="/"
                                onClick={(e) => {
                                  e.preventDefault();
                                  encodeAndSendToChat(
                                    freelancerId,
                                    props.applicationDetails.clientId
                                  );
                                }}
                                className={classes.AppliedCaseChat}
                              >
                                Chat
                              </Link> */}
                            </div></>
                        ) : (<div className={classes.AppliedCase}>
                          <ActionButton
                            buttonText="Apply Now"
                            buttonType="green"
                            onClick={
                              localStorage.getItem("userLoggedIn")
                                ? props.openForm
                                : () => navigate("/login")
                            }
                          />
                          {/* <Link
                            to="/"
                            onClick={(e) => {
                              e.preventDefault();
                              encodeAndSendToChat(
                                freelancerId,
                                props.applicationDetails.clientId
                              );
                            }}
                            className={classes.AppliedCaseChat}
                          >
                            Chat
                          </Link> */}
                        </div>
                        )}
                     </>}
                    </>
                    }
                </>


              )}

            </div>
            

            {applyModal && (
              <CustomModalTheme onClose={() => setApplyModal(false)}>
                {ModalView()}
              </CustomModalTheme>
            )}
          </div>
        );
    }
  };

  return <>{MobileWebHandlerSwitch(props.device)}</>;
};

export default ApplicationDetail;
