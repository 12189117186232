import React, { useEffect } from 'react'
import { FcSportsMode } from 'react-icons/fc';
import ActionButton from '../Components/ActionButton';
import classes from './NoApplication.module.css';
import { useNavigate } from 'react-router-dom';
import { pageViewTracker } from '../Components/GoogleTracking';
const NoApplication = (props) => {
    const navigate = useNavigate()
    useEffect(() => {
      pageViewTracker();
    }, [])
    
    return (
        <div className={classes.mainContainer}>
            <div className={classes.contentWeb}>
                <FcSportsMode size={"50px"} />
                <div className={classes.headingFont}>{props.Text} </div>
                <div className={classes.buttonWidth}>
                    <ActionButton buttonText="Apply Now" buttonType="green" onClick={() => { navigate('/requirementList') }} />
                </div>
            </div>
        </div>

    )
}

export default NoApplication;