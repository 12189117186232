import React, { useContext, useEffect, useState } from 'react'
import classes from './Login.module.css';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../Context/UserContext';
import { pageViewTracker } from '../../Components/GoogleTracking';

const RegisterModal = React.lazy(() => import('../../Components/Modals/RegisterModal'));
const ActionButton = React.lazy(() => import('../../Components/ActionButton'));
const RiTextInputs = React.lazy(() => import('../../Components/PostRequirement/RiTextInputs'));
const LoginOptions = React.lazy(() => import('../../Components/RegistrationComponents/LoginOptions'));

const Login = () => {
    const { isUserLoggedIn, setIsUserLoggedIn } = useContext(UserContext)
   
    const [userName, setUserName] = useState();
    const [error, setError] = useState({});
    const [password, setPassword] = useState();
    const [credientialCheck, setCredientialCheck] = useState(true)
   
    const [isLoading, setIsLoading] = useState(false)
    const [modalShow, setModalShow] = useState(false);

    const afterAuthRedirectUrl = sessionStorage.getItem("afterAuthRedirectUrl");
   

   
    const formValidation = () => {
        let errors = {};
        let isValid = true;

  
        if (!userName || userName === '') {
            errors.userName = "Please enter your Email / Mobile number"
            isValid = false;
        }
        if (!password || password===" ") {
            errors.emptydetails = "Please enter your password"
            isValid = false;
        }
        else {
        }
        setError(errors)
        return isValid;
    }
    let navigate = useNavigate();


    const UserLogin = () => {
        setIsLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
        myHeaders.append("Content-Type", "application/json");

        var raw = (userName.includes("@")) ? JSON.stringify({
            "Password": (password),
            "Email": (userName),
        })
            :
            JSON.stringify({
                "Password": (password),
                "Mobile": (userName)

            })
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(global.apiLink+"/api/freelancerapp/rozgaarapi/UserLogin", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "SUCCESS" && result.status_code === 200) {
                
               
                    sessionStorage.setItem("userID", result.data.FreelancerId);
                    sessionStorage.setItem('USERMOBILENO', result.data.Mobile)
                    sessionStorage.setItem('USEREMAIL', result.data.Email)
                    sessionStorage.setItem('FirstName', result.data.FirstName)
                    sessionStorage.setItem('LastName', result.data.LastName)
                    sessionStorage.setItem('ProfilePicture', result.data.ProfilePicture)
                    
                    localStorage.setItem('userLoggedIn', true)
                    localStorage.setItem("IsLoginType","Freelancer")

                    localStorage.setItem('userID', result.data.FreelancerId)
                    localStorage.setItem('USERMOBILENO', result.data.Mobile)
                    localStorage.setItem('USEREMAIL', result.data.Email)
                    localStorage.setItem('FirstName', result.data.FirstName)
                    localStorage.setItem('LastName', result.data.LastName)
                    localStorage.setItem('UserName', result.data.UserName)
                    localStorage.setItem('ProfilePicture', result.data.ProfilePicture)
                    sessionStorage.removeItem("afterAuthRedirectUrl")
                    setIsUserLoggedIn(true)
                    navigate(afterAuthRedirectUrl ? afterAuthRedirectUrl : "/FreelancerWorkPlace")
                }

               
                else{
                    setCredientialCheck(false)
                }
            })
            .catch(error => console.log('error', error))
            .finally(() => { setIsLoading(false) });
    }

    // const SocialLogin = (res) => {
    //     var myHeaders = new Headers();
    //     myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");
    //     myHeaders.append("Content-Type", "application/json");

    //     var raw = JSON.stringify({
    //         "Email": ('profileObj' in res ? res.profileObj.email : res.email),
    //         // "Mobile": (res.profileObj.mobile),
    //         // "RequestUrl": "url"
    //     });

    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: raw,
    //         redirect: 'follow'
    //     };

    //     fetch(global.apiLink + "/api/rozgaarapi/SocialLogin", requestOptions)
    //         .then(response => response.json())
    //         .then(result => {
    //             if (result.status === "SUCCESS" && result.status_code === 200 && result.message === "data successfully created") {
    //                 sessionStorage.setItem("googleBiEmail", ('profileObj' in res ? res.profileObj.email : res.email))
    //                 sessionStorage.setItem("userID", result.data.UserId)
    //                 navigate("/SocialLogin")
    //             }
    //             if (result.status === "SUCCESS" && result.status_code === 200 && result.message === "data successfully updated" && result.data.Country === "") {
    //                 sessionStorage.setItem("googleBiEmail", ('profileObj' in res ? res.profileObj.email : res.email))
    //                 sessionStorage.setItem("userID", result.data.UserId)
 
    //                 navigate("/SocialLogin")
    //             }
    //             if (result.status === "SUCCESS" && result.status_code === 200 && result.message === "data successfully updated" && result.data.Country !== "") {
    //                 sessionStorage.setItem("googleBiEmail", ('profileObj' in res ? res.profileObj.email : res.email))
    //                 sessionStorage.setItem("userID", result.data.UserId)

    //                 sessionStorage.setItem('USERMOBILENO', result.data.Mobile)
    //                 sessionStorage.setItem('USEREMAIL', result.data.Email)
    //                 sessionStorage.setItem('FirstName', result.data.FirstName)
    //                 sessionStorage.setItem('LastName', result.data.LastName)
    //                 sessionStorage.setItem('ProfilePicture', result.data.ProfilePicture)
    //                 localStorage.setItem('userLoggedIn', true)
    //                 localStorage.setItem("googleBiEmail", ('profileObj' in res ? res.profileObj.email : res.email))
    //                 localStorage.setItem('USERMOBILENO', result.data.Mobile)
    //                 localStorage.setItem('USEREMAIL', result.data.Email)
    //                 localStorage.setItem('FirstName', result.data.FirstName)
    //                 localStorage.setItem('LastName', result.data.LastName)
    //                 localStorage.setItem("IsLoginType","Freelancer")
    //                 localStorage.setItem('UserName', result.data.UserName)
    //                 localStorage.setItem("userID", result.data.UserId)
    //                 localStorage.setItem('ProfilePicture', result.data.ProfilePicture)
    //                 navigate(afterAuthRedirectUrl ? afterAuthRedirectUrl :"/workProfile")
    //                 sessionStorage.removeItem("afterAuthRedirectUrl")
    //                 setIsUserLoggedIn(true) 
    //                 setModalShow(true);
    //                 //    navigate("/")
    //             }
    //         }
    //         )
    //         .catch(error => console.log('error', error));
    // }
    useEffect(() => {
        pageViewTracker();
        if (isUserLoggedIn) {
            navigate("/FreelancerWorkPlace")
        }
    }, [])
    const handleSubmit=(e)=>{
  
        if (e.key === 'Enter') {
          formValidation()
          ? UserLogin()
          : console.log("something went wrong");
        }
      }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Best Remote work and freelance projects at RozgaarIndia.com via Times Ascent`}</title>
                <meta name="description" content={`Rozgaar India is one of India's Leading online service marketplace for remote work and freelance projects. You can find the best skilled online service providers at RozgaarIndia.com`} />
                <link rel="canonical" href="https://www.rozgaarindia.com/login" /> 
            </Helmet>
            <React.Suspense fallback={<div className="pageLoader"><Loader type="TailSpin" color="red" height={80} width={80} className="text-center my-5" /></div>}>
                <div className={classes.Pagewrapper}>
                    <div className={classes.Maincontainer_border}>
                        {modalShow && <RegisterModal heading={"Login successfully"} onClick={() => navigate("/FreelancerWorkPlace")} />}
                        <div className={classes.mainContainer}>
                            <h1 >Welcome back!</h1>
                            <div className={classes.loginLink}>Don't have an account?<Link to="/signup" className={classes.terms_link}> Signup</Link></div>
                            <div className={classes.input_container}>

                                <RiTextInputs
                                    input={"SingleLineInput"}
                                    type={"text"}
                                    onInput={() => { error.userName = " " }}
                                    placeHolder={"Email / Mobile"}
                                    onChange={(e) => setUserName(e.target.value)}
                                    value={userName}
                                    onKeyPress={handleSubmit}
                                />
                               
                                   
                                <div className={classes.error_message}>{error.userName}</div>

                                <RiTextInputs
                                    input={"password"}
                                    placeHolder={"Password"}
                                    onInput={() => { error.emptydetails=' ' }}
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    onKeyPress={handleSubmit}
                                />
                                <div className={classes.error_message}>{error.emptydetails}</div>

                                {!credientialCheck &&
                                    <div className={classes.error_message}>
                                        <div>Your login details and password do not match</div>
                                    </div>}
                                <div className={classes.loginWith_Otp}>   <Link to="/loginWithOTP" className={classes.loginWith_Otp}>Login with OTP</Link></div>
                              
                                <div className={classes.error_message}><div >{error.checked}</div></div>
                            </div>
                            <div >
                            <div onClick={(e) => { e.preventDefault(); formValidation() ? UserLogin() : console.log("Something's wrong"); }}>

                                    {isLoading ?
                                        (
                                            <div className={classes.LoadingBtn}>
                                                <Loader type="TailSpin" color="white" width={20} height={18} />
                                            </div>
                                        ) : (
                                            <ActionButton buttonText={"Login"} />
                                        )}
                                </div>

                            </div>

                            <div onClick={() => navigate("/ForgotPasswordPreScreen")}  ><div className={classes.forgot_password}>Forgot password?</div></div>
                            <div className={classes.signup_link}>Don't have an account?<Link to="/signup" className={classes.terms_link}> Signup</Link></div>
                         
                            <a href ="https://rozgaarindia.com/" className={classes.freelancerlink} target="_blank" rel="noreferrer"> Login as Client</a>
                        </div>
                    </div>
                </div>
            </React.Suspense>
        </>
    )
}

export default Login
