import NoApplication from '../../Screens/NoApplication';
import PublicRecRequirementCard from '../ApplyRequirements/PublicRecRequirmentCard';
import classes from './MyApplicationSnippet.module.css'

const MyApplicationSnippet = (props) => {


    return (
        <div className={classes.mainContainer}>
            <div className={classes.mainHeading}> My Applications</div>

            {props.postArequirementDetails.length ?
                <PublicRecRequirementCard requirementsCard={props.postArequirementDetails} />
                :

                <NoApplication Text={"You haven't applied on any requirement yet"} />
            }
        </div>
    )


}

export default MyApplicationSnippet;