import classes from './LeftMenu.module.css';
import React from 'react';
import { FcCustomerSupport, FcBriefcase, FcPositiveDynamic, FcSalesPerformance, FcSms,  FcRules,  FcClock, FcManager,  FcRating,  FcApprove } from "react-icons/fc";

import { Link } from 'react-router-dom';
import { BiChevronDown } from "react-icons/bi";



const LeftMenuDisabled = (props) => {






  const menuOptions = (menu) => {
   
    props.option(menu)
  }


  return (
    <div>


      <div className={classes.rightMenuMain}>



        <div className={classes.leftMenuDisabled}> <FcBriefcase size='18px' />
          <div className={classes.menuTextDisabled}  >Work Place</div>

        </div>


        <div className={classes.leftMenuDisabled}> <FcRules size='18px' />
          <div className={classes.menuTextDisabled} >Find work</div>
        </div>



        <div className={classes.leftMenuDisabled}> <FcSalesPerformance size='18px' />
          <div className={classes.menuTextDisabled} onClick={() => { menuOptions('Revenue') }}>Applications</div>
        </div>


        <div className={classes.leftMenuDisabled}> <FcPositiveDynamic size='18px' />
          <div className={classes.menuTextDisabled} onClick={() => { menuOptions('Sales') }}>Income</div>
        </div>


        <div className={classes.leftMenuDisabled}> <FcSms size='18px' />
          <div className={classes.menuTextDisabled} onClick={() => { menuOptions('Chat') }}>Chat</div>
        </div>


        <div className={classes.leftMenuDisabled}> <FcClock size='18px' />
          <div className={classes.menuTextDisabled} onClick={() => { menuOptions('Notifications') }} >Notifications</div>
        </div>

        <Link to="/workProfile" className={classes.linkdesign}>
          <div className={classes.leftMenu}> <FcManager size='18px' />
            <div className={classes.menuText} >Work Profile</div>  <BiChevronDown className={classes.downIcon} size={20} /> </div>
        </Link>

        {
          (window.location.pathname === '/workProfile' ||
            window.location.pathname === '/personalDetails' ||
            window.location.pathname === '/professionAndEducation' ||
            window.location.pathname === '/portfolio' ||
            window.location.pathname === '/companyDetails') &&
          <div className={classes.subMenu}>
            <Link to="/workProfile" className={classes.linkdesign}>
              <div className={classes.leftMenu}>
                <div className={classes.menuText} >Work Info</div>   </div>
            </Link>
            <Link to="/personalDetails" className={classes.linkdesign}>
              <div className={classes.leftMenu}>
                <div className={classes.menuText} >Personal Details</div>   </div>
            </Link>
            <Link to="/professionAndEducation" className={classes.linkdesign}>
              <div className={classes.leftMenu}>
                <div className={classes.menuText} >Profession & Education</div>   </div>
            </Link>
            <Link to="/portfolio" className={classes.linkdesign}>
              <div className={classes.leftMenu}>
                <div className={classes.menuText} >Portfolio</div>   </div>
            </Link>

            <Link to="/companyDetails" className={classes.linkdesign}>
              <div className={classes.leftMenu}>
                <div className={classes.menuText} >Company Details</div>   </div>
            </Link>

          </div>
        }








        <Link to="/FreelancerPackage" className={classes.linkdesign}>
          <div className={classes.leftMenu}> <FcRating size='18px' />
            <div className={classes.menuText} onClick={() => { menuOptions('Membership') }} >Membership</div>
          </div>
        </Link>

        <Link to="/KycForm" className={classes.linkdesign}>
          <div className={classes.leftMenu}> <FcApprove size='18px' />
            <div className={classes.menuText} onClick={() => { menuOptions('KYC') }} >KYC</div>
          </div>
        </Link>

        <Link to="/DashboardContact" className={classes.linkdesign}>
          <div className={classes.leftMenu}> <FcCustomerSupport size='18px' />
            <div className={classes.menuText}>Contact support</div>

          </div>
        </Link>

      </div>

    </div>
  )
}

export default LeftMenuDisabled;