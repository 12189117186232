import React, { useState } from "react";
import { FiMapPin } from "react-icons/fi";
import classes from "./RequirementDetailArea.module.css";
import { FcRules, FcList } from "react-icons/fc";
import { encodeAndSendToChat } from "../../App";
import { Link, useNavigate } from "react-router-dom";
import CustomModalTheme from "./CustomModalTheme";
import { AiFillCheckCircle } from "react-icons/ai";
import RiTextInputs from "../PostRequirement/RiTextInputs";
import ActionButton from "../ActionButton";
import Loader from "react-loader-spinner";



const RequirementDetailArea = (props) => {

  const [applyModal, setApplyModal] = useState(false);
  const [amount, setAmount] = useState({ value: "", currency: "INR" });
  const [error, setError] = useState({});
  const [loading, setLoading] = useState("");

  const navigate = useNavigate();
  const freelancerId = localStorage.getItem("userID");
  const requirementId = props.requirementDetail.RequirementID;

  const timeSince = (date) => {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return interval + " years";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + " months";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + " days";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + " hours";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  };

  const ApplyRequirementAPI = () => {
    setLoading("loading");
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3"
    );
    var formdata = new FormData();
    formdata.append("FreelancerId", freelancerId);
    formdata.append("RequirementId", requirementId);
    formdata.append("ProposalAmount", amount.value);
    formdata.append("Currency", amount.currency);
    formdata.append("Unit", "");
    formdata.append("Status", "Applied");
    formdata.append("PaymentStatus", "Paid");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(global.apiLink + "/api/rzadmin/applyrequirement", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setLoading("success");
          setTimeout(() => {
            setApplyModal(false);
            setLoading("");
          }, 1000);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const UserName = (firstName, lastName) => {

    return (
      <>
        {firstName != null ? firstName.charAt(0).toUpperCase() +
          firstName.slice(1) +
          " " +
          lastName.charAt(0).toUpperCase() : <></>}
      </>

    );
  };

  const addOnType = (type) => {
    if (type === "Feature") {
      return (
        <div className={classes.addonContainer}>
          <img src="/assets/application_detail/featured.svg" alt="featured" className={classes.addonNameIcon} />

          <span className={classes.AddonName}>  {"Featured"}</span>
        </div>
      );
    }
    if (type === "Urgent") {
      return (
        <div className={classes.addonContainer}>
          <img src="/assets/application_detail/Urgent.svg" alt="urgent" className={classes.addonNameIcon} />

          <span className={classes.AddonName}>  {"Urgent"}</span>
        </div>
      );
    }
    if (type === "Project Manager") {
      return (
        <div className={classes.addonContainer}>
          <FcRules className={classes.addonNameIcon} />
          <span className={classes.AddonName}>  {"Project Manager"}</span>
        </div>
      );
    }
    if (type === "Non Disclosure Agreement") {
      return (
        <div className={classes.addonContainer}>
          <img src="/assets/application_detail/NDA.svg" alt="NDA" className={classes.addonNameIcon} />
          <span className={classes.AddonName}>  {"NDA"}</span>
        </div>
      );
    }
    if (type === "Access Waitlist") {
      return (
        <div className={classes.addonContainer}>
          <FcList className={classes.addonNameIcon} />
          <span className={classes.AddonName}>  {"Pro"}</span>
        </div>
      );
    }
  }

  const ModalView = () => {
    switch (loading) {
      case "":
        return (
          <>
            <RiTextInputs
              input={"SingleLineInput"}
              type={"text"}
              placeHolder={"Enter proposed amount"}
              label={"Amount"}
              value={amount.value}
              onInput={() => {
                error.value = " ";
              }}
              onChange={(e) => {
                e.target.value === "" || /^-?\d+$/.test(e.target.value)
                  ? setAmount({ ...amount, value: e.target.value })
                  : setError({ ...error, value: "Enter Valid Amount" });
              }}
            />
            <div className={classes.error_message}>{error.value}</div>
            <RiTextInputs
              input={"Dropdown"}
              placeHolder={"Choose your currency"}
              label={"Select Currency"}
              onChange={(e) =>
                setAmount({ ...amount, currency: e.target.value })
              }
              value={amount.currency}
            />
            <ActionButton
              onClick={() => ApplyRequirementAPI()}
              buttonText="Apply Now"
              buttonType="green"
            />
          </>
        );

      case "loading":
        return (
          <Loader
            className={classes.Loader}
            type="TailSpin"
            color="#1778f2"
            width={50}
            height={50}
          />
        );
      case "success":
        return (
          <div className={classes.Loader}>
            <AiFillCheckCircle size="50" color="green" />
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.timeApplyBtn}>
      <div className={classes.dateAndPostType}> {timeSince(new Date(props.requirementDetail.UpdatedDate))} ago</div>


      <div className={classes.alignButton}>
              {props.requirementDetail.Status === "Closed" ? (
                               <div className={classes.closePostStatus}>Closed</div>

              ) : (
                <>
                  {props.requirementDetail.ClientRStatus === "Accept" && props.requirementDetail.hasFreelancerApplied === "True" ?
                    <div className={classes.AccpetedProposal}> <div className={classes.ClientRStatusText}>Proposal accepted</div>
                    
                      <Link
                        to="/"
                        onClick={(e) => {
                          e.preventDefault();
                          encodeAndSendToChat(
                            freelancerId,
                            props.requirementDetail.clientId
                          );
                        }}
                        className={classes.AppliedCaseChat}
                      >
                        Chat
                      </Link>
                    </div>
                    :<>{props.requirementDetail.ClientRStatus === "" && props.requirementDetail.hasFreelancerApplied === "True"?
                    <div className={classes.AccpetedProposal}> <div className={classes.ClientRStatusText}>Applied</div></div>:
                    <>
                      {props.requirementDetail.ClientRStatus === "Reject" ? (
                        <>
                          <div className={classes.ClientRStatusTextR}>Proposal rejected</div>
                          <div className={classes.AppliedCase}>
                            <ActionButton
                              buttonText="Resend proposal"
                              buttonType="green"
                              onClick={props.openForm}
                            />
                            {/* <Link
                                to="/"
                                onClick={(e) => {
                                  e.preventDefault();
                                  encodeAndSendToChat(
                                    freelancerId,
                                    props.requirementDetail.clientId
                                  );
                                }}
                                className={classes.AppliedCaseChat}
                              >
                                Chat
                              </Link> */}
                          </div>
                        </>
                      ) : (<div className={classes.AppliedCase}>
                        <ActionButton
                          buttonText="Apply Now"
                          buttonType="green"
                          onClick={
                            localStorage.getItem("userLoggedIn")
                              ? props.openForm
                              : () => navigate("/login")
                          }
                        />
                        {/* <Link
                            to="/"
                            onClick={(e) => {
                              e.preventDefault();
                              encodeAndSendToChat(
                                freelancerId,
                                props.requirementDetail.clientId
                              );
                            }}
                            className={classes.AppliedCaseChat}
                          >
                            Chat
                          </Link> */}
                      </div>
                      )}
                    </>}
                    </>
                  }
                </>
              )}

            </div>

            {applyModal && (
              <CustomModalTheme onClose={() => setApplyModal(false)}>
                {ModalView()}
              </CustomModalTheme>
            )}
      </div>
      <div className={classes.featuredListing}>
        {props.requirementDetail.Addons &&
          props.requirementDetail.Addons.map((item, index) => {
            return (
              <div className={classes.tagFeatured}>
                {" "}
                {addOnType(item.AddonName)}
              </div>
            );
          })}
      </div>
      <div className={classes.displayTitleAndLogo}>
        <div className={classes.title}>{props.requirementDetail.Title}</div>
        <div className={classes.CompanyLogowithText}>
          {props.requirementDetail.IsCompany === "1" && props.requirementDetail.CompanyLogo !== "" ? (

            <div className={classes.logoPlacement}>
              <img
                src={props.requirementDetail.CompanyLogo}
                alt="Company_Logo"
                className={classes.riLogo}
                width={120}
              />
            </div>


          ) : (
            <></>
          )}
          <div className={classes.CompanyNamewithUserName}>
            {props.requirementDetail.IsCompany === "1" && props.requirementDetail.CompanyName !== "" ? (

              <div className={classes.Comapany_Name}> {props.requirementDetail.CompanyName} </div>

            ) : (
              <></>
            )}

         
          <div>{UserName(props.requirementDetail.FirstName, props.requirementDetail.LastName)}</div>
          </div>
        </div>
      </div>
      <div className={classes.dateAndPostTypeSection}>
        {(props.requirementDetail.FreelancerPolicy === "Onsite" || props.requirementDetail.FreelancerPolicy === "Hybrid") && props.requirementDetail.Country || props.requirementDetail.State || props.requirementDetail.City ?
          (
            <div className={classes.LocationSection}><FiMapPin size={15} /> &nbsp; <span className={classes.LocationText}>{props.requirementDetail.Country},{props.requirementDetail.State},{props.requirementDetail.City}</span></div>) : <></>
        }
        
      </div>

      <div className={classes.contentIndetail}>
        {" "}
        {props.requirementDetail.Description}
      </div>

      <div className={classes.skilltagsContainer}>
        {" "}
        {props.requirementDetail.Skills &&
          props.requirementDetail.Skills.map((item, i) => {
            return <div className={classes.skillTags}> {item.Skill.charAt(0).toUpperCase() +
              item.Skill.slice(1)}</div>;
          })}
      </div>
    </div>
  );
};

export default RequirementDetailArea;
