import React from 'react';
import classes from './SuccessModal.module.css';
import { MdPendingActions } from "react-icons/md";
import { BsCheck, BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import ActionButton from '../ActionButton';


const SuccessModal = (props) => {
    let navigate = useNavigate();


    const SuccessModalType = (type) => {
        switch (type) {
            case "ProposalSuccessModal":
                return (
                    <div className={classes.modalContainer}>
                         <div className={classes.backdrop} onClick={props.BackdropClick} ></div>
                        <div className={classes.modal}>
                            <div className={classes.icon} >
                                <BsCheck color='white' size={60} className={classes.checkIconMain} />

                            </div>
                            <div className={classes.heading}>Congratulations !</div>
                            <div className={classes.subHeading}>{props.heading}</div>
                            <div className={classes.okBtnContainer}><ActionButton onClick={props.onSubmit}
                                buttonText="OK"
                                buttonType="small" /></div>


                        </div>
                    </div>
                );
                case "LogoutModal":
                    return (
                        <>

                       
                            
                            
                            <div className={classes.modalContainer}>
                                <div className={classes.backdrop} onClick={props.BackdropClick} ></div>
                                <div className={classes.modalLogout}>
                                   
                                {/* <div className={classes.heading}>Congratulations !</div> */}
                                    <div className={classes.subHeading}>Are you sure want to logout ?</div>

                                  
                                        <div className={classes.actionBtnContainerLogout}>
                                            <div onClick={props.YesButton} className={classes.dashboardBtnYes}> <span className={classes.btnText}>Yes</span></div>
                                            <div onClick={props.NoButton} className={classes.joinBtnNo}><span>No</span></div>
                                        </div>
                                     
                                </div>
                            </div>
                        
                    </>
                    );
            default:
                return (
                    <>

                        {props.mandatoryFieldsLeft.length>0 ? <div className={classes.modalContainer}>
                            <div className={classes.modal}>
                                <div className={classes.icon} >
                                    <MdPendingActions color='green' size={100} />

                                </div>
                                <div className={classes.heading}>Incomplete Profile</div>
                                <div className={classes.subHeading}>Please fill the following information</div>
                                {props.mandatoryFieldsLeft.map((item, i) => {
                                    return (
                                        <div className={classes.mandatoryFields}>{item}</div>
                                    )

                                })
                                }

                                <div onClick={() => navigate('/FreelancerWorkPlace')} className={classes.okBtn}>OK</div>
                            </div>
                        </div>
                            :
                            <div className={classes.modalContainer}>
                                <div className={classes.backdrop} onClick={props.BackdropClick} ></div>
                                <div className={classes.modal}>
                                    <div className={classes.icon} >
                                        <BsCheck color='white' size={60} className={classes.checkIconMain} />

                                    </div>
                                    <div className={classes.heading}>Congratulations !</div>
                                    <div className={classes.subHeading}>You have successfully updated your profile</div>

                                  
                                        <div className={classes.actionBtnContainer}>
                                            <div onClick={() => navigate('/FreelancerWorkPlace')} className={classes.dashboardBtn}> <BsArrowLeftShort size={25} className={classes.dashboardIcon} /><span className={classes.btnText}>Go to dashboard</span></div>
                                            <div onClick={() => navigate('/FreelancerPackage')} className={classes.joinBtn}><span>Join membership</span><BsArrowRightShort size={25} className={classes.dashboardIcon} /></div>
                                        </div>
                                       <div className={classes.cancelBtnContainer} onClick={props.CancelButton}>Cancel</div>
                                </div>
                            </div>
                        }
                    </>
                );
        }
    }
    return <div>{SuccessModalType(props.SuccessModalType)}</div>;
};

export default SuccessModal;