import React, { useState, useEffect } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import classes from './NewRequirementCard.module.css'
import Skeleton from 'react-loading-skeleton';
import HeadingFreelancer from './HeadingFreelancer';
const ActionButton = React.lazy(() => import('../ActionButton'));


const NewRequirementCard = (props) => {
    let navigate = useNavigate()
  
    const [skeletonDefaultArr, setSkeletonDefaultArr] = useState([]);
    useEffect(() => {
        let arr = [];
        for (var i = 1; i <= 3; i++) {
            arr.push(i)
        }
        setSkeletonDefaultArr(arr)
    }, [])
    const statusHandler = (status) => {
        if (status === "Pending") {
            return "Under Review"
        }
        if (status === "Draft") {
            return "Draft"
        }
        if (status === "Approved") {
            return "Active"
        }
    }
    const workTypeColor = (workType) => {
        if (workType === "commission") {
            return "Commission"
        }
        if (workType === "monthly-basis") {
            return "Monthly"
        }
        if (workType === "onetime") {
            return "One-Time"
        }

        if (workType === "contract") {
            return "Contract"
        }
    }
    const timeSince = (date) => {

        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
            return interval + " years";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
            return interval + " months";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
            return interval + " days";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
            return interval + " hours";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
            return interval + " minutes";
        }
        return Math.floor(seconds) + " seconds";
    }

    const companyName = (firstName, lastName) => {
        return firstName.charAt(0).toUpperCase() + firstName.slice(1) + " " + lastName.charAt(0).toUpperCase()
    }
    const mainSkillHandler = (skill) => {
        let firstSkill = skill[0]

        let mainSkill = firstSkill.Skill.charAt(0).toUpperCase() + firstSkill.Skill.slice(1)
        return mainSkill
    }

    const budget = (currency, budget, unit, workType) => {
        return <>{currency}{budget} <span className={classes.budgetUnit}>/{unit}</span></>
    }
    return (
        <>
            <React.Suspense fallback={<div className="pageLoader"><Loader type="TailSpin" color="red" height={80} width={80} className="text-center my-5" /></div>}>
                <div className={classes.mainContainer}>

                    {props.heading ? <div className={classes.headingFromProps}> {props.heading}</div> :
                        <HeadingFreelancer heading="Apply to the best freelance projects " />
                    }

                    {
                        props.isLoading ?
                            <div className={classes.webLayout}>
                                {skeletonDefaultArr.map(() => {
                                    return (
                                        <div className={classes.mainCardContainer}>
                                            <> <div className={classes.cardHeightSetting} >
                                                <div className={classes.skillImageContainer}>
                                                    <div className={classes.skillTitleContainer}>
                                                        <div className={classes.workPolicyAndWorkType}>
                                                            <Skeleton width={100} /> &nbsp;
                                                            <Skeleton width={100} />
                                                        </div>
                                                        <div className={classes.skillContainer}>
                                                            <Skeleton width={100} />
                                                        </div>
                                                        <div className={classes.skillContainer}>
                                                            <Skeleton width={100} />
                                                        </div>
                                                        <div className={classes.compnayLocationDate}>
                                                            <Skeleton width={180} />
                                                        </div>
                                                        <div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={classes.statusAnddate}>
                                                    <Skeleton width={100} /> &nbsp;
                                                    <Skeleton width={100} />
                                                </div>
                                                <div className={classes.budget} ><Skeleton width={200} /></div>
                                            </div>
                                                <div className={classes.actionContainer}>
                                                    <div className="border"> </div>
                                                    <div className={classes.iconAndButtonContainer}>
                                                        <div className={classes.ActionButton_skeleton}>
                                                            <Skeleton height={25} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        </div>
                                    )
                                })}
                            </div>
                            :
                            <div className={classes.webLayout}>
                                {props.RequirementData && props.RequirementData.slice(0, 4).map((item, index) => {
                                    return (
                                        <div className={classes.mainCardContainer}>
                                            {props.isLoading ? <div className={classes.pageLoader}><Loader type="TailSpin" color="#1678f2" height={80} width={80} /></div> :
                                                <> <div className={classes.cardHeightSetting} onClick={() => navigate("/requirementDetail/" + item.RequirementID)} >
                                                    <div className={classes.skillImageContainer}>
                                                      
                                                        <div className={classes.skillTitleContainer}>
                                                            <div className={classes.workPolicyAndWorkType}>
                                                                <div className={classes.workPolicy}> {item.FreelancerPolicy} </div>
                                                                <div className={`${classes.workType} ${classes[workTypeColor(item.RequirementType)]}`}> {workTypeColor(item.RequirementType)}</div>
                                                            </div>
                                                            <div className={classes.skillContainer}>
                                                                {mainSkillHandler(item.Skills)}
                                                            </div>
                                                            <div className={classes.compnayLocationDate}>
                                                                {item.IsCompany === "0" ? companyName(item.FirstName, item.LastName) : item.CompanyName}
                                                            </div>
                                                            <div >
                                                              
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={classes.titleArea}>{item.Title.length > 70 ? <div>{item.Title.substring(0, 80)} ...</div> : <>{item.Title}</>}</div>
                                                    <div className={classes.statusAnddate}>
                                                        <div className={classes.workStatus}> {statusHandler(item.Status)} </div>
                                                        <div className={classes.postedDate}>{timeSince(new Date(item.UpdatedDate))} ago</div>
                                                    </div>
                                                    <div className={classes.budget} >{budget(item.BudgetCurrency, item.Budget, item.BudgetUnit, item.RequirementType)} </div>

                                                </div>
                                                    <div className={classes.ActionButton}>
                                                        <Link to={"/requirementDetail/" + item.RequirementID} className={classes.PARBtn_Link}> <ActionButton buttonType="smallForReqCard" buttonText="View" /></Link>

                                                    </div>
                                                </>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                    }
                    <Link to="/requirementList" className={classes.PARBtn_Link}>
                        <div className={classes.knowMore}> View More <AiOutlineArrowRight className={classes.iconArrow} /> </div>
                    </Link>
                </div>
            </React.Suspense>
        </>
    )
}

export default NewRequirementCard;

