import React from "react";
import classes from "./HireType.module.css";

const HireType = () => {
  const hireType = [
    {
      type: "One Time Task",
      border: "2px solid #375BA7",
      detailType:
        "Work remotely for one-time gig. E.g Work as a graphic designer to create a logo for a client ",
    },
    {
      type: "Monthly Basis",
      border: "2px solid #1C5652",
      detailType:
        "Work as freelancer  on monthly term. E.g  Social Media handler to post for client everyday for 2 months ",
    },
    {
      type: "Contract Work",
      border: "2px solid #4B6A35",
      detailType:
        "Work for a short-term or a long-term project. E.g  React website developer to work on an E-commerce Project",
    },
    {
      type: "Commission Based",
      border: "2px solid #1C5652",
      detailType:
        "Work as remote freelancer on commission. E.g POS agents for providing verified leads and earn on maturity ",
    },
  ];
  return (
    <div className={classes.hireTypeMainDiv}>
      {hireType.map((item, index) => {
        return (
          <div className={classes.mapDiv}>
            <div className={classes.typeDiv}>{item.type}</div>
            <div
              style={{ border: `${item.border}` }}
              className={classes.border}
            ></div>
            <div className={classes.detailDiv}>{item.detailType}</div>
          </div>
        );
      })}
    </div>
  );
};

export default HireType;
