import React, { useState } from "react";
import classes from "../RITheme.module.css";
import { BsEyeSlash, BsCaretUpFill, BsCaretDownFill } from "react-icons/bs";

import { AiOutlineEye } from "react-icons/ai";
import { AiFillCheckCircle } from "react-icons/ai";
import { RiTapeFill,  RiImageFill, RiFileListFill } from "react-icons/ri";
import countries from "../../JsonFiles/countries.json";
import states from "../../JsonFiles/states.json";
import currency from "../../JsonFiles/Currency.json";
import { countryCode } from "../../JsonFiles/ContryCodes";


const yearsData = [
  {
    type: "One",
    value: "1",
  },
  {
    type: "Two",
    value: "2",
  },
  {
    type: "Three",
    value: "3",
  },
  {
    type: "Four",
    value: "4",
  },
  {
    type: "Five",
    value: "5",
  },
  {
    type: "Six",
    value: "6",
  },
  {
    type: "Seven",
    value: "7",
  },
  {
    type: "Eight",
    value: "8",
  },
  {
    type: "Nine",
    value: "9",
  },
  {
    type: "Ten",
    value: "10",
  },
  {
    type: "Eleven",
    value: "11",
  },
  {
    type: "Twelve",
    value: "12",
  },
  {
    type: "More than twelve",
    value: "12+",
  },
];
let genderData1 = [
 
  "Signup or Login",
  "Profile ",
  "KYC",
  "Freelance Jobs",
  "Payment",
  "Career",
  "Feedback",
  "Complaints",
  "Other"
];
let reportaBug = [
  "General improvements",
  "Minor",
  "Major",
  "Critical",
];

const RiTextInputs = (props) => {
  const [passwordShow, setPasswordShow] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [mobileFocus, setMobileFocus] = useState();
  const [dropDownFocus, setDropDownFocus] = useState();
  const [countryNameFilter, setCountryNameFilter] = useState(false);

  const [focused, setFocused] = useState(false)
  const [passwordFocus, setPasswordFocus] = useState();
  const [flag, setFlag] = useState();
  const [searchItem, setSearchItem] = useState("India");
  const [countryDialCode, setCountryCode] = useState();
  const toggling = () => setIsOpen(!isOpen);

  let genderData = ["Male", "Female", "Others"];
let idProof=["Passport","Driving License ","Adhaar(UID)","Election Commission ID Card"]


  const onOptionClicked = (item, value, flag, countryDialCode, country) => {
    setSelectedOption(value);
    setSearchItem(value);
    setIsOpen(false);
    setFlag(flag);
    setCountryCode(countryDialCode);
    if (props.setCountry) {
      props.setCountry(value);
    }
    
    if (props.setCountryObject) {
      props.setCountryObject(item);
    }
  };

  searchItem === undefined && setSearchItem("");

  const DropdowonOptions = () => {
    switch (props.label || props.placeHolder) {
      case "Country":
        return (
          <>
            {props.displayData
              .filter((data) => {
                if (
                  searchItem === "" ||
                  searchItem === "India" ||
                  countryNameFilter
                ) {
                  return props.displayData;
                } else if (
                  data.name.toLowerCase().startsWith(searchItem.toLowerCase())
                ) {
                  return data.name;
                }
              })
              .map((item, index) => {
                return (
                  <>
                    <li
                      onClick={() =>
                        onOptionClicked(
                          item,
                          item.name,
                          `https://flagpedia.net/data/flags/normal/${item.code.toLowerCase()}.png`,
                          item.dial_code,
                          item.name,
                          item
                        )
                      }
                      className={classes.dropdownLi}
                      key={item}
                    >
                      <img
                        className={classes.flagImage}
                        src={`https://flagpedia.net/data/flags/normal/${item.code.toLowerCase()}.png`}
                        alt="Country_Flag_Logo"
                      />{" "}
                      {item.name}
                    </li>
                  </>
                );
              })}
          </>
        );
      case "Select Gender":
        return (
          <>
            <option value="" disabled>
              select your gender
            </option>
            {!!genderData &&
              genderData.map((item, id) => {
                return <option key={item}>{item}</option>;
              })}
          </>
        );
        case "Select Document Name":
        return (
          <>
            <option value="" disabled>
              select your document name
            </option>
            {!!idProof &&
              idProof.map((item, id) => {
                return <option key={item}>{item}</option>;
              })}
          </>
        );
      case "Gender":
        return (
          <>
            {!!props.displayData &&
              props.displayData.map((item, id) => {
                return <option key={item.id}>{item.type}</option>;
              })}
          </>
        );
      case "Language":
        return (
          <>
            {!!props.displayData &&
              props.displayData.map((item, id) => {
                return <option key={item.id}>{item.name}</option>;
              })}
          </>
        );
      case "Select Country":
        return (
          <>
            <option value="" disabled>
              Select country
            </option>
            {!!countries &&
              countries.map((item, id) => {
                return (
                  <>
                    <option key={item.id} value={item.country}>
                      {item.country}
                    </option>
                  </>
                );
              })}
          </>
        );

      case "Select State":
        return (
          <>
            <option value="">Select state</option>
            {!!states &&
              states.map((item, id) => {
                return (
                  item.country_name === props.selectedCountry && (
                    <option key={item.id} value={item.name}>
                      {item.name}
                    </option>
                  )
                );
              })}
          </>
        );
      case "Select City":
        return (
          <>
            <option value=""> Select city</option>
            {!!props.displayData &&
              props.displayData.map((item, id) => {
                return (
                  <option key={item.id} value={item.City}>
                    {item.City}
                  </option>
                );
              })}
          </>
        );
      case "Experience":
        return (
          <>
            <option value="0">{props.placeHolder}</option>
            {yearsData &&
              yearsData.map((item, id) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.type}
                  </option>
                );
              })}
          </>
        );
      case "Select Currency":
        return (
          <>
            <option value="0" disabled>
              Select your currency
            </option>
            {currency &&
              currency.map((item, id) => {
                return (
                  <option key={item.code} value={item.code}>
                    {item.name + " (" + item.symbolNative + ")"}
                  </option>
                );
              })}
          </>
        );
      case "Year":
        return (
          <>
            <option value="0">Select Year</option>
            {props.displayData &&
              props.displayData.map((item, id) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.type}
                  </option>
                );
              })}
          </>
        );
        case "Subject":
          return (
            <>
  
              <option value="">Select Subject</option>
              {!!genderData1 &&
                genderData1.map((item, id) => {
                  return <option key={item}>{item}</option>;
                })}
            </>
          );
          case "Select Bugtype":
            return (
              <>
                <option value="">Select Subject</option>
                {!!reportaBug &&
                  reportaBug.map((item, id) => {
                    return <option key={item}>{item}</option>;
                  })}
              </>
            );
      default:
        break;
    }
  };

  const inputType = (type) => {
    switch (type) {
      case "SingleLineInput":
        return (
          <div className={classes.formContainer}>
            <label className={classes.lableDesign}>{props.label}</label>
            <input
              type={props.type}
              readOnly={props.readOnly}
              name={props.label}
              onInput={props.onInput}
              className={classes.inputArea}
              disabled={props.disabled}
              placeholder={props.placeHolder}
              maxLength={props.maxLength}
              minLength={props.minLength}
              value={props.value}
              onChange={props.onChange}
              onFocus={props.onFocus}
              onKeyPress={props.onKeyPress}
            />
          </div>
        );
      case "SingleLineNameInput":
        return (
          <div className={classes.formContainer}>
            <label className={classes.lableDesign}>{props.label}</label>
            <input
              type={props.type}
              name={props.label}
              onInput={props.onInput}
              className={classes.inputArea_name}
              placeholder={props.placeHolder}
              maxLength={props.maxLength}
              minLength={props.minLength}
              value={props.value}
              onChange={props.onChange}
              onFocus={props.onFocus}
              onKeyPress={props.onKeyPress}
            />
          </div>
        );
        case "InputWithIcon":
          return (
            <div className={classes.formContainer}>
              <label className={classes.iconInputLabel}>{props.label}</label>
              <div className={classes.subLabel}>{props.subLabel}</div>
              <div
                className={
                  focused === true
                    ? classes.focuedIconInputDiv
                    : classes.iconInputDiv
                }
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              >
                <div className={classes.icon}>{props.currencyIcon}</div>
                <input
                  type={props.type}
                  name={props.label}
                  onInput={props.onInput}
                  className={classes.inputAreaIcon}
                  placeholder={props.placeHolder}
                  maxLength={props.maxLength}
                  minLength={props.minLength}
                  value={props.value}
                  onChange={props.onChange}
                  onFocus={props.onFocus}
                  onKeyPress={props.onKeyPress}
                />
              </div>
            </div>
          );

          case "SmallMultiLineInput":
            return (
              <div className={classes.formContainer}>
                <label className={classes.lableDesign}>{props.label}</label>
                <textarea
                  rows="5"
                  cols="20"
                  name="text"
                  onInput={props.onInput}
                  onChange={props.onChange}
                  value={props.value}
                  className={classes.inputAreaSmall}
                  placeholder={props.placeholder}
                  maxLength={props.maxLength}
                  onKeyPress={props.onKeyPress}
                />
              </div>
            );
            case "plainCheckBox":
        return(
        <div>
          <input
            type="checkbox"
            onClick={props.onClick}
            onChange={props.onChange}
            checked={props.checked}
            value={props.value}
            onInput={props.onInput}
            className = {classes.plainCheckBoxClass}
            onKeyPress={props.onKeyPress}
          />
        </div>
        );

        case "newRadio":
          return (
            <div>
              <input
                type={"radio"}
                placeholder={props.placeholder}
                name={props.name}
                id={props.id}
                checked={props.checked}
                onInput={props.onInput}
                value={props.value}
                onChange={props.onChange}
                className={classes.newRadioButton}
                onKeyPress={props.onKeyPress}
              />
              <label className={classes.newRadioLabel}>{props.label}</label>
            </div>
          );
      case "MultiLineInput":
        return (
          <div className={classes.formContainer}>
            <label className={classes.lableDesign}>{props.label}</label>
            <textarea
              rows="10"
              cols="30"
              name="text"
              onInput={props.onInput}
              onChange={props.onChange}
              value={props.value}
              className={classes.inputArea}
              placeholder={props.placeholder}
              maxLength={props.maxLength}
              onKeyPress={props.onKeyPress}
            />
          </div>
        );
      case "CountryDropdown":
        return (
          <div className={classes.formContainer}>
            <label className={classes.lableDesign}>{props.label}</label>
            <div onClick={props.onClick} value={props.value}>
            <div
                className={
                  dropDownFocus
                    ? classes.flagContainerFocus
                    : classes.flagContainer
                }
                onClick={()=> setCountryNameFilter(true)}
                onFocus={() => {
                  setDropDownFocus(true);
                 
                }}
                onBlur={() => {
                  setDropDownFocus(false);
                }}
              >
                <div>
                  {" "}
                  <img
                    src={
                      flag || "https://flagpedia.net/data/flags/normal/in.png"
                    }
                    className={classes.flagImage}
                    alt="Country_Flag_Logo"
                  />
                </div>
                <input
                  type="text"
                  onInput={() => {
                    setCountryNameFilter(false);
                    props.setCountry("");
                  }}
                  value={searchItem}
                  onClick={toggling}
                  className={classes.inputArea_Custom_dropdown}
                  onKeyPress={props.onKeyPress}
                ></input>
                {isOpen ? (
                  <BsCaretUpFill
                    color="gray"
                    onClick={toggling}
                    onFocus={() => {
                      setDropDownFocus(true);
                    }}
                    onBlur={() => {
                      setDropDownFocus(false);
                    }}
                  />
                ) : (
                  <BsCaretDownFill
                    color="gray"
                    onClick={toggling}
                    onFocus={() => {
                      setDropDownFocus(true);
                    }}
                    onBlur={() => {
                      setDropDownFocus(false);
                    }}
                  />
                )}
              </div>
              {isOpen && (
                <div className={classes.contriesList}>
                  <ul className={classes.uldropdown}>{DropdowonOptions()}</ul>
                </div>
              )}
            </div>
          </div>
        );
      case "Dropdown":
        return (
          <div className={classes.formContainer} ref={props.ref}>
            <label className={classes.lableDesign}>{props.label}</label>
            <select
              onChange={props.onChange}
              className={classes.inputArea_dropdown}
              value={props.value}
              disabled={props.disabled}
            >
              {DropdowonOptions()}
            </select>
          </div>
        );
      case "password":
        return (
          <div className={classes.formContainer}>
            <label className={classes.lableDesign}>{props.label}</label>
            <div
              className={
                passwordFocus
                  ? classes.input_fields_mobile
                  : classes.input_fields
              }
            >
              <input
                type={passwordShow ? "password" : "text"}
                onFocus={() => {
                  setPasswordFocus(true);
                }}
                onInput={props.onInput}
                onBlur={() => {
                  setPasswordFocus(false);
                }}
                name={props.label}
                className={classes.inputArea_withIcon}
                placeholder={props.placeHolder}
                value={props.value}
                onChange={props.onChange}
                onKeyPress={props.onKeyPress}
              />
              {passwordShow ? (
                <AiOutlineEye
                  onClick={() => setPasswordShow(!passwordShow)}
                  className={classes.eyeIcon}
                />
              ) : (
                <>
                  <BsEyeSlash
                    onClick={() => setPasswordShow(!passwordShow)}
                    className={classes.eyeIcon}
                  />
                </>
              )}
            </div>
          </div>
        );
      case "mobile":
        return (
          <div className={classes.formContainer}>
            <label className={classes.lableDesign}>{props.label}</label>
            <div
              className={
                mobileFocus ? classes.input_fields_mobile : classes.input_fields
              }
            >
              <div className={classes.mobileWrapper}>
              <select
                  value={props.dialCodeValue}
                  className={classes.dialCode}
                  onChange={props.onDialCodechange}
                >
                  {!!countryCode &&
                    countryCode.map((item, id) => {
                      return (
                        <option key={item.id} value={item.dial_code}>
                          {item.dial_code}
                        </option>
                      );
                    })}
                </select>
                <input
                  disabled={props.disabled}
                  type={"tel"}
                  onFocus={() => {
                    setMobileFocus(true);
                  }}
                  onBlur={() => {
                    setMobileFocus(false);
                  }}
                  onInput={props.onInput}
                  name={props.label}
                  className={classes.inputArea_withIcon}
                  placeholder={props.placeHolder}
                  value={props.value}
                  maxLength={props.maxLength}
                  minLength={props.minLength}
                  onChange={props.onChange}
                  onKeyPress={props.onKeyPress}
                />
              </div>
              {props.checkMObileVal ? (
                <AiFillCheckCircle className={classes.tickIcon} />
              ) : (
                ""
              )}
            </div>
          </div>
        );
      case "social":
        return (
          <div className={classes.formContainer}>
            <label className={classes.lableDesign}>{props.label}</label>
            <div className={classes.IconContainer}>
              {props.icon}

              <input
                type={props.type}
                name={props.label}
                onInput={props.onInput}
                className={classes.inputArea_nameSocial}
                placeholder={props.placeHolder}
                maxLength={props.maxLength}
                minLength={props.minLength}
                value={props.value}
                onChange={props.onChange}
                onFocus={props.onFocus}
                onKeyPress={props.onKeyPress}
              />
            </div>
          </div>
        );
         case "mobileWithDropdown":
        return (
          <div className={classes.formContainer}>
            <div className={classes.labelWithCheckBox}>
              <label className={classes.lableDesign}>{props.label}</label>
            </div>
            <div
              className={
                mobileFocus ? classes.input_fields_mobile : classes.input_fields
              }
            >
              <div className={classes.mobileWrapper}>
                <select
               value={props.dialCodeValue}
               className={classes.dialCode}
               onChange={props.onDialCodechange}
                >
                  {!!countryCode &&
                    countryCode.map((item, id) => {
                      return (
                        <option key={item.id} value={item.dial_code}>
                          {item.dial_code}
                        </option>
                      );
                    })}
                </select>
                <input
                  type={"tel"}
                  onFocus={() => {
                    setMobileFocus(true);
                  }}
                  onBlur={() => {
                    setMobileFocus(false);
                  }}
                  onInput={props.onInput}
                  name={props.label}
                  className={classes.inputArea_withIcon}
                  placeholder={props.placeHolder}
                  value={props.value}
                  maxLength={props.maxLength}
                  minLength={props.minLength}
                  onChange={props.onChange}
                  onKeyPress={props.onKeyPress}
                />
              </div>
              {props.checkMObileVal ? (
                <AiFillCheckCircle className={classes.tickIcon} />
              ) : (
                ""
              )}
              {props.checkWhatsAppMObile ? (
                <AiFillCheckCircle className={classes.tickIcon} />
              ) : (
                ""
              )}
              {props.checkAlternateMobile ? (
                <AiFillCheckCircle className={classes.tickIcon} />
              ) : (
                ""
              )}
              {props.button}
            </div>
          </div>
        );
      case "checkbox":
        return (
          <div onClick={props.onClick} className={classes.CheckboxStyle}>
            <div>
              <div className={classes.CheckBoxInput}>
                <input
                  type="checkbox"
                  onClick={props.onClick}
                  value={props.value}
                  onInput={props.onInput}
                  className={classes.checkBox_input}
                  checked={props.checked}
                  onKeyPress={props.onKeyPress}
                />
                <div className={classes.CheckBoxInput_Text}>{props.text}</div>
              </div>
              <div className={classes.CheckBox_Text}>{props.subText}</div>
            </div>
          </div>
        );
      case "radioOne":
        return (
          <div className={classes.formContainer}>
            <label className={classes.lableDesign}>{props.label}</label>
            <div className={classes.formContainer}>
              <div className={classes.inputArea}>
                <input
                  type={"radio"}
                  name={props.label}
                  placeholder={props.placeHolder}
                  onInput={props.onInput}
                  value={props.value}
                  onChange={props.onChange}
                  onKeyPress={props.onKeyPress}
                />
                <span>{props.value}</span>{" "}
              </div>
            </div>
          </div>
        );
      case "radioTwo":
        return (
          <div className={classes.formContainer}>
            <div className={classes.inputArea}>
              <input
                type={"radio"}
                name={props.name}
                placeholder={props.placeHolder}
                onInput={props.onInput}
                value={props.value}
                onChange={props.onChange}
                onKeyPress={props.onKeyPress}
              />
              <span>{props.value}</span>{" "}
            </div>
          </div>
        );
       
        case "file":
          return (
            <>
              <div className={classes.formContainer}>
                <label className={classes.lableDesign}>{props.label}</label>
                <label
                  className={classes.inputArea + " " + classes.form_upload_label}
                  vlaue={props.value}
                  for={props.label === "" ? props.placeHolder : props.label}
                >
                  {(props.placeHolder !== 'Audio/Video' && props.placeHolder !== 'Document' && props.placeHolder !== 'Image') && props.placeHolder}
                  {props.placeHolder === 'Audio/Video' && <div className={classes.uploadBoxIconContainer}><RiTapeFill size={20} className={classes.uploadBoxIcon} /><b>Audio/Video</b></div>}
                  {props.placeHolder === 'Document' && <div className={classes.uploadBoxIconContainer}><RiFileListFill size={20} className={classes.uploadBoxIcon} /><b>Document</b></div>}
                  {props.placeHolder === 'Image' && <div className={classes.uploadBoxIconContainer}><RiImageFill size={20} className={classes.uploadBoxIcon} /><b>Image</b></div>}
                  {props.icon}
                </label>
                <input
                  type="file"
                  hidden
                  id={props.label === "" ? props.placeHolder : props.label}
                  accept={props.accept}
                  onInput={props.onInput}
                  onChange={props.onChange}
                  disabled={props.disabled}
                  onKeyPress={props.onKeyPress}
                />
              </div>{" "}
            </>
          );
      case "upload":
        return (
          <>
            <div className={classes.formContainer}>
              <label className={classes.lableDesign}>{props.label}</label>
              <label className={classes.inputArea_upload} for={props.label}>
                {" "}
                <div className={classes.uploadIcon}>
                  <img
                    src={props.src}
                    alt=""
                    className={classes.uploadedImage}
                  />
                  {props.icon}
                  {props.placeHolder}
                </div>
              </label>
              <input
                type="file"
                id={props.label}
                onChange={props.onChange}
                className={classes.visibiltyCollapse}
                onKeyPress={props.onKeyPress}
              />
            </div>{" "}
          </>
        );
        case "file_screemshot":
          return (
            <>
              <div className={classes.formContainer}>
                <label className={classes.lableDesign}>{props.label}</label>
                <label
                  className={classes.inputArea + " " + classes.form_upload_label}
                  vlaue={props.value}
                  for="upload"
                >
                  {" "}
                  <input
                    type="file"
                    id="upload"
                    accept=".png, .jpg, .jpeg"
                    onChange={props.onChange}
                    onKeyPress={props.onKeyPress}
                  />
                  {props.icon}
                </label>
              </div>{" "}
            </>
          );
      case "date":
        return (
          <div ref={props.ref} className={classes.formContainer}>
            <label className={classes.lableDesign}>{props.label}</label>
            <div className={classes.inputAreaDateContainer}>
              <input
                type={props.type}
                name={props.label}
                max={props.max}
                className={classes.inputAreadate}
                placeholder={props.placeHolder}
                onInput={props.onInput}
                maxLength={props.maxLength}
                minLength={props.minLength}
                value={props.value}
                onChange={props.onChange}
                readOnly={props.readOnly}
                onKeyPress={props.onKeyPress}
                pattern="(?:19|20)(?:(?:[13579][26]|[02468][048])-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))|(?:[0-9]{2}-(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-8])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:29|30))|(?:(?:0[13578]|1[02])-31)))"
              />
            </div>
          </div>
        );

      default:
        console.log("Invalid Input");
        break;
    }
  };
  return <div>{inputType(props.input)}</div>;
};
export default RiTextInputs;
