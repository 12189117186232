import { GiFallingStar } from "react-icons/gi";
import { Link } from "react-router-dom";
import classes from "./ProfileHealth.module.css";

const ProfileHealth = (props) => {
  const profileRoundOff = (profilePercent) =>{
    let profileNum = parseInt(profilePercent)
    
    
    let roundOff = Math.round(profileNum)
 
    return roundOff;
    
    }
 
  return (
    <div className={classes.mainContainer}>
      {props.profileHealthData&&
      <div className={classes.nameAndDetailArea}>
        <div className={classes.fullName}>
          @{props.profileHealthData.userName} 
        </div>
       
        {props.profileHealthData.activePackage.Packagename?<div className={classes.memberShipContent}>{props.profileHealthData.activePackage.Packagename} MEMBERSHIP</div>
        :<Link to="/FreelancerPackage" className={classes.GetStarted}>BUY MEMBERSHIP</Link>}
       
      </div>
}
      <div className={classes.infoArea}>
        <div>
          {" "}
          <GiFallingStar className={classes.infoAreaIcon} />{" "}
        </div>
        <div className={classes.hireAssistant}>
          <div> Put your hiring on auto-pilot </div>
          <Link to="/HireAssistent">
            <div>Hire Rozgaar Assistant </div>
          </Link>
        </div>
      </div>

      <div>
        <div className={classes.headingContainer}>
          <div className={classes.accontStatusHeading}>
            Achieve all-star profile
          </div>
          <div>
            <div className={classes.accontStatussub}>
              {" "}
              Complete the recommended sections for relevant freelancers and
              receive more requirement views{" "}
            </div>
          </div>
        </div>
        <div className={classes.progressbar}>
          <div
            style={{
              width:  props.profileHealthData.ProfileCompleted + "%", 
              backgroundColor: "rgb(184, 184, 255)",
             padding:5,
              fontSize: 12,
              fontWeight: "bold",
              paddingLeft: 10,
              justifyContent: "center",
            }}
          >
            {profileRoundOff(props.profileHealthData.ProfileCompleted)} %

          </div>
        </div>
        {props.profileHealthData.EmptyProfileFields.lenght &&
        <Link to="/workProfile" class="linkTag">
         
          <div className={classes.profileTask}>
            {" "}
             Add your  {props.profileHealthData.EmptyProfileFields[0]}  
          </div>
        </Link>
}
      </div>
    </div>
  );
};
export default ProfileHealth;
