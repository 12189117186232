import React from "react";
import classes from "./HireTypeWeb.module.css";

const HireTypeWeb = () => {
  return (
    <div className={classes.mainDiv}>
      <div className={classes.contentDiv}>
        <div className={classes.typeDiv}>
          One Time
          <br />
          Task
        </div>
        <div className={classes.borderDivFirst}></div>
        <div className={classes.paragraph}>
          Work remotely for one-time gig
          <br />
          E.g Work as a graphic designer to create a logo for a client
        </div>
      </div>
      <div className={classes.contentDiv}>
        <div className={classes.secondParagraph}>
        Work as freelancer on monthly term
          <br />
          E.g  Social Media handler to post for client everyday for 2 months 
        </div>
        <div className={classes.borderDivSecond}></div>
        <div className={classes.secondTypeDiv}>
          Monthly
          <br />
          Basis
        </div>
      </div>
      <div className={classes.contentDiv}>
        <div className={classes.typeDiv}>
          Contract
          <br />
          Work
        </div>
        <div className={classes.borderDivThird}></div>
        <div className={classes.paragraph}>
        Work for a short-term or a long-term project
          <br />
          E.g  React website developer to work on an E-commerce Project
        </div>
      </div>
      <div className={classes.contentDiv}>
        <div className={classes.secondParagraph}>
        Work as remote freelancer on commission
          <br />
          E.g POS agents for providing verified leads and earn on maturity 
        </div>
        <div className={classes.borderDivFourth}></div>
        <div className={classes.secondTypeDiv}>
          Commission
          <br />
          Based
        </div>
      </div>
    </div>
  );
};

export default HireTypeWeb;
