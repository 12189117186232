import React,{useEffect} from "react";
import classes from "./FutureWork.module.css";
import Loader from "react-loader-spinner";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { pageViewTracker } from "../../Components/GoogleTracking";
const ActionButton = React.lazy(() => import("../../Components/ActionButton"));
const RIFooter = React.lazy(() => import("../../Components/RIFooter"));

const FutureWork = () => {

  useEffect(() => {
   pageViewTracker();
  }, [])
  

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Best Remote work and freelance projects at RozgaarIndia.com via Times Ascent`}</title>
        <meta
          name="description"
          content={`Rozgaar India is one of India's Leading online service marketplace for remote work and freelance projects. You can find the best skilled online service providers at RozgaarIndia.com`}
        />
         <link rel="canonical" href="https://www.rozgaarindia.com/FutureWork" /> 
      </Helmet>

      <React.Suspense
        fallback={
          <div className="pageLoader">
            <Loader
              type="TailSpin"
              color="red"
              height={80}
              width={80}
              className="text-center my-5"
            />
          </div>
        }
      >
        <div>
          <div>
            <div className={classes.FutureWork_bannerovertext}>
              To connect the world with talented verified freelancers faster
              than ever before to collaborate, and get work done in a safe and
              secure online environment.
            </div>
            <img
              src="/assets/Futureworkassets/bannerimagemob.jpg"
              alt="futurework"
              className={classes.FutureWork_bannerimage}
            />
            <img
              src="/assets/Futureworkassets/bannerimageweb.jpg"
              alt="futurework"
              className={classes.FutureWork_bannerimageweb}
            />
          </div>
          <div className={classes.FutureWork_maindivsecond}>
            <div className={classes.FutureWork_maintext}>
              We are revolutionising the way people work and
              <br />
              debunk the archaic 9-to-5 work model
            </div>
            <div className={classes.FutureWork_borderbottomfirst}></div>
            <div className={classes.FutureWork_dflex}>
              <img
                src="/assets/Futureworkassets/firstimagemob.jpg"
                alt="futurework"
                className={classes.FutureWork_imagefirst}
              />
              <div className={classes.FutureWork_text}>
                Our mission is to simplify remote hiring providing most aligned
                freelancers for a day, month or a year fulfilling your
                professional needs.
                <div className={classes.FutureWork_borderbottomsecond}></div>
              </div>
              <img
                src="/assets/Futureworkassets/firstimageweb.jpg"
                alt="futurework"
                className={classes.FutureWork_imagefirstweb}
              />
            </div>
            <div className={classes.FutureWork_dflex}>
              <img
                src="/assets/Futureworkassets/secondimagemob.jpg"
                alt="futureimage"
                className={classes.FutureWork_imagefirstweb}
              />
              <img
                src="/assets/Futureworkassets//secondimageweb.jpg"
                alt="futureimage"
                className={classes.FutureWork_imagefirst}
              />
              <div className={classes.FutureWork_text}>
                Our easy-to-use platform enables you to manage off-balance
                sheet talented workers in a single click with confidence and
                trust so you can save time and focus on your success.
                <div className={classes.FutureWork_borderbottomthird}></div>
              </div>
            </div>
            <div className={classes.FutureWork_bgcolor}>
              <div className={classes.FutureWork_bgcolorwebone}>
                <div className={classes.FutureWork_lastheadingfirst}>
                Explore the world of {" "}
                </div>
                <div className={classes.FutureWork_lastheadingsecond}>
                Opportunities                
                </div>
              </div>
              <div className={classes.ActionButton}>
                {" "}
                <Link to={localStorage.getItem("userLoggedIn")?"/FreelancerWorkPlace":"/SignUp"} className={classes.PARBtn_Link}>
                  <ActionButton
                    buttonText="Join as a Freelancer"
                    buttonType={"small"}
                  />
                </Link>
              </div>
              <div className={classes.FutureWork_bgcolorwebtwo}>
                {" "}
                <Link to={localStorage.getItem("userLoggedIn")?"/FreelancerWorkPlace":"/SignUp"} className={classes.PARBtn_Link}>
                Join as a Freelancer
                </Link>
                <div className={classes.FutureWork_border}> </div>
              </div>
            </div>
          </div>
        </div>
        {/* <RIFooter /> */}
      </React.Suspense>
    </>
  );
};
export default FutureWork;
