import classes from './RIHeader.module.css'
import React, { useState, useEffect, useContext,useRef } from "react"
import { slide as Menu } from 'react-burger-menu'
import { FcSearch } from 'react-icons/fc';
import { IoIosPerson } from 'react-icons/io';
import { IoClose} from "react-icons/io5";
import { AiFillHome,  AiOutlinePoweroff, AiOutlineSearch } from "react-icons/ai";
import { MdDashboard, MdOutlineNotifications, MdListAlt } from "react-icons/md";
import {FaSignInAlt, FaSignOutAlt, FaQuestion } from 'react-icons/fa';
import { BsChatRightDots,BsTelephoneFill } from 'react-icons/bs';
import { BiMenuAltRight } from 'react-icons/bi';
import { useNavigate,Link } from 'react-router-dom';
import useOnClickOutside from '../Components/useOnClickOutside'
import ActionButton from './ActionButton';
import { AiTwotoneHome } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { UserContext } from '../Context/UserContext';
import ProfileIcon from './FreelancerHomePageComp/ProfileIcon';
import { encodeAndSendToChat } from '../App';
import SuccessModal from './FreelancerProfile/SuccessModal';


const RIHeaderMobile = (props) => {
    const { setIsUserLoggedIn } = useContext(UserContext)
    const dropdownRef = useRef(null)
    const [openMenu, setOpenMenu] = useState(false);
    const [menuClicked, setMenuClicked] = useState(false);
    const [userLoginStatus, setUserLoginStatus] = useState();
  const [modal, setModal] = useState(false);


    useOnClickOutside(dropdownRef, () => { setMenuClicked(false) });


    let navigate = useNavigate();
    var isMenuOpen = (state) => {
        if (state.isOpen === true) {
            setOpenMenu(true);
        }
        else {
            setOpenMenu(false)
        }
        return state.isOpen;
    };


    let userLoggedIn = localStorage.getItem('userLoggedIn');
    let FirstName = localStorage.getItem('FirstName');
    let LastName = localStorage.getItem('LastName');
    let UserName = localStorage.getItem('UserName');
    let ProfilePicture = localStorage.getItem('ProfilePicture');
    

    const Logout = () => {
        sessionStorage.removeItem("googleBiEmail")
        sessionStorage.removeItem("userID");
        sessionStorage.removeItem('USERMOBILENO')
        sessionStorage.removeItem('USEREMAIL')
        sessionStorage.removeItem('FirstName')
        sessionStorage.removeItem('LastName')
        localStorage.removeItem('IsLoginType')
        localStorage.removeItem('userLoggedIn')
        localStorage.removeItem("googleBiEmail")
        localStorage.removeItem('userID')
        localStorage.removeItem('USERMOBILENO')
        localStorage.removeItem('USEREMAIL')
        localStorage.removeItem('FirstName')
        localStorage.removeItem('LastName')
        localStorage.removeItem('UserName')
        localStorage.removeItem('ProfilePicture')
        localStorage.removeItem('ProfilePicture')
        localStorage.removeItem("freelancerProfileStatus")
        setIsUserLoggedIn(false)
        setMenuClicked(!menuClicked)
        navigate("/")
        setModal(false)

    }

    useEffect(() => {
        if (userLoggedIn) {
            setUserLoginStatus(true)
        }
        else {
            setUserLoginStatus(false)
        }
    }, []);

    const menuStatusHandler = () => {
        setMenuClicked(!menuClicked)
    }

    return (

        <>
  {modal && (
               <SuccessModal SuccessModalType={"LogoutModal"} YesButton={()=>Logout()} NoButton={()=>setModal(false)} BackdropClick={()=>setModal(false)}/>
            )}
            {props.type === "web" && !userLoggedIn ? <div className={classes.headerWeb}>
                <Link to="/" className={classes.linkDesignNone}>
                    <img src="/assets/IPassets/Rozgaar_Black_Logo.svg" alt="Rozgaar_India_Logo" className={classes.riLogo} width={150} height={60} loading="lazy" title='Rozgaar_India_Logo'/>
                </Link>
                <div className={classes.menuContainerTop}>
                    <div className={classes.topMenuHeads}>
                        <Link to="/HowToHire" onClick={() => { sessionStorage.setItem("afterAuthRedirectUrl", "/HowToHire"); sessionStorage.setItem("goBackRedirectUrl", "/") }} className={classes.linkDesignNone}>
                            <div className={classes.menubuttons}> How it works </div>
                        </Link>
                        <Link to="/ExploreSkills" className={classes.linkDesignNone}>
                            <div className={classes.menubuttons}> Explore Skills </div>
                        </Link>
                        <Link to={"/requirementList"} onClick={() => { sessionStorage.setItem("afterAuthRedirectUrl", "/requirementList"); sessionStorage.setItem("goBackRedirectUrl", "/") }} className={classes.linkDesignNone}>
                            <div className={classes.menubuttons}>Search Freelance Jobs </div>
                        </Link>
                    </div>
                    <div className={classes.topMenuHeadsRight}>
                        <Link to="/login" className={classes.linkDesignNone}>
                            <div className={classes.menubuttons}> Log In </div>
                        </Link>
                        <Link to="/signup" className={classes.PARBtn_Link}> <ActionButton buttonText="Sign Up" buttonType="signUp" /></Link>
                    </div>
                </div>
            </div>
                :
                props.type === "web" && userLoggedIn ? <div className={classes.headerWeb}>
                    <Link to="/" className={classes.linkDesignNone}>
                        <img src="/assets/IPassets/Rozgaar_Black_Logo.svg" alt="Rozgaar_India_Logo" className={classes.riLogo} width={150} height={60} loading="lazy" title='Rozgaar_India_Logo'/>
                    </Link>
                    <div className={classes.menuContainerTop}>
                        <div className={classes.topMenuHeads}>
                            <Link to="/ExploreSkills" className={classes.linkDesignNone}>
                                <div className={classes.menubuttons}> Explore Skills</div>
                            </Link>

                            <Link to={"/requirementList"} onClick={() => { sessionStorage.setItem("afterAuthRedirectUrl", "/requirementList"); sessionStorage.setItem("goBackRedirectUrl", "/") }} className={classes.linkDesignNone}>
                                <div className={classes.menubuttons}>Search Freelance Jobs</div>
                            </Link>

                        </div>
                        <div className={classes.topMenuHeadsRight}>
                            <div className={classes.menubuttons}> <BsChatRightDots onClick={() => { encodeAndSendToChat(localStorage.getItem("userID")) }} />

                            </div>
                            <Link to="/notification" className={classes.linkDesignNone}>
                                <div className={classes.menubuttons}> <MdOutlineNotifications size={'25px'} />    </div>
                            </Link>
                            <div className={classes.menubuttons} onClick={() => menuStatusHandler()} > <ProfileIcon
                                size={"25px"}
                                FirstName={FirstName}
                                LastName={LastName}
                                ProfilePicture={ProfilePicture}
                            /></div>

                        </div>

                        <div ref={dropdownRef} className={menuClicked === true ? classes.menuDropdown : classes.menuDropdownHide}>
                            <Link to='/FreelancerWorkPlace' onClick={() => { menuStatusHandler(); sessionStorage.setItem("afterAuthRedirectUrl", "/FreelancerWorkPlace") }} className={classes.linkTag}>
                                <div className={classes.iconAndText}>
                                    <MdDashboard />
                                    <div className={classes.userMenuOptions}> Work Place </div>
                                </div>
                            </Link>
                            <Link to='/myApplications' onClick={() => { menuStatusHandler(); sessionStorage.setItem("afterAuthRedirectUrl", "/DashboardRequirement"); sessionStorage.setItem("goBackRedirectUrl", "/") }} className={classes.linkTag}>
                                <div className={classes.iconAndText}>
                                    <MdListAlt />
                                    <div className={classes.userMenuOptions}> My Applications</div>
                                </div>
                            </Link>
                            <Link to='/requirementList' onClick={() => { menuStatusHandler(); sessionStorage.setItem("afterAuthRedirectUrl", "/requirementList"); sessionStorage.setItem("goBackRedirectUrl", "/") }} className={classes.linkTag}>
                                <div className={classes.iconAndText}>
                                    <FcSearch />
                                    <div className={classes.userMenuOptions}> Search Freelance Jobs</div>
                                </div>
                            </Link>
                            <Link to='/workProfile' onClick={() => { menuStatusHandler(); sessionStorage.setItem("afterAuthRedirectUrl", "/workProfile"); sessionStorage.setItem("goBackRedirectUrl", "/") }} className={classes.linkTag} ></Link>
                            <Link to='/workProfile' onClick={() => { menuStatusHandler(); sessionStorage.setItem("afterAuthRedirectUrl", "/workProfile"); sessionStorage.setItem("goBackRedirectUrl", "/") }} className={classes.linkTag} >
                                <div className={classes.iconAndText}>
                                    <IoIosPerson />
                                    <div className={classes.userMenuOptions}>Profile </div>
                                </div>
                            </Link>


                            <div className={classes.iconAndText} onClick={() => {setModal(true) }}>
                                <AiOutlinePoweroff />
                                <div className={classes.userMenuOptions}> Logout</div>
                            </div>


                        </div>

                    </div>
                </div>
                    :
                    <>
                        <Menu isOpen={openMenu} onStateChange={isMenuOpen} >
                            <div className={classes.menuWrapper}>
                                <div className={classes.menuNav_contaner}>
                                    {userLoggedIn ? <>
                                        <div className={classes.drawer_menu}>
                                            <div className={classes.drawer_menu_header}>
                                                <div className={classes.close_button}>   <IoClose size="24px" onClick={() => { setOpenMenu(false) }} /></div>
                                                <div className={classes.menu_profile}>
                                                    <ProfileIcon
                                                        size={"25px"}
                                                        FirstName={FirstName}
                                                        LastName={LastName}
                                                        ProfilePicture={ProfilePicture}
                                                    />
                                                    <div >
                                                        <h2 className={classes.menuProfile_name}>{UserName}</h2>
                                                        <button onClick={() => { navigate("/workProfile"); setOpenMenu(false) }} className={classes.menuProfile_button}>Update Profile</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.menuNav}>
                                            <div className={classes.link_container}>
                                                <AiTwotoneHome className={classes.menu_icon} />
                                                <Link
                                                    onClick={() => {
                                                        setOpenMenu(false);
                                                    }}
                                                    to="/"
                                                    className={classes.link}
                                                >
                                                    <h3>Home</h3>
                                                </Link>
                                            </div>
                                            <div className={classes.link_container}>
                                                <MdDashboard className={classes.menu_icon} />
                                                <Link
                                                    onClick={() => {
                                                        setOpenMenu(false);
                                                    }}
                                                    to="/FreelancerWorkPlace"
                                                    className={classes.link}
                                                >
                                                    <h3>Work Place</h3>
                                                </Link>
                                            </div>
                                            <div className={classes.link_container}>
                                                <MdListAlt className={classes.menu_icon} />
                                                <Link
                                                    onClick={() => {
                                                        setOpenMenu(false);
                                                    }}
                                                    to="/myApplications"
                                                    className={classes.link}
                                                >
                                                    <h3>My Application</h3>
                                                </Link>
                                            </div>
                                            <div className={classes.link_container}>
                                                <AiFillHome className={classes.menu_icon} />
                                                <Link
                                                    onClick={() => {
                                                        setOpenMenu(false);
                                                    }}
                                                    to="/ExploreSkills"
                                                    className={classes.link}
                                                >
                                                    <h3>Explore Skills</h3>
                                                </Link>
                                            </div>
                                            <div className={classes.link_container}>
                                                <AiOutlineSearch className={classes.menu_icon} />
                                                <Link
                                                    onClick={() => {
                                                        setOpenMenu(false);
                                                    }}
                                                    to="/requirementList"
                                                    className={classes.link}
                                                >
                                                    <h3>Search freelancer jobs</h3>
                                                </Link>
                                            </div>
                                            <div className={classes.link_container}>
                                                <FaUserAlt className={classes.menu_icon} />
                                                <Link
                                                    onClick={() => {
                                                        setOpenMenu(false);
                                                    }}
                                                    to="/workProfile"
                                                    className={classes.link}
                                                >
                                                    <h3>Profile</h3>
                                                </Link>
                                            </div>


                                        </div>
                                        <div className={classes.border}></div>
                                        <div className={classes.menuNav}>
                                            <div className={classes.link_container}>
                                                <FaSignOutAlt className={classes.menu_icon} />
                                                <div className={classes.link_bottom} onClick={() => { setModal(true); setOpenMenu(false) }}><h3>Logout</h3></div>
                                            </div>

                                        </div>
                                    </> : <>
                                        <div className={classes.crossIcon}>   <IoClose size="24px" onClick={() => { setOpenMenu(false) }} /></div>
                                        <div className={classes.menu_nav}>
                                             <div className={classes.link_container}>
                                                <AiTwotoneHome className={classes.menu_icon} />
                                                <Link
                                                    onClick={() => {
                                                        setOpenMenu(false);
                                                    }}
                                                    to="/"
                                                    className={classes.link}
                                                >
                                                    <h3>Home</h3>
                                                </Link>
                                            </div>
                                              <div className={classes.link_container}>
                                                <MdListAlt className={classes.menu_icon} />
                                                <Link
                                                    onClick={() => {
                                                        setOpenMenu(false);
                                                    }}
                                                    to="/myApplications"
                                                    className={classes.link}
                                                >
                                                    <h3>My Application</h3>
                                                </Link>
                                            </div>
                                            <div className={classes.link_container}>
                                                <FaQuestion className={classes.menu_icon} />
                                                <Link onClick={() => { setOpenMenu(false) }} to="/HowToHire"
                                                    className={classes.link}
                                                >
                                                    <h3>How it works</h3>
                                                </Link>
                                            </div>
                                            <div className={classes.link_container}>
                                                <AiOutlineSearch className={classes.menu_icon} />
                                                <Link to={"/requirementList"} onClick={() => { sessionStorage.setItem("afterAuthRedirectUrl", "/requirementList"); sessionStorage.setItem("goBackRedirectUrl", "/"); setOpenMenu(false) }}
                                                    className={classes.link}
                                                >
                                                    <h3>Search Freelance Jobs</h3>
                                                </Link>
                                            </div>
                                            <div className={classes.link_container}>
                                                <AiFillHome className={classes.menu_icon} />
                                                <Link
                                                    onClick={() => {
                                                        setOpenMenu(false);
                                                    }}
                                                    to="/ExploreSkills"
                                                    className={classes.link}
                                                >
                                                    <h3>Explore Skills</h3>
                                                </Link>
                                            </div>
                                            <div className={classes.link_container}>
                                                <BsTelephoneFill className={classes.menu_icon} />
                                                <Link onClick={() => { setOpenMenu(false) }} to="/ContactUs"
                                                    className={classes.link}
                                                >
                                                    <h3>Contact Us</h3>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className={classes.border}></div>
                                        <div className={classes.menuNav}>
                                            <div className={classes.link_container}>
                                                <FaSignInAlt className={classes.menu_icon} />
                                                <Link onClick={() => { setOpenMenu(false) }} to="/login" className={classes.link} ><h3>Login</h3></Link>
                                            </div>
                                            <div className={classes.link_container}>
                                                <FaSignOutAlt className={classes.menu_icon} />
                                                <Link onClick={() => { setOpenMenu(false) }} to="/signup" className={classes.link} ><h3>Signup</h3></Link>
                                            </div>

                                        </div></>}
                                </div>
                            </div>
                        </Menu>
                        {userLoggedIn ? <>  <div className={classes.headerMainContainer_Mobile} >
                            <div className={classes.logoAndMenuDiv}>   <BiMenuAltRight size="35" className={classes.hamburger} onClick={() => { setOpenMenu(!openMenu) }} />
                                <Link to="/" className={classes.linkDesignNone}>
                                    <img src="/assets/IPassets/Rozgaar_Black_Logo.svg" alt="Rozgaar_India_Logo" className={classes.riLogo_Mobile} width={110} height={35} loading="lazy" title='Rozgaar_India_Logo'/>
                                </Link>
                            </div>
                            <div className={classes.headerMobile}>
                                <div className={classes.menuContainerTopMobile}>
                                    <div className={classes.menubuttons} onClick={() => menuStatusHandler()}   > <ProfileIcon
                                        size={"25px"}
                                        FirstName={FirstName}
                                        LastName={LastName}
                                        ProfilePicture={ProfilePicture}
                                    /></div>
                                </div>
                            </div>
                        </div>
                            <div ref={dropdownRef} className={menuClicked === true ? classes.menuDropdown : classes.menuDropdownHide}>
                                <Link to='/FreelancerWorkPlace' onClick={() => { menuStatusHandler(); sessionStorage.setItem("afterAuthRedirectUrl", "/FreelancerWorkPlace"); sessionStorage.setItem("goBackRedirectUrl", "/") }} className={classes.linkTag}>
                                    <div className={classes.iconAndText}>
                                        <MdDashboard />
                                        <div className={classes.userMenuOptions}> Work Place </div>
                                    </div>
                                </Link>
                                <Link to='/myApplications' onClick={() => { menuStatusHandler(); sessionStorage.setItem("afterAuthRedirectUrl", "/DashboardRequirement"); sessionStorage.setItem("goBackRedirectUrl", "/") }} className={classes.linkTag}>
                                    <div className={classes.iconAndText}>
                                        <MdListAlt />
                                        <div className={classes.userMenuOptions}> My Applications</div>
                                    </div>
                                </Link>
                                <Link to='/requirementList' onClick={() => { menuStatusHandler(); sessionStorage.setItem("afterAuthRedirectUrl", "/DashboardRequirement"); sessionStorage.setItem("goBackRedirectUrl", "/") }} className={classes.linkTag}>
                                    <div className={classes.iconAndText}>
                                        <MdListAlt />
                                        <div className={classes.userMenuOptions}> Search Freelance Jobs</div>
                                    </div>
                                </Link>
                                <Link to='/workProfile' onClick={() => { menuStatusHandler(); sessionStorage.setItem("afterAuthRedirectUrl", "/workProfile"); sessionStorage.setItem("goBackRedirectUrl", "/") }} className={classes.linkTag} >
                                    <div className={classes.iconAndText}>
                                        <MdDashboard />
                                        <div className={classes.userMenuOptions}>Profile </div>
                                    </div>
                                </Link>

                                <div className={classes.iconAndText} onClick={() => {setModal(true); setOpenMenu(false)}} >
                                    <AiOutlinePoweroff />
                                    <div className={classes.userMenuOptions}> Logout</div>
                                </div>
                            </div>
                        </>
                            :
                            <div className={classes.headerMainContainer_Mobile} >
                                <BiMenuAltRight size="35" className={classes.hamburger} onClick={() => { setOpenMenu(!openMenu) }} />
                                <Link to="/" className={classes.linkDesignNone}>
                                    <img src="/assets/IPassets/Rozgaar_Black_Logo.svg" alt="Rozgaar_India_Logo" className={classes.riLogo_Mobile} width={110} height={35} loading="lazy" title='Rozgaar_India_Logo'/>
                                </Link>
                                <Link to="/login" className={classes.JoinBtn_Link}><h1 className={classes.heading1}> Join</h1></Link>
                            </div>
                        }
                    </>
            }
        </>
    )
}

export default RIHeaderMobile;