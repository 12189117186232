import { useState, useEffect } from 'react';
import classes from './ProfileImage.module.css'
import ActionButton from '../ActionButton';
import { FaTwitter, FaLinkedin, FaGlobe, FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import countryjsonwithflag from '../../JsonFiles/countryjsonwithflag.json';
const ProfileImage = (props) => {

    const [countryFlag, setCountryFlag] = useState('');
    const [website, setWebsite] = useState('');
    const [twitter, setTwitter] = useState('');
    const [linkedIn, setLinkedIn] = useState('');
    const rating = props.profileImageData.rating || '0.0';

    useEffect(() => {
        countryjsonwithflag.forEach(item => {
            if (item.country === props.profileImageData.availableCountry)
                setCountryFlag(item.flag);
        });
        props.profileImageData.SocialMedia && props.profileImageData.SocialMedia.forEach(obj => {
            switch (obj.Name) {
                case 'Website':
                    setWebsite(obj.URL);
                    break;
                case 'Twitter':
                    setTwitter(obj.URL);
                    break;
                case 'LinkedIn':
                    setLinkedIn(obj.URL);
                    break;
                default:
                    break;
            }
        });

    }, [props])

    const StarRating = (rating) => {
        let fullStars = parseInt(rating.split('.')[0]);
        let halfStar = rating.split('.')[1] !== '0';
        let emptyStar = halfStar ? 5 - fullStars - 1 : 5 - fullStars;

        return <>{Array.apply(null, { length: fullStars }).map(() => (
            <FaStar color='#FDCA51' size={25} />
        ))}{halfStar && <FaStarHalfAlt color='#FDCA51' size={25} />}{Array.apply(null, { length: emptyStar }).map(() => (
            <FaRegStar color='#FDCA51' size={25} />
        ))}</>
    }

    return (
        <div className={classes.mainContainer}>


            <div className={classes.profilePictureNameContainer}>

                <div className={classes.profilePicture}>

                    <img src={props.profileImageData.profilePicture} alt="" className={classes.profilePicture} />

                </div>
                {props.hasOwnProperty('setEditSection') && <div className={classes.editBtn}>
                    <ActionButton buttonType={'edit'} buttonText={'Edit'} onClick={() => props.setEditSection('ProfileImage')} />
                </div>}

                <div className={classes.nameAndSkillContainer}>
                    <div className={classes.freelancerName}> {props.profileImageData.firstName + ' ' + props.profileImageData.lastName} </div>
                    <div className={classes.skillCat}> {props.profileImageData.primarySkill} </div>
                    <div className={classes.skillCat}>
                        <img className={classes.flagImage} src={countryFlag} alt="" />
                        {props.profileImageData.availableCity}, {props.profileImageData.availableCountry} </div>
                </div>
                {props.profileImageData.SocialMedia && <div className={classes.socialContainer}>
                    <div className={classes.mainHeading}>
                        Social Media
                    </div>
                    <div className={classes.iconContainer}>
                        {website !== '' && <a target='_blank' href={website} rel="noreferrer"><FaGlobe size={22} className={classes.socialIcon} color='#0e76a8' /></a>}
                        {twitter !== '' && <a target='_blank' href={twitter} rel="noreferrer"><FaTwitter size={22} className={classes.socialIcon} color='#00acee ' /></a>}
                        {linkedIn !== '' && <a target='_blank' href={linkedIn} rel="noreferrer"><FaLinkedin size={22} className={classes.socialIcon} color='#0e76a8' /></a>}
                    </div>
                </div>}
             
                {rating !== '' && <div className={classes.ratings}>
                    <div className={classes.mainHeading}>
                        Ratings
                    </div>
                    <div className={classes.ratingStar}>
                    {rating} {StarRating(rating)}
                    </div>
                </div>}
            </div>

        </div>

    )
}

export default ProfileImage;