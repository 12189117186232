import React, {useEffect} from 'react'
import classes from './Tab.module.css'
import { NavLink } from 'react-router-dom';

const Tab = ()=> {
    useEffect(() => {
    document.getElementById(window.location.pathname).scrollIntoView({"block":'nearest'})
    }, [])
    
  return (
    <div className={classes.mainContainer}>
        <NavLink id='/workProfile' to="/workProfile"
                  className={(navData) =>
                    navData.isActive ? classes.tabSelected : classes.tab
                  }
                >Work Profile</NavLink>
        <NavLink id='/personalDetails' to="/personalDetails"
                  className={(navData) =>
                    navData.isActive ? classes.tabSelected : classes.tab
                  }
                >Personal Details</NavLink>
        <NavLink id='/professionAndEducation' to="/professionAndEducation"
                  className={(navData) =>
                    navData.isActive ? classes.tabSelected : classes.tab
                  }
                >Profession & Education</NavLink>
        <NavLink id='/portfolio' to="/portfolio"
                  className={(navData) =>
                    navData.isActive ? classes.tabSelected : classes.tab
                  }
                >Portfolio</NavLink>
        <NavLink id="/companyDetails" to="/companyDetails"
                  className={(navData) =>
                    navData.isActive ? classes.tabSelected : classes.tab
                  }
                >Company Details</NavLink>
    </div>
  )
}

export default Tab