import React,{useEffect} from "react";
import ActionButton from "../../Components/ActionButton";
import classes from "./MyProfileSuccess.module.css";
import { BsCheckLg } from "react-icons/bs";
import { pageViewTracker } from '../../Components/GoogleTracking';

const MyProfileSuccess = (props) => {

  useEffect(() => {
   pageViewTracker();
  }, [])
  
  const Backdrop = () => {
    return <div className={classes.backdrop} />;
  };
  return (
    <>
      <Backdrop />
      <div className={classes.modal}>
        <div className={classes.ModalCheckIcon}>
          <BsCheckLg className={classes.modalcheck} />
        </div>
        <div className={classes.ModalCheckTextFirst}>Thank you</div>
        <div className={classes.ModalCheckTextSecond}>
          Your profile is under review.
        </div>
        <div className={classes.ModalCheckTextthird}>You will receive a notification <br/>as soon as your profile is live. <br/> You can start applying for work<br/>as soon as your profile is live
        </div>
        <div className={classes.ActionBtn}>
        <ActionButton buttonText={"Ok"} onClick={props.onClick} />
        </div>
      </div>
    </>
  );
};

export default MyProfileSuccess;
