import React, { useEffect } from "react";
import classes from "./ExploreSkills.module.css";
import Loader from "react-loader-spinner";
import { Helmet } from "react-helmet";
import ExploreSkillsNavbar from "../../Components/ExploreSkills/ExploreSkillsNavbar";
import RIFooter from "../../Components/RIFooter";
import ReactGA from 'react-ga';
import { pageViewTracker } from '../../Components/GoogleTracking';



const ExploreSkills = () => {

  useEffect(() => {
    ReactGA.initialize('UA-109083878-3', {
      debug: true,
      titleCase: false,
      gaOptions: {
        userId: 123
      }
    });
    ReactGA.pageview(window.location.pathname + window.location.search);

  }, [window]);

  useEffect(()=>{
    pageViewTracker();
  },[])


  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Best Remote work and freelance projects at RozgaarIndia.com via Times Ascent`}</title>
        <meta
          name="description"
          content={`Rozgaar India is one of India's Leading online service marketplace for remote work and freelance projects. You can find the best skilled online service providers at RozgaarIndia.com`}
        />
        <link rel="canonical" href="https://www.rozgaarindia.com/ExploreSkills" />
      </Helmet>
      <React.Suspense
        fallback={
          <div className="pageLoader">
            <Loader
              type="TailSpin"
              color="red"
              height={80}
              width={80}
              className="text-center my-5"
            />
          </div>
        }
      >
        <div className={classes.ExploreSkills_maincontainer}>
          <img
            src="/assets/ExploreSkill/exploreskillsbanner.jpg"
            alt="Explore_The_Most_In_Demand_Freelancer_Skills"
            title="Explore_The_Most_In_Demand_Freelancer_Skills"
            loading="lazy"
            className={classes.ExploreSkills_img}
            width={"100%"}
            height={146}
          />
          <img
            src="/assets/ExploreSkill/Skill_Web_banner.jpg"
            alt="Explore_The_Most_In_Demand_Freelancer_Skills"
            title="Explore_The_Most_In_Demand_Freelancer_Skills"
            loading="lazy"
            className={classes.ExploreSkills_imgweb}
            width= {"100%"}
            height= {280}
          />
          <div className={classes.ExploreSkills_maintext}>
            <h1 className={classes.ExploreSkills_heading}>
              Explore the most
              <br />
              In-demand Freelancer Skills
            </h1>
            <h2 className={classes.ExploreSkills_subheading}>
              Each skill includes a pool of talented
              <br />
              freelancers to hire on our
              <br />
              work marketplace
            </h2>
          </div>
        </div>
        <div className={classes.partnerCompanies_border}></div>
        <div className={classes.partnerCompanies_about}>
          <ExploreSkillsNavbar />
        </div>
        {/* <RIFooter /> */}
      </React.Suspense>
    </div>
  );
};

export default ExploreSkills;
