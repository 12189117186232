import React, { useEffect, useState } from 'react'
import classes from './MyApplications.module.css'
import { Helmet } from 'react-helmet';
import PublicRecRequirementCard from '../Components/ApplyRequirements/PublicRecRequirmentCard';
import NewFreelancerDashBoardMobile from '../Components/NewFreelancerDashBoard/NewFreelancerDashBoardMobile';
import NewFreelancerDashBoard from '../Components/NewFreelancerDashBoard/NewFreelancerDashboard';
import NoApplication from './NoApplication';
import { pageViewTracker } from '../Components/GoogleTracking';

const MyApplications = (props) => {

    const [postArequirementDetails, setPostArequirementDetails] = useState([]);
    


    useEffect(() => {
        MyRequirementApplicationsAPI();
        pageViewTracker();
    }, [])

    const userId = localStorage.getItem("userID")

    const MyRequirementApplicationsAPI = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Token 52e6faf229b4c8d1f6832edd4dde9a9d60a8c0d3");

        var formdata = new FormData();
        formdata.append("FreelancerId", userId);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(global.apiLink + "/api/freelancerapp/rozgaarapi/MyAaplication", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status_code === 200) {

                    setPostArequirementDetails(result.dataFreelancerdetail)
                   
                }
            })
            .catch(error => console.log('error', error));
    }

   

    

    const MobileWebHandlerSwitch = (device) => {
        switch (device) {
            case "Mobile":
                return (
                    <NewFreelancerDashBoardMobile>
                        <div className={classes.pageLayout}>

                            <div className={classes.sortAndRequirmentContainer}>

                                {postArequirementDetails.length ? <PublicRecRequirementCard requirementsCard={postArequirementDetails} />

                                    :
                                    <NoApplication Text={"You have not applied to any freelance work yet"} />
                                }
                            </div>



                        </div>
                    </NewFreelancerDashBoardMobile>
                );

            default:
                return (
                    <NewFreelancerDashBoard>
                        <div className={classes.pageLayout}>
                            <div className={classes.pageDivider}>

                                <div className={classes.sortAndRequirmentContainer}>

                                    <div className={classes.headingforApplication}> Your Applied freelancer work</div>
                                    {postArequirementDetails.length ? <PublicRecRequirementCard requirementsCard={postArequirementDetails} />
                                        :

                                        <NoApplication Text={"You have not applied to any freelance work yet"} />
                                    }
                                </div>

                            </div>
                        </div>
                    </NewFreelancerDashBoard>
                );
        }
    };



    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{`Best Remote work and freelance projects at RozgaarIndia.com via Times Ascent`}</title>
            <meta name="description" content={`Rozgaar India is one of India's Leading online service marketplace for remote work and freelance projects. You can find the best skilled online service providers at RozgaarIndia.com`} />
            <link rel="canonical" href="https://www.rozgaarindia.com/myApplications" /> 
        </Helmet>
        {MobileWebHandlerSwitch(props.device)}
    </>
    )
}

export default MyApplications